import { Box, Typography } from "@mui/material"
import React from "react"

import notFoundImage from '../../../../assets/icons/empty-state/NotFoundImage.png';

const NotFoundPage = () => {
    return (
        <Box id="notFoundPage">
            <Box className="not-found-image-container">
                <Box className="not-found-image" component="img" src={notFoundImage} />
            </Box>
            <Box className="not-found-text">
                <Typography>{"Oops! The Page You are Looking for Cannot be Found."}</Typography>
            </Box>
        </Box>
    )
}

export default NotFoundPage;