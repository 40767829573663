import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormTabs from "../../../../common/FormTabs";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddFolder from "../../../../components/Events/PhotoSharing/AddFolder";

import './styles.scss';
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import AddPhotos from "../../../../components/Events/PhotoSharing/AddPhotos";
import { EventPhotoSharingContext } from "../../../../contexts/EventPhotoSharing/EventPhotoSharingContext";
import { Tab, Tabs } from "@mui/material";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";
import Photos from "./Photos";
import { useLocation, useNavigate } from "react-router-dom";
import { EventPhotoSharingFolder } from "../../interfaces/event-photo-sharing_interface";

const PhotosPage = (): React.JSX.Element =>
{

    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const isHidden = currentpath?.includes('hidden');
    const isDefault = currentpath?.includes('default-photos');
    const isUserUploads = currentpath?.includes('user-uploads');
    const isApproved = currentpath?.includes('approved');

    const { eventId, folders, setRefresh, selectedFolder, setSelectedFolder, isLoading, photos, setOffset, setPhotos, fetchPhotosForFolder, hasMore, foldersRefresh, setFoldersRefresh, orgLink, eventLink, folderData, userUploadFolder } = useContext(EventPhotoSharingContext);

    // variable to check if the action button should display or not
    const shouldActionButtonDisplay = (folders?.length > 0 || currentpath?.includes('default-photos')) && !isHidden && !isUserUploads && !isApproved;

    // tab data to render the list of folders present - empty by default
    const folderTabs: {[key: string]: string | number | undefined}[] = [];
    // const folderTabsUnique = _.uniqBy(folderTabs, 'id');

    // tab data to select the filter for folders: hidden and visible - visible will be selected by default
    const hiddenTabs = [
        {
            tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'eye-slash']} /><p>Hidden</p></div>,
            selectedTab: currentpath?.includes('hidden'),
            navigation: (): void => 
            {
                if(currentpath?.includes('hidden'))
                {
                    setOffset(0);
                    return navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1].id)}`);
                }
                setOffset(0);
                navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1]?.id)}/hidden`);
            }
        }
    ];

    // tab data to render the default photos - unselected by default
    const defaultPhotosTabs = [
        {
            tabName: <div className="iconed-tab"><p>Default Photos</p></div>,
            selectedTab: currentpath?.includes('default-photos'),
            navigation: (): void => 
            {
                if(currentpath?.includes('default-photos'))
                {
                    setOffset(0);
                    return navigate(`/events/${eventId}/photo-sharing/${folders[folders?.length - 1].id}`);
                }
                setOffset(0);
                setSelectedFolder('');
                navigate(`/events/${eventId}/photo-sharing/default-photos`);
            }
        }
    ];

    // function to handle folder change
    const handleFolderChange = (value: any): void =>
    {
        setPhotos([]);
        setOffset(0);
        setSelectedFolder(value);
        navigate(`/events/${eventId}/photo-sharing/${value}`);
    };

    // function to open the add photos drawer
    const openAddPhotosDrawer = (): void =>
    {

        setTimeout((): void => 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                heading: <div><h3 style={{ color: 'var(--colors-text-text-primary-900, #101828)', fontSize: '18px', fontWeight: 600 }}>Upload Photos</h3><p style={{ color: 'var(--colors-text-text-tertiary-600, #475467)', fontSize: '14px', fontWeight: 400 }}>Please upload photos with file size 25MB. You can select a maximum of 200 files.</p></div>,
                component: <AddPhotos eventId={eventId} folderId={Number(selectedFolder) || ''} isDefault={isDefault} />,
                componentWidth: '600px',
                componentHeight: '600px'
            });

            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        });
    };

    // function to open the add folder drawer
    const openAddFolderDrawer = (folderData?: EventPhotoSharingFolder): void =>
    {
        setTimeout((): void => 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                iconHeading: 'folder',
                component: <AddFolder setRefresh={setRefresh} eventId={eventId} setFoldersRefresh={setFoldersRefresh} existingFolderData={folderData} />,
                componentWidth: '400px',
                componentHeight: '330'
            });

            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        });
    }

    if (folders && folders?.length > 0 && folderTabs?.length <= 5) 
    {
        folders.forEach((folder) => {
            folderTabs.unshift({
                name: folder.name,
                id: folder.id
            });
        });
    }

    return(
        <div id="eventPhotoSharing">

            <div className="heading-filter">
                {/* header */}
                <div id='inside-event-module-name-section'>
                    <div className="page-header">
                        <h3>{'Photo Sharing'}</h3>
                        <div className="header-copy-icon">
                            <FontAwesomeIcon icon={['fal', 'copy']} onClick={() => { 
                                navigator.clipboard.writeText(`${orgLink}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventLink}/photos`);  
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Link copied',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                            }} />
                        </div>
                    </div>
                </div>

                {/* Filters and other buttons container */}
                {folders && folders?.length > 0 && <div className="filters-and-buttons-container">

                    <div className="filters-wrapper">
                        {/* Folders */}
                        <div className="folder-tabs">
                            {folders && <Filters tabData={folderTabs} selectedTab={selectedFolder} maxWidth={'240px'} maxLength={5} selectedIcon={'folder-open'} defaultIcon={'folder'} handleFilter={(value) => { handleFolderChange(value) }} handleRemoveFilter={() => { setSelectedFolder(''); }} />}
                            {folders && folders?.length <=5 && <div className="add-folder-btn"><FontAwesomeIcon icon={['fal', 'plus']} onClick={() => { openAddFolderDrawer() }} /></div>} 
                        </div>

                        {/* Hidden filter */}
                        {folders?.length > 0 && <div>
                            <FormTabs tabs={hiddenTabs} />
                        </div>}

                        {/* Default photo filter */}
                        {folders?.length > 0 && <div>
                            <FormTabs tabs={defaultPhotosTabs} />
                        </div>}

                        {/* Upload filters */}
                        <div>
                            <FormTabs tabs={[
                                {
                                    tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'user-group']} /><p>User uploads</p></div>,
                                    selectedTab: currentpath?.includes('user-uploads'),
                                    navigation: () =>  { 
                                        // if(currentpath?.includes('user-uploads'))
                                        // {
                                        //     setOffset(0);
                                        //     return navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1].id)}`);
                                        // }
                                        setOffset(0);
                                        setSelectedFolder('');
                                        navigate(`/events/${eventId}/photo-sharing/user-uploads`);
                                    }
                                },
                                {
                                    tabName: <div className="iconed-tab"><FontAwesomeIcon icon={['fal', 'image']} /><p>Approved</p></div>,
                                    selectedTab: currentpath?.includes('approved'),
                                    navigation: () => { 
                                        // if(currentpath?.includes('approved'))
                                        // {
                                        //     setOffset(0);
                                        //     return navigate(`/events/${eventId}/photo-sharing/${selectedFolder || Number(folders[folders?.length - 1].id)}`);
                                        // }
                                        setOffset(0);
                                        setSelectedFolder('');
                                        navigate(`/events/${eventId}/photo-sharing/approved`);
                                    }
                                }
                            ]} 
                            />
                        </div>

                    </div>

                    {shouldActionButtonDisplay && <div>
                        <CustomButton name='Add Photos' btnType="secondary" onClick={openAddPhotosDrawer} />
                    </div>}
                </div>}
            </div>

            {selectedFolder ? <div className="header">
                <h3>{folderData?.name}</h3>
                <div className="action-buttons">
                    <FontAwesomeIcon onClick={() => {openAddFolderDrawer(folderData) }} className="edit-icon" icon={['fal', 'edit']} />
                </div>
            </div> : <div style={{ padding: '16px 0 0' }} ></div>}

            {photos?.length > 0 ? 
                <Photos setRefresh={setRefresh} setFoldersRefresh={setFoldersRefresh} photos={photos} hasMore={hasMore} eventId={eventId} folderId={isUserUploads || isApproved ? userUploadFolder?.id : selectedFolder || ''} fetchPhotosForFolder={fetchPhotosForFolder} setOffset={setOffset} />
                :
                <>
                    {(!isHidden && !isApproved && !isUserUploads) && <div className="empty-state">
                        <h3>{`Add Photos to get started !`}</h3>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CustomButton name={'Add Photos'} btnType="secondary" onClick={() => { openAddPhotosDrawer() }} />
                        </div>
                    </div>}
                </>
            }

            {
                (isUserUploads || isApproved) && photos && photos?.length === 0 ? 
                    <div className="user-uploads-empty-state">
                        <h3>{'No photos uploaded by users'}</h3>
                    </div> : null 
            }
        </div>
    )
};

export default PhotosPage;

export const Filters: React.FC<{ tabData: { [key: string]: string | number | undefined }[], selectedTab: string | number, handleFilter: (value: any) => void, maxWidth?: string, maxLength?: number, removeIcon?: IconName, handleRemoveFilter: (value: any) => void, defaultIcon: IconName, selectedIcon: IconName }> = (props) =>
{
    const { tabData, selectedTab, handleFilter, maxWidth, maxLength, defaultIcon, selectedIcon } = props;

    return(
        <div id="filters" style={{ borderRadius: tabData?.length <= (maxLength || 5) ? '8px 0 0 8px' : '8px' }}>
            <Tabs style={{ maxWidth: maxWidth || '' }} variant='scrollable' className="agenda-tabs-component" value={selectedTab || false} onChange={(event, value) => { handleFilter(value) }}>
                {tabData?.map((item, index) => (
                    <Tab key={index} label={item?.name} value={item.id || false} className="tab-component-button" icon={<FontAwesomeIcon icon={['fal', item?.id === selectedTab ? selectedIcon : defaultIcon]} /> || undefined} iconPosition="start" />
                ))}
            </Tabs>
        </div>
    )
};
