import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './styles.scss';
import { NumberFormatter } from '../../scripts/helpers';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import TableDataGrid from '../../common/TableDataGrid';
// import CustomSpinner from '../../common/CustomSpinner';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid-pro';
import MuiChip from '../../common/FormComponents/MuiChip';
import { createColumnHelper } from '@tanstack/react-table';
import { Budget } from './interfaces';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import BudgetsPageToolbar from '../../components/Budgets/BudgetsPageToolbar';

const BudgetAnalysisTable = (props): React.JSX.Element => {

    const [rows, setRows] = useState<Budget[]>([]);
    const [budgetData, setBudgetData] = useState<Budget[]>([]);
    const chartVisibility = props?.chartVisibility;
    // const budgetColumns: GridColDef[] = [
    //     {
    //         field: 'title', headerName: 'Event Name', editable: false, disableColumnMenu: true, flex: 1, renderCell: (params): React.ReactElement => 
    //         {
    //             return categoryName(params.row);
    //         },
    //         valueGetter: (params): string => 
    //         {
    //             return params?.row?.name;
    //         },
    //     },
    //     {
    //         field: 'category', headerName: 'Event Category', editable: false, disableColumnMenu: true, flex: 1, renderCell: (params): React.ReactElement => 
    //         {
    //             return eventCategory(params.row);
    //         }
    //     },
    //     {
    //         field: 'budget', headerName: 'Budget', editable: false, disableColumnMenu: true, renderCell: (params): React.ReactElement => 
    //         {
    //             return categoryBudget(params.row);
    //         }, flex: 1
    //     },
    //     {
    //         field: 'actualSpend', headerName: 'Expense', editable: false, disableColumnMenu: true, renderCell: (params): React.ReactElement => 
    //         {
    //             return actualSpending(params.row);
    //         }, flex: 1
    //     },
    //     {
    //         field: 'analysis', headerName: 'Analysis', editable: false, disableColumnMenu: true, renderCell: (params): React.ReactElement => 
    //         {
    //             const chipAnalysisColor = AnalysisColor(params.value);
    //             const chipLabelAnalysisColor = AnalysisChipColor(params.value);
    //             return budgetAnalysis(params.value, chipAnalysisColor, chipLabelAnalysisColor);
    //         }, flex: 1,
    //         valueGetter: (params): string => 
    //         {
    //             const row = params.row;
    //             if (Number(row.budget) > Number(row.actualSpend)) 
    //             {
    //                 return 'Underspend';
    //             }
    //             else if (Number(row.budget) < Number(row.actualSpend)) 
    //             {
    //                 return 'Overspend';
    //             }
    //             else 
    //             {
    //                 return 'In Budget';
    //             }
    //         },
    //     },
    // ];

    const categoryBudget = (row): React.ReactElement => {
        let budget;
        if (row.budget !== 0) {
            budget = row.budget;
        }
        return (
            <Box>
                <Typography className="budget-value">{!budget ? '-' : '$' + NumberFormatter(budget)}</Typography>
            </Box>
        );
    };

    const categoryName = (row): React.ReactElement => {
        let categoryName = '';
        if (row.name) {
            categoryName = row.name;
        }
        return (
            <Box>
                <Typography className="data-grid-bolded-cellContent">{categoryName}</Typography>
            </Box>
        );
    };

    const eventCategory = (row): React.ReactElement => {
        const eventCategory = row?.category && row?.category !== '' ? row?.category : '';
        return (
            <Box className="event-category">
                {eventCategory !== '' ? <Typography className="MuiDataGrid-cellContent">{eventCategory}</Typography> : <Typography>{'-'}</Typography>}
            </Box>
        );
    };

    const actualSpending = (row): React.ReactElement => {
        let actualSpent;
        if (row.actualSpend !== 0) {
            actualSpent = row.actualSpend;
        }
        return (
            <Box>
                <Typography className="MuiDataGrid-cellContent">{!actualSpent ? '-' : '$' + NumberFormatter(actualSpent)}</Typography>
            </Box>
        );
    };

    const budgetAnalysis = (data: string, chipAnalysisColor: 'green' | 'red' | 'yellow' | 'null', chipLabelAnalysisColor: string): React.ReactElement => {
        return (
            <Box className="budget-analysis-container">
                <MuiChip
                    chipColor={chipAnalysisColor}
                    label={data}
                />
            </Box>
        );
    };

    const AnalysisColor = (value: string): 'green' | 'red' | 'yellow' | 'null' => {
        switch (value) {
            case 'Underspend':
                return 'green';
            case 'Overspend':
                return 'red';
            case 'In Budget':
                return 'yellow';
            default:
                return 'null';
        }
    };

    const AnalysisChipColor = (value: string): 'red' | 'green' | 'yellow' | 'null' => {
        switch (value) {
            case 'Underspend':
                return 'red';
            case 'Overspend':
                return 'green';
            case 'In Budget':
                return 'yellow';
            default:
                return 'null';
        }
    };

    const columnHelper = createColumnHelper<Budget>();
    const budgetColumns = [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Event Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('category', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Event Category',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'category'
        }),
        columnHelper.accessor('budget', {
            cell: (row) => {
                return (<p className="cellContent">{row.row?.original?.budget && row.row?.original?.budget !== 0 ? `$${NumberFormatter(Number(row.row?.original?.budget))}` : '-'}</p>)
            },
            header: 'Budget',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'budget'
        }),
        columnHelper.accessor('actualSpend', {
            cell: (row) => {
                let actualSpent;
                if (row.row.original?.actualSpend !== 0) {
                    actualSpent = row.row.original?.actualSpend;
                }
                return (<p className="cellContent">{!actualSpent ? '-' : `$${NumberFormatter(row.getValue())}`}</p>)
            },
            header: 'Expense',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'actualSpend'
        }),
        columnHelper.accessor('analysis', {
            cell: ({ row }) => {
                let chipLabel: string = '';
                let chipColor: 'green' | 'red' | 'yellow' | 'null' = 'null';
                if (Number(row?.original?.actualSpend) < Number(row?.original?.budget)) {
                    chipLabel = 'Underspend';
                    chipColor = 'green';
                }
                else if (Number(row?.original?.actualSpend) > Number(row?.original?.budget)) {
                    chipLabel = 'Overspend';
                    chipColor = 'red';
                }
                else {
                    chipLabel = 'In Budget';
                    chipColor = 'yellow';
                }

                return (
                    <MuiChip
                        chipColor={chipColor}
                        label={chipLabel}
                    />
                );
            },
            header: 'Analysis',
            size: 200,
            sortingFn: (rowA, rowB) => {
                const valueA = Number(rowA.original?.budget) > Number(rowA.original?.actualSpend) ? 'Overspend' :
                    Number(rowA.original?.actualSpend) < Number(rowA.original?.budget) ? 'Underspend' : 'In Budget';
                const valueB = Number(rowB.original?.budget) > Number(rowB.original?.actualSpend) ? 'Overspend' :
                    Number(rowB.original?.actualSpend) < Number(rowB.original?.budget) ? 'Underspend' : 'In Budget';
                return valueA.localeCompare(valueB);
            },
            id: 'analysis'
        }),
    ];
    const [allColumns, setAllColumns] = useState(budgetColumns);

    useEffect(() => {
        if (props?.rows)
        {
            setRows(props?.rows);
            setBudgetData(props?.rows);
        }
    }, [props?.rows]);

    return (
        <div id='budgetTable'>
            {
                !props?.isEmpty ?
                    (
                        <div style={{ height: 'calc(100% - 58px)' }}>
                            <BudgetsPageToolbar 
                                columns={budgetColumns}
                                setColumns={setAllColumns}
                                rows={rows}
                                setRows={setBudgetData}
                                count={props?.totalBudgetCount}
                            />
                            <TanstackTable
                                data={budgetData}
                                initialColumns={allColumns}
                                rowCount={props?.totalBudgetCount}
                                pageSize={props?.pageSize}
                                currentPage={props?.currentPage - 1}
                                updateCurrentPage={props?.updateCurrentPage}
                                updatePageSize={props?.updatePageSize}
                                showSpinner={props?.showSpinner}
                                height={chartVisibility ? `calc(100% - 124px)` : `calc(100% - 72px)`}
                            />
                        </div>
                    ) :
                    (<TableEmptyComponent emptyImg={''} openDrawer={null} infoText={'No Budget Data'} subInfoText={''} buttonName={''} />)
                //  :
                // (<CustomSpinner height={'100%'} />)
            }
        </div>
    );
};

export default BudgetAnalysisTable;