import CouponsProvider from "../../../../../contexts/EventCoupons/EventCouponsContext";
import TicketsPageProvider from "../../../../../contexts/EventDetailsRegistration/TicketsPageContext";
import CouponsPage from "../../EventTicketCoupons/CouponsPage";
import EventRegistrationDetails from "../EventRegDetails";
import Ticket from "../Tickets/Ticket";
import UtmComponent from "../UTMTable";

import './styles.scss';

const BasicRegistrationsPage: React.FC<{eventId: string | number}> = ({ eventId }): React.JSX.Element =>
{
    return (
        <div id="basicRegistrations">
            {/* <div id='inside-event-module-name-section' style={{ borderBottom: '1px solid var(--Colors-Border-border-secondary, #EAECF0)' }}>
                {'Registrations'}
            </div> */}
            <div className="registrations-container">
                <EventRegistrationDetails eventId={Number(eventId)} />
                <TicketsPageProvider eventId={eventId}><Ticket eventId={eventId} /></TicketsPageProvider>
                <CouponsProvider eventId={eventId}><CouponsPage /></CouponsProvider>
                <UtmComponent eventId={eventId} />
            </div>
        </div>
    )
};

export default BasicRegistrationsPage;