import { useState } from "react";
import FormTabs from "../../common/FormTabs";
import ColumnVisibilityDropdown from "../../common/TanstackTable/ColumnVisibilityDropdown";
import TableFilter from "../../common/TanstackTable/TableFilter";
import { RegistrationForm, RegistrationFormType } from "../../pages/Registration/interfaces/registration-form_interface";

import './styles.scss';

const RegistrationsPageToolbar: React.FC<{ count: number, rows: RegistrationForm[], setRows: React.SetStateAction<React.Dispatch<RegistrationForm[]>>, columns: any[], setColumns: React.SetStateAction<React.Dispatch<any[]>> }> = ({ setRows, count, rows, columns, setColumns }): React.JSX.Element =>
    {
        const columnOptions = columns
            .filter(column => column.id !== 'actions')
            .map(column => {
                return {
                    label: typeof(column?.header) === 'string' ? column?.header : column?.meta?.headerName,
                    value: column?.accessorKey || column?.id
                } 
            }
        );
    
        const [filters, setFilters] = useState([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
    
        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => 
        {
            const value = event.target.value;
            const newFilters = [...filters];
            newFilters[index].inputValue = value;
            setFilters(newFilters);
            // filterFn(newFilters);
        };
    
        const handleColumnChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => 
        {
            const value = event.target.value;
            const newFilters = [...filters];
            newFilters[index].selectedColumn = value;
            setFilters(newFilters);
            // filterFn(newFilters);
        };
    
        const addFilter = () => 
        {
            const selectedColumns = filters.map(filter => filter.selectedColumn);
            const availableColumns = columnOptions.filter(option => !selectedColumns.includes(option.value));
            const defaultColumn = availableColumns.length > 0 ? availableColumns[0].value : '';
            setFilters([...filters, { selectedColumn: defaultColumn, inputValue: '' }]);
        };
    
        const removeFilter = (index: number) => 
        {
            const newFilters = filters.filter((_, i) => i !== index);
            setFilters(newFilters);
            // filterFn(newFilters);
        };
    
        const clearFilters = () => 
        {
            setFilters([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
            // filterFn([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
        };

        const getRegistrationTypes = (form: RegistrationForm) => {
            if(form?.type === RegistrationFormType.REGISTRATION_FORM)
            {
                return 'Event Registrations';
            }
            else if(form?.type === RegistrationFormType.LEAD_CAPTURE)
            {
                return 'Lead Capture';
            }
        };

        const customFilterFunctions = {
            type: (form: RegistrationForm, value: string | number) => {
                const type = getRegistrationTypes(form);
                return type ? type.toLowerCase().includes(String(value).toLowerCase()) : false;
            },
        };
    
        return (
            <div className="registrations-page-toolbar">
                <FormTabs 
                    tabs={[{
                        tabName: 'All',
                        count: count,
                        navigation: () => {},
                        selectedTab: true,
                    }]}
                />
                <div className="toolbar-actions">
                    <TableFilter 
                        rows={rows}
                        columnOptions={columnOptions}
                        setRows={setRows}
                        filters={filters}
                        handleColumnChange={handleColumnChange}
                        handleInputChange={handleInputChange}
                        // filterFn={() => filterFn(filters)}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        clearFilters={clearFilters}
                        customFilterFunctions={customFilterFunctions}
                    />
                    <ColumnVisibilityDropdown 
                        columns={columns}
                        setColumns={setColumns}
                    />
                </div>
            </div>
        )
    };
    
    export default RegistrationsPageToolbar;