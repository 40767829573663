/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../../scripts/event-bus';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { updateRegistrationForm } from '../../../scripts/apis/registrationForm';
import APP_CONSTANTS from '../../../scripts/constants';
import { useDispatch } from 'react-redux';
import { resetFormField } from '../../../redux/registrationsForms/FormComponentDisplayField';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import LexicalEditor from '../../../lexical';
import { CustomFieldType } from '../enum/custom-field.enum';

interface IGDPRComponentOptions {
    paragraph?: string;
    fieldOptions?: {
        name: string;
        value: string;
    },
    fieldMandatory: '1';
}

interface IGdprLabelOptions {
    label: string;
    option: IGDPRComponentOptions;

}

interface IGDPRSideDrawerProps {
    drawerTitle: string;
    gdprContentType: number;
    formId: string | number;
    csrfTokenData: string;
    setRefresh: (refresh: boolean) => void;
    componentType: number;
    existingComponentData?: any;
}

const GdprSideDrawer = ({ drawerTitle, gdprContentType, formId, csrfTokenData, setRefresh, componentType, existingComponentData }: IGDPRSideDrawerProps): React.JSX.Element => 
{
    const dispatch = useDispatch();
    const orgName = LocalStorage.get('@Org')?.name;

    const [spinner, setSpinner] = useState(false);

    const [backBtnClicked, setBackBtnClicked] = useState<boolean>(false);

    const [gdprOptions, setGdprOptions] = useState<IGdprLabelOptions[]>([]);
   

    useEffect((): void => 
    {
        if (backBtnClicked) 
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: 0
            });
            setBackBtnClicked(false);
            dispatch(resetFormField());
            eventBus.dispatch('event-update-form-component', true);
        }

    }, [backBtnClicked]);

    useEffect((): void => 
    {
       
        if (!existingComponentData) 
        {
            if (gdprContentType === CustomFieldType.GDPR_COMMUNICATION_PROCESSING) 
            {
                setGdprOptions(componentGDPR1);
            }
            else if (gdprContentType === CustomFieldType.GDPR_LEGITIMATE_INTEREST) 
            {
                setGdprOptions(componentGDPR2);
            }
            else if (gdprContentType === CustomFieldType.GDPR_FORM_SUBMIT_AS_CONSENT) 
            {
                setGdprOptions(componentGDPR3);
            }
            else 
            {
                setGdprOptions([]);
            }

        }
        else 
        {
            if (Number(existingComponentData.type) === CustomFieldType.GDPR_COMMUNICATION_PROCESSING) 
            {
                const gdprOptions: IGdprLabelOptions[] = [
                    {
                        label: 'Communication Consent',
                        option: {
                            paragraph: existingComponentData?.gdpr[0]?.paragraph,
                            fieldOptions: undefined,
                            fieldMandatory: '1',
                        }
                    },
                    {
                        label: 'Consent to communication checkbox',
                        option: {
                            fieldOptions: existingComponentData?.gdpr[1]?.fieldOptions,
                            fieldMandatory: '1',
                        },
                    },
                    {
                        label: 'Privacy text',
                        option: {
                            paragraph: existingComponentData?.gdpr[2]?.paragraph,
                            fieldOptions: undefined,
                            fieldMandatory: '1',
                        },
                    },
                    {
                        label: 'Process consent',
                        option: {
                            paragraph: existingComponentData?.gdpr[3]?.paragraph,
                            fieldOptions: undefined,
                            fieldMandatory: '1',
                        }
                    },
                    {
                        label: 'Consent to process checkbox',
                        option: {
                            fieldMandatory: '1',
                            fieldOptions: existingComponentData?.gdpr[4]?.fieldOptions,
                        }
                    }
                ];

                setGdprOptions(gdprOptions);

            }
            else if (Number(existingComponentData.type) === CustomFieldType.GDPR_LEGITIMATE_INTEREST) 
            {
                const gdprOptions: IGdprLabelOptions[] = [
                    {
                        label: 'Consent to communication checkbox',
                        option: {
                            fieldOptions: existingComponentData?.gdpr[0]?.fieldOptions,
                            fieldMandatory: '1',
                        },
                    },
                ];
                setGdprOptions(gdprOptions);
            }
            else if (Number(existingComponentData.type) === CustomFieldType.GDPR_FORM_SUBMIT_AS_CONSENT) 
            {
                const gdprOptions: IGdprLabelOptions[] = [
                    {
                        label: 'Communication Consent',
                        option: {
                            paragraph: existingComponentData?.gdpr[0]?.paragraph,
                            fieldOptions: undefined,
                            fieldMandatory: '1',
                        }
                    },
                    {
                        label: 'Consent to communication checkbox',
                        option: {
                            fieldOptions: existingComponentData?.gdpr[1]?.fieldOptions,
                            fieldMandatory: '1',
                        },
                    },
                    {
                        label: 'Privacy text',
                        option: {
                            paragraph: existingComponentData?.gdpr[2]?.paragraph,
                            fieldOptions: undefined,
                            fieldMandatory: '1',
                        },
                    },
                    {
                        label: 'Consent to process checkbox',
                        option: {
                            fieldMandatory: '1',
                            paragraph: existingComponentData?.gdpr[3]?.paragraph,
                            fieldOptions: undefined
                        }
                    }
                ];
                setGdprOptions(gdprOptions);
            }
        }
    }, [gdprContentType]);

    const componentGDPR1: IGdprLabelOptions[] = [
        {
            label: 'Communication Consent',
            option: {
                paragraph: orgName + ' is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you',
                fieldOptions: undefined,
                fieldMandatory: '1',
            }
        },
        {
            label: 'Consent to communication checkbox',
            option: {
                fieldOptions:
                {
                    name: 'I agree to receive other communications from ' + orgName,
                    value: 'I agree to receive other communications from ' + orgName,
                },
                fieldMandatory: '1',
            },
        },
        {
            label: 'Privacy text',
            option: {
                paragraph: 'You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.',
                fieldOptions: undefined,
                fieldMandatory: '1',
            },
        },
        {
            label: 'Process consent',
            option: {
                paragraph: 'In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below.',
                fieldOptions: undefined,
                fieldMandatory: '1',
            }
        },
        {
            label: 'Consent to process checkbox',
            option: {
                fieldMandatory: '1',
                fieldOptions:
                {
                    name: 'I agree to allow ' + orgName + ' to store and process my personal data.',
                    value: 'I agree to allow ' + orgName + ' to store and process my personal data.',
                },
            }
        }
    ];

    const componentGDPR2: IGdprLabelOptions[] = [
        {
            label: 'Consent to communication checkbox',
            option: {
                fieldOptions:
                {
                    name: 'I agree to receive other communications from ' + orgName,
                    value: 'I agree to receive other communications from ' + orgName,
                },
                fieldMandatory: '1',
            },
        },
    ];

    const componentGDPR3: IGdprLabelOptions[] = [
        {
            label: 'Communication Consent',
            option: {
                paragraph: orgName + ' is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you',
                fieldOptions: undefined,
                fieldMandatory: '1',
            }
        },
        {
            label: 'Consent to communication checkbox',
            option: {
                fieldOptions:
                {
                    name: 'I agree to receive other communications from ' + orgName,
                    value: 'I agree to receive other communications from ' + orgName,
                },
                fieldMandatory: '1',
            },
        },
        {
            label: 'Privacy text',
            option: {
                paragraph: 'By clicking submit below, you consent to allow ' + orgName + ' to store and process the personal information submitted above to provide you the content requested.',
                fieldOptions: undefined,
                fieldMandatory: '1',
            }
        },
        {
            label: 'Consent to process checkbox',
            option: {
                fieldMandatory: '1',
                paragraph: 'By clicking submit below, you consent to allow Eventhq-Stg to store and process the personal information submitted above to provide you the content requested.',
                fieldOptions: undefined
            }
        }
    ];

    const handleSubmit = (): void => 
    {
        // const componentDetails = {
        //     type: componentType,
        //     fieldDisabled: CustomFieldDisabled.NOT_DISABLED,
        //     fieldMandatory: CustomFieldMandatory.MANDATORY,
        //     label: '',
        //     placeholder: '',
        //     componentGDPR: gdprOptions.map((item): any => 
        //     {
        //         return {
        //             paragraph: item?.option?.paragraph ? item.option.paragraph : null,
        //             fieldOptions: item?.option?.fieldOptions ? item.option.fieldOptions : null,
        //             fieldMandatory: item.option.fieldMandatory,
        //         };

        //     })
        // };
        setSpinner(true);
        updateForm();
    };

    const updateForm = async (): Promise<void> => 
    {
        const fieldGDPR = gdprOptions.map((item): any => {
            return {
                paragraph: item?.option?.paragraph ? item.option.paragraph : null,
                fieldOptions: item?.option?.fieldOptions ? item.option.fieldOptions : null,
                fieldMandatory: item.option.fieldMandatory,
            };
        })
        try 
        {
            const componentUpdated = await updateRegistrationForm(Number(formId), {formGDPR: { type: gdprContentType , gdpr: fieldGDPR }});

            if (componentUpdated) 
            {
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Component successfully saved',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    return (
        <Box id='gdprSideDrawer'>
            <Box padding={'13px 24px'} className="drawer-header">
                <Box className="drawer-inner-cont" onClick={():void => 
                    {
                        setBackBtnClicked(true);
                        
                    }}>
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} className='back-icon' />
                    <Typography className='component-type-name'>{drawerTitle}</Typography>
                </Box>
            </Box>
            <Box className="drawer-content">
                {gdprOptions.length > 0 && gdprOptions.map((gdprOption: IGdprLabelOptions, index: number): React.JSX.Element => 
                {
                    return (
                        <Box className="editor-block" key={index}>
                            <Typography className='form-label'>{gdprOption.label}</Typography>
                            {
                                gdprOption.option?.paragraph && <LexicalEditor label='' toolBarOptions={['Bold', 'Italic', 'Link', 'Underline']} value={gdprOption.option?.paragraph} handleChange={(html, json) => {
                                    setGdprOptions((prevState: IGdprLabelOptions[]): IGdprLabelOptions[] => 
                                    {
                                        const tempData = [...prevState];
                                        tempData[index].option.paragraph = html;
                                        return tempData;
                                    });
                                }} />
                            }
                            {gdprOption.option?.fieldOptions?.name && gdprOption.option?.fieldOptions?.value && (
                                <LexicalEditor toolBarOptions={['Bold', 'Italic', 'Link', 'Underline']} value={gdprOption.option?.fieldOptions.value} handleChange={(html, json) => {
                                    setGdprOptions((prevState):IGdprLabelOptions[] => 
                                    {
                                        const newState = [...prevState]; 
                                        const updatedOption = {
                                            ...newState[index].option,
                                            fieldOptions: {
                                                ...newState[index].option?.fieldOptions, 
                                                name: html,
                                                value: html
                                            }
                                        };
                                        newState[index] = {
                                            ...newState[index],
                                            option: updatedOption
                                        };
                                        return newState;
                                    });
                                }} />
                            )}


                        </Box>
                    );
                })}



            </Box>
            <Box className="bottom-btn-block">
                <CustomButton btnType='primary' name='Save' loading={spinner} type='submit' onClick={handleSubmit} />
            </Box>
        </Box>
    );
};

export default GdprSideDrawer;