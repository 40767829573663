import React, { useContext, useState } from 'react';
import { CustomFieldPageContext } from '../../../contexts/Settings/CustomFieldContext';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import { Box } from '@mui/material';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import CreateCustomField from './CreateCustomField';
import moment from 'moment';
import buyerPersonaEmptyImg from '../../../assets/icons/empty-state/ICPEmptyState.svg';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { CustomField } from '../../Registration/interfaces/custom-field_interface';
import TanstackToolbar from '../../../common/TanstackTable/TanstackToolbar';
import HeaderBar from '../../../common/Headerbar';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import DeletePopup from '../../../common/DeletePopup';
import { stripHtmlTags } from '../../../scripts/helpers';

import './styles.scss';

const CustomFieldPage = ():React.JSX.Element => 
{

    const { 
        isEmpty,
        rows,
        setRows,
        currentPage,
        selectedCustomField,
        setSelectedCustomField,
        setShowDeletePopup,
        showDeletePopup, 
        customFieldCount, 
        customFields, 
        setRefresh, 
        deleteCustomFieldFromTable, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner 
    } = useContext(CustomFieldPageContext);

    // const columns = [
    //     {
    //         field: 'fieldName', headerName: 'Name', flex: 1, editable: false, disableColumnMenu: true, renderCell: (params):React.JSX.Element =>
    //         {
    //             return(
    //                 <div className="data-grid-bolded-cellContent" dangerouslySetInnerHTML={{
    //                     __html: params?.row?.label
    //                 }} />
    //             );
    //         }
    //     },
    //     {
    //         field: 'fieldLocations', headerName: 'Location', flex: 1, editable: false, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return fieldLocation(params);
    //         }
    //     },
    //     {
    //         field: 'type', headerName: 'Type', flex: 1, editable: false, disableColumnMenu: true, sortable: false, renderCell: (params):React.JSX.Element => 
    //         {
    //             return fieldType(params);
    //         }
    //     },
    //     {
    //         field: 'createdBy', headerName: 'Created By', flex: 1, editable: false, disableColumnMenu: true, sortable: false, renderCell: (params):React.JSX.Element => 
    //         {
    //             return createdBy(params);
    //         }
    //     },
    //     {
    //         field: 'created', headerName: 'Created At', flex: 1, minWidth: 205, editable: false, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return createdAt(params);
    //         }
    //     },
    //     {
    //         field: 'actions', headerName: '', editable: false, sortable: false, flex: 1, maxWidth: 40, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return customFieldActions(params.row);
    //         }
    //     },
    // ];

    const columnHelper = createColumnHelper<CustomField>();
    const columns = 
    [
        columnHelper.accessor('fieldName', {
            cell: (row) => {
                return (
                    <div className="bolded-cellContent" dangerouslySetInnerHTML={{
                        __html: row?.getValue()
                    }} />
                )
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('fieldLocations', {
            cell: (row) => {
                return fieldLocation(row.row.original);
            },
            header: 'Location',
            size: 180,
            sortingFn: (rowA, rowB, columnId) => {
                const locationsA = (rowA.getValue(columnId) as string[]).map((loc: string) => CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[loc]).toString().toLowerCase();
                const locationsB = (rowB.getValue(columnId) as string[]).map((loc: string) => CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[loc]).toString().toLowerCase();
                return locationsA.localeCompare(locationsB);
            },
            id: 'location'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return fieldType(row.row.original);
            },
            header: 'Type',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('createdBy', {
            cell: (row) => {
                return createdBy(row.row.original);
            },
            header: 'Created By',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'createdBy'
        }),
        columnHelper.accessor('created', {
            cell: (row) => {
                return createdAt(row.row.original);
            },
            header: 'Created At',
            size: 250,
            sortingFn: (rowA, rowB, columnId) => {
                const dateA = moment(rowA.getValue(columnId), 'YYYY-MM-DDTHH:mm:ssZ');
                const dateB = moment(rowB.getValue(columnId), 'YYYY-MM-DDTHH:mm:ssZ');
                return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
            },
            id: 'createdAt'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return customFieldActions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const customFilterFunctions = {
        fieldLocations: (obj: CustomField, inputValue: string | number) => {
            if (obj.fieldLocations.length === 1) {
                return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[obj.fieldLocations[0]].toLowerCase().includes(String(inputValue).toLowerCase());
            }
            else {
                const res = [];

                for (let i = 0; i < obj.fieldLocations.length; i++) {
                    res.push(CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[obj.fieldLocations[i]]);
                }

                return res.toString().toLowerCase().includes(String(inputValue).toLowerCase());
            }
        },
        type: (obj: CustomField, inputValue: string | number) => { 
            return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE[obj.type as keyof typeof CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE].toLowerCase().includes(String(inputValue).toLowerCase());
        }
    };

    const fieldLocation = (row: CustomField):React.JSX.Element => 
    {
        if (row.fieldLocations.length === 1) 
        {
            return (
                <p className="cellContent">
                    {CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[row.fieldLocations[0]]}
                </p>
            );

        }
        else 
        {
            const res = [];

            for (let i = 0; i < row.fieldLocations.length; i++) 
            {
                res.push(CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[row.fieldLocations[i]]);

            }

            return (
                <p className="cellContent">
                    {res.toString()}
                </p>
            );
        }

    };

    const fieldType = (row: CustomField):React.JSX.Element => 
    {
        return (
            <p className="cellContent">
                {CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE[row.type as keyof typeof CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE]}
            </p>
        );
    };

    const createdBy = (row: CustomField):React.JSX.Element => 
    {
        return (
            <p className="cellContent">
                {row?.createdBy}
            </p>
        );
    };

    const createdAt = (row: CustomField):React.JSX.Element => 
    {
        const date = moment(row.created).format('MMMM Do YYYY, h:mm:ss a');
        return (
            <p className="cellContent">
                {date}
            </p>
        );
    };

    const openDrawer = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.SIDE_DRAWER.HEADING.CREATE,
            hideCloseButton: true,
            component: <CreateCustomField setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const customFieldActions = (customFieldInfo: CustomField):React.JSX.Element => 
    {

        const editClick = ():void => 
        {
            handleViewEdit(customFieldInfo);
        };

        const deleteClick = ():void => 
        {
            setSelectedCustomField(customFieldInfo);
            setShowDeletePopup(true);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleViewEdit = (customFieldInfo: CustomField):void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.SIDE_DRAWER.HEADING.EDIT,
            component: <CreateCustomField setRefresh={setRefresh} customFieldData={customFieldInfo} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });

    };

    return (
        <Box id="customFieldPage">
            <HeaderBar 
                title={CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.HEADING}
                buttons={[
                    <CustomButton name="Create Custom Field" btnType="secondary" onClick={openDrawer} />
                ]}
            />
            <Box sx={{
                flex: 1,
                height: 'calc(100% - 64px)',  
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={buyerPersonaEmptyImg} openDrawer={openDrawer} infoText={'No Custom Field'} subInfoText={'Add your first Custom Field'} buttonName={'Add Custom Field'} />) :
                    (
                        <div className="h-100">
                            <TanstackToolbar 
                                rows={customFields}
                                setRows={setRows}
                                columns={columns}
                                setColumns={setAllColumns}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: customFieldCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                                customFilterFunctions={customFilterFunctions}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={rows}
                                rowCount={customFieldCount}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                showSpinner={showSpinner}
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => handleViewEdit(row)}
                            />
                        </div>
                    )}
            </Box>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteCustomFieldFromTable(selectedCustomField as CustomField)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedCustomField(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${stripHtmlTags(selectedCustomField?.fieldName as string)}?`}
                        modalTitle='Delete Custom Field'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};
export default CustomFieldPage;