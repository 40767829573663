import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRegistrationFormById } from '../scripts/apis/registrationForm';
import { addRegistrationForms } from '../redux/registrationsForms/RegistrationForms';
import _ from 'lodash';
import { addFormComponent, resetFormComponents } from '../redux/registrationsForms/FormComponents';
import { getComponentCategory } from '../pages/Registration/RegistrationSubPages/FormComponents/ComponentsCategories';
import { RegistrationFormComponentDisabled } from '../pages/Registration/enum';
import { getCustomField, getCustomFieldCount } from '../scripts/apis/customField';
import { CustomField } from '../pages/Registration/interfaces/custom-field_interface';
import { RegistrationForm } from '../pages/Registration/interfaces/registration-form_interface';
import { CustomFieldDisabled, CustomFieldLocations } from '../pages/Registration/enum/custom-field.enum';

export interface IFormComponentsContext {
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    formName: string | null;
    formId: string | undefined;
    registrationData: RegistrationForm | null;
    customFields: CustomField[];
    setCustomFields: React.Dispatch<React.SetStateAction<CustomField[]>>;
}

export const FormComponentsContext = createContext<IFormComponentsContext | null>(null);

const FormComponentsProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{

    const { formId } = useParams();
    const dispatch = useDispatch();
    const [formName, setFormName] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [registrationData, setRegistrationData] = useState(null);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);

    const fetchCustomFields = async (): Promise<void> =>
    {
        try 
        {
            const count = await getCustomFieldCount();
            if(count)
            {
                const customFieldData = await getCustomField(25, 0, CustomFieldLocations.REGISTRATION_FORM);
                if(customFieldData)
                {
                    setCustomFields(customFieldData);
                }
            }
        } 
        catch (error) 
        {
            
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const registrationFormData = await getRegistrationFormById(Number(formId));
            if (registrationFormData) 
            {
                setFormName(registrationFormData.name);
                setRegistrationData(registrationFormData);

                if (!(_.isEmpty(registrationFormData.options))) 
                {
                    dispatch(addRegistrationForms({
                        name: registrationFormData.name,
                        description: registrationFormData.description,
                        link: registrationFormData.link,
                        // options: {
                        //     message: registrationFormData.options.message,
                        //     redirectionUrl: registrationFormData.options.redirectionUrl,
                        // },
                        componentPosition: registrationFormData.componentPosition,
                        captcha: registrationFormData.captcha,
                    }));
                }


                if (registrationFormData?.formFields && registrationFormData?.formFields.length > 0) 
                {
                    dispatch(resetFormComponents());
                    registrationFormData?.formFields?.forEach((elem): void => 
                    {
                        // const existingDataWithId = formComponentsReduxData.find(existingItem => existingItem.id === elem.id);
                        // if (!existingDataWithId) {
                        dispatch(addFormComponent({
                            category: getComponentCategory(Number(elem.type)),
                            id: elem.id,
                            default: elem.default,
                            type: elem.type,
                            // subType: elem.subType,
                            helpText: elem.helpText,
                            label: elem.label,
                            placeholder: elem.placeholder,
                            // typeAttribute: elem.typeAttribute,
                            componentMandatory: elem?.updatedLabels && elem?.updatedLabels?.length > 0 && elem?.updatedLabels[0]?.formId == formId ? elem?.updatedLabels[0]?.fieldMandatory : elem.fieldMandatory,
                            componentDisabled: elem.fieldDisabled === CustomFieldDisabled.DISABLED ? true : false,
                            componentOptions: elem.fieldOptions,
                            componentNumeric: {
                                ...elem.fieldNumeric
                            },
                            heading: elem?.heading,
                            paragraph: elem.paragraph,
                        }));
                        //     } 
                    });

                    dispatch(addFormComponent({ 
                        componentGDPR: registrationFormData?.formGDPR
                    }));
                }

                setRefresh(false);
            }

        }
        catch (error) 
        {
            console.log(error);
        }

    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
            fetchCustomFields();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        fetchData();
        fetchCustomFields();
    }, []);

    return (
        <FormComponentsContext.Provider value={{
            setRefresh, formName, formId, registrationData, customFields, setCustomFields 
        }}>
            {children}
        </FormComponentsContext.Provider>
    );
};

export default FormComponentsProvider;
