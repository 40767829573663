import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import eventBus from '../../../scripts/event-bus';
import { getAllHubspotProperties, getHubspotIntegrationProperties, postHubspotIntegrationProperties } from '../../../scripts/apis/integration';
import APP_CONSTANTS from '../../../scripts/constants';
import { IIntegrationMappingPopup } from '../interface/integration_interface';
import { HubspotContactsProperties, IntegrationTypes } from '../enum/integrations.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Box, TextField } from '@mui/material';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';

interface IPropertyObjectType{
    [key:string]: string|number | boolean | string[] | object | {[key:string]: string | number | boolean} | boolean;
}

interface IMappingProperties {
    contacts: IPropertyObjectType[];
    deals?: IPropertyObjectType[];
    companies?: IPropertyObjectType[];
    availableContacts: IPropertyObjectType[];
    availableDeals?: IPropertyObjectType[];
    availableCompanies?: IPropertyObjectType[];  
}

const IntegrationMappingPopUp = ({ popUpHeading, CloseButtonFunction, type }: IIntegrationMappingPopup):React.JSX.Element => 
{

    const [integrationMappingObject, setIntegrationMappingObject] = useState(null);
    const [allIntegrationProperties, setAllIntegrationProperties] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [mappingPayload, setMappingPayload] = useState<{ [key: string]: IPropertyObjectType[]; }>({
    });

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    useEffect(():void => 
    {
        fetchAllIntegrationDataByType(type);

        fetchExistingIntegrationDataByType(type);

        eventBus.on('updated_contact_details', (data):void => 
        {
            setMappingPayload((prevMappingPayload):{contacts: IPropertyObjectType[]} => 
            {

                const existingContacts = prevMappingPayload.contacts || integrationMappingObject?.['contacts'] || [];

                const updatedContacts = existingContacts.filter((item):boolean => 
                {
                    if (item.propertyType) 
                    {
                        return item; 
                    } 
                });

                const existingIndex = updatedContacts.findIndex((contact):boolean => 
                {
                    return Number(contact?.propertyType) === data.data.contactsProperty.propertyType;
                });
                if (existingIndex !== -1) 
                {
                    updatedContacts[existingIndex] = data.data.contactsProperty;
                }
                else 
                {
                    updatedContacts.push(data.data.contactsProperty);
                }

                return {
                    ...prevMappingPayload,
                    contacts: updatedContacts
                };
            });
        });

    }, [type]);

    const fetchExistingIntegrationDataByType = async (type: number):Promise<void> => 
    {

        if (type === IntegrationTypes.HUBSPOT) 
        {

            try 
            {
                const ExistingHubspotProperties = await getHubspotIntegrationProperties();

                if (ExistingHubspotProperties) 
                {

                    setIntegrationMappingObject(ExistingHubspotProperties);
                    setMappingPayload({
                        'contacts': ExistingHubspotProperties['contacts'] 
                    });

                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const fetchAllIntegrationDataByType = async (type: number):Promise<void> => 
    {

        if (type === IntegrationTypes.HUBSPOT) 
        {

            try 
            {
                const AllHubspotProperties = await getAllHubspotProperties();

                if (AllHubspotProperties) 
                {
                    setAllIntegrationProperties(AllHubspotProperties);
                }

            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const postIntegrationDataByType = async (type: number):Promise<void> => 
    {

        if (type === IntegrationTypes.HUBSPOT) 
        {

            try 
            {

                if (!processing) 
                {
                    setProcessing(true);

                    const HubspotPropertiesSent = await postHubspotIntegrationProperties(mappingPayload, csrfTokenData);

                    if (HubspotPropertiesSent) 
                    {

                        setProcessing(false);

                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Hubspot Integration Successfully Updated',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'center',
                        });
                    }
                }

            }
            catch (error) 
            {
                console.log(error);
                setProcessing(false);

                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'something went wrong',
                    severity: 'error',
                    positionVertical: 'top',
                    positionHorizontal: 'center',
                });
            }
        }
    };

    const handleNextClick = ():void => 
    {
        postIntegrationDataByType(type); 
    };

    return (
        <div id="IntegrationMappingPopUpContainer">
            <div className="popUpContentBlock">
                <div className="headerBlock">
                    <div className="heading">
                        {popUpHeading ? popUpHeading : 'Map Columns'}
                    </div>
                    <div className="closeIcon" onClick={CloseButtonFunction}>
                        <FontAwesomeIcon icon={['fal', 'xmark']} className="closeIconImg" />
                    </div>
                </div>
                <div className="contentBlock">
                    <IntegrationMappingTable
                        contacts={allIntegrationProperties?.['contacts']}
                        deals={allIntegrationProperties?.['deals']}
                        companies={allIntegrationProperties?.['companies']}
                        availableContacts={
                            integrationMappingObject?.['contacts']
                        }
                        availableDeals={integrationMappingObject?.['deals']}
                        availableCompanies={
                            integrationMappingObject?.['companies']
                        }
                    />
                </div>

                <div className="footerBlock">
                    <div className="btnsBlock">
                        <button
                            className="closeBtn"
                            onClick={CloseButtonFunction}
                        >
                            Close
                        </button>
                        <button className="nextBtn" onClick={handleNextClick}>
                            {processing ? (<Spinner size='sm' />) : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationMappingPopUp;

const IntegrationMappingTable = (props: IMappingProperties):React.JSX.Element => 
{

    const [contactRows, setContactRows] = useState([
        {
            id: 1,
            titleName: 'First Name',
            contact: 'First Name (editable)',
            selectedValue: null,
            enumType: HubspotContactsProperties.FIRST_NAME
        },
        {
            id: 2,
            titleName: 'Last Name',
            contact: 'Last Name (editable)',
            selectedValue: null,
            enumType: HubspotContactsProperties.LAST_NAME
        },
        {
            id: 3,
            titleName: 'Email',
            contact: 'Email (editable)',
            selectedValue: null,
            enumType: HubspotContactsProperties.EMAIL
        },
        {
            id: 4,
            titleName: 'Phone Number',
            contact: 'Phone Number (editable)',
            selectedValue: null,
            enumType: HubspotContactsProperties.PHONE_NUMER
        }
    ]);

    useEffect(():void => 
    {
        if (props.availableContacts) 
        {

            const availableMapping = {
            };

            props.availableContacts.forEach((item):void => 
            {
                if (item.propertyType) 
                {
                    if(typeof(item.propertyType) === 'number' || typeof(item.propertyType) === 'string')
                    {
                        availableMapping[item.propertyType] = item.label;
                    }
                    
                }
            });

            const updatedRows = contactRows.map((row):{
                id: number;
                titleName: string;
                contact: string;
                selectedValue: number | string;
                enumType: HubspotContactsProperties;
            } => 
            {
                if (availableMapping[row.enumType]) 
                {
                    return {
                        ...row,
                        selectedValue: availableMapping[row.enumType]
                    };
                }
                return row;
            });

            setContactRows(updatedRows);
        }
    }, [props.availableContacts]);

    const onDropDownChangeForContacts = (id, propertyType, event, value):void => 
    {
        if (value && id && propertyType) 
        {

            const updatedRows = contactRows.map((row):{
                id: number;
                titleName: string;
                contact: string;
                selectedValue: number | string;
                enumType: HubspotContactsProperties;
            } => 
            {
                if (row.id === id) 
                {
                    return {
                        ...row, selectedValue: value 
                    };
                }
                return row;
            });

            setContactRows(updatedRows);

            const validProperty = props.contacts.filter((item):boolean => 
            {
                if (item.label === value) 
                {
                    return true;
                }

            }).map((item):{
                propertyType: string | number;
            } => 
            {
                const x = {
                    ...item,
                    ['propertyType']: propertyType
                };
                return x;
            });
            eventBus.dispatch('updated_contact_details', {
                data: {
                    contactsProperty: validProperty[0]
                }
            });
        }
    };


    return (
        <div id="mappingTableBlock">
            <div className="mapping-table-1">
                <Box id="integrationMappingContainer">
                    <Box className="heading-row">
                        <Box className="first-col">{'Title of the Segment'}</Box>
                        <Box className="second-col">{'Contacts'}</Box>
                    </Box>
                    {contactRows.length > 0 && contactRows.map((item, index):React.JSX.Element => 
                    {
                        return (
                            <Box key={index} className="properties-row">
                                <Box className="property-name">{item.titleName}</Box>
                                <Box className="property-value">
                                    <Autocomplete
                                        defaultValue={item.selectedValue || null}
                                        value={item.selectedValue || null}
                                        renderTags={():null => 
                                        {
                                            return null; 
                                        }}
                                        popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                                        onChange={(e, value):void => 
                                        {
                                            return onDropDownChangeForContacts(item?.id, item?.enumType, e, value); 
                                        }}
                                        className="integration-option-select"
                                        options={props?.contacts?.map((option):string|number => 
                                        {
                                            if(typeof (option.label) === 'string')
                                            {
                                                return option.label;
                                            }
                                            
                                        }) || []}
                                        renderInput={(params): React.JSX.Element => 
                                        {
                                            return (
                                                <TextField className="dropdown-text" placeholder='Select an Option' {...params} />
                                            ); 
                                        }}
                                    />
                                </Box>
                            </Box>
                        ); 
                    })}
                </Box>
            </div>
        </div>
    );
};

