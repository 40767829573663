import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import { getAllSchedules, getScheduleCount, removeEventSchedule, updateEventSchedule } from '../../scripts/apis/eventSchedule';
import { eventSpeakersCount, getEventSpeakers } from '../../scripts/apis/eventSpeakers';
import { useDispatch } from 'react-redux';
import { addSchedule } from '../../redux/events/eventVenue/EventSchedule';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useTablePagination } from '../TablePaginationContext';
import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';
// import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';

export const ScheduleContext = createContext(null);

const SchedulePageProvider: React.FC<{children: React.ReactNode; eventReduxData: IEventReduxValues; eventId: string}> = ({ children, eventReduxData, eventId }): React.JSX.Element => 
{

    const dispatch = useDispatch();

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [rows, setRows] = useState([]);
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [speakerInfo, setSpeakerInfo] = useState([]);
    const [eventStartDateTime, setEventStartDateTime] = useState('');
    const [eventEndDateTime, setEventEndDateTime] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [scheduleCount, setScheduleCount] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const eventStartingDate = moment(moment(eventStartDateTime).format('YYYY-MM-DD'));
    const eventEndingDate = moment(moment(eventEndDateTime).format('YYYY-MM-DD'));
    const daysArr = [];
    const currentDate = eventStartingDate.clone();
    while (currentDate.isSameOrBefore(eventEndingDate, 'day')) 
    {
        daysArr.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }

    const removeScheduleFromTable = async (): Promise<void> => 
    {
        if (schedules && schedules.length > 0 && filteredRowData.length > 0) 
        {
            eventBus.on(APP_CONSTANTS.EVENTS.EVENT_EXPO.DELETE, async (object): Promise<void> => 
            {
                const acceptObj = object.acceptObj;
                let deleteSuccess = true;
                if (deleteSuccess) 
                {
                    if (acceptObj.id) 
                    {
                        try 
                        {
                            const scheduleDeleted = await removeEventSchedule(eventId, acceptObj.id);
                            if (scheduleDeleted) 
                            {
                                setRefresh(true);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Schedule Deleted',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                                deleteSuccess = false;
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                }
            });
        }
    };

    const fetchSessionDataCount = async (): Promise<void> => 
    {
        try 
        {
            const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
            const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

            const startTimeStamp = startDateTime.unix();
            const endTimeStamp = endDateTime.unix();
            const count = await getScheduleCount(eventId, startTimeStamp, endTimeStamp);
            if (count) 
            {
                setScheduleCount(count);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchScheduleData = async (): Promise<void> => 
    {
        let startTime = eventReduxData.eventStartDateTime;
        let endTime = eventReduxData.eventEndDateTime;
        startTime = moment.unix(Number(startTime)).format('LLLL');
        endTime = moment.unix(Number(endTime)).format('LLLL');
        setEventStartDateTime(startTime);
        setEventEndDateTime(endTime);
      
        try 
        {
            // const count = await getScheduleCount(eventId);
            // if (count) {
            if (daysArr[tabValue] || eventReduxData.eventStartDate) 
            {
                const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
                const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

                const startTimeStamp = startDateTime.unix();
                const endTimeStamp = endDateTime.unix();

                const scheduleData = await getAllSchedules(eventId, pageSize, currentPage - 1, startTimeStamp, endTimeStamp);
                if (scheduleData) 
                {
                    setRows([...scheduleData]);

                    if (refresh) 
                    {
                        setFilteredRowData([...scheduleData]);
                        setTabValue(tabValue);
                        dispatch(addSchedule({
                            ticketSaleStartDate: daysArr[tabValue],
                            ticketSaleEndDate: daysArr[tabValue],
                        }));
                    }
                    else 
                    {
                        setFilteredRowData([...scheduleData]);
                        dispatch(addSchedule({
                            ticketSaleStartDate: moment.unix(startTimeStamp).format('YYYY-MM-DD'),
                            ticketSaleEndDate: moment.unix(endTimeStamp).format('YYYY-MM-DD'),
                        }));
                    }
                }
            }
            // }
        }
        catch (error) 
        {
            console.log(error);
        }

        try 
        {
            const speakerCount = await eventSpeakersCount(Number(eventId));
            if (speakerCount) 
            {
                const speakerData = await getEventSpeakers(speakerCount, 0, Number(eventId));
                if (speakerData) 
                {
                    setSpeakerInfo([...speakerData]);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        setRefresh(false);
    };

    useEffect((): void => 
    {
        if (eventReduxData.title) 
        {
            if (currentPage > 0 && pageSize) 
            {
                fetchSessionDataCount();
                fetchScheduleData();
            }

        }
    }, [eventReduxData, tabValue, currentPage, pageSize]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchSessionDataCount();
            fetchScheduleData();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        setIsEmpty(filteredRowData.length === 0);
        setSchedules([...filteredRowData]);
    }, [filteredRowData]);

    const editScheduleFromTable = async (scheduleData): Promise<void> => 
    {
        const eventScheduleDetails = {
            title: scheduleData.title,
            description: scheduleData.description,
            status: 1,
            segmentType: scheduleData.segmentType,
            segmentTypeIds: {
                stage: scheduleData.segmentTypeIds.stage,
                session: scheduleData.segmentTypeIds.session,
                expo: scheduleData.segmentTypeIds.expo
            },
            ticketSaleStartDateTime: scheduleData.ticketSaleStartDateTime,
            ticketSaleEndDateTime: scheduleData.ticketSaleEndDateTime,
            ticketSpeakerIds: scheduleData.ticketSpeakerIds.length > 0 ? scheduleData.ticketSpeakerIds : [],
            tags: []
        };

        try 
        {
            const eventScheduleUpdated = await updateEventSchedule(eventId, scheduleData.id, eventScheduleDetails);
            if (eventScheduleUpdated) 
            {
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <ScheduleContext.Provider value={{
            speakerInfo, setRefresh, removeScheduleFromTable, setTabValue, rows, daysArr, setFilteredRowData, tabValue, isEmpty, filteredRowData, showSpinner, pageSize, updateCurrentPage, updatePageSize, scheduleCount, editScheduleFromTable 
        }}>
            {children}
        </ScheduleContext.Provider>
    );
};

export default SchedulePageProvider;
