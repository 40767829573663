import { Stack, Typography } from '@mui/material';
import React from 'react';

const Recording = (): React.JSX.Element =>
{
    return(
        <Stack height="100%" alignItems="center" justifyContent="center">
            <Typography sx={{
                color: '#fff', fontSize: '24px'
            }}>Coming Soon !!!</Typography>
        </Stack>
    );
};

export default Recording;