import React, { useEffect, useState } from 'react';
import FormPreview from './FormPreview';
import { Alert, Box, Fade, Snackbar, Typography } from '@mui/material';
// import LandingPagePreviewMain from '../../../LandingPagePreview/Main/LandingPagePreviewMain';
// import MeetupsLandingPageMain from '../../../MeetupsLandingPage/Main/MeetupsLandingPageMain';
// import { TransitionProps } from '@mui/material/transitions';
import RegistrationLandingPage from '../../../RegistrationLandingPage/MainPage';
// eslint-disable-next-line import/named
import { TransitionProps } from '@mui/material/transitions';
import './styles.scss';


export default function OpenPreview():React.JSX.Element 
{
    // return <MeetupsLandingPageMain />;
    // return <LandingPagePreviewMain />
    return <RegistrationLandingPage />;
}

export const FormPublicAlerts = (data):React.JSX.Element => 
{

    // const SlideTransition = (props: SlideProps):React.JSX.Element => 
    // {
    //     return <Slide {...props} direction="up" />;
    // };

    type Vertical = 'top' | 'bottom';
    type Horizontal = 'left' | 'center' | 'right';

    const [state] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<
            TransitionProps & {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                children: React.ReactElement<any,any>;
            }
        >;
    }>({
        open: false,
        Transition: Fade,
    });

    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState();
    const [positionVertical, setPositionVertical] = useState<Vertical>('top');
    const [positionHorizontal, setPositionHorizontal] = useState<Horizontal>('right');
    const [alert, setAlert] = useState(false);

    const handleAlertClose = (event, reason):void => 
    {
        if (reason === 'clickaway') 
        {
            return;
        }
        setAlert(false);
    };

    useEffect(():void => 
    {
        if (data) 
        {
            setAlert(data.open);
            setMessage(data.message);
            setSeverity(data.severity);
            setPositionVertical(data.positionVertical);
            setPositionHorizontal(data.positionHorizontal);
        }
    }, [data]);

    return (
        <Box id="alerts">
            <Snackbar anchorOrigin={{
                vertical: positionVertical,
                horizontal: positionHorizontal
            }}
            open={alert}
            autoHideDuration={3000}
            TransitionComponent={state.Transition}
            onClose={handleAlertClose}
            >
                <Alert severity={severity} className="alert-container">
                    <Typography className="message">{message}</Typography>
                </Alert>
            </Snackbar>
        </Box>
    );
};

export const RegistrationFormPopup = (props): React.JSX.Element => 
{ 
    return (
        <Box id='registrationFormPopup'>
            <FormPreview eventCoupon={props?.coupon} registerMessage={props?.registerMessage} contentType={props?.contentType} isValidRegistrationForm={true} registrationFormData={props.registrationFormData} eventToken={props.eventToken} onClose={props.onClose} ctaColor={props.ctaColor} />
        </Box>
    );
};

