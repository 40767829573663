import React, { createContext, useEffect, useState } from 'react';
import { deleteTicket, getAlleventTickets, ticketsCount } from '../../scripts/apis/eventTickets';
import eventBus from '../../scripts/event-bus';
import { useTablePagination } from '../TablePaginationContext';
import { EventTicket } from '../../pages/Events/interfaces';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

interface ITicketsContext {
    tickets: EventTicket[];
    isEmpty: boolean;
    ticketRows: EventTicket[];
    setTicketRows: React.Dispatch<React.SetStateAction<EventTicket[]>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    removeTicketFromTable: (ticket: EventTicket) => Promise<void>;
    eventId: number;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (page: number) => void;
    updatePageSize: (size: number) => void;
    showSpinner: boolean;
    eventTicketsCount: number | null;
    presentTicketsName: string[] | null;
    isTableView: boolean;
    selectedTicket?: EventTicket | null;
    showDeletePopup?: boolean;
    setSelectedTicket: React.Dispatch<React.SetStateAction<EventTicket | null>>;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TicketsPageContext = createContext<ITicketsContext>({
    tickets: [],
    isEmpty: true,
    ticketRows: [],
    setTicketRows: () => {},
    setRefresh: () => {},
    removeTicketFromTable: async () => {},
    eventId: 0,
    pageSize: 25,
    currentPage: 1,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    eventTicketsCount: null,
    presentTicketsName: null,
    isTableView: false,
    selectedTicket: null,
    showDeletePopup: false,
    setSelectedTicket: () => {},
    setShowDeletePopup: () => {},
});

const TicketsPageProvider: React.FC<{children: React.ReactNode; eventId: number;}> = ({ children, eventId }): React.JSX.Element => 
{
    const currentpath = useLocation().pathname;
    const isTableView = currentpath?.includes('tickets');

    const [ticketRows, setTicketRows] = useState<EventTicket[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [tickets, setTickets] = useState<EventTicket[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [eventTicketsCount, setEventTicketsCount] = useState<number | null>(null);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [presentTicketsName, setPresentTicketsName] = useState<string[] | null>(null);
    const [selectedTicket, setSelectedTicket] = useState<EventTicket | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const removeTicketFromTable = async (ticket: EventTicket): Promise<void> => 
    {
        try 
        {
            const ticketDeleted = await deleteTicket(eventId, ticket?.id as string);
            if (ticketDeleted) 
            {
                setShowDeletePopup(false);
                setSelectedTicket(null);
                toast.success('Ticket Deleted');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            toast.error((error as Error)?.message);
            console.log(error);
        }         
    };

    const fetchTicketsData = async (): Promise<void> => 
    {
        try 
        {
            const eventTicketCount = await ticketsCount(eventId);
            if (eventTicketCount) 
            {
                setEventTicketsCount(eventTicketCount);
                try 
                {
                    if (isTableView)
                    {
                        const eventTickets = await getAlleventTickets(pageSize, currentPage - 1, eventId);
                        
                        if (eventTickets) 
                        {
                            if(eventTickets.length > 0)
                            {
                                const usedNames = eventTickets.map((item):string => 
                                {
                                    return item.name.replace(/\s/g, '').toLowerCase(); 
                                });
    
                                setPresentTicketsName(usedNames);
                            }
                            else
                            {
                                setPresentTicketsName([]);
                            }
                            setTickets([...eventTickets]);
                            setTicketRows([...eventTickets]);
                            setIsEmpty(eventTickets.length === 0);
                        }
                    }
                    else
                    {
                        const eventTickets = await getAlleventTickets(6, 0, eventId);
                        if (eventTickets) 
                        {
                            if(eventTickets.length > 0)
                            {
                                const usedNames = eventTickets.map((item):string => 
                                {
                                    return item.name.replace(/\s/g, '').toLowerCase(); 
                                });
    
                                setPresentTicketsName(usedNames);
                            }
                            else
                            {
                                setPresentTicketsName([]);
                            }
                            setTicketRows(eventTickets);
                            setTickets(eventTickets);
                            setIsEmpty(eventTickets.length === 0);
                        }
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
            setRefresh(false);
        }
    };

    useEffect((): void => 
    {
        if (currentPage > 0 && pageSize) 
        {
            fetchTicketsData();
        }
    }, [currentPage, pageSize]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            eventBus.dispatch('event-details-refreshed', {
                refresh: true 
            });
            fetchTicketsData();
        }
    }, [refresh]);

    useEffect(() => 
    {   
        if(!isTableView)
        {
            fetchTicketsData();
        }
    }, []);

    return (
        <TicketsPageContext.Provider 
            value={{
                tickets, 
                isEmpty, 
                ticketRows, 
                setTicketRows,
                setRefresh, 
                removeTicketFromTable, 
                eventId, 
                pageSize,
                currentPage, 
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                eventTicketsCount, 
                presentTicketsName, 
                isTableView,
                selectedTicket,
                showDeletePopup,
                setSelectedTicket,
                setShowDeletePopup
            }}
        >
            {children}
        </TicketsPageContext.Provider>
    );
};

export default TicketsPageProvider;