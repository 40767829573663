import { Box } from '@mui/material';
import React from 'react';
import RegistrationDetailsSection from './RegistrationDetailsSection';
import RegistrationFormsSection from './RegistrationFormsSection';
import RegistrationEmailsSection from './RegistrationEmailSection';
import useEventRegisreationDetailsData from '../../../../hooks/eventRegistrationDetails';
import CustomSpinner from '../../../../common/CustomSpinner';
import { IEventReduxValues } from '../../../../components/Events/interfaces/create-events_interface';
import { RegistrationTypes } from './Tickets/Ticket';


const EventRegistrationDetails: React.FC<{eventId: number}> = (props: {eventId: number}): React.JSX.Element => 
{
    // const eventReduxData = props.eventReduxData;
    const eventId = props.eventId;
    const [eventDetails, spinner, isEventTicketPresent, isTicketApprovalOn, isTicketPaid] = useEventRegisreationDetailsData({
        eventId: Number(eventId) 
    });

    return (
        <Box id="eventRegDetails">
            {/* <Box id='inside-event-module-name-section'>{'Forms'}</Box> */}
            {spinner ? <CustomSpinner height={'100%'} /> : <>
                <Box className="details-block">
                    <RegistrationDetailsSection eventDetails={eventDetails} spinner={spinner} />
                    <RegistrationEmailsSection eventId={Number(eventId)} eventName={eventDetails.title || ''} registrationType={eventDetails?.registrationType || RegistrationTypes.INVITE_ONLY} isEventTicketPresent = {isEventTicketPresent} isTicketApprovalOn={isTicketApprovalOn} isTicketPaid = {isTicketPaid} />
                    <RegistrationFormsSection registrationFormId={String(eventDetails?.registrationFormId)} eventId={eventId}/>
                </Box>
                {/* <TicketsPageProvider eventId={eventId} ><Ticket eventReduxData={eventReduxData} eventId={Number(eventId)} /></TicketsPageProvider> */}
            </>}
        </Box>
    );
};

export default EventRegistrationDetails;
