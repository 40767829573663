import React, { useEffect, useState } from 'react';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const CustomDialogSecondary = (): React.JSX.Element => 
{

    const [heading, setHeading] = useState(null);
    const [subHeading, setSubHeading] = useState(null);
    const [acceptEvent, setAcceptEvent] = useState('');
    const [acceptObj, setAcceptObj] = useState(new Object());
    const [acceptButton, setAcceptButton] = useState(null);
    const [cancelButton, setCancelButton] = useState(null);
    const [open, setOpen] = useState(false);
    
    const handleDialogClose = (): void => 
    {
        setOpen(false);
    };

    const handleAccept = (): void => 
    {
        eventBus.dispatch(acceptEvent, {
            result: true,
            acceptObj
        });
        handleDialogClose();
    };

    useEffect(():()=> void => 
    {
        const updateEvent = (data): void => 
        {
            setHeading(data.heading);
            setSubHeading(data.subHeading);
            setAcceptEvent(data.acceptEvent);
            if (data.acceptObj) 
            {
                setAcceptObj(data.acceptObj);
            }
            setAcceptButton(data.acceptButton);
            setCancelButton(data.cancelButton);
        };

        const openEvent = (data): void => 
        {
            setOpen(data.open);
        };

        const closeEvent = (): void => 
        {
            handleDialogClose();
        };

        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.UPDATE_EVENT, updateEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.OPEN_EVENT, openEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.CLOSE_EVENT, closeEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.CLOSE_EVENT, (data) : void => 
        {
            setOpen(data.open);
        });

        return (): void => 
        {
            eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.UPDATE_EVENT, updateEvent);
            eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.OPEN_EVENT, openEvent);
            eventBus.on(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.CLOSE_EVENT, closeEvent);
        };

    }, []);

    return (
        <div id="customDialogSecondary">
            <Dialog slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    }
                }
            }} PaperProps={{
                sx: {
                    minWidth: '518px',
                    minHeight: '174px',
                    padding: '24px 24px 16px 24px',
                    borderRadius: '16px',
                    border: '1px solid #ffffff',
                    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.10)'
                }
            }} open={open} onClose={handleDialogClose} className="custom-dialog-container">
                <DialogTitle sx={{
                    padding: '12px',
                    color: '#2A2E34',
                    fontSize: '16px',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    lineHeight: '22px',
                    letterSpacing: '-0.1px'
                }}>{heading}</DialogTitle>
                <DialogContent sx={{
                    padding: 'unset' 
                }}>
                    <DialogContentText sx={{
                        padding: '12px',
                        color: '#4C5057',
                        fontSize: '12px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px',
                        letterSpacing: '-0.1px'
                    }}>{subHeading}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{
                        borderRadius: '6px',
                        padding: '10px 12px',
                        background: '#ffffff',
                        border: '1px solid #E5E5E5',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
                        height: '30px',
                        color: '#2A2E34',
                        fontSize: '12px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        textTransform: 'none',
                    }} onClick={handleDialogClose} autoFocus>{cancelButton}</Button>
                    <Button sx={{
                        borderRadius: '6px',
                        padding: '10px 12px',
                        background: '#007AFF !important',
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
                        height: '30px',
                        color: '#ffffff',
                        fontSize: '12px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        textTransform: 'none',
                    }} onClick={handleAccept}>{acceptButton}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomDialogSecondary;