import APP_CONSTANTS from "../constants";
import { triggerApi } from "../helpers";

export const getIntegrationsData = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations';
    return triggerApi(API_URL, 'GET', '');
};

export const getIntegrationByType = async (type: number) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations/integrate/' + type;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllHubspotProperties = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/all-hubspot-properties';
    return triggerApi(API_URL, 'GET', '');
}

export const getHubspotIntegrationProperties = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties';
    return triggerApi(API_URL, 'GET', '');
}

export const postHubspotIntegrationProperties = async (requestBody: any, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties';
    return triggerApi(API_URL, 'POST', requestBody, csrfToken);
}
export const deleteHubspotIntegrationProperties = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/hubspot/hubspot-properties';
    return triggerApi(API_URL, 'DELETE', '');
}

export const deleteRazorpayIntegration = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/razorpay';
    return triggerApi(API_URL, 'DELETE', '');
}

export const createIntegrationWithApiKey = async (type: number, data: {apiKey: string}, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations/integrate/' + type;
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

export const deleteLumaIntegration = async () => { 
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/luma';
    return triggerApi(API_URL, 'DELETE', '');
};
