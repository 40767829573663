import React, { useContext } from 'react';
import { Box, Typography, } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../scripts/i18n';
import { LocalStorage } from '../../scripts/LocalStorage';
import { updateGeneralSettings, uploadUserImage } from '../../scripts/apis/users';
import { isImageBuffer } from '../../scripts/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import './styles.scss';
import { useDropzone } from 'react-dropzone';
import { PersonalPageContext } from '../../contexts/Settings/PersonalPageContext';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useSelector } from 'react-redux';
import { FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PersonalPage: React.FC = (): React.JSX.Element => 
{

    const { setProfileImage, setSpinner, userInfo, profileImage, setRefresh, user, spinner, isEditing, setIsEditing } = useContext(PersonalPageContext);

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const { getInputProps, getRootProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        disabled: isEditing,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setProfileImage(file);
                    // setProfile(URL.createObjectURL(file));
                    formik.setFieldValue('profileImage', URL.createObjectURL(file));
                }
                else 
                {
                    setProfileImage(null);
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image',
                        severity: 'warning',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name cannot be empty'),
        lastName: Yup.string()
            .required('Last Name cannot be empty'),
        // orgName: Yup.string()
        //     .required('Organisation Name cannot be empty')
    });    

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnMount:false,
        initialValues: {
            firstName: userInfo?.firstName ? userInfo?.firstName : '',
            lastName: userInfo?.lastName ? userInfo?.lastName : '',
            profileImage: userInfo?.additionalInfo?.image ? userInfo?.additionalInfo?.image : undefined,
        },
        // onSubmit: async (): Promise<void> => 
        // {
        //     setSpinner(true);

        //     let imageUpdated: string, userUpdated: User;
        //     if (userInfo.additionalInfo.image !== profileImage) 
        //     {
        //         const image = new FormData();
        //         image.append('file', profileImage);
        //         try 
        //         {
        //             imageUpdated = await uploadUserImage(image, csrfTokenData);
        //         }
        //         catch (error) 
        //         {
        //             console.log(error);
        //         }
        //     } 
        //     else setSpinner(false);

        //     if (userInfo.firstName !== formik.values.firstName || userInfo.lastName !== formik.values.lastName) 
        //     {
        //         const userDetails = {
        //             firstName: formik.values.firstName,
        //             lastName: formik.values.lastName,
        //         };
        //         try 
        //         {
        //             userUpdated = await updateGeneralSettings(userDetails);
        //             if (userUpdated) 
        //             {
        //                 LocalStorage.set('@UserMe', {
        //                     firstName: userUpdated.firstName,
        //                     lastName: userUpdated.lastName,
        //                 });
        //             }
        //         }
        //         catch (error) 
        //         {
        //             console.log(error);
        //         }
        //     }
        //     else setSpinner(false);

        //     if (imageUpdated || userUpdated) 
        //     {
        //         setSpinner(false);
        //         setRefresh(true);
        //     }
        // }
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);

            const imageFormData = new FormData();
            imageFormData.append('file', profileImage);

            const imagePromise = ((userInfo?.additionalInfo?.image !== formik.values?.profileImage) && profileImage)
                ? uploadUserImage(imageFormData, csrfTokenData)
                : Promise.resolve();

            const userPromise = userInfo.firstName !== formik.values.firstName || userInfo.lastName !== formik.values.lastName
                ? updateGeneralSettings({
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                })
                : Promise.resolve();

            try 
            {
                const [imageUpdated, userUpdated] = await Promise.all([imagePromise, userPromise]);

                if (userUpdated) 
                {
                    LocalStorage.set('@UserMe', {
                        firstName: userUpdated.firstName,
                        lastName: userUpdated.lastName,
                    });
                }

                if (imageUpdated || userUpdated) 
                {
                    setRefresh(true);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally 
            {
                setSpinner(false);
                setIsEditing(!isEditing);
            }
        }
    });    

    return (
        <Box id='globalBlock'>
            <Box id="PersonalPage">
                <Form noValidate onSubmit={(values): void => 
                {
                    return formik.handleSubmit(values);
                }} autoComplete="off">

                    <Box className="title-cont">
                        <Typography className="title-text">{'Edit Profile'}</Typography>
                        <CustomButton btnType={isEditing ? 'secondary' : 'primary'} disabled={!isEditing} name={'Edit'} onClick={(): void => 
                            {
                                    setIsEditing(!isEditing);
                            }} type={isEditing ? 'submit' : 'button'}  />
                    </Box>

                    <Box className="profile-form-cont">
                        <Box className="profileImage-and-button-cont">
                            <Box>
                                {
                                    formik.values?.profileImage ? 
                                        <Box className="profile-img-cont">
                                            <div {...getRootProps()}>
                                                <input {...getInputProps({
                                                    disabled: isEditing 
                                                })} />
                                                
                                                <img src={formik.values?.profileImage} alt="Preview" />
                                                
                                                {!isEditing && formik.values?.profileImage && (
                                                    <Box className="icon-box">
                                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(event): void => 
                                                        {
                                                            event.stopPropagation();
                                                            setProfileImage(null);
                                                            formik.setFieldValue('profileImage', null);    
                                                        }} />
                                                    </Box>
                                                )}
                                            </div>
                                        </Box> :
                                        <Box className="empty-profileImg-cont" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                                        </Box>
                                }
                            </Box>
                            <div />
                        </Box>
                            
                        <Box className="sidebar-container-spacing">
                            <FormLabelComponent label={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.LABEL.FIRST_NAME} />
                            <FormControlComponent
                                type='text'
                                placeholder={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.PLACEHOLDER.FIRST_NAME}
                                value={formik.values.firstName}
                                onChange={(event): void => 
                                {
                                    formik.setFieldValue('firstName', event.target.value);
                                }}
                                disabled={isEditing}
                            />
                            {formik.errors.firstName && formik.touched.firstName ? <Typography className="error-msg">{formik.errors.firstName}</Typography> : null}
                        </Box>

                        <Box className="sidebar-container-spacing">
                            <FormLabelComponent label={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.LABEL.LAST_NAME} />
                            <FormControlComponent
                                type='text'
                                placeholder={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.PLACEHOLDER.LAST_NAME}
                                value={formik.values.lastName}
                                disabled={isEditing}
                                onChange={(event): void => 
                                {
                                    formik.setFieldValue('lastName', event.target.value);
                                }}
                            />
                            {formik.errors.lastName && formik.touched.lastName ? <Typography className="error-msg">{formik.errors.lastName}</Typography> : null}
                        </Box>

                        <Box className="sidebar-container-spacing">
                            <FormLabelComponent label={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.LABEL.LOGGED_IN_EMAIL} />
                            <FormControlComponent
                                type='email'
                                placeholder={CONTENT.SETTINGS_PAGE.GENERAL.FORMS.PLACEHOLDER.LOGGED_IN_EMAIL}
                                value={userInfo?.email}
                                disabled
                            />
                        </Box>

                        {!isEditing && <Box className="sidebar-container-spacing profile-submit-button-cont">
                            <CustomButton btnType='primary' name='Save' loading={spinner} type='submit' />
                            <CustomButton btnType='secondary' name='Cancel' type='button' onClick={() => 
                                {
                                    setIsEditing(!isEditing);
                                    formik.resetForm();
                                }
                            } />
                        </Box>}
                    </Box>
                </Form>
            </Box>
            {/* <Box className='profile-organization-block'>
                {userInfo?.role === 3 && <OrganisationPageProvider><OrganizationPage /></OrganisationPageProvider>}
            </Box> */}
           
        </Box>
    );
};
export default PersonalPage;