import _ from "lodash";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import MuiChip from "../../../common/FormComponents/MuiChip";
import { buildInitialsForPicture } from "../../../scripts/helpers";

export const generateColumnsForSponsorPortalTables = (data, setAnchorEl, anchorEl, ignoreClearbitName?: boolean) => 
{
    const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];
    const columns = [];
    data.forEach((item: {
        field?: string;
        hide?: boolean;
        headerName?: string;
        disableColumnMenu?: boolean;
    }): void => 
    {
        if (item.field === 'companyLogo') 
        {
            columns.push({
                field: item.field,
                sortable: false,
                filterable: false,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    const clearbitFName = params.row?.clearbitData?.givenName;
                    const clearbitLName = params.row?.clearbitData?.familyName;

                    const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : params?.row?.name ? buildInitialsForPicture('', params?.row?.name, '') : buildInitialsForPicture('', params.row?.firstName, '');
                    return <Box className="avatar-container"><Avatar className="logo-avatar-img" src={params.value ? params.value : ''}>{!params.value ? name : ''}</Avatar></Box>
                },
                valueGetter: (params): string => 
                {
                    return params.row?.clearbitData?.[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 60,
            });
        }
        else if (item.field === 'firstName') 
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    if (item.field === 'firstName') 
                    {
                        const clearbitFName = params.row?.clearbitData?.givenName;
                        const clearbitLName = params.row?.clearbitData?.familyName;
                        const name = clearbitFName && clearbitLName ? clearbitFName + ' ' + clearbitLName : params.value;
                        if(ignoreClearbitName)
                        {
                            return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params?.row?.firstName}</Typography>}><Box className="data-grid-bolded-cellContent">{params?.row?.firstName}</Box></Tooltip>;
                        }
                        else 
                        {
                            return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{name}</Typography>}><Box className="data-grid-bolded-cellContent">{name}</Box></Tooltip>;
                        }
                    }
                    else 
                    {
                        return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="data-grid-bolded-cellContent">{params.value}</Box></Tooltip>;
                    }
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 160
            });
        }
        else if (item.field === 'email') 
        {
            columns.push({
                field: item.field,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    return (<Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>);
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 160
            });
        }
        else if (item.field === 'lastName') 
        {
            columns.push({
                field: item.field,
                headerName: 'Last Name',
                renderCell: (params): React.ReactElement => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params?.row?.lastName || params?.row?.additionalData?.lastName || '-'}</Typography>}><Box className="MuiDataGrid-cellContent">{params?.row?.lastName || params?.row?.additionalData?.lastName || '-'}</Box></Tooltip>;
                },
                valueGetter: (params): string => 
                {
                    return params.row['lastName'] || params.row?.additionalData?.lastName || '-';
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 160
            });
        }
        else if (item.field === 'icp' || item.field === 'buyerPersona') 
        {

            columns.push({
                field: item.field,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    const icpcolor = item.field === 'icp' ? 'blue' : 'pink';

                    const handlePopoverOpen = (event, params): void => 
                    {
                        setAnchorEl({
                            id: params.id, field: params.field 
                        });
                    };
                    const handlePopoverClose = (): void => 
                    {
                        setAnchorEl(false);
                    };

                    return (
                        <Box
                            onMouseEnter={(event): void => 
                            {
                                handlePopoverOpen(event, params); 
                            }}
                            onMouseLeave={handlePopoverClose}
                        >
                            {anchorEl?.id === params?.id && anchorEl?.field === params?.field ? (
                                // sx={{ right: item.field === 'buyerPersona' ? 0 : null }}
                                <Box className="popupBlock">
                                    <Box className="tooltip-chip-cont">
                                        {params.value.map((tag: string, index: number): React.ReactElement => 
                                        {
                                            return (
                                                <MuiChip key={index} chipColor={icpcolor} label={tag} />
                                            ); 
                                        })}
                                    </Box>
                                </Box>
                            ) : (
                                <Box onMouseLeave={handlePopoverClose}>
                                    {params.value?.length > 0 ? (
                                        params.value?.length > 1 ? (
                                            <Box className="table-chip-cell">
                                                <MuiChip label={params.value[0]} chipColor={icpcolor}/>
                                                <Typography className="table-chip-count">
                                                    +{params.value?.length - 1}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <MuiChip label={params.value[0]} chipColor={icpcolor}/>
                                        )
                                    ) : null}
                                </Box>
                            )}
                        </Box>
                    );
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                width: 105,
                minWidth: 105
            });
        }
        else 
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>;
                },
                valueGetter: (params): string => 
                {
                    let value = '';
                    if (params.row?.clearbitData) 
                    {
                        const keys = item.field?.split('.');
                        let tempValue = params.row?.clearbitData;
                        for (let key of keys) {
                            if (tempValue && key in tempValue) {
                                tempValue = tempValue[key];
                            } else {
                                tempValue = undefined;
                                break;
                            }
                        }
                        if (tempValue !== undefined) {
                            value = tempValue;
                        }
                    }
                    if(params.row?.additionalData && item.field in params.row.additionalData)
                    {
                        value += params.row?.additionalData[item?.field];
                    }
                    if(params.row?.leadData && item.field in params.row.leadData)
                    {
                        value += params.row?.leadData[item?.field];
                    }
                    return value ? value : '';
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 140
            });
        }
    });

    const filteredColumns = columns.filter((column): boolean => 
    {
        return !excludedFields.includes(column.field); 
    });
    return filteredColumns;
};