import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Box } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import Manual from '../../components/Users/Manual';
import moment from 'moment';
import { MembersContext } from '../../contexts/Settings/MembersPageContext';
import { buildInitialsForPicture } from '../../scripts/helpers';
import { User } from '../../interfaces/settings/user_interface';
import MuiChip from '../../common/FormComponents/MuiChip';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { ActionsDropdown } from '../Events/EventBudget/EventBudgetDataGrid';
import { UserOrgRoles, UserOrgStatus } from './enum/users.enum';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../../redux/billing/billing-redux_interface';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import MembersPageToolbar from '../../components/Users/MembersPageToolbar';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';

import './styles.scss';

/**
 * @returns
 * Functional component to render the users data
 * Variable to store the logged in user information from the local storage
 * Variable to store the column information 
 * Variable to store the user rows data
 * Function to render the chip element in the row which contains user profile image
 * Function to render the user avatar and name of the user
 * Function to render a dropdown which handles the crud opertion in users page
 * Function to handle the deletion of the user
 * Function to handle the open & close of the side drawer
 * Function to handle the edit and view operations from the actions column and by clicking the grid cell
 */

const MembersPage: React.FC = (): React.JSX.Element => 
{

    const { 
        isEmpty, 
        usersCount, 
        userMeData, 
        rows, 
        setRefresh, 
        removeMemberFromTeam, 
        pageSize, 
        currentPage,
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        users,
        setUsers,
        selectedUser,
        setSelectedUser,
        showDeletePopup,
        setShowDeletePopup 
    } = useContext(MembersContext);
    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const userAccessOptions = {
        1: {
            name: CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[1],
            className: 'green',
        },
        2: {
            name: CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[2],
            className: 'blue',
        },
        3: {
            name: CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[3],
            className: 'orange',
        },
    };

    // const IconButtonGroupElement = (userInfo: User): React.ReactElement => 
    // {
    //     return MembersPageDropDown(userInfo);
    // };

    // const MembersPageDropDown = (userInfo: User): React.ReactElement => 
    // {
    //     const [anchorEl, setAnchorEl] = useState(null);

    //     const handleOpenDropdown = (event): void => 
    //     {
    //         setAnchorEl(event.currentTarget);
    //         event.stopPropagation();
    //     };

    //     const handleCloseDropdown = (): void => 
    //     {
    //         setAnchorEl(null);
    //         event.stopPropagation();
    //     };

    //     const accessControlEdit = Number(userOnline.role) === 3 && Number(userInfo.role) !== 3 && Number(userInfo.status) !== 3 && Number(userInfo.orgStatus) !== 3;

    //     const isNotaMember = Number(userOnline.role) === 3 && Number(userInfo.role) !== 3 && Number(userInfo.role) === 2;
    //     const isNotanAdmin = Number(userOnline.role) === 3 && Number(userInfo.role) !== 3 && Number(userInfo.role) === 1;

    //     const isAMember = userOnline.role === 1;
    //     const isAnAdmin = userOnline.role === 2;
    //     const isAnOwner = userOnline.role === 3;
    //     const invitedStatus = userInfo.orgStatus === 4;

    //     const handleConvertRole = async (toWhat: string): Promise<void> => 
    //     {

    //         if (toWhat === 'toMember') 
    //         {
    //             try 
    //             {
    //                 const userDetails = {
    //                     userIds: [String(userInfo.id)],
    //                     role: Number(1),
    //                 };
    //                 const userRoleUpdated = await editUser(userDetails);
    //                 if (userRoleUpdated) 
    //                 {
    //                     handleCloseDropdown();
    //                     // handleSnackbarOpen();
    //                     // <CustomSnackBar open={openSnackbar} message={userInfo.firstName + ' ' + userInfo.lastName + " 's status has been changed from"} ChipComponent={<RoleChangedChipComponent updatedRole={1} />} handleSnackbarClose={handleSnackbarClose} />
    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.UPDATE_EVENT, {
    //                         heading: <Box className="role-change-dialog-container"><Typography className="dialog-user-name">{userInfo.firstName + ' ' + userInfo.lastName}</Typography><Typography className="dialog-content-text">{'\'s status has been changed from'}</Typography></Box>,
    //                         dialogContent: <RoleChangedChipComponent updatedRole={1} />,
    //                         actionButton: <UndoComponent role={2} />,
    //                     });

    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.OPEN_EVENT, {
    //                         open: true
    //                     });

    //                     setRefresh(true);
    //                 }
    //             }
    //             catch (error) 
    //             {
    //                 console.log(error);
    //             }
    //         }
    //         else if (toWhat === 'toAdmin') 
    //         {
    //             try 
    //             {
    //                 const userDetails = {
    //                     userIds: [String(userInfo.id)],
    //                     role: Number(2),
    //                 };
    //                 const userRoleUpdated = await editUser(userDetails);
    //                 if (userRoleUpdated) 
    //                 {
    //                     handleCloseDropdown();

    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.UPDATE_EVENT, {
    //                         heading: <Box className="role-change-dialog-container"><Typography className="dialog-user-name">{userInfo.firstName + ' ' + userInfo.lastName}</Typography><Typography className="dialog-content-text">{' \'s status has been changed from'}</Typography></Box>,
    //                         dialogContent: <RoleChangedChipComponent updatedRole={2} />,
    //                         actionButton: <UndoComponent role={1} />,
    //                     });

    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.OPEN_EVENT, {
    //                         open: true
    //                     });

    //                     // handleSnackbarOpen();
    //                     // <CustomSnackBar open={openSnackbar} message={userInfo.firstName + ' ' + userInfo.lastName + " 's status has been changed from"} ChipComponent={<RoleChangedChipComponent updatedRole={2} />} handleSnackbarClose={handleSnackbarClose} />
    //                     setRefresh(true);
    //                 }
    //             }
    //             catch (error) 
    //             {
    //                 console.log(error);
    //             }
    //         }
    //         else if (toWhat === 'toOwner') 
    //         {
    //             try 
    //             {
    //                 const userDetails = {
    //                     userIds: [String(userInfo.id)],
    //                     role: Number(3),
    //                 };
    //                 const userRoleUpdated = await editUser(userDetails);
    //                 if (userRoleUpdated) 
    //                 {
    //                     handleCloseDropdown();

    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.UPDATE_EVENT, {
    //                         heading: <Box className="role-change-dialog-container"><Typography className="dialog-user-name">{userInfo.firstName + ' ' + userInfo.lastName}</Typography><Typography className="dialog-content-text">{' \'s status has been changed from'}</Typography></Box>,
    //                         dialogContent: <RoleChangedChipComponent updatedRole={3} />,
    //                         actionButton: null,
    //                     });

    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.OPEN_EVENT, {
    //                         open: true
    //                     });

    //                     // handleSnackbarOpen();
    //                     // <CustomSnackBar open={openSnackbar} message={userInfo.firstName + ' ' + userInfo.lastName + " 's status has been changed from"} ChipComponent={<RoleChangedChipComponent updatedRole={2} />} handleSnackbarClose={handleSnackbarClose} />
    //                     setRefresh(true);
    //                 }
    //             }
    //             catch (error) 
    //             {
    //                 console.log(error);
    //             }
    //         }
    //     };

    //     const handleCancelRequest = async (): Promise<void> => 
    //     {
    //         try 
    //         {
    //             const userDeleted = await removeUser(userInfo.id);
    //             if (userDeleted) 
    //             {
    //                 setUsersCount(rows.length - 1);
    //                 setRefresh(true);

    //                 handleCloseDropdown();

    //                 eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.UPDATE_EVENT, {
    //                     heading: <Box className="role-change-dialog-container"><Typography className="dialog-user-name">{'Request has been cancelled successfully'}</Typography></Box>,
    //                     dialogContent: null,
    //                     actionButton: null,
    //                     removalSnackbar: true,
    //                 });

    //                 eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.OPEN_EVENT, {
    //                     open: true
    //                 });
    //             }
    //         }
    //         catch (error) 
    //         {
    //             console.log(error);
    //         }
    //     };

    //     const handleRemoveMember = (): void => 
    //     {
    //         const fullName = userInfo.firstName + ' ' + userInfo.lastName;

    //         eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.UPDATE_EVENT, {
    //             heading: 'Remove Member ?',
    //             subHeading: 'Are you sure you want to remove ' + fullName + ' from team?',
    //             acceptButton: 'Remove',
    //             cancelButton: 'Cancel',
    //             acceptEvent: 'remove-member',
    //             acceptObj: {
    //                 id: userInfo.id
    //             }
    //         });

    //         eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG_SECONDARY.OPEN_EVENT, {
    //             open: true
    //         });

    //         removeMemberFromTeam();
    //     };

    //     const ConvertToMemberComponent = (): React.ReactElement => 
    //     {
    //         return (
    //             <Box className="member-convert-component" onClick={(): void => 
    //             {
    //                 handleConvertRole('toMember'); 
    //             }}>
    //                 <FontAwesomeIcon icon={['fal', 'user-check']} className="member-convert-icon" />
    //                 <Typography className="members-dropdown-text">{CONTENT.SETTINGS_PAGE.USERS.DROPDOWN_ITEM[5]}</Typography>
    //             </Box>
    //         );
    //     };

    //     const ConvertToAdminComponent = (): React.ReactElement => 
    //     {
    //         return (
    //             <Box className="admin-convert-component" onClick={(): void => 
    //             {
    //                 handleConvertRole('toAdmin'); 
    //             }}>
    //                 <FontAwesomeIcon icon={['fal', 'user-shield']} className="admin-convert-icon" />
    //                 <Typography className="admin-dropdown-text">{CONTENT.SETTINGS_PAGE.USERS.DROPDOWN_ITEM[4]}</Typography>
    //             </Box>
    //         );
    //     };

    //     const ConvertToOwnerComponent = (): React.ReactElement => 
    //     {
    //         return (
    //             <Box className="owner-convert-component" onClick={(): void => 
    //             {
    //                 handleConvertRole('toOwner'); 
    //             }}>
    //                 <FontAwesomeIcon icon={['fal', 'user-crown']} className="owner-convert-icon" />
    //                 <Typography className="owner-dropdown-text">{CONTENT.SETTINGS_PAGE.USERS.DROPDOWN_ITEM[6]}</Typography>
    //             </Box>
    //         );
    //     };

    //     const CancelRequestComponent = (): React.ReactElement => 
    //     {
    //         return (
    //             <Box className="cancel-request-component" onClick={handleCancelRequest}>
    //                 <FontAwesomeIcon icon={['fal', 'pen-to-square']} className="cancel-request-icon" />
    //                 <Typography className="cancel-request-text">{CONTENT.SETTINGS_PAGE.USERS.DROPDOWN_ITEM[7]}</Typography>
    //             </Box>
    //         );
    //     };

    //     const RoleChangedChipComponent = (props): React.ReactElement => 
    //     {

    //         const chipLabel = userAccessOptions[Number(userInfo.role)]['name'];
    //         const roleChipColor = userAccessOptions[Number(userInfo.role)]['className'];
    //         const updatedChipLabel = userAccessOptions[Number(props.updatedRole)].name;
    //         const updatedChipColor = userAccessOptions[Number(props.updatedRole)].className;

    //         return (
    //             <div>
    //                 <Box className="users-role-chip-container">
    //                     <Box display={'flex'} alignItems={'center'} gap={'12px'}>
    //                         <MuiChip label={chipLabel} chipColor={roleChipColor} />
    //                         <FontAwesomeIcon icon={['fal', 'arrow-right']} />
    //                         <MuiChip label={updatedChipLabel} chipColor={updatedChipColor} />
    //                     </Box>
    //                 </Box>
    //             </div>
    //         );
    //     };

    //     const RemoveComponent = (): React.ReactElement => 
    //     {
    //         return (
    //             <Box className="remove-component" onClick={handleRemoveMember}>
    //                 <FontAwesomeIcon icon={['fal', 'user-xmark']} className="user-remove-icon" />
    //                 <Typography className="remove-dropdown-text">{CONTENT.SETTINGS_PAGE.USERS.DROPDOWN_ITEM[3]}</Typography>
    //             </Box>
    //         );
    //     };

    //     const UndoComponent = (props): React.ReactElement => 
    //     {
    //         const handleUndo = async (): Promise<void> => 
    //         {
    //             try 
    //             {
    //                 const userDetails = {
    //                     userIds: [String(userInfo.id)],
    //                     role: props.role,
    //                 };

    //                 const userRoleUpdated = await editUser(userDetails);
    //                 if (userRoleUpdated) 
    //                 {
    //                     eventBus.dispatch(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.CLOSE_EVENT, true);
    //                     setRefresh(true);
    //                 }
    //             }
    //             catch (error) 
    //             {
    //                 console.log(error);
    //             }
    //         };

    //         return (
    //             <Box className="undo-component" onClick={handleUndo}>
    //                 <FontAwesomeIcon icon={['fal', 'arrow-rotate-left']} />
    //                 <Typography className="undo-text">{'Undo'}</Typography>
    //             </Box>
    //         );
    //     };

    //     if (isAnOwner && !isAMember && !isAnAdmin && Number(userInfo.role) !== 3 && !invitedStatus) 
    //     {
    //         return (
    //             <div>
    //                 <IconButton className="actions-column-button" onClick={handleOpenDropdown}>
    //                     <FontAwesomeIcon icon={['fal', 'ellipsis-vertical']} />
    //                 </IconButton>
    //                 <Popover
    //                     open={Boolean(anchorEl)}
    //                     anchorEl={anchorEl}
    //                     onClose={handleCloseDropdown}
    //                     anchorOrigin={{
    //                         vertical: 'bottom',
    //                         horizontal: 'right',
    //                     }}
    //                     transformOrigin={{
    //                         vertical: 'top',
    //                         horizontal: 'left',
    //                     }}
    //                 >
    //                     <div id="membersDropdownBlock">
    //                         {accessControlEdit && Number(userOnline.role) === 3 && Number(userInfo.role) !== 3 ? <div className="drop-down-menu">{isNotanAdmin ? <ConvertToAdminComponent /> : isNotaMember ? <ConvertToMemberComponent /> : ''}</div> : ''}
    //                         {accessControlEdit && <div className="drop-down-menu"><ConvertToOwnerComponent /></div>}
    //                         <Divider />
    //                         {
    //                             accessControlEdit && Number(userOnline.role) === 3 && Number(userInfo.role) !== 3 ? <div className="drop-down-menu" ><RemoveComponent /></div> : ''
    //                         }
    //                     </div>
    //                 </Popover>
    //             </div>
    //         );
    //     }
    //     else if (isAnOwner && (Number(userOnline.role !== 1) || Number(userOnline.role !== 2)) && invitedStatus) 
    //     {
    //         return (
    //             <Box id="actions">
    //                 <div>
    //                     <IconButton className="actions-column-button" onClick={handleOpenDropdown}>
    //                         <FontAwesomeIcon icon={['fal', 'ellipsis-vertical']} />
    //                     </IconButton>
    //                     <Popover
    //                         open={Boolean(anchorEl)}
    //                         anchorEl={anchorEl}
    //                         onClose={handleCloseDropdown}
    //                         anchorOrigin={{
    //                             vertical: 'bottom',
    //                             horizontal: 'right',
    //                         }}
    //                         transformOrigin={{
    //                             vertical: 'top',
    //                             horizontal: 'left',
    //                         }}
    //                     >
    //                         <div id="membersDropdownBlock">
    //                             <div className="drop-down-menu"><CancelRequestComponent /></div>
    //                         </div>
    //                     </Popover>
    //                 </div>
    //             </Box>
    //         );
    //     }
    // };

    const openDrawer = (userInfo?: User): void => 
    {
       
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: userInfo ? 'Edit Role' : 'Invite Member',
            component: <Manual setRefresh={setRefresh} userInfo={userInfo} />,
        });
        
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const columnHelper = createColumnHelper<User>();

    const generateUserColumns = () =>
    [
        columnHelper.accessor('additionalInfo.image', {
            cell: (row) => {
                const profilePicture = row.row.original?.additionalInfo && row.row.original?.additionalInfo.image ? row.row.original?.additionalInfo.image : '';
                return (profilePicture !== '' ? <Avatar sx={{
                    width: '32px', height: '32px', borderRadius: '20px' 
                }} src={profilePicture}></Avatar> : <Avatar className="empty-user-avatar">
                    <p>{buildInitialsForPicture(row.row.original.email, row.row.original?.firstName, row.row.original?.lastName)}</p>
                </Avatar>)
            },
            header: 'Photo',
            size: 40,
            maxSize: 40,
            id: 'photo',
            enableSorting: false,
            enableResizing: false
        }),
        columnHelper.accessor('firstName', {
            cell: (row) => {
                return <p className="bolded-cellContent">{row.row.original.firstName ? row.row.original.firstName : '-'} {row.row.original.lastName ? row.row.original.lastName : '-'}</p>
            },
            header: 'Name',
            id: 'name',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('email', {
            cell: (row) => { 
                return <p className="cellContent">{row.getValue()}</p>
            },
            header: 'Email',
            id: 'email',
            sortingFn: 'alphanumericCaseSensitive',
        }),
        columnHelper.accessor('lastActive', {
            cell: (row) => {
                return <p className="cellContent">{row.row.original.orgStatus === 4 ? 'Request Sent' : moment(row.row.original?.lastActive).format('DD MMMM, YYYY')}</p>
            },
            header: 'Last Active',
            id: 'lastActive',
            sortingFn: (a, b) => { 
                if (a.original?.orgStatus === UserOrgStatus.INVITED && b.original?.orgStatus === UserOrgStatus.INVITED) return 0;
                if (a.original?.orgStatus === UserOrgStatus.INVITED) return 1;
                if (b.original?.orgStatus === UserOrgStatus.INVITED) return -1;
                return moment(a.original?.lastActive).diff(moment(b.original?.lastActive));
            }
        }),
        columnHelper.accessor('role', {
            cell: (row) => {
                const chipLabel = userAccessOptions[row.row.original.role as keyof typeof userAccessOptions]['name'];
                const userOptionsChipColor = userAccessOptions[row.row.original.role as keyof typeof userAccessOptions]['className'];
                return (
                    <Box className="users-role-chip-container">
                        <MuiChip label={chipLabel} chipColor={userOptionsChipColor as 'green' | 'orange' | 'grey'} />
                    </Box>
                );
            },
            header: 'Role',
            id: 'role',
            sortingFn: (a, b) => { 
                return a.original?.role - b.original?.role;
            }
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                if (userMeData && (Number(userMeData.role) === UserOrgRoles.SUPERADMIN) && (row.row.original?.email !== userMeData?.email))
                {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ActionsDropdown 
                                onEditClick={() => openDrawer(row.row.original)} 
                                onDeleteClick={() => { 
                                    setSelectedUser(row.row.original);
                                    setShowDeletePopup(true);
                                }} 
                            />
                        </div>
                    );
                }
            },
            size: 40,
            header: '',
            id: 'actions',
            enableSorting: false,
            enableResizing: false,
        })
    ];

    const [allColumns, setAllColumns] = useState(generateUserColumns());

    useEffect(() => {
        setAllColumns(generateUserColumns());
    }, [userMeData])

    return (
        <Box id="access">
            <HeaderBar 
                title="Members"
                buttons={
                    (userMeData?.role && Number(userMeData.role) === UserOrgRoles.SUPERADMIN) ?
                        [<CustomButton btnType='secondary' name='+ Add Member' onClick={() => 
                        {
                            openDrawer(); 
                        }} 
                        />] : []
                }
            />
            <div className="h-100">
                <MembersPageToolbar 
                    columns={generateUserColumns()}
                    setColumns={setAllColumns}
                    count={usersCount}
                    rows={rows}
                    setRows={setUsers}
                />
                <TanstackTable 
                    initialColumns={allColumns}
                    data={users}
                    showSpinner={showSpinner}
                    pageSize={pageSize}
                    currentPage={currentPage - 1}
                    updatePageSize={updatePageSize}
                    updateCurrentPage={updateCurrentPage as any}
                    rowCount={usersCount}
                    rightPinnedColumns={['actions']}
                    height='calc(100% - 124px)'
                    onRowClick={(row) => {
                        if((userMeData?.role && Number(userMeData.role) === UserOrgRoles.SUPERADMIN && !(row?.orgStatus === UserOrgStatus.INVITED) && !(row?.role === UserOrgRoles.SUPERADMIN)))
                        {
                            openDrawer(row);
                        }
                    }}
                />
            </div>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Yes' 
                        acceptClick={() => (removeMemberFromTeam(selectedUser as User))} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedUser(null);
                        }} 
                        modalContent={`Are you sure you want to remove ${selectedUser?.firstName} ?`}
                        modalTitle='Remove User'
                        show={showDeletePopup}
                        rejectBtn='No'
                    />
            }
        </Box>
    );
};

export default MembersPage;