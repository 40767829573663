import { useSelector } from "react-redux";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { AutocompletewithTags, CloseIconComponent, FormControlComponent, FormLabelComponent, RadioGroupComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { useEffect, useState } from "react";
import { EventTicket } from "../../../pages/Events/interfaces";
import { getAlleventTickets, ticketsCount } from "../../../scripts/apis/eventTickets";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { MuiDateTimeRangePicker } from "../../../common/FormComponents/DateTimePickers";
import moment from "moment";
import { Stack } from "@mui/material";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import { EventCoupon } from "../../../pages/Events/interfaces/event-coupon_interface";
import { createCoupon, deleteCoupon, updateCoupon } from "../../../scripts/apis/eventTicketCoupons";
import _ from "lodash";
import { TicketType } from "../../../pages/Events/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeletePopup from "../../../common/DeletePopup";
import toast from "react-hot-toast";

import './styles.scss';

interface IAddCouponProps
{
    eventId: string | number;
    existingCouponData?: EventCoupon | undefined;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    isTableView?: boolean;
}

const AddCoupon: React.FC<IAddCouponProps> = (props): React.JSX.Element =>
{

    const { eventId, existingCouponData, setRefresh, isTableView } = props;

    const isCouponDataEditable = existingCouponData ? Number(existingCouponData?.couponsCount) !== 0 : false;

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const ticketTypeOptions = [
        {  
            name: 'All', value: 'all', description: 'All Tickets will be available for this coupon code'
        },
        {
            name: 'Custom', value: 'custom', description: 'Select specific tickets for this coupon code'
        }
    ];

    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const [expiredTickets, setExpiredTickets] = useState<string[]>([]);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [minMaxTime, setMinMaxTime] = useState<{min: string, max: string}>({min: '', max: ''});
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
    };

    const handleTicketChange = (event: any, newValue: string[], setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | any>): void =>
    {
        setFieldValue('ticketNames', newValue);

        if(newValue?.length === eventTickets?.length)
        {
            setFieldValue('ticketSelection', 'all');
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z0-9-_]*$/, 'Only alphabets and numbers are allowed')
            .required('Name is required'),
        totalCouponsAvailable: Yup.number().required('Total Coupons Available is required'),
        ticketSelection: Yup.string(),
        ticketNames: Yup.array().of(Yup.string()).test('isTicketNamesRequired', 'Ticket Names is required', function(value) { 
            if(eventTickets?.length > 0 && value?.length === 0 && this.parent.ticketSelection === 'custom')
            {
                return false;
            }
            return true;
        }),
        description: Yup.string(),
        couponStartDateTime: Yup.string().required('Coupon Start Date Time is required'),
        couponEndDateTime: Yup.string().required('Coupon End Date Time is required'),
        couponValueInCurrency: Yup.string().test('isCurrencyValueRequired', 'Coupon Value is required', function(value) { 
            if(!this.parent.couponValueInPercentage && !value)
            {
                return false;
            }
            return true;
        }),
        couponValueInPercentage: Yup.string().test('isPercentageValueRequired', 'Coupon Value is required', function(value) { 
            if(!this.parent.couponValueInCurrency && !value)
            {
                return false;
            }
            return true;
        }),
    });

    const deleteCouponFn = async (coupon: EventCoupon): Promise<void> =>
    {    
        try 
        {
            const couponDeleted = await deleteCoupon(eventId, coupon.id as string);
            if (couponDeleted) 
            {
                setShowDeletePopup(false);
                handleDrawerClose();
                toast.success('Coupon Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }   
    };

    const handleDeleteCoupon = (): void =>
    {
        setShowDeletePopup(true);
    };

    const fetchEventTickets = async (): Promise<void> =>
    {
        try 
        {
            const count = await ticketsCount(eventId);
            if(count)
            {
                const tickets = await getAlleventTickets(count, 0, Number(eventId));
                if(tickets)
                {
                    const ticketsWithPrice = tickets?.filter((ticket) => ticket?.type === TicketType.PAID);
                    setEventTickets(ticketsWithPrice);

                    const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                    const expiredTickets = ticketsWithPrice?.filter((ticket) => ticket?.saleCloseDateTime < currentTimestamp)?.map((ticket) => ticket?.name) || [];
                    setExpiredTickets(expiredTickets);

                    const validTickets = ticketsWithPrice.filter(ticket => !expiredTickets.includes(ticket.name));

                    const earliestTicket = validTickets.reduce((prev, current) => (prev.saleCloseDateTime < current.saleStartDateTime ? prev : current), validTickets[0]);
                    const latestTicket = validTickets.reduce((prev, current) => (prev.saleCloseDateTime > current.saleStartDateTime ? prev : current), validTickets[0]);
 
                    setMinMaxTime({
                        min: moment.unix(Number(earliestTicket.saleStartDateTime)).format('YYYY-MM-DD hh:mm A'),
                        max: moment.unix(Number(latestTicket.saleCloseDateTime)).format('YYYY-MM-DD hh:mm A')
                    })
                }
            }
        } 
        catch (error) 
        {
            console.log(error, 'Error in fetching event tickets')
        }
    }

    useEffect(() => {
        fetchEventTickets();
    }, []);

    return (
        <div id="addCoupon">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Formik
                initialValues={{
                    name: existingCouponData?.name || '',
                    totalCouponsAvailable: existingCouponData?.totalCouponsAvailable || '',
                    ticketSelection: existingCouponData?.ticketIds?.length === eventTickets?.length ? 'all' : 'custom',
                    ticketNames: existingCouponData ? existingCouponData?.tickets?.map((ticket) => ticket?.name) || [] : [],
                    description: existingCouponData?.description || '',
                    couponStartDateTime: existingCouponData?.couponCodeStartDateTime ? moment.unix(Number(existingCouponData?.couponCodeStartDateTime)).format('MM/DD/YYYY hh:mm A') : '',
                    couponEndDateTime: existingCouponData?.couponCodeCloseDateTime ? moment.unix(Number(existingCouponData?.couponCodeCloseDateTime)).format('MM/DD/YYYY hh:mm A') : '',
                    couponValueInCurrency: existingCouponData?.couponValue || '',
                    couponValueInPercentage: existingCouponData?.couponPercentage || '',
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    setSpinner(true);

                    const filteredEventTicketIds = eventTickets?.filter((ticket) => !expiredTickets?.includes(ticket?.name))?.map((ticket) => ticket?.id);

                    const ticketIds = values?.ticketSelection === 'all' ? filteredEventTicketIds : eventTickets.filter((ticket) => values.ticketNames?.includes(ticket?.name)).map((ticket) => ticket.id);

                    const data = {
                        name: values.name,
                        totalCouponsAvailable: values.totalCouponsAvailable,
                        ticketIds: ticketIds || [],
                        description: values.description,
                        couponCodeStartDateTime: moment(values.couponStartDateTime).unix(),
                        couponCodeCloseDateTime: moment(values.couponEndDateTime).unix(),
                        couponValue: values.couponValueInCurrency !== '' ? values.couponValueInCurrency : null,
                        couponPercentage: values.couponValueInPercentage !== '' ? values.couponValueInPercentage : null,
                    };

                    const updatedPayload = _.pickBy(data, (value) => value !== null && value !== undefined && value !== '');

                    if(existingCouponData)
                    {
                        try 
                        {
                            const couponCreated = await updateCoupon(eventId, existingCouponData?.id || '', updatedPayload);
                            if(couponCreated)
                            {
                                setRefresh(true);
                                handleDrawerClose();
                            }
                        } 
                        catch (error) {
                            console.log(error, 'Error in updating coupon');
                        }   
                        finally
                        {
                            setSpinner(false);
                        }
                    }
                    else
                    {
                        try 
                        {
                            const couponCreated = await createCoupon(eventId, updatedPayload, csrfTokenData);
                            if(couponCreated)
                            {
                                setRefresh(true);
                                handleDrawerClose();
                            }
                        } 
                        catch (error) {
                            console.log(error, 'Error in creating coupon');
                        }   
                        finally
                        {
                            setSpinner(false);
                        }
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }): React.ReactElement => 
                {
                    return (
                        <Form onSubmit={handleSubmit} noValidate>
                            
                            {/* Coupon Name */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Coupon Name" required />
                                <FormControlComponent 
                                    type="text"
                                    name="name"
                                    placeholder="General"
                                    value={values.name}
                                    disabled={isCouponDataEditable}
                                    onChange={(event) => {
                                        setFieldValue('name', event.target.value.toUpperCase())
                                    }}
                                    isInvalid={(touched.name && errors.name) as boolean}
                                />
                                {errors.name && touched.name ? <div className="error-msg">{errors.name}</div> : undefined}
                                <p className="description-text">{'Customers can also access this code via custom URL'}</p>
                            </div>

                            {/* Total Coupons Available */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Total Coupons Available" required />
                                <FormControlComponent 
                                    type="number"
                                    name="totalCouponsAvailable"
                                    disabled={isCouponDataEditable}
                                    placeholder="100"
                                    value={values.totalCouponsAvailable}
                                    onChange={handleChange}
                                    isInvalid={(touched.totalCouponsAvailable && errors.totalCouponsAvailable) as boolean}
                                />
                                {errors.totalCouponsAvailable && touched.totalCouponsAvailable ? <div className="error-msg">{errors.totalCouponsAvailable}</div> : undefined}
                                <p className="description-text">{'Total number of tickets that can be purchased with this code'}</p>
                            </div>

                            {/* Tickets */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Tickets" required />
                                <RadioGroupComponent disabled={isCouponDataEditable} value={values.ticketSelection} onChange={function (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void 
                                {
                                    setFieldValue('ticketSelection', event.target.value);
                                } } options={ticketTypeOptions}  />
                            </div>

                            {/* Ticket selection */}
                            {values.ticketSelection === 'custom' && <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Select Tickets" required />
                                <AutocompletewithTags 
                                    defaultValue={values?.ticketNames} 
                                    disabled={isCouponDataEditable}
                                    value={values?.ticketNames} 
                                    options={eventTickets} 
                                    keyToShow='name' 
                                    onChange={(event, newValue) => { handleTicketChange(event, newValue, setFieldValue) }} 
                                    placeholder='Select Ticket' 
                                    disabledOptions={expiredTickets}
                                    onRemoveClick={(index): void => 
                                    {
                                        if(isCouponDataEditable)
                                        {
                                            return;
                                        }
                                        const newValues = [...values?.ticketNames];
                                        newValues.splice(index, 1);
                                        setFieldValue('ticketNames', newValues);
                                    }} 
                                />
                                {errors.ticketNames && touched.ticketNames ? <div className="error-msg">{errors.ticketNames}</div> : undefined}
                            </div>}

                            {/* Coupon Value */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Coupon Value" required />
                                <Stack direction={'row'} spacing={1}>
                                    <FormControlComponent 
                                        type="number"
                                        name="couponValueInCurrency"
                                        value={values.couponValueInCurrency}
                                        onChange={(event) => {
                                            setFieldValue('couponValueInCurrency', event.target.value);
                                            setFieldValue('couponValueInPercentage', '');
                                        }}
                                        asInputGroup
                                        disabled={isCouponDataEditable}
                                        inputGroupText="₹"
                                        required
                                        isInvalid={(touched.couponValueInCurrency && errors.couponValueInCurrency) as boolean}
                                    />
                                    <FormControlComponent 
                                        type="number"
                                        name="couponValueInPercentage"
                                        value={values.couponValueInPercentage}
                                        onChange={(event) => {
                                            setFieldValue('couponValueInPercentage', event.target.value);
                                            setFieldValue('couponValueInCurrency', '');
                                        }}
                                        asInputGroup
                                        disabled={isCouponDataEditable}
                                        inputGroupText="%"
                                        required
                                        isInvalid={(touched.couponValueInPercentage && errors.couponValueInPercentage) as boolean}
                                    />
                                </Stack>
                                {errors.couponValueInCurrency || errors.couponValueInPercentage ? <div className="error-msg">{'Either one value is required'}</div> : undefined}
                            </div>

                            {/* Description */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Description" />
                                <FormControlComponent 
                                    type="text"
                                    as="textarea"
                                    rows={3}
                                    name="description"
                                    placeholder="Enter a description"
                                    value={values.description}
                                    onChange={handleChange}
                                    isInvalid={(touched.description && errors.description) as boolean}
                                />
                                {errors.description && touched.description && <div className="error-msg">{errors.description}</div>}
                            </div>

                            {/* Coupon Date Time */}
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label="Coupon start and end Date" />
                                <MuiDateTimeRangePicker 
                                    value={[String(values.couponStartDateTime), String(values.couponEndDateTime)]} onChange={(newValue): void => 
                                    {
                                        setFieldValue('couponStartDateTime', newValue[0]?.format('MM/DD/YYYY hh:mm A'));
                                        setFieldValue('couponEndDateTime', newValue[1]?.format('MM/DD/YYYY hh:mm A')); 
                                    }}
                                    min={minMaxTime?.min}
                                    max={minMaxTime?.max}
                                />
                                <Stack direction={'row'} spacing={1}>
                                    {errors.couponStartDateTime && touched.couponStartDateTime ? <div className="error-msg">{errors.couponStartDateTime}</div> : <div/>}
                                    {errors.couponEndDateTime && touched.couponEndDateTime ? <div className="error-msg">{errors.couponEndDateTime}</div> : <div/>}
                                </Stack>
                            </div>

                            {/* Submit button */}
                            <div className="submit-btn-container">
                                {(!existingCouponData) ? <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                                    <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                    <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                                </Stack>
                                : (existingCouponData) &&
                                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                                    <Stack direction={'row'} spacing={1} display={'flex'}>
                                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                        <CustomButton btnType='primary' loading={spinner} name={!existingCouponData ? 'Update Ticket' : 'Save'} type='submit' />
                                    </Stack>
                                    {!isTableView && <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={handleDeleteCoupon} />}
                                </Stack>
                                }
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteCouponFn(existingCouponData as EventCoupon)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                        }} 
                        modalContent={`Are you sure you want to delete ${existingCouponData?.name}?`}
                        modalTitle='Delete Coupon'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </div>
    );
};

export default AddCoupon;