import { useState } from "react";
import FormTabs from "../FormTabs";
import TableFilter from "./TableFilter";
import ColumnVisibilityDropdown from "./ColumnVisibilityDropdown";

import './styles.scss';

interface ITanstackToolbar {
    rows: any[];
    setRows: React.SetStateAction<React.Dispatch<any[]>>;
    columns: any[];
    setColumns: React.SetStateAction<React.Dispatch<any[]>>;
    customFilterFunctions?: {[key: string]: (obj: any, inputValue: string | number) => boolean};
    tabs: { tabName: string, count: number, navigation: () => void, selectedTab: boolean }[];
}

const TanstackToolbar: React.FC<ITanstackToolbar> = ({ setRows, rows, columns, setColumns, tabs, customFilterFunctions }): React.JSX.Element =>
{
    const columnOptions = columns
        .filter(column => column.id !== 'actions' && column.id !== 'copyLink')
        .map(column => {
            return {
                label: typeof(column?.header) === 'string' ? column?.header : column?.meta?.headerName,
                value: column?.accessorKey || column?.id
            } 
        }
    );

    const [filters, setFilters] = useState([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].inputValue = value;
        setFilters(newFilters);
    };

    const handleColumnChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].selectedColumn = value;
        setFilters(newFilters);
    };

    const addFilter = () => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        const availableColumns = columnOptions.filter(option => !selectedColumns.includes(option.value));
        const defaultColumn = availableColumns.length > 0 ? availableColumns[0].value : '';
        setFilters([...filters, { selectedColumn: defaultColumn, inputValue: '' }]);
    };

    const removeFilter = (index: number) => 
    {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const clearFilters = () => 
    {
        setFilters([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
    };

    return (
        <div className="tanstack-toolbar">
            <FormTabs 
                tabs={tabs}
            />
            <div className="toolbar-actions">
                <TableFilter 
                    rows={rows}
                    columnOptions={columnOptions}
                    setRows={setRows}
                    filters={filters}
                    handleColumnChange={handleColumnChange}
                    handleInputChange={handleInputChange}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    clearFilters={clearFilters}
                    customFilterFunctions={customFilterFunctions || undefined}
                />
                <ColumnVisibilityDropdown 
                    columns={columns}
                    setColumns={setColumns}
                />
            </div>
        </div>
    )
};

export default TanstackToolbar;