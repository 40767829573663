import { useEffect, useMemo, useState } from "react";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import SponsorPortalDatagrid from "../../Components/Common/SponsorPortalDatagrid";
import { generateColumnsForSponsorPortalTables } from "../../helpers/sponsor-portal.helper";
import FormTabs from "../../../../common/FormTabs";
import eventBus from "../../../../scripts/event-bus";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToTargetList, getAllRegistrantsInSponsorPortal, getSponsorPortalRegistrantsCount, sponsorPortalRegistrants } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { columnProperties } from "./columnProperties";
import { GRID_CHECKBOX_SELECTION_FIELD } from "@mui/x-data-grid-pro";
import _ from "lodash";
import toast from "react-hot-toast";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { FormControlComponent } from "../../../../common/FormComponents/ReusableFormComponents";

const RegistrantsPage: React.FC = (): React.JSX.Element =>
{

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [registrantCount, setRegistrantCount] = useState<number>(0);
    const [registrants, setRegistrants] = useState<any[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRegistrants, setSelectedRegistrants] = useState<string[] | number[]>([]);
    const [addTargetListSpinner, setAddTargetListSpinner] = useState<boolean>(false);
    const [columnProperty, setColumnProperty] = useState<any[]>([]);

    const [searchText, setSearchText] = useState<string>('');
    const [componentMounted, setComponentMounted] = useState(false);

    const columns = generateColumnsForSponsorPortalTables(columnProperty?.filter((item) => item?.field !== 'email'), setAnchorEl, anchorEl, true);

    const RegistrantsToolbar = (): React.JSX.Element =>
    {

      const tabs = [
        {
          tabName: 'All',
          selectedTab: true,
          count: registrantCount,
          navigation: (): void => 
          {
            
          }
        }
      ];

      const addToTargetListSponsorPortal = async (): Promise<void> =>
      {
        setAddTargetListSpinner(true);
        try 
        {

          // const initialTargets = await getAllTargetList();
          // const initialTargetIds = initialTargets?.map((target: any) => target.id);
          
          // const updatedTargetListIds = [...initialTargetIds, ...selectedRegistrants]; 

          const targetListUpdated = await addToTargetList( { registrantIds: selectedRegistrants });
          if(targetListUpdated)
          {
            toast.success('Added to target list');
            eventBus.dispatch('selected-row-ids', []);
          }
        } 
        catch (error) 
        {
          toast.error('Failed to add to target list');
        }
        finally
        {
          setAddTargetListSpinner(false);
        }
      };

      return (
          <div className="registrants-toolbar">
            <FormTabs tabs={tabs} />
             
              <div className="button-container">
              {
                selectedRegistrants?.length > 0 && 
                  <>
                    <CustomButton name='Cancel' btnType="secondary" onClick={() => { eventBus.dispatch('selected-row-ids', []) }} />
                    <CustomButton name='Add to Target List' loading={addTargetListSpinner} btnType="primary" onClick={() => addToTargetListSponsorPortal() } startIcon={<FontAwesomeIcon icon={['fal', 'bullseye-arrow']} />} />
                  </>
              }
              </div>
            
          </div>
      )
    };

    const fetchData = async (): Promise<void> =>
    {
        try 
        {

          const count = await getSponsorPortalRegistrantsCount();
          if(count)
          {
            setRegistrantCount(count);
            const registrantsData = await getAllRegistrantsInSponsorPortal(pageSize, (currentPage - 1));
            setIsEmpty(registrantsData?.length === 0);
            setRegistrants(registrantsData);

            const initialColumnKeys = ['firstName', 'lastName'];
            const initialColumns = initialColumnKeys.map((field) => ({
                field,
                headerName: _.startCase(field),
                disableColumnMenu: true,
            }));

            if (registrantsData?.length > 0 && registrantsData.some(obj => 'additionalData' in obj)) {
              const additionalData = registrantsData.map(obj => _.omit(obj.additionalData, 'gdprData'));
              const additionalDataKeys = Object.keys(additionalData[0]);
              const additionalDataColumns = additionalDataKeys.map((field) => ({
                  field,
                  headerName: _.startCase(field),
                  // hide: !fieldsToShow.includes(field),
                  disableColumnMenu: true,
              }));
              setColumnProperty([...initialColumns, ...additionalDataColumns, ...columnProperties?.filter((item) => item?.field !== 'firstName' && item?.field !== 'lastName')]);
            }

          }
        } 
        catch (error) 
        {
            
        }
        finally
        {
          setShowSpinner(false);
        }
    }; 

    const fetchPaginationData = async (): Promise<void> =>
    {
      try 
      {
        const count = await getSponsorPortalRegistrantsCount();
        if(count)
        {
          setRegistrantCount(count);
          const registrantsData = await getAllRegistrantsInSponsorPortal(pageSize, (currentPage - 1));
          setIsEmpty(registrantsData?.length === 0);
          setRegistrants(registrantsData);

          const initialColumnKeys = ['firstName', 'lastName'];
          const initialColumns = initialColumnKeys.map((field) => ({
              field,
              headerName: _.startCase(field),
              disableColumnMenu: true,
          }));

          if (registrantsData?.length > 0 && registrantsData.some(obj => 'additionalData' in obj)) {
            const additionalData = registrantsData.map(obj => _.omit(obj.additionalData, 'gdprData'));
            const additionalDataKeys = Object.keys(additionalData[0]);
            const additionalDataColumns = additionalDataKeys.map((field) => ({
                field,
                headerName: _.startCase(field),
                // hide: !fieldsToShow.includes(field),
                disableColumnMenu: true,
            }));
            setColumnProperty([...initialColumns, ...additionalDataColumns, ...columnProperties?.filter((item) => item?.field !== 'firstName' && item?.field !== 'lastName')]);
          }
        }
      } 
      catch (error) 
      {
        
      }
      finally
      {
        setShowSpinner(false);
      }
    };

    useEffect(() => {
      // Component did mount
      setComponentMounted(true);

      eventBus.on('selected-row-ids', (ids: string[] | number[]) =>
      {
        setSelectedRegistrants(ids);
      })
    }, []);

    useEffect((): void => 
    {
        if (currentPage === 1 && pageSize) 
        {
          fetchData();
        }
        else if (currentPage > 1 && pageSize) 
        {
          fetchPaginationData();
        }
    }, [currentPage, pageSize]);

    useEffect(() => {
      const delayDebounceFn = setTimeout(async (): Promise<void> => {
        if (searchText?.length >= 2) 
        {
            try {
                const registrantData = await sponsorPortalRegistrants (searchText);

                if (registrantData) {
                  setRegistrants(registrantData);
                  setShowSpinner(false);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setShowSpinner(false);
            }
        } 
        else if (searchText === '' && componentMounted) 
        {
          fetchData();
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }, [searchText, componentMounted]);

    return (
      <div id="sponsorPortalRegistrantsPage" >
        <div style={{ height: 'calc(100vh - 120px)', maxHeight: 'calc(100vh - 120px)' }}>
            {
              !isEmpty &&
              <div className="search-container">
                <FormControlComponent 
                  placeholder="Search for a registrant"
                  type="text"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  startIcon={'search'}
                  clearable
                />
              </div>
            }
            {!isEmpty ? <SponsorPortalDatagrid 
              rows={registrants}
              columns={columns}
              isEmpty={isEmpty}
              pageSize={pageSize} 
              page={currentPage - 1}
              updateCurrentPage={updateCurrentPage} 
              updatePageSize={updatePageSize}
              showSpinner={showSpinner}
              isPagination={true}
              checkBoxSelection
              rowCount={registrantCount}
              customToolbar={RegistrantsToolbar}
              pinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD ,'companyLogo', 'firstName', 'lastName', 'icp', 'buyerPersona']}
            /> : <TableEmptyComponent infoText={'No Registrants available'}/>}
        </div>
      </div>
    )
};

export default RegistrantsPage;