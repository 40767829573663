// eslint-disable-next-line import/no-unresolved
import { EventSponsor, EventSponsorGraph, EventSponsorTier } from '../../pages/Events/interfaces';
import { ISponsor } from '../../pages/Sponsors/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getEventSponsorsCount = async (eventId: string | number): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventSponsors = async (pageSize: number, offset: number, eventId: string | number, sponsorId?: number, status?: number):Promise<ISponsor[]> => 
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        sponsorId,
        status,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventSponsorById = async (eventId: number, sponsorId: number):Promise<EventSponsor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/' + sponsorId;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventSponsors = async (eventId: number, EVENT_SPONSOR_DATA, csrfToken: string): Promise<EventSponsor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId;
    return triggerApi(API_URL, 'POST', EVENT_SPONSOR_DATA, csrfToken);
};

// export const updatEventSponsors = async (EVENT_SPONSOR_DATA, EVENT_ID) => {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + EVENT_ID;
//     return triggerApi(API_URL, 'PUT', EVENT_SPONSOR_DATA);
// };

export const removeEventSponsor = async (eventId: string | number, sponsorId: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/' + sponsorId;
    return triggerApi(API_URL, 'DELETE', '');
};

export const sponsorTierCount = async (eventId: string | number): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/tier/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllSponsorTiers = async (pageSize: number, offset: number, eventId: string | number, status?: number): Promise<EventSponsorTier[]> => 
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/tier?' + query + '&offset=0';
    return triggerApi(API_URL, 'GET', '');
};

export const getSponsorTierByTierId = async (eventId: number | string, tierId: number | string): Promise<EventSponsorTier> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/tier/' + tierId;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventSponsorGraphData = async (eventId: string | number): Promise<EventSponsorGraph> => 
{
    const query = convertJSONToGETParams({
        icp: true,
        buyerPersona: true,
        arr: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + eventId + '/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const updateEventSponsor = async (EVENT_ID: number, SPONSOR_ID: number, EVENT_SPONSOR_DATA): Promise<EventSponsor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/' + SPONSOR_ID;
    return triggerApi(API_URL, 'PUT', EVENT_SPONSOR_DATA);
};

export const createSponsorTier = async (EVENT_ID: number, SPONSOR_TIER_DATA, csrfToken: string): Promise<EventSponsorTier> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/tier';
    return triggerApi(API_URL, 'POST', SPONSOR_TIER_DATA, csrfToken);
};

export const updateSponsorTier = async (EVENT_ID: number, SPONSOR_TIER_ID: number, SPONSOR_TIER_DATA): Promise<EventSponsorTier> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/tier/' + SPONSOR_TIER_ID;
    return triggerApi(API_URL, 'PUT', SPONSOR_TIER_DATA);
};

export const deleteSponsorTier = async (EVENT_ID: number, SPONSOR_TIER_ID: number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-sponsors/' + EVENT_ID + '/tier/' + SPONSOR_TIER_ID;
    return triggerApi(API_URL, 'DELETE', '');
};