import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import './styles.scss';
import { Organization } from "../../../interfaces/settings/organization_interface";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";

const CopyCouponComponent: React.FC<{ couponCode: string, orgData: Organization | undefined, eventLink: string }> = ({ couponCode, orgData, eventLink }): React.JSX.Element => 
{ 
    return (
        <div id="copyCouponComponent">
            <div className="header">
                <h2>Share Discount Coupon</h2>
                <p>Share this link with attendees to automatically apply the code to their order.</p>
            </div>
            <div className="coupon-code-container">
                <FormLabelComponent label="Coupon Code" />
                <div className="coupon-value-container">
                    <FormControlComponent 
                        type="text"
                        value={`${APP_CONSTANTS.PROTOCOL}://${orgData?.link}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventLink}?coupon=${couponCode}`}
                        disabled={true}
                    />
                    <FontAwesomeIcon onClick={() => {
                        navigator.clipboard.writeText(`${APP_CONSTANTS.PROTOCOL}://${orgData?.link}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventLink}?coupon=${couponCode}`);  
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Link copied',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                    }} className="coupon-copy-icon" icon={['fal', 'copy']} />
                </div>
            </div>
        </div>
    )
};

export default CopyCouponComponent;