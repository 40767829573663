import React, { createContext, useEffect, useState } from "react";
import { useTablePagination } from "../TablePaginationContext";
import { deleteEventAccessControl, getAllEventAccessControl, getAllEventAccessControlCount } from "../../scripts/apis/eventAccessControl";
import { EventAccessControl } from "../../pages/Events/interfaces/event-access-control_interface";
import { getAllStage, getStageCount } from "../../scripts/apis/eventStage";
import { Event, EventStage } from "../../pages/Events/interfaces";
import eventBus from "../../scripts/event-bus";
import APP_CONSTANTS from "../../scripts/constants";
import { getEventById } from "../../scripts/apis/events";

interface IEventAccessControlContext { 
    rows: any[];
    isEmpty: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    showSpinner: boolean;
    eventId: string | number;
    pageSize?: number;
    updateCurrentPage?: (page: number) => void;
    updatePageSize?: (size: number) => void;
    eventAccessControlCount?: number;
    eventStages: EventStage[];
    deleteDeviceFromAccessControl: (id: string | number) => Promise<void>;
    disableAndEnableAccessForDevice: (id: string | number, status: number) => Promise<void>;
    event: Event;
}

export const EventAccessControlContext = createContext<IEventAccessControlContext>(null);

const EventAccessControlProvider: React.FC<{children: React.ReactNode, eventId: string | number}> = ({ children, eventId }) => { 

    const [rows, setRows] = useState<EventAccessControl[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [eventAccessControlCount, setEventAccessControlCount] = useState<number>(0);
    const [eventStages, setEventStages] = useState<EventStage[]>([]);
    const [event, setEvent] = useState<Event>();
    
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchAccessControlData = async() =>
    {
        try 
        {
            const count = await getAllEventAccessControlCount(eventId);
            if(count)
            {
                setEventAccessControlCount(count);
                const accessControlData = await getAllEventAccessControl(pageSize, currentPage - 1, eventId);
                if(accessControlData)
                {
                    setRows(accessControlData);
                    setIsEmpty(accessControlData.length === 0);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching Access control data', error);
        }
    };

    const fetchStageData = async ():Promise<void> => 
    {
        try 
        {
            const count = await getStageCount(eventId);
            if(count)
            {
                const eventStageData = await getAllStage(eventId, count, 0);
                if(eventStageData)
                {
                    setEventStages(eventStageData);
                }
            }
        } catch (error) 
        {
            console.log('Error in fetching Stage data', error);
        }
    };

    const fetchPaginationData = async ():Promise<void> => 
    {
        if(eventAccessControlCount)
        {
            try 
            {
                const count = await getAllEventAccessControlCount(eventId);
                if(count)
                {
                    setEventAccessControlCount(count);
                    const accessControlData = await getAllEventAccessControl(pageSize, currentPage, eventId);
                    if(accessControlData)
                    {
                        setRows(accessControlData);
                        setIsEmpty(accessControlData.length === 0);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                }
            } 
            catch (error) 
            {
                console.log('Error in fetching Access control data', error);
            }
        }
    };

    const deleteDeviceFromAccessControl = async (id: string | number): Promise<void> => 
    {
        if (rows?.length > 0) 
        {
            let deleteSuccess = true;
            if (deleteSuccess) 
            {
                if (id) 
                {
                    try 
                    {
                        const eventDeleted = await deleteEventAccessControl(eventId, id);
                        if (eventDeleted) 
                        {
                            setRefresh(true);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, true);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: 'Access Control Deleted',
                                severity: 'success',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                            deleteSuccess = false;
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }

                }
            }
        }
    };

    const disableAndEnableAccessForDevice = async (id: string | number): Promise<void> =>
    {        
        if(rows?.length > 0)
        {
            if(id)
            {
                // try 
                // {
                //     const disableAccess = await updateEventAccessControl({status: EventAccessControlStatus.DISABLED}, eventId, id);
                //     if(disableAccess)
                //     {
                //         setRefresh(true);
                //         eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, true);
                //         eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                //             open: true,
                //             message: 'Access Disabled',
                //             severity: 'success',
                //             positionVertical: 'top',
                //             positionHorizontal: 'right',
                //         });
                //     }
                // } 
                // catch (error) 
                // {
                    
                // }
            }
        }
    };
    
    const fetchEventData = async (): Promise<void> => {
        try {
            const event = await getEventById(eventId.toString());
            if(event){
                setEvent(event);
            }    
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };

    useEffect((): void => 
    {
        fetchEventData();
        if (currentPage === 1 && pageSize) 
        {
            fetchAccessControlData();
            fetchStageData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchPaginationData();
            fetchStageData();
        }
    }, [currentPage, pageSize]);

    useEffect(() =>
    {
        if(refresh)
        {
            fetchEventData();
            fetchStageData();
            fetchAccessControlData();
        }
    }, [refresh]);

    return (
        <EventAccessControlContext.Provider value={{
                rows, isEmpty, setRefresh, showSpinner, eventId, eventAccessControlCount, pageSize, updateCurrentPage, updatePageSize, eventStages, deleteDeviceFromAccessControl, disableAndEnableAccessForDevice, event
          }}>
            {children}
        </EventAccessControlContext.Provider>
    );
};

export default EventAccessControlProvider;