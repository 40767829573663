import { Audience, AudienceEventType, AudienceGraph, AudienceGraphList, AudienceProperties, AudienceTypeCount } from '../../pages/Audience/interfaces';
import { EventLocationTypeProperties } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const count = async (): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllAudience = async (pageSize: number, offset: number): Promise<Audience[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience?' + '&pageSize=' + pageSize + '&offset=' + offset;
    return triggerApi(API_URL, 'GET', '');
};

export const getAudienceById = async (AUDIENCE_ID: number): Promise<Audience> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/' + AUDIENCE_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const getAudienceColumnProperties = async (): Promise<AudienceProperties[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/properties';
    return triggerApi(API_URL, 'GET', '');
};

export const deleteAudience = async (audienceId: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/' + audienceId;
    return triggerApi(API_URL, 'DELETE', '');
};

export const getAudienceEventPreference = async (AUDIENCE_ID: number): Promise<AudienceTypeCount> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/type/' + AUDIENCE_ID + '/count';
    return triggerApi(API_URL, 'GET', '',);
};

export const getAudienceParticipatedEvents = async (AUDIENCE_ID: number): Promise<AudienceEventType[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/events/' + AUDIENCE_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const getAudienceGraphData = async ():Promise<AudienceGraph> => 
{
    const graphList: AudienceGraphList = {
        trend: true,
        icp: true,
        buyerPersona: true,
        arr: true,
        audienceDistribution: true,
        employeeCount: true,
        role: true,
        seniority: true,
        lifeCycleStage: true,
        dealStage: true,
        prospectCustomerSplitRevenue: true,
        prospectCustomerSplitEmpCount: true,
        revenueRecognizedByIcp: true,
        pipelineByIcp: true,
        icpToBpMapping: true
    };
    const query = convertJSONToGETParams(graphList);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/graph' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAudienceAttendance = async (): Promise<EventLocationTypeProperties> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/attendance';
    return triggerApi(API_URL, 'GET', '');
};

export const searchAudience = async (email?: string, firstName?: string): Promise<Audience[]> =>
{
    const searchParams = {
        email,
        firstName,
    };

    const filteredSearchParams = Object.fromEntries(Object.entries(searchParams).filter(([key, value]) => value !== undefined));

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/audience/search?' + convertJSONToGETParams(filteredSearchParams);
    return triggerApi(API_URL, 'GET', '');
};