import React, { useContext, useEffect, useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import './styles.scss';
import { resetICP } from '../../../redux/settings/ICP';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import { ICPPageContext } from '../../../contexts/Settings/ICPPageContext';
import ICP from './AddICP';
import SettingsTopAppBar from '../common/SettingsTopAppBar';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import { ARR, COUNTRY, EMPLOYEE_RANGE, INDUSTRY, TECH, TECH_CATEGORIES } from './icp-dataset';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import icpEmptyImg from '../../../assets/icons/empty-state/ICPEmptyState.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { ICP as IICP } from '../interface/icp_interface';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import TanstackToolbar from '../../../common/TanstackTable/TanstackToolbar';
import DeletePopup from '../../../common/DeletePopup';
import HeaderBar from '../../../common/Headerbar';
import { CustomButton } from '../../../common/FormComponents/Buttons';

const ICPPage = ():React.JSX.Element => 
{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { 
        isEmpty, 
        rows, 
        icpDataCount, 
        icp, 
        deleteICPFromTable, 
        setRefresh, 
        pageSize, 
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner, 
        showDeletePopup,
        setShowDeletePopup,
        selectedIcp,
        setSelectedIcp,
        setRows 
    } = useContext(ICPPageContext);

    let matchingTech: any[] = [];

    const CustomEditComponent = (props):React.JSX.Element => 
    {
        const { id, value, field } = props;
        
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {INDUSTRY.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomEmployeeRangeEditComponent = (props):React.JSX.Element => 
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {EMPLOYEE_RANGE.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomArrEditComponent = (props):React.JSX.Element => 
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {ARR.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomCountryEditComponent = (props):React.JSX.Element => 
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {COUNTRY.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomTechEditComponent = (props): React.JSX.Element =>
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        setTechCategoryChanges(props?.row?.techCategories);

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {matchingTech?.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomTechCategoriesEditComponent = (props): React.JSX.Element =>
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
        
        const handleChange = (event): void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });

            const selectedTechCategories = newValue.length > 0 ? newValue.map((elem: string):string => 
            {
                return _.find(TECH_CATEGORIES, (techCategories):boolean => 
                {
                    return elem === techCategories; 
                }); 
            }) : [];

            if(selectedTechCategories?.length > 0)
            {
                setTechCategoryChanges(selectedTechCategories);
            }
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {TECH_CATEGORIES.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomIndusryEditCell = (params):React.JSX.Element => 
    {
        return <CustomEditComponent {...params} />; 
    };

    const CustomEmployeeRangeEditCell = (params):React.JSX.Element => 
    {
        return <CustomEmployeeRangeEditComponent {...params} />; 
    };

    const CustomArrEditCell = (params):React.JSX.Element => 
    {
        return <CustomArrEditComponent {...params} />; 
    };

    const CustomCountryEditCell = (params):React.JSX.Element => 
    {
        return <CustomCountryEditComponent {...params} />; 
    };

    const CustomTechEditCell = (params): React.JSX.Element =>
    {
        return <CustomTechEditComponent {...params} />;
    };

    const CustomTechCategoriesEditCell = (params): React.JSX.Element =>
    {
        return <CustomTechCategoriesEditComponent {...params} />;
    };

    const setTechCategoryChanges = (value): void => 
    {
        if(value)
        {
            if (typeof (value) === 'string') 
            {
                const newValue = value.split(',');
                const techCategoriesPositions = [];
                    
                newValue.forEach((element):void => 
                {
                    const techCategoriesPosition = TECH_CATEGORIES.indexOf(element);
                    techCategoriesPositions.push(techCategoriesPosition);
                });
            
                if (techCategoriesPositions.length > 0) 
                {
                    matchingTech = [];
                    techCategoriesPositions.forEach((item):void => 
                    {
                        if (TECH[item].length > 0) 
                        {
                            TECH[item].map((tech):void => 
                            {
                                matchingTech.push(tech);
                            });
                        }
            
                    });
                }
            }
            else 
            {
                const techCategoriesPositions = [];
                value.forEach((element):void => 
                {
                    const techCategoriesPosition = TECH_CATEGORIES.indexOf(element);
                    techCategoriesPositions.push(techCategoriesPosition);
                });
    
                if (techCategoriesPositions.length > 0) 
                {
                    matchingTech = [];
                    techCategoriesPositions.forEach((item):void => 
                    {
                        if (TECH[item].length > 0) 
                        {
                            TECH[item].map((tech):void => 
                            {
                                matchingTech.push(tech);
                            });
                        }                        
                    });
                }
            }
        }
       
    };
 
    // const columns = [
    //     {
    //         field: 'name', headerName: 'Name', flex: 1, editable: true, disableColumnMenu: true, renderCell: (params):React.JSX.Element =>
    //         {
    //             return(
    //                 <Typography className="data-grid-bolded-cellContent">{params?.value}</Typography>
    //             );
    //         }
    //     },
    //     {
    //         field: 'subIndustry', headerName: 'Industry', flex: 1, editable: true, disableColumnMenu: true, type: 'singleselect', valueOptions: INDUSTRY,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : []); 
    //         },
    //         renderEditCell: CustomIndusryEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value?.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'employeesRange', headerName: 'Employee Headcount', flex: 1, editable: true, disableColumnMenu: true, sortable: false, type: 'singleselect', valueOptions: EMPLOYEE_RANGE,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomEmployeeRangeEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value?.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'estimatedAnnualRevenue', headerName: 'ARR', flex: 1, editable: true, disableColumnMenu: true, sortable: false, type: 'singleselect', valueOptions: INDUSTRY,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomArrEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value?.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'country', headerName: 'Geography', flex: 1, editable: true, disableColumnMenu: true, sortable: false, type: 'singleselect', valueOptions: INDUSTRY,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomCountryEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value?.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'techCategories', headerName: 'Tech Categories', flex: 1, editable: true, disableColumnMenu: true, sortable: false, type: 'singleselect', valueOptions: TECH_CATEGORIES,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomTechCategoriesEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'tech', headerName: 'Tech', flex: 1, editable: true, disableColumnMenu: true, sortable: false, type: 'singleselect', valueOptions: TECH,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomTechEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'actions', headerName: '', editable: false, sortable: false, filterable: false, flex: 1, maxWidth: 40, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return icpActions(params.row);
    //         }
    //     },
    // ];

    const columnHelper = createColumnHelper<IICP>();
    const columns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('subIndustry', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Industry',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'subIndustry'
        }),
        columnHelper.accessor('employeesRange', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Employee Headcount',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'employeesRange'
        }),
        columnHelper.accessor('estimatedAnnualRevenue', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);            },
            header: 'ARR',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'estimatedAnnualRevenue'
        }),
        columnHelper.accessor('country', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);            },
            header: 'Geography',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'country'
        }),
        columnHelper.accessor('techCategories', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);            },
            header: 'Tech Categories',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'techCategories'
        }),
        columnHelper.accessor('tech', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);            },
            header: 'Tech',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'tech'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return icpActions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const openDrawer = ():void => 
    {
        dispatch(resetICP());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.ICP.SIDE_DRAWER.HEADING.CREATE,
            component: < ICP setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const icpActions = (icpInfo: IICP):React.JSX.Element => 
    {

        const editClick = ():void => 
        {
            handleViewEdit(icpInfo);
        };

        const deleteClick = ():void => 
        {
            setShowDeletePopup(true);
            setSelectedIcp(icpInfo);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleViewEdit = (icpInfo: IICP):void => 
    {
        dispatch(resetICP());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.ICP.SIDE_DRAWER.HEADING.EDIT,
            component: <ICP icpData={icpInfo} is-editable={true} setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    return (
        <Box id="icpPage">
            <HeaderBar 
                title={"Idea Customer Profile (ICP)"}
                buttons={[
                    <CustomButton name="Create ICP" btnType="secondary" onClick={() => openDrawer()} />
                ]}
            />
            <Box sx={{
                flex: 1,
                height: 'calc(100% - 64px)', 
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={icpEmptyImg} openDrawer={openDrawer} infoText={'No ICP'} subInfoText={'Add your first ICP'} buttonName={'Add ICP'} />) :
                    (
                        <div className="h-100">
                            <TanstackToolbar 
                                columns={columns}
                                setColumns={setAllColumns}
                                rows={icp}
                                setRows={setRows}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: icpDataCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={rows}
                                rowCount={icpDataCount}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                showSpinner={showSpinner}
                                rightPinnedColumns={['actions']}
                                height='calc(100% - 109px)'
                                onRowClick={(row) => handleViewEdit(row as IICP)}
                            />
                        </div>
                    )}
            </Box>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteICPFromTable(selectedIcp as IICP)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedIcp(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedIcp?.name}?`}
                        modalTitle='Delete ICP'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};
export default ICPPage;