/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button,FormHelperText,ListItemText,MenuItem,Select,Stack,Typography, } from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import { CONTENT } from '../../scripts/i18n';
import { addBudgetByEventId,updateEventBudget } from '../../scripts/apis/eventBudget';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import { refreshBudgetDetails } from '../../redux/events/eventBudget/EventBudget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { CloseIconComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
// import { CsrfTokens } from '../../interfaces/csrftokens_interface';
// import { SubBudget, SubBudgetCategories } from './ICreateBudgetCategory';


export const BudgetTags = {
    Marketing: 1,
    Sales: 2,
    Design: 3,
    Booths: 4,
    Vendors: 5,
};


const CreateBudgetCategory = (props): React.JSX.Element =>
{
    const [formState, setFormState] = useState({
    });
    const [subCategories, setSubCategories] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const dispatch = useDispatch();

    // const existingIds = [];

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });
    
    const postBudgetData = async (payloadData):Promise<void> => 
    {
        try 
        {
            const data = await addBudgetByEventId(
                Number(props.eventId),
                payloadData,
                csrfTokenData
            );
            if (data) 
            {
                dispatch(refreshBudgetDetails({
                    refreshBudget: true
                }));
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Budget Category Created',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                handleDrawerClose();
            }
        }
        catch (error) 
        {
            setSpinner(false);
            console.log(error);
        }
    };
   
    const updateBudgetData = async (payloadData, budgetId):Promise<void>  => 
    {
        try 
        {
            const data = await updateEventBudget(
                props.eventId,
                budgetId,
                payloadData
            );
            if (data) 
            {
                dispatch(refreshBudgetDetails({
                    refreshBudget: true
                }));
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Budget Details Updated',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                handleDrawerClose();
            }
        }
        catch (error) 
        {
            setSpinner(false);
            console.log(error);
        }
    };
   

    useEffect(():void => 
    {
        if (props.budgetDetails) 
        {
            const { name, tags, budget, subBudget, actualSpend } =
        props.budgetDetails;

            setFormState({
                name: name || '',
                tags: tags || '',
                budget: budget || '',
                actualSpend: actualSpend || '',
            });

            if (subBudget && Array.isArray(subBudget)) 
            {
                setSubCategories(
                    subBudget.map((subItem):{
                        name: string;
                        budget: string | number;
                        actualSpend: string | number;
                        id: string | number;
                    } => 
                    {
                        return {
                            name: subItem.name || '',
                            budget: subItem.budget || 0,
                            actualSpend: subItem.actualSpend || 0,
                            id: subItem.id,
                        }; 
                    })
                );
            }
            formik.setValues({
                name: name || '',
                tags: tags || '',
                budget: budget || 0,
                actualSpend: actualSpend,
                subCategories: subBudget
                    ? subBudget.map((subItem):{
                        name: string;
                        budget: string | number;
                        actualSpend: string | number;
                    } => 
                    {
                        return {
                            name: subItem.name || '',
                            budget: `${subItem.budget}` || 0,
                            actualSpend: `${subItem.actualSpend}` || 0,
                        }; 
                    })
                    : [],
            });


        }
    }, [props.budgetDetails]);


    useEffect(():void => 
    {
        if (spinner) 
        {
            let payload;
            if (!formState['actualSpend']) 
            {
                payload = {
                    ...formState, actualSpend: 0, subBudget: subCategories 
                };
            }
            else 
            {
                payload = {
                    ...formState, subBudget: subCategories 
                };
            }
            if (payload) 
            {

                if (props.budgetDetails == null) 
                {
                    postBudgetData(payload);
                }
                else
                {
                    if(props?.subBudgetData)
                    {
                        if(props?.parentBudgetValue?.parentBudgetDetails?.subBudget?.length > 0)
                        {
                            if(props?.parentBudgetValue?.parentBudgetDetails?.subBudget[props?.parentBudgetValue?.currentSubBudgetId])
                            {
                                props.parentBudgetValue.parentBudgetDetails.subBudget = {
                                    ...props.parentBudgetValue.parentBudgetDetails.subBudget,
                                    [props.parentBudgetValue.currentSubBudgetId]:{
                                        name: payload?.name,
                                        budget: Number(payload?.budget),
                                        actualSpend: Number(payload?.actualSpend)
                                    }
                                };

                                updateBudgetData(props?.parentBudgetValue?.parentBudgetDetails,props?.parentBudgetValue?.parentBudgetDetails?.id );
                            }

                        }
                    }
                    else
                    {
                        updateBudgetData(payload, props?.budgetDetails?.id);
                    }
                    
                }
            }
        }
    }, [spinner]);

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const removeSubCategory = (index): void => 
    {

        const updatedSubCategories = subCategories.filter((item, itemIndex):boolean => 
        {
            if (itemIndex !== index) 
            {
                return item;
            }
        }).map((item):object => 
        {
            return item;
        });

        formik.setFieldValue('subCategories', updatedSubCategories);
        setSubCategories(updatedSubCategories);
    };

    const addSubCategory = (): void => 
    {
        setSubCategories([
            ...subCategories,
            {
                name: '', budget: '', actualSpend: 0
            },
        ]);
    };
    const handleSubCategoryChange = (event, index): void => 
    {
        const { name, value } = event.target;
        const propertyName = extractPropertyName(name);

        const updatedSubCategories = [...subCategories];
        updatedSubCategories[index][propertyName] = value;
        setSubCategories(updatedSubCategories);
        formik.handleChange(event);
    };

    const handleInputChange = (event): void => 
    {
        const { name, value } = event.target;
        setFormState((prevState):{[key:string]:string|number} => 
        {
            return {
                ...prevState,
                [name]: value,
            };
        });
        formik.handleChange(event);
    };

    const handleSubmit = (): void => 
    {
        setSpinner(true);
    };

    const validationSchema = Yup.object().shape({
        tags: Yup.lazy((): Yup.NumberSchema<number, Yup.AnyObject, undefined, ''> => 
        {
            if (!props.subBudgetData) 
            {
                return Yup.number().required('required');
            }
            else 
            {
                return Yup.number();
            }
        }),
        name: Yup.string().required('required'),
        budget: Yup.number()
            .required('required')
            .min(0, 'Budget must be a non-negative value')
            .test('isValidBudget', 'Invalid Amount', (value: number): boolean => 
            {
                let x = 0;
                if (subCategories.length > 0) 
                {
                    subCategories.forEach((item): void => 
                    {
                        if (item.budget) 
                        {
                            x += Number(item.budget);
                        }
                    });
                }
                if (props?.subBudgetData) 
                {
                    if (props.parentBudgetValue.parentActualBudget) 
                    {
                        x = Number(props.parentBudgetValue.parentActualBudget);
                    }
                    if (x > Number(value)) 
                    {
                        return true;
                    }
                    else return false;
                }
                else 
                {
                    if (x > Number(value)) 
                    {
                        return false;
                    }
                    else return true;
                }
            }),
        actualSpend: Yup.number().min(0, 'Actual Spend must be a non-negative value'),
        subCategories: Yup.lazy(():any => 
        {
            if (subCategories.length > 0) 
            {
                return Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required('required'),
                        budget: Yup.number().required('required').min(0, 'Budget must be a non-negative value'),
                        actualSpend: Yup.number().min(0, 'Actual Spend must be a non-negative value'),
                    })
                );
            }
            else 
            {
                return Yup.array();
            }
        }),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            tags: '',
            name: '',
            budget: '',
            actualSpend: '',
            subCategories: [{
                name: '', budget: '', actualSpend: ''
            }],
        },
        onSubmit: ():Promise<void> => 
        {
            if (formState) 
            {
                handleSubmit();
            }
            else 
            {
                return;
            }
        },
    });

    return (
        <Box id="createBudget">
            <CloseIconComponent onClick={handleDrawerClose} />
            {/* <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack> */}
            <Form
                noValidate
                autoComplete="off"
                onSubmit={(values): void => 
                {
                    return formik.handleSubmit(values);
                }}
            >
                {props?.subBudgetData ? null : (
                    <Box className="component-spacing">
                        <Form.Label className="label-name">
                            {CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT.TAGS_LABEL} <span className="required-icon">* </span>
                        </Form.Label>

                        <Select
                            className="type-select"
                            onChange={handleInputChange}
                            name="tags"
                            value={formik.values.tags || ''}
                            IconComponent={(props): React.JSX.Element => 
                            {
                                return (
                                    <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                                ); 
                            }}
                            displayEmpty
                        >
                            <MenuItem value={0} disabled>
                                <em className="type-placeholder">Select a Tag</em>
                            </MenuItem>
                            {Object.keys(BudgetTags).map((key): React.JSX.Element => 
                            {
                                return (
                                    <MenuItem
                                        key={BudgetTags[key]}
                                        value={BudgetTags[key]}
                                        onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => 
                                        {
                                            event.stopPropagation();
                                        }}
                                    >
                                        <ListItemText primary={key} className="type-placeholder" />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {formik.errors.tags && formik.touched.tags ? (
                            <FormHelperText className="error-message">
                                {formik.errors.tags}
                            </FormHelperText>
                        ) : null}
                    </Box>
                )}
                <Box className="component-spacing">
                    <Form.Label className="label-name">
                        {
                            CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT
                                .CATEGORY_NAME_LABEL
                        } <span className="required-icon">* </span>
                    </Form.Label>
                    <Form.Control
                        className="type-input"
                        type="text"
                        size="lg"
                        placeholder="Marketing"
                        onChange={handleInputChange}
                        name={'name'}
                        value={formik.values.name}
                        isInvalid={formik.touched.name && formik.errors.name ? true : false}
                    />

                    {formik.errors.name && formik.touched.name ? (
                        <FormHelperText className="error-message">
                            {formik.errors.name}
                        </FormHelperText>
                    ) : null}
                </Box>
                <Box className="component-spacing">
                    <Form.Label className="label-name">
                        {CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT.BUDGET_LABEL} <span className="required-icon">* </span>
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                        <Form.Control
                            className="type-input-budget"
                            aria-describedby="basic-addon1"
                            type="text"
                            size="lg"
                            placeholder="20000"
                            onChange={handleInputChange}
                            name={'budget'}
                            value={formik.values.budget}
                            isInvalid={
                                formik.touched.budget && formik.errors.budget ? true : false
                            }
                        />
                    </InputGroup>
                    {formik.errors.budget && formik.touched.budget ? (
                        <FormHelperText className="error-message">
                            {formik.errors.budget}
                        </FormHelperText>
                    ) : null}
                </Box>
                {subCategories.length > 0 ? null : (
                    <Box className="component-spacing">
                        <Form.Label className="label-name">
                            {
                                CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT
                                    .ACTUAL_SPEND_LABEL
                            }
                        </Form.Label>
                        <InputGroup  >
                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                            <Form.Control
                                className="type-input-budget"
                                aria-describedby="basic-addon1"
                                type="text"
                                size="lg"
                                placeholder="20000"
                                onChange={handleInputChange}
                                name={'actualSpend'}
                                value={formik.values.actualSpend}
                                // disabled={
                                //   props.parentBudgetData || props.subBudgetData ? false : true
                                // }
                            />
                        </InputGroup>
                        {formik.errors.actualSpend && formik.touched.actualSpend ? (
                            <FormHelperText className="error-message">
                                {formik.errors.actualSpend}
                            </FormHelperText>
                        ) : null}
                    </Box>
                )}

                {/* <Box className="horizontal-line"></Box> */}

                {subCategories.length > 0 ? (
                    <Fragment>
                        <Box className="horizontal-line"></Box>
                        <Box className="sub-category-block">
                            {subCategories.map((subCategory, index) : React.JSX.Element => 
                            {
                                return (
                                    <Box className="sub-category-card" key={index}>

                                        {props.budgetDetails ? null : (<Button
                                            className="delete-sub-category-btn"
                                            onClick={() : void => 
                                            {
                                                return removeSubCategory(index);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={['fal', 'circle-minus']} />
                                        </Button>)}
                                        <Box className="component-spacing" key={index + 1}>
                                            <Form.Label className="label-name">
                                                {
                                                    CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT
                                                        .SUB_CATEGORY_LABEL
                                                } <span className="required-icon">* </span>
                                            </Form.Label>

                                            <Form.Control
                                                className="type-input"
                                                type="text"
                                                size="lg"
                                                placeholder="Email-Marketing"
                                                onChange={(): void => 
                                                {
                                                    return handleSubCategoryChange(event, index);
                                                }}
                                                name={`subCategories[${index}].name`}
                                                value={formik.values.subCategories?.[index]?.['name']}
                                                isInvalid={
                                                    formik.errors?.subCategories &&
                            formik?.errors?.subCategories[index]
                                                        ? formik.touched?.subCategories?.[index]?.name &&
                            formik.errors?.subCategories[index]['name']
                                                        : false
                                                }
                                            />

                                            {formik.errors.subCategories &&
                        formik.errors.subCategories[index] &&
                        formik.touched.subCategories &&
                        formik.touched.subCategories[index] ? (
                                                    <FormHelperText className="error-message">
                                                        {formik.errors.subCategories[index]?.[`${name}`]}
                                                    </FormHelperText>
                                                ) : null}
                                        </Box>
                                        <Box className="component-spacing" key={index + 2}>
                                            <Form.Label className="label-name">
                                                {
                                                    CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT
                                                        .BUDGET_LABEL
                                                } <span className="required-icon">* </span>
                                            </Form.Label>
                                            <InputGroup  >
                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                <Form.Control
                                                    className="type-input-budget"
                                                    aria-describedby="basic-addon1"
                                                    type="text"
                                                    size="lg"
                                                    placeholder="20000"
                                                    onChange={(event): void => 
                                                    {
                                                        return handleSubCategoryChange(event, index);
                                                    }
                                                    }
                                                    name={`subCategories[${index}].budget`}
                                                    value={formik?.values?.subCategories?.[index]?.budget}
                                                />
                                            </InputGroup>
                                            {formik.errors?.subCategories &&
                        formik.errors?.subCategories?.[index] &&
                        formik.touched?.subCategories &&
                        formik.touched?.subCategories?.[index] ? (
                                                    <FormHelperText className="error-message">
                                                        {formik.errors.subCategories[index]['budget']}
                                                    </FormHelperText>
                                                ) : null}
                                        </Box>
                                        <Box className="component-spacing" key={index + 3}>
                                            <Form.Label className="label-name">
                                                {
                                                    CONTENT.EVENTS_MODULE.BUDGET.SIDE_DRAWER_CONTENT
                                                        .ACTUAL_SPEND_LABEL
                                                }
                                            </Form.Label>
                                            <InputGroup  >
                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                <Form.Control
                                                    className="type-input-budget"
                                                    aria-describedby="basic-addon1"
                                                    type="text"
                                                    size="lg"
                                                    placeholder="20000"
                                                    onChange={(event): void => 
                                                    {
                                                        return handleSubCategoryChange(event, index);
                                                    }
                                                    }
                                                    name={`subCategories[${index}].actualSpend`}
                                                    // disabled={
                                                    //   props.parentBudgetData || props.subBudgetData
                                                    //     ? false
                                                    //     : true
                                                    // }
                                                    value={
                                                        formik?.values?.subCategories?.[index]?.actualSpend
                                                    }
                                                />
                                            </InputGroup>
                                            {formik.errors?.subCategories &&
                        formik.errors?.subCategories?.[index] &&
                        formik.touched?.subCategories &&
                        formik.touched?.subCategories?.[index] ? (
                                                    <FormHelperText className="error-message">
                                                        {formik.errors.subCategories[index]['actualSpend']}
                                                    </FormHelperText>
                                                ) : null}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Fragment>
                ) : null}

                {props?.subBudgetData ? null : (
                    <Box className="component-spacing">
                        <Button className="add-sub-category" onClick={addSubCategory}>
                            <FontAwesomeIcon icon={['fal', 'plus']} />
                            <Typography className="add-btn">Add New Sub-Category</Typography>
                        </Button>
                    </Box>
                )}

                <Box className="submit-btn-container">
                    <Stack
                        direction={'row'}
                        gap={'12px'}
                        display={'flex'}
                        justifyContent={'flex-end'}
                    >
                        <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} onClick={handleDrawerClose} btnType='secondary' />
                        <CustomButton type="submit"
                            loading={spinner} 
                            btnType='primary' 
                            name={props?.budgetDetails ? 'Update' : 'Create'} 
                            onClick={():void => 
                            {
                                formik.handleSubmit();
                            }}  />
                    </Stack>
                </Box>
            </Form>
        </Box>
    );
};

const extractPropertyName = (propertyString) : string =>
{
    const matches = propertyString.match(/\.(\w+)$/);
    if (matches && matches[1]) 
    {
        return matches[1];
    }
    return null;
};

export default CreateBudgetCategory;
