import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/named
import { Box, Button, FormControlLabel, Radio, RadioGroup, RadioProps, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { getTimeZones } from '@vvo/tzdb';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';

import { registerOptions } from '../../../../hooks/eventRegistrationDetails';
import { updateEventRegistrationDetails } from '../../../../scripts/apis/events';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import { CONTENT } from '../../../../scripts/i18n';
import { addEvent } from '../../../../redux/events/Events';
import { Event } from '../../interfaces';

import { EmptyRadioBtn, RadioBtn } from '../../../../common/StyledComponents/CustomRadio.styled';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { CustomSwitch } from '../../../../common/StyledComponents/Switch.styled';
import { MuiDateTimePicker } from '../../../../common/FormComponents/DateTimePickers';

import './styles.scss';
import { CustomButton } from '../../../../common/FormComponents/Buttons';

const CreateRegistrationDetails: React.FC<{eventDetails: Event}> = (props: {eventDetails: Event}): React.JSX.Element => 
{
    const { eventDetails } = props;
    const dispatch = useDispatch();
    const [registerOptionSelected, setRegisterOptionSelected] = useState<{ message?: boolean, redirectionUrl?: boolean }>({
        message: true 
    });
    const [enableWaitlist, setEnableWaitList] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [successMessageLength, setSuccessMessageLength] = useState(props?.eventDetails?.registerOptions?.message?.length);

    const timeZones = getTimeZones();
    const timezoneOptions = [];
    const timezoneListArr = (): void => 
    {
        timeZones.map((item): void => 
        {
            const utcSplitted = item.rawFormat.split(' ')[0];
            timezoneOptions.push({
                name: 'GMT' + utcSplitted + ' ' + item.countryName,
                value: item.name,
            });
        });
    };

    timezoneListArr();

    const validationSchema = Yup.object().shape({
        maximumRegistration: Yup.number().nullable(),
        // maximumTickets: Yup.number().required('Enter Maximum Tickets'),
        waitlistSuccessMessage: Yup.string(),
        closingDateTime: Yup.string().required('Enter Registration Closing Date and Time'),
        registrationClosingDateTimezone: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            maximumRegistration: eventDetails.maximumRegistration ? eventDetails.maximumRegistration : null,
            closingDateTime: eventDetails.closingDateTime ? moment.unix(Number(eventDetails.closingDateTime)).tz(eventDetails?.timezone as string).format('YYYY-MM-DD hh:mm A') : null,            
            message: eventDetails?.registerOptions?.message ? eventDetails?.registerOptions.message : null,
            redirectionUrl: eventDetails?.registerOptions?.redirectionUrl ? eventDetails?.registerOptions.redirectionUrl : null,
        },
        onSubmit: async (values): Promise<void> => 
        {

            if (moment(`${formik.values.closingDateTime}`).unix() > Number(eventDetails.eventEndDateTime)) 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Registration Closing Date and Time should be less than Event End Date and Time',
                    severity: 'error',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                return;
            }

            const details : Event = {
                maximumRegistration: values.maximumRegistration || 0,
                closingDateTime: moment(values.closingDateTime).unix(),
                enableWaitlistStatus: enableWaitlist ? 1 : 2,
                enableAuthenticationStatus: eventDetails.enableAuthenticationStatus,
                registerOptions: registerOptionSelected.message ? {
                    message: values.message,
                    redirectionUrl: null,
                } : {
                    message: null,
                    redirectionUrl: values.redirectionUrl,
                },
            };

            const isUpdateAvailable = (eventDetails.maximumRegistration !== formik.values.maximumRegistration || Number(eventDetails.closingDateTime) !== moment(formik.values.closingDateTime).unix() || eventDetails.registerOptions.message !== formik.values.message || eventDetails.registerOptions.redirectionUrl !== formik.values.redirectionUrl
            );

            if (isUpdateAvailable) 
            {
                try 
                {
                    setSpinner(true);
                    const detailsUpdated = await updateEventRegistrationDetails(Number(eventDetails.id), details);
                    if (detailsUpdated) 
                    {
                        dispatch(addEvent({
                            closingDateTime: moment(`${values.closingDateTime}`).unix(),
                            registerOptions: detailsUpdated.registerOptions,
                            enableWaitlistStatus: detailsUpdated.enableWaitlistStatus
                        }));

                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Registration Details updated',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                        setSpinner(false);
                        handleDrawerClose();
                        eventBus.dispatch('event-details-refreshed', {
                            refresh: true 
                        });
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setSpinner(false);
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message:_.startCase(error.message.toLowerCase()),
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                }
            }

        }
    });

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    useEffect((): void => 
    {  
        if(String(props?.eventDetails?.enableWaitlistStatus) === '1') 
        {
            setEnableWaitList(true);
        }
    }, []);

    return (
        <Box id="createRegDetails">
            <CloseIconComponent onClick={handleDrawerClose} />

            {/* Max registrations */}
            <Box className="sidebar-container-spacing">
                <FormLabelComponent label="Maximum Registrations" />
                <FormControlComponent
                    type='number'
                    value={formik.values.maximumRegistration}
                    onChange={(event): void => 
                    {
                        formik.setFieldValue('maximumRegistration', event.target.value); 
                        if (formik.values?.maximumRegistration === 0 || formik.values?.maximumRegistration === '') 
                        {
                            setEnableWaitList(false);
                        }
                    }}
                    onKeyDown={(evt): void => 
                    {
                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                    }}
                    placeholder='Unlimited'
                />
                {formik.errors.maximumRegistration && formik.touched.maximumRegistration ? <Typography className="error-msg">{formik.errors.maximumRegistration}</Typography> : null}
            </Box>

            {/* Enable Waitlist */} 
            <Box className="sidebar-container-spacing">
                <Box className="approval-container">
                    <Box className="approval-icon-label-container">
                        <FormLabelComponent label={'Enable Waitlist'} noBottomMargin />
                    </Box>
                    <FormControlLabel
                        control={<CustomSwitch sx={{
                            m: 1 
                        }} checked={((formik.values?.maximumRegistration === null || formik.values?.maximumRegistration === 0 || formik.values?.maximumRegistration === '')) ? false : enableWaitlist} 
                        onChange={(): void => 
                        {
                            setEnableWaitList(!enableWaitlist); 
                        }} name="waitList" />}
                        label={null}
                    />
                </Box>
            </Box>

            {/* Registration Closing */}
            <Box className="sidebar-container-spacing">
                <div className="reg-closing-time-label">
                    <FormLabelComponent label='Registration Closing' required />
                    <p className="timezone">{`(${eventDetails?.timezone})`}</p>
                </div>
                <MuiDateTimePicker value={formik.values.closingDateTime} max={eventDetails?.eventEndDateTime * 1000} onChange={(newValue): void =>
                {
                    formik.setFieldValue('closingDateTime', newValue?.format('MM/DD/YYYY hh:mm A'));
                }} />
                {formik.errors.closingDateTime && formik.touched.closingDateTime ? <Typography className="error-msg">{formik.errors.closingDateTime}</Typography> : null}
            </Box>

            {/* Registration success actions */}
            <Box className="sidebar-container-spacing">
                <Box className="registration-options">
                    <FormLabelComponent label='Upon successful submission' required />
                    <RadioGroup>
                        <Box className={'radio-group-container'}>
                            {registerOptions.map((item, index): React.ReactElement => 
                            {
                                let borderStyle = '2px solid transparent';
                                if (item.name === 'message' && registerOptionSelected['message']) 
                                {
                                    borderStyle = '2px solid var(--Colors-Border-border-brand-solid, #1570EF) !important';
                                }
                                else if (item.name === 'redirectionUrl' && registerOptionSelected['redirectionUrl']) 
                                {
                                    borderStyle = '2px solid var(--Colors-Border-border-brand-solid, #1570EF) !important';
                                }

                                return (
                                    <Box key={index} className="options-box" border={borderStyle}>
                                        <FormControlLabel
                                            value={registerOptionSelected[item.name]}
                                            control={
                                                <OptionRadioGroup
                                                    checked={registerOptionSelected[item.name] ? registerOptionSelected[item.name] : false}
                                                    onChange={(): void => 
                                                    {
                                                        setRegisterOptionSelected({
                                                            [item.name]: true 
                                                        });
                                                    }}
                                                />
                                            }
                                            label={<Typography className="option-label">{item.option}</Typography>}
                                            name={item.name}
                                            className="radio-label"
                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    </RadioGroup>
                    <Box className="reg-msg-block">
                        {registerOptionSelected.message &&
                        <>
                            <FormControlComponent
                                value={formik.values.message}
                                onChange={(event): void => 
                                {
                                    formik.setFieldValue('message', event.target.value); 
                                    setSuccessMessageLength(event.target.value.length);
                                }}
                                type="text"
                                rows={3}
                                as="textarea"
                                placeholder='Description'
                                maxLength={512}
                                isInvalid={successMessageLength > 512}
                            />
                            <Typography className="title-length">{successMessageLength}/512</Typography>
                        </>
                        }

                        {registerOptionSelected.redirectionUrl &&
                            <FormControlComponent
                                value={formik.values.redirectionUrl}
                                onChange={(event): void => 
                                {
                                    formik.setFieldValue('redirectionUrl', event.target.value); 
                                }}
                                type="text"
                                rows={2}
                                placeholder="Url"
                            />
                        }
                    </Box>

                </Box>
            </Box>

            <Box className="submit-btn-container">
                {
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' name='Cancel' type='button' onClick={handleDrawerClose} />
                        <CustomButton loading={spinner} btnType='primary' name='Save' type='submit' onClick={(): void => 
                        {
                            formik.handleSubmit(); 
                        }} />
                    </Stack>
                }

            </Box>
        </Box>
    );
};

export default CreateRegistrationDetails;


const OptionRadioGroup = (props: RadioProps): React.ReactElement => 
{
    const { checked, onChange } = props;
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<RadioBtn />}
            icon={<EmptyRadioBtn />}
            checked={checked}
            onChange={onChange}
            {...props}
            size='small'
            className="radio-label"
        />
    );
}; 