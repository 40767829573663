import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material';
// import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
// import { DataGridPro } from '@mui/x-data-grid-pro';
import eventBus from '../scripts/event-bus';
import CheckboxWrapper from './CheckboxWrapper';
import APP_CONSTANTS from '../scripts/constants';
import { Spinner } from 'react-bootstrap';
import CustomSpinner from './CustomSpinner';
// eslint-disable-next-line import/named
import { DataGridPro, GridColDef, GridRowId, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridCellModes, GridCellModesModel, GridCellParams, useGridApiContext, useGridSelector, gridPageSelector, gridPageSizeSelector } from '@mui/x-data-grid-pro';
import { TableDataGridProps } from './ITableDataGrid';
import { CustomPaginationComponent } from './ColumnFilterDataGrid';
import DataGridtabs from './DateGridTabs/DataGridTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @returns
 * Functional component to render a datagrid
 * Variable to store the props data of the rows
 * Variable to store the props data of the columns
 * Variable to store the props data of the row click event string
 * Variable to store the initial state of the loader in the data grid
 * Variable to store the state of the selected rows using checkboxes
 * Variable to store the state of the selected rows while clicking the grid cells
 * Variable to store the column fields
 * Function to handle cell click of the data grid
 */


const TableDataGrid = (props: TableDataGridProps ) : React.JSX.Element => 
{

    const rows = props.rows;
    const columns = props.columns;
    const eventBusConst = props.rowClickEvent;
    const gridHeight = props.height;
    const isEmpty = props.isEmpty;
    const rowCount = props.rowCount ? props.rowCount : 15;
    const isPaginationEnabled = props.isPagination ? props.isPagination : false;
    const [selectedRowsData, setSelectedRowsData] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [tableHeight, setTableHeight] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const tableContainerRef = useRef(null);
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const [spinner, setSpinner] = useState(false);
    const [isEditing, setIsEditing] = useState<boolean>(null);
    const [containerWidth, setContainerWidth] = useState(null);
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({
    });
    const [saveData, isDataSaved] = useState(false);
    const [rowsUpdated, setRowsUpdated] = useState(null);
    const [cellUpdated, setCellUpdated] = useState(false);
    const [cellUpdatedCount, setCellUpdatedCount] = useState(0);

    useEffect(():()=>void => 
    {
        const handleResize = (): void => 
        {
            if (tableContainerRef.current) 
            {
                const tableHeight = tableContainerRef.current.clientHeight;
                setContainerWidth(tableContainerRef.current.clientWidth + 3);

                if (rowCount > 17) 
                {
                    setTableHeight(tableHeight);
                }
                else 
                {
                    setTableHeight(tableHeight);
                }
            }
            setWindowHeight(window.innerHeight);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return (): void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, [tableContainerRef, rowCount, windowHeight]);

    useEffect(():void => 
    {
        if(isEditing)
        {
            setTableHeight(tableHeight - 64);
        } 
        if(isEditing === false)
        {
            setTableHeight(tableHeight + 64);
        }

    },[isEditing]);

    useEffect((): void => 
    {
        if(props.updatePageSize)
        {
            props.updatePageSize(25);
        }
       
    }, []);

    

    useEffect(():void => 
    {
        if (rowsUpdated) 
        {
            setIsEditing(true);
        }
        else 
        {
            setIsEditing(false);
        }

        eventBus.on('table-selected-tab', (data): void => 
        {
            setSelectedTab(data);
        });
    }, [rowsUpdated]);

    const handleSaveClick = ():void => 
    {

        Object.keys(rowsUpdated).forEach((key):void => 
        {
            const updatedRow = rowsUpdated[key];
            setSpinner(true);
            props.editAction(updatedRow).then(():void => 
            {
                setSpinner(false);
                setCellUpdatedCount(0);
                setIsEditing(false);
                delete rowsUpdated[key];
            }).catch((error):void => 
            {
                setSpinner(false);
                setIsEditing(false);
                props.setRefresh(true);
                setCellUpdatedCount(0);
                console.log(error);
            });
        });

    };

    const handleCancelClick = (): void => 
    {
        if (!saveData) 
        {

            props.setRefresh(true);
            setCellUpdatedCount(0);
            setIsEditing(false);
            isDataSaved(false);
        }
        else 
        {
            isDataSaved(false);
        }
    };

    useEffect((): void => 
    {
        eventBus.on('selected-row-id', (rowId): void => 
        {
            if (rowId) 
            {
                setSelectionModel([rowId]);
            }
            else 
            {
                setSelectionModel([]);
            }
        });
    }, []);

    const handleCellClick = (event): void => 
    {
        setSelectedRowsData(event.row);
        if (Object.keys(event.row).length > 0) 
        {
            eventBus.dispatch(eventBusConst, {
                rowData: event.row,
            });
        }
    };

    const handleCellClickForEdit = React.useCallback(
        (params: GridCellParams, event: React.MouseEvent): void => 
        {
            if (!params.isEditable) 
            {
                return;
            }

            if (props.editAction) 
            {
                if (params.colDef.editable) 
                {
                    setIsEditing(true);
                }
            }

            if (
                (event.target as HTMLDivElement).nodeType === 1 &&
                !event.currentTarget.contains(event.target as Element)
            ) 
            {
                return;
            }

            setCellModesModel((prevModel:GridCellModesModel):GridCellModesModel => 
            {
                return {
                    ...Object.keys(prevModel).reduce(
                        (acc, id):object => 
                        {
                            return {
                                ...acc,
                                [id]: Object.keys(prevModel[id]).reduce(
                                    (acc2, field):object => 
                                    {
                                        return {
                                            ...acc2,
                                            [field]: {
                                                mode: GridCellModes.View 
                                            },
                                        }; 
                                    },
                                    {
                                    },
                                ),
                            }; 
                        },
                        {
                        },
                    ),
                    [params.id]: {
                        ...Object.keys(prevModel[params.id] || {
                        }).reduce(
                            (acc, field):object => 
                            {
                                return {
                                    ...acc, [field]: {
                                        mode: GridCellModes.View 
                                    } 
                                }; 
                            },
                            {
                            },
                        ),
                        [params.field]: {
                            mode: GridCellModes.Edit 
                        },
                    },
                };
            });
        },
        [],
    );

    const handleCellModesModelChange = React.useCallback(
        (newModel: GridCellModesModel): void => 
        {
            setCellModesModel(newModel);
        },
        [],
    );

    const processRowUpdate = useCallback(
        (newRow, oldRow):object => 
        {
            setRowsUpdated((prev):object[] => 
            {
                const updateRows = {
                    ...prev 
                };
                updateRows[newRow.id] = newRow;
                return updateRows;
            });
            if(!(JSON.stringify(oldRow) === JSON.stringify(newRow)))
            {
                setCellUpdated(true);
            }
            return newRow;
        }, [],
    );

    useEffect(():void => 
    {
        if(cellUpdated)
        {
            setCellUpdatedCount(cellUpdatedCount + 1);
            setCellUpdated(false);
        }
    },[cellUpdated]);

    const CustomToolbar = (): React.JSX.Element => 
    {
        return (
            <Box className="datagrid-toolbar-container">
                {/* <Box className="recordsText">
                    {rowCount} {rowCount > 1 ? 'Records' : 'Record'}
                </Box> */}
                <div>
                    {props?.datagridTabsData && props?.datagridTabsData?.tabs?.length > 0 ? <DataGridtabs tabsData={props?.datagridTabsData} selectedTab={selectedTab} /> : !props?.customTabs && <DataGridtabs dataLength={props?.dataCount} selectedTab={0} />}
                    {props?.customTabs && props.customTabs}
                </div>
                <GridToolbarContainer>
                    <GridToolbarFilterButton className="toolbar-columns-button" />
                    <GridToolbarColumnsButton className="toolbar-columns-button" />
                    {props.extraHeaderButtons && props.extraHeaderButtons.length > 0 &&
                        props.extraHeaderButtons.map((item):string => 
                        {
                            return item;
                        })
                    }
                </GridToolbarContainer>
            </Box>
        );
    };

    const getTogglableColumns = (columns: GridColDef[]): string[] => 
    {
        if (props?.hiddenFields) 
        {
            return columns
                .filter((column) : boolean => 
                {
                    return !props?.hiddenFields.includes(column.field); 
                })
                .map((column): string => 
                {
                    return column.field; 
                });
        }
    };

    function CustomPagination(): React.JSX.Element 
    {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

        const handlePageSizeChange = (event): void =>
        {
            apiRef.current.setPageSize(Number(event.target.value));
        };

        const pageSizeOptions = [
            {
                name: '25', value: 25,
            },
            {
                name: '50', value: 50,
            },
            {
                name: '75', value: 75,
            },
            {
                name: '100', value: 100,
            },
        ]

        return (
            <Box className="pagination-container">
                <Box className="page-indicator-component">
                    <Typography>{`Page ${page+1} of ${isPaginationEnabled ? Math.ceil(rowCount / props.pageSize) : Math.ceil(rows.length / 5)}`}</Typography>
                </Box>
                <CustomPaginationComponent paginationCount={isPaginationEnabled ? Math.ceil(rowCount / props.pageSize) : Math.ceil(rows.length / 5)} selectedPage={page + 1} onChangeFunc={(value: number): void => 
                {
                    return apiRef.current.setPage(value - 1);
                }
                } />
                <Select IconComponent={(props): React.JSX.Element => 
                {
                    return (
                        <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                    ); 
                }} value={pageSize ? pageSize : 25} onChange={handlePageSizeChange} className="pagesize-select" defaultValue={25} renderValue={(selected) =>
                {
                    return(
                        <Box>
                            <Typography className="selected-pagesize-text">{`${selected} Per Page`}</Typography>
                        </Box>
                    )
                }}>
                    {pageSizeOptions?.map((elem, index) => {
                        return (
                            <MenuItem key={index} value={elem.value}>{elem.name}</MenuItem>
                        )
                    })}
                </Select>  
            </Box>
        );
    }
      

    return (
        <Box id="tableDataGridPro" ref={tableContainerRef} >
            {(!isEmpty) && <Box height={gridHeight} className="grid-box" >
                {(rows.length > 0 || !spinner) ?
                    <div className="datagridContainer" style={{
                        maxHeight: tableHeight ? `${tableHeight}px` : '100%', overflowY: 'auto' 
                    }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            rowHeight={52}
                            hideFooterRowCount={props?.disablePagination? true : false}
                            hideFooterPagination={props?.disablePagination? true : false}
                            rowCount={isPaginationEnabled ? Number(rowCount) : rows.length}
                            checkboxSelection={props.checkbox ? props.checkbox : false}
                            keepNonExistentRowsSelected={true}
                            experimentalFeatures={{
                                columnGrouping: true
                            }}
                            disableRowSelectionOnClick={!(props?.disableRowSelection)}
                            pagination={!props?.disablePagination}
                            paginationModel={{
                                page: currentPage, pageSize: isPaginationEnabled ? props.pageSize : 5 
                            }}
                            paginationMode={isPaginationEnabled ? 'server' : 'client'}
                            loading={props.showSpinner ? props.showSpinner : false}
                            processRowUpdate={processRowUpdate}
                            onCellModesModelChange={handleCellModesModelChange}
                            // onCellEditStop={():void => 
                            // {
                            //     setCellUpdatedCount(cellUpdatedCount + 1);
                            // }}
                            cellModesModel={cellModesModel}
                            onProcessRowUpdateError={(error): void => 
                            {
                                console.log(error); 
                            }}
                            rowSelectionModel={selectionModel}
                            onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel): void =>
                            {
                                return setSelectionModel(rowSelectionModel); 
                            }
                            }
                            onPaginationModelChange={(paginationModel): void => 
                            {
                                props.updateCurrentPage(paginationModel.page + 1);
                                setCurrentPage(paginationModel.page);
                                props.updatePageSize(paginationModel.pageSize);
                            }}
                            slots={{
                                baseCheckbox: CheckboxWrapper,
                                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                loadingOverlay: () => 
                                {
                                    return CustomSpinner({
                                        height: '100%' 
                                    }); 
                                },
                                toolbar: !props?.removeToolbarOptions && CustomToolbar,
                                pagination: selectedTab === 0 && CustomPagination,
                            }}
                            slotProps={{
                                panel: {
                                    sx:
                                    {
                                        '& .MuiInputLabel-shrink': {
                                            visibility: 'hidden', display: 'none' 
                                        },
                                        '& .MuiInput-root.Mui-focused:after': {
                                            borderBottom: 'unset' 
                                        },
                                        '& .MuiInput-root:after': {
                                            borderBottom: 'none !important' 
                                        },
                                        '& .MuiInput-root:before': {
                                            borderBottom: 'none !important' 
                                        },
                                        '& .MuiInputLabel-animated': {
                                            display: 'none' 
                                        },
                                    },
                                    placement: 'bottom-end'
                                },
                                columnsPanel: {
                                    getTogglableColumns,
                                },
                            }}
                            onCellClick={(params, event): void => 
                            {
                                if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field) && !params.isEditable) 
                                {
                                    if (props?.disableRowClicksForField && props.disableRowClicksForField.length > 0) 
                                    {
                                        if(!props.disableRowClicksForField.includes(params.field))
                                        {
                                            return handleCellClick(params);
                                        }
                                        else 
                                        {
                                            return null;
                                        }
                                       
                                    }
                                    else 
                                    {
                                        return handleCellClick(params);
                                    }

                                }
                                else 
                                {
                                    if (props.editAction) 
                                    {
                                        handleCellClickForEdit(params, event);
                                    }
                                    else 
                                    {
                                        if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field)) 
                                        {
                                            return handleCellClick(params);
                                        }
                                    }
                                }
                            }}
                            hideFooter={false}
                            pageSizeOptions={[{
                                value: 25, label: '25 per page' 
                            },{
                                value: 50, label: '50 per page' 
                            }, {
                                value: 75, label: '75 per page' 
                            }, {
                                value: 100, label: '100 per page' 
                            }]}
                        />
                    </div>
                    : <CustomSpinner height={'100%'} />}
            </Box>}
            {isEditing && (
                <BottomDrawer
                    handleCancelClick={handleCancelClick}
                    handleSaveClick={handleSaveClick}
                    width={`${containerWidth}px`}
                    left={`calc(100vw - ${containerWidth}px)`}
                    updatedCellCount={cellUpdatedCount}
                />
            )}
        </Box>
    );
};

export default TableDataGrid;

export const LoadingSkeleton = (): React.JSX.Element => 
{
    return (
        <Box
            sx={{
                height: 'max-content'
            }}
        >
            {[...Array(10)].map((_, index): React.JSX.Element => 
            {
                return (
                    <Stack direction={'row'} key={index}>
                        <Skeleton variant="rectangular" sx={{
                            my: 2, mx: 1, backgroundColor: '#f4f4f7', width: '100%' 
                        }} />
                        <Skeleton variant="rectangular" sx={{
                            my: 2, mx: 1, backgroundColor: '#f4f4f7', width: '100%' 
                        }} />
                        <Skeleton variant="rectangular" sx={{
                            my: 2, mx: 1, backgroundColor: '#f4f4f7', width: '100%' 
                        }} />
                        <Skeleton variant="rectangular" sx={{
                            my: 2, mx: 1, backgroundColor: '#f4f4f7', width: '100%' 
                        }} />
                    </Stack>


                ); 
            })}
        </Box>
    );
};

export const CustomTableSpinner = (): React.JSX.Element => 
{
    return (
        <Box className="table-spinner-container">
            <Spinner animation="border" role="status" />
        </Box>
    );
};

const BottomDrawer = (props: { handleSaveClick: () => void, handleCancelClick: () => void, width: string, left: string, updatedCellCount: number }):React.JSX.Element => 
{

    return (
        <Box id="bottomDrawer" width={props.width} left={props.left} >
            <Button className="save-btn" onClick={props.handleSaveClick}>Save</Button>
            <Button className="cancel-btn" onClick={props.handleCancelClick}>Cancel</Button>
            {props.updatedCellCount > 0 &&
                <Typography className='instruction-text'> {`${props.updatedCellCount} unsaved ${props.updatedCellCount > 1 ? 'changes': 'change'}`}</Typography>
            }
        </Box>
    );
};
