import React, { useContext, useEffect, useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import { resetBuyerPersona } from '../../../redux/settings/BuyerPersona';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import BuyerPersona from './AddBuyerPersona';
import SettingsTopAppBar from '../common/SettingsTopAppBar';
import { BuyerPersonaPageContext } from '../../../contexts/Settings/BuyerPersonaPageContext';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { ROLE, SENIORITY, SUB_ROLE } from './buyer-persona-dataset';
import _ from 'lodash';
import buyerPersonaEmptyImg from '../../../assets/icons/empty-state/ICPEmptyState.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { BuyerPersona as IBuyerPersona } from '../interface/buyer-persona_interface';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import TanstackToolbar from '../../../common/TanstackTable/TanstackToolbar';
import DeletePopup from '../../../common/DeletePopup';

import './styles.scss';
import HeaderBar from '../../../common/Headerbar';
import { CustomButton } from '../../../common/FormComponents/Buttons';

const BuyerPersonaPage = ():React.JSX.Element => 
{
    let matchingSubRole: any[] = [];

    const CustomSeniorityEditCell = (params):React.JSX.Element => 
    {
        return <CustomSeniorityComponent {...params} />; 
    };

    const CustomRoleEditCell = (params): React.JSX.Element =>
    {
        return <CustomRoleEditComponent {...params} />;
    };

    const CustomSubRoleEditCell = (params): React.JSX.Element =>
    {
        return <CustomSubRoleEditComponent {...params} />;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { 
        isEmpty, 
        rows,
        setRows, 
        buyerPersonaDataCount, 
        buyerPersona, 
        deleteBuyerPersonaFromTable, 
        setRefresh, 
        pageSize, 
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        showDeletePopup,
        setShowDeletePopup,
        selectedBuyerPersona,
        setSelectedBuyerPersona, 
    } = useContext(BuyerPersonaPageContext);


    const CustomSeniorityComponent = (props):React.JSX.Element => 
    {
        const { id, value, field } = props;
        
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {SENIORITY.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomRoleEditComponent = (props): React.JSX.Element =>
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
        
        const handleChange = (event): void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });

            const selectedRoles = newValue.length > 0 ? newValue.map((elem: string):string => 
            {
                return _.find(ROLE, (role):boolean => 
                {
                    return elem === role; 
                }); 
            }) : [];

            if(selectedRoles?.length > 0)
            {
                setRoleChanges(selectedRoles);
            }
        };

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {ROLE.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const CustomSubRoleEditComponent = (props): React.JSX.Element =>
    {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event):void => 
        {
            const eventValue = event.target.value;
            const newValue =
                typeof eventValue === 'string' ? value.split(',') : eventValue;
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        setRoleChanges(props?.row?.role);

        return (
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value ? typeof value === 'string' ? value.split(',') : value : []}
                onChange={handleChange}
                sx={{
                    width: '100%' 
                }}
            >
                {matchingSubRole?.map((option):React.JSX.Element => 
                {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ); 
                })}
            </Select>
        );
    };

    const setRoleChanges = (value): void => 
    {
        if(value)
        {
            if (typeof (value) === 'string') 
            {
                const newValue = value.split(',');
                const rolesPositions = [];
                    
                newValue.forEach((element):void => 
                {
                    const rolesPosition = ROLE.indexOf(element);
                    rolesPositions.push(rolesPosition);
                });
            
                if (rolesPositions.length > 0) 
                {
                    matchingSubRole= [];
                    rolesPositions.forEach((item):void => 
                    {
                        if (SUB_ROLE[item].length > 0) 
                        {
                            SUB_ROLE[item].map((tech):void => 
                            {
                                matchingSubRole.push(tech);
                            });
                        }
            
                    });
                }
            }
            else 
            {
                const rolesPositions = [];
                value.forEach((element):void => 
                {
                    const rolesPosition = ROLE.indexOf(element);
                    rolesPositions.push(rolesPosition);
                });
    
                if (rolesPositions.length > 0) 
                {
                    matchingSubRole = [];
                    rolesPositions.forEach((item):void => 
                    {
                        if (SUB_ROLE[item].length > 0) 
                        {
                            SUB_ROLE[item].map((tech):void => 
                            {
                                matchingSubRole.push(tech);
                            });
                        }                        
                    });
                }
            }
        }
       
    };

    // const columns = [
    //     {
    //         field: 'name', headerName: 'Name', flex: 1, editable: true, disableColumnMenu: true, renderCell: (params):React.JSX.Element =>
    //         {
    //             return(
    //                 <Typography className="data-grid-bolded-cellContent">{params?.value}</Typography>
    //             );
    //         }
    //     },
    //     {
    //         field: 'role', headerName: 'Role', flex: 1, editable: true, disableColumnMenu: true,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomRoleEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'subRole', headerName: 'Sub Role', flex: 1, editable: true, disableColumnMenu: true, sortable: false,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : ''); 
    //         },
    //         renderEditCell: CustomSubRoleEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'seniority', headerName: 'Seniority', flex: 1, editable: true, disableColumnMenu: true, sortable: false,type: 'singleselect', valueOptions: SENIORITY,
    //         valueFormatter: ({ value }):string => 
    //         {
    //             return (value ? typeof value === 'string' ? value.split(',') : value : []); 
    //         },
    //         renderEditCell: CustomSeniorityEditCell,
    //         filterOperators: [
    //             {
    //                 value: 'contains',
    //                 // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    //                 getApplyFilterFn: (filterItem) => 
    //                 {
    //                     if (filterItem.value == null || filterItem.value === '') 
    //                     {
    //                         return null;
    //                     }
    //                     return ({ value }):string => 
    //                     {
    //                         return value?.some((cellValue):boolean => 
    //                         {
    //                             return cellValue === filterItem.value; 
    //                         });
    //                     };
    //                 },
    //             }
    //         ]
    //     },
    //     {
    //         field: 'description', headerName: 'Description', flex: 1, editable: true, disableColumnMenu: true,
    //     },
    //     {
    //         field: 'actions', headerName: '', editable: false, sortable: false, flex: 1, maxWidth: 40, disableColumnMenu: true, renderCell: (params):React.JSX.Element => 
    //         {
    //             return buyerPersonaActions(params.row);
    //         }
    //     },
    // ];

    const columnHelper = createColumnHelper<IBuyerPersona>();
    const columns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('role', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Role',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'role'
        }),
        columnHelper.accessor('subRole', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Sub Role',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'subRole'
        }),
        columnHelper.accessor('seniority', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Seniority',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'seniority'
        }),
        columnHelper.accessor('description', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Description',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'description'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return buyerPersonaActions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const openDrawer = ():void => 
    {
        
        dispatch(resetBuyerPersona());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.BUYER_PERSONA.SIDE_DRAWER.HEADING.CREATE,
            component: < BuyerPersona setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const buyerPersonaActions = (buyerPersonaInfo: IBuyerPersona):React.JSX.Element => 
    {

        const editClick = ():void => 
        {
            handleViewEdit(buyerPersonaInfo);
        };

        const deleteClick = ():void => 
        {
            setSelectedBuyerPersona(buyerPersonaInfo);
            setShowDeletePopup(true);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleViewEdit = (buyerPersonaInfo: IBuyerPersona):void => 
    {

        dispatch(resetBuyerPersona());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.BUYER_PERSONA.SIDE_DRAWER.HEADING.EDIT,
            component: <BuyerPersona buyerPersonaData={buyerPersonaInfo} is-editable={true} setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    return (
        <Box id="buyerPersonaPage">
            <HeaderBar 
                title={'Buyer Persona (BP)'}
                buttons={[
                    <CustomButton btnType="secondary" name="Create Buyer Persona" onClick={() => openDrawer()} />
                ]}
            />
            <Box sx={{
                flex: 1,
                height: 'calc(100% - 64px)', 
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={buyerPersonaEmptyImg} openDrawer={openDrawer} infoText={'No Buyer Persona'} subInfoText={'Add your first Buyer Persona'} buttonName={'Add Buyer Persona'} />) :
                    (
                        <div className="h-100">
                            <TanstackToolbar 
                                columns={columns}
                                setColumns={setAllColumns}
                                rows={buyerPersona}
                                setRows={setRows}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: buyerPersonaDataCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={rows}
                                showSpinner={showSpinner}
                                pageSize={pageSize}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                rowCount={buyerPersonaDataCount}
                                currentPage={currentPage - 1}
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => handleViewEdit(row)}
                                height='calc(100% - 109px)'
                            />
                        </div>
                    )}
            </Box>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteBuyerPersonaFromTable(selectedBuyerPersona as IBuyerPersona)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedBuyerPersona(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedBuyerPersona?.name}?`}
                        modalTitle='Delete Buyer Persona'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};
export default BuyerPersonaPage;