import { useContext, useEffect, useState } from "react";
import { RegistrationForm } from "../../../Registration/interfaces/registration-form_interface";
import FormPreview from "../../../Registration/RegistrationSubPages/Preview/FormPreview";

import SponsorPortalAppBar from "../../Components/Common/SponsorPortalAppbar";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { SponsorPortalContext } from "../../../../contexts/SponsorPortal/SponsorPortalContext";
import SponsorPortalFormsProvider from "../../../../contexts/SponsorPortal/SponsorPortalForms";
import LeadCaptureRegistrations from "./LeadCaptureRegistrations";
import { getLeadCaptureForm } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";

const LeadCaptureFormPage: React.FC = (): React.JSX.Element =>
{

    const { selectedModule ,setSelectedModule } = useContext(SponsorPortalContext);

    const [regFormData, setRegFormData] = useState<RegistrationForm | null>(null);

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const formData = await getLeadCaptureForm();
    
            if(formData && formData?.formFields && formData?.formFields?.length > 0)
            {
                const filteredFields = formData?.formFields?.filter((field) => !(['firstName', 'lastName', 'email']?.includes(field?.default)));
                setRegFormData({
                    ...formData,
                    formFields: filteredFields,
                });
            }
        } 
        catch (error) 
        {
            
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {selectedModule === 'form' && <div id="leadCaptureFormPage">
                <SponsorPortalAppBar title={regFormData?.name || ''} buttons={[<CustomButton btnType="primary" name='Edit Form' onClick={() => setSelectedModule('formFieldEdit')} />]} />
                <FormPreview formId={regFormData?.id} registrationFormData={{ registrationForm: regFormData }} width={'50%'} maxWidth={'50%'} maxHeight={'calc(100vh - 68px)'} margin={'20px 20px 0px 20px'} />
            </div>}

            {
                selectedModule === 'formFieldEdit' && 
                <SponsorPortalFormsProvider>
                    <LeadCaptureRegistrations />
                </SponsorPortalFormsProvider>
            }
        </>
    )
};

export default LeadCaptureFormPage;