import { useState } from "react";
import { Event, EventGraphObject } from "../../../pages/Events/interfaces";
import FormTabs from "../../../common/FormTabs";
import TableFilter from "../../../common/TanstackTable/TableFilter";
import ColumnVisibilityDropdown from "../../../common/TanstackTable/ColumnVisibilityDropdown";
import { syncLumaEvents } from "../../../scripts/apis/events";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip } from "@mui/material";
import { CONTENT } from "../../../scripts/i18n";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import './styles.scss';

interface EventsPageToolbarProps { 
    eventsCount: number; 
    rows: Event[]; 
    setEvents: React.SetStateAction<React.Dispatch<Event[]>>;
    columns: any[];
    setColumns: React.SetStateAction<React.Dispatch<any[]>>;
    lumaIntegration?: boolean;
    statusCount?: EventGraphObject;
}

const EventsPageToolbar: React.FC<EventsPageToolbarProps> = ({ setEvents, eventsCount, rows, columns, setColumns, lumaIntegration, statusCount }): React.JSX.Element =>
{
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const columnOptions = columns
        .filter(column => column.id !== 'actions')
        .map(column => {
            return {
                label: column?.header,
                value: column?.accessorKey || column?.id
            } 
        }
    );
    
    const [filters, setFilters] = useState([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].inputValue = value;
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const handleColumnChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].selectedColumn = value;
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const addFilter = () => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        const availableColumns = columnOptions.filter(option => !selectedColumns.includes(option.value));
        const defaultColumn = availableColumns.length > 0 ? availableColumns[0].value : '';
        setFilters([...filters, { selectedColumn: defaultColumn, inputValue: '' }]);
    };

    const removeFilter = (index: number) => 
    {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const clearFilters = () => 
    {
        setFilters([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
        // filterFn([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
    };

    const getEventPhase = (event: Event) => {
        const currentTimestamp = Math.floor(new Date().getTime() / 1000.0);
        const isOngoingEvent = (currentTimestamp > event.eventStartDateTime && currentTimestamp < event.eventEndDateTime);
        const isUpcomingEvent = event.eventStartDateTime > currentTimestamp;
        const isEventEnded = currentTimestamp > event.eventEndDateTime;
    
        if (isOngoingEvent) return 'Ongoing';
        if (isUpcomingEvent) return 'Upcoming';
        if (isEventEnded) return 'Ended';
        return '-';
    };

    const getEventDates = (event: Event) => {
        const startDate = `${moment.unix(event?.eventStartDateTime).format('MMM DD')}`;
        const endDate = `${moment.unix(event?.eventEndDateTime).format('MMM DD')}`;
        return `${startDate} - ${endDate}`;
    }

    const customFilterFunctions = {
        eventPhase: (obj: any, inputValue: string | number) => {
            const phase = getEventPhase(obj);
            return phase.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        eventDates: (obj: any, inputValue: string | number) => { 
            console.log('inside eventDates');
            
            const dates = getEventDates(obj);
            console.log(dates, 'dates')
            return dates.toLowerCase().includes(String(inputValue).toLowerCase());
        }
    };

    const SyncMappedDataComponent = ():React.JSX.Element =>
    {
        const handleLumaSync = async ():Promise<void> => {
            try {
                await syncLumaEvents().then(() => toast.success('Events sync in progress'));
            } 
            catch (error) 
            {
                console.log('Error in syncing Luma Events', error);
            }
        };

        return(
            <Box className="sync-button">
                <Tooltip title='Sync Data'><FontAwesomeIcon onClick={handleLumaSync} className="sync-mapped-data-button" icon={['fal', 'sync']} /></Tooltip>
            </Box>
        )
    }
    
    return (
        <div id="eventsPageToolbar">
            <div className="events-toolbar">
                <FormTabs 
                    tabs={[
                        {
                            tabName: 'All',
                            count: eventsCount,
                            navigation: () => navigate('/events'),
                            selectedTab: currentpath?.endsWith('events'),
                        },
                        {
                            tabName: 'Published',
                            count: statusCount && Number(statusCount['PUBLISHED']) || '-',
                            navigation: () => navigate('/events/published'),
                            selectedTab: currentpath?.includes('published'),
                        },
                        {
                            tabName: 'Draft',
                            count: statusCount && Number(statusCount['DRAFT']) || '-',
                            navigation: () => navigate('/events/draft'),
                            selectedTab: currentpath?.includes('draft'),
                        }
                    ]}
                />
                <div className="toolbar-actions">
                    <TableFilter 
                        rows={rows}
                        columnOptions={columnOptions}
                        setRows={setEvents}
                        filters={filters}
                        handleColumnChange={handleColumnChange}
                        handleInputChange={handleInputChange}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        clearFilters={clearFilters}
                        customMappings={{
                            'type': CONTENT.DASHBOARD_PAGE.EVENT_TYPES,
                            'locationType': CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES,
                            'integrationType': CONTENT.EVENTS_PAGE.EVENT_INTEGRATION_TYPES,
                            'status': CONTENT.EVENTS_PAGE.EVENT_STATUS,
                        }}
                        customFilterFunctions={customFilterFunctions}
                    />
                    <ColumnVisibilityDropdown 
                        columns={columns}
                        setColumns={setColumns}
                    />
                    {
                        lumaIntegration && <SyncMappedDataComponent />
                    }
                </div>
            </div>
        </div>
    )
};

export default EventsPageToolbar;