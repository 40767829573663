/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Stack, Tooltip, Typography, createFilterOptions } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../scripts/i18n';
import moment from 'moment-timezone';
import { addEvent } from '../../redux/events/Events';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import APP_CONSTANTS from '../../scripts/constants';
import { createEvent, createEventRegistrationToolMap, getMapEvents, updateEvent } from '../../scripts/apis/events';
import eventBus from '../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEventCategory from './CreateEventCategory';
import { getAllEventCategories } from '../../scripts/apis/eventCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorage } from '../../scripts/LocalStorage';
import { AutocompleteComponentWithFilterOption, CloseIconComponent, FormControlComponent, FormLabelComponent, SelectComponent, TimezoneSelectComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomSwitch } from '../../common/StyledComponents/Switch.styled';
import { uniqueTimezoneArr } from './timezoneGenerateFunction';
// eslint-disable-next-line import/no-unresolved
import { Event, EventCategory, EventMapObj } from '../../pages/Events/interfaces';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../pages/Settings/CustomField/CustomTypeComponent';
import { CustomFieldLocations, CustomFieldType, customFieldCountByLocation, customFieldsByLocation } from '../../pages/Settings/CustomField/CustomField.helper';
import { ICreateEventProps, IEventReduxValues, IEventsDispatch } from './interfaces/create-events_interface';
import { eventLocationOptions, eventTypeOptions } from '../../contexts/EventsPageContext';
import CustomSpinner from '../../common/CustomSpinner';
import { CustomButton } from '../../common/FormComponents/Buttons';
import './styles.scss';
import { MuiDateTimeRangePicker } from '../../common/FormComponents/DateTimePickers';
import { MapEvent } from '../../pages/Events/interfaces/event-registration-tools_interface';
import { EventTypes } from '../../pages/Events/enum';

const CreateEvent= (props: ICreateEventProps): React.JSX.Element => 
{

    const eventData = props.eventData;
    const filter = createFilterOptions();

    const [eventTitleLength, setEventTitleLength] = useState(0);
    const [eventCategoryDetails, setEventCategoryDetails] = useState([]);
    const [spinner, setSpinner] = useState(false);
    // const [endDateEnabled, setEndDataEnabled] = useState(false);
    // const [endTimeEnabled, setEndTimeEnabled] = useState(false);
    const [customFormState, setCustomFormState] = useState({
    });
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [mapEvents, setMapEvents] = useState<MapEvent[]>([]);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const orgLink = LocalStorage.get('@Org')?.link;

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const eventMapOptionLabel = mapEvents?.length > 0 && mapEvents?.reduce((acc, event) => {
        acc[event.id] = event.name;
        return acc;
    }, {});

    const fetchEventsCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.EVENT);
            if(cfcount)
            {
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.EVENT);
                    if(customFields)
                    {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const handleTimeZoneChange = (event: React.SyntheticEvent, newValue: string): void => 
    {

        formik.setFieldValue('timezone', newValue);

        if (newValue) 
        {
            const timezoneForSelectedValue = _.find(uniqueTimezoneArr, function (item: {name: string; value: string}) : boolean
            {
                return item.name === newValue;
            }).value;

            dispatch(addEvent({
                timezoneDisplayName: newValue
            }));

            dispatch(addEvent({
                timeZone: timezoneForSelectedValue ? timezoneForSelectedValue : ''
            }));
        }
    };

    const handleEventType = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        formik.setFieldValue('type', event.target.value);
        formik.setFieldValue('link', '');
        dispatch(addEvent({
            type: event.target.value,
            link: ''
        }));
    };

    const handleEventLocation = (event: React.ChangeEvent<HTMLInputElement>): void => 
    {
        console.log(event.target.value);
        
        formik.setFieldValue('locationType', Number(event.target.value));
        dispatch(addEvent({
            locationType: event.target.value
        }));
    };

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/events');
        }
    };

    const handleEventCategoryChange = (event: React.SyntheticEvent, newValue: string): void => 
    {
        if (newValue && newValue.includes('Add :')) 
        {
            const eventCategoryName = newValue.split('Add : ')[1];

            setTimeout((): void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    heading: 'Create New Event Category',
                    component: <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={eventCategoryName} />,
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true
                });
            });
        }
        else 
        {
            formik.setFieldValue('eventCategoryId', newValue);

            const selectedEventCategory = _.filter(eventCategoryDetails, function (group: EventCategory) : boolean
            {
                return newValue === group.name;
            })[0];
            // setGroup(selectedEventCategory);

            dispatch(addEvent({
                eventCategory: newValue ? newValue : ''
            }));

            dispatch(addEvent({
                eventCategoryId: selectedEventCategory ? selectedEventCategory.id : ''
            }));
        }
    };

    const categoryFilterOptions = (options: any, params: any):any[] => 
    {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !options.includes(params.inputValue)) {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required')
            .max(100, 'Event Title length exceeded'),
        link: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'Enter valid Public URL').test('isValidLink', 'Public URL is required', (value): boolean => 
        {
            if (formik.values.type === 1) 
            {
                if (!value) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                return true;
            }
        }),
        locationType: Yup.number()
            .required('Please select the Event location'),
        type: Yup.number()
            .required('Please select the Event Type'),
        timezone: Yup.string()
            .required('Timezone is required'),
        registrationType: Yup.number(),
        eventStartDateTime: Yup.string()
            .required('Start Date is required'),
        eventEndDateTime: Yup.string()
            .required('End Date is required'),
        status: Yup.number(),
        eventCategoryId: Yup.string()
            .required('Event Category is required'),
        eventMapId: Yup.string().notRequired(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            title: eventReduxData.title ? eventReduxData.title : '',
            link: eventReduxData.link ? eventReduxData.link : '',
            type: eventReduxData.type ? eventReduxData.type : 1,
            registrationType: eventReduxData.registrationType ? eventReduxData.registrationType : 1,
            locationType: eventReduxData.locationType ? eventReduxData.locationType : 1,
            timezone: eventReduxData.timezoneDisplayName ? eventReduxData.timezoneDisplayName : '',
            eventStartDateTime: eventReduxData.eventStartDateTime ? eventReduxData.eventStartDateTime : '',
            eventEndDateTime: eventReduxData.eventEndDateTime ? eventReduxData.eventEndDateTime : '',
            status: eventReduxData.status ? eventReduxData.status : 0,
            eventCategoryId: eventReduxData.eventCategory ? eventReduxData.eventCategory : '',
            eventMapId: eventData?.lumaEventId ? eventData?.lumaEventId : '',
        },
        onSubmit: (): void => 
        {          
            const { title, link, type, locationType, eventStartDateTime, eventEndDateTime, registrationType } = formik.values;

            if (!(_.isEmpty((eventData)))) 
            {
                const isEventStartDateTimeChanged = moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD HH:mm') !== moment.unix(Number(eventStartDateTime)).format('YYYY-MM-DD HH:mm');
                const isEventEndDateTimeChanged = moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD HH:mm') !== moment.unix(Number(eventEndDateTime)).format('YYYY-MM-DD HH:mm');
                const isEventDataChanged = eventData.title !== title || eventData.link !== link || eventData.type !== type || eventData.locationType !== locationType || eventData.timezone !== eventReduxData?.timeZone || eventData.eventCategoryId !== eventReduxData?.eventCategoryId || eventData.registrationType !== registrationType || eventData.landingPageType !== eventReduxData?.landingPageType || customFormState;
                
                if (!(_.isEmpty((eventData))) && (isEventStartDateTimeChanged || isEventEndDateTimeChanged || isEventDataChanged)) 
                {
                    updateEventDetails();
                }
                else 
                {
                    return;
                }
            }
            else 
            {
                addEventDetails();
            }
        }
    });

    const updateEventDetails = async (): Promise<void> => 
    {
        setSpinner(true);
        if (moment(`${formik.values.eventEndDateTime}`).unix() < moment(`${formik.values.eventStartDateTime}`).unix()) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                open: true,
                message: 'Event End Timing and Date should be Greater than Event Start Timing and Date',
                severity: 'error',
                positionVertical: 'top',
                positionHorizontal: 'right',
            });

            return;
        }

        const eventDetails = {
            title: formik.values.title,
            ...(formik.values.type === EventTypes.HOST && { link: formik.values.link }),
            type: formik.values.type,
            locationType: formik.values.locationType,
            registrationType: formik.values.registrationType,
            timezone: eventReduxData.timeZone,
            eventStartDateTime: moment(`${formik.values.eventStartDateTime}`).unix(),
            eventEndDateTime: moment(`${formik.values.eventEndDateTime}`).unix(),
            eventCategoryId: String(eventReduxData.eventCategoryId),
        };
        if(customFormState)
        {
            const newEventDetails = {
                ...eventDetails,
                ['additionalFieldData']:{
                    ...customFormState
                }
            };

            try 
            {
                const eventsDataUpdated = await updateEvent(String(eventData?.id), newEventDetails);
                if (!eventsDataUpdated) return;

                if (eventsDataUpdated) {
                    dispatch(addEvent({
                        type: Number(eventsDataUpdated.type),
                        eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                        eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                    }));
                }

                if(formik.values.eventMapId) {
                    const eventMapObj = {
                        type: 5,
                        mapEvent: [
                            {
                                eventId: eventData?.id,
                                integrationMapId: formik.values?.eventMapId
                            }
                        ]
                    };
            
                    try {
                        await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                    } catch (error) {
                        console.log('Error with mapping the event' ,error);
                    }
                }
            
                if (!props.fromOverview) {
                    props?.setRefresh(true);
                }

                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Event details updated successfully',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            
                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                eventBus.dispatch('event-details-refreshed', {
                    refresh: true 
                });
                eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
            
                handleDrawerClose();
            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setSpinner(false);
            }
        }
        else 
        {
            try {
                const eventsDataUpdated = await updateEvent(String(eventData?.id), eventDetails);
                if (!eventsDataUpdated) return;

                if (eventsDataUpdated) {
                    dispatch(addEvent({
                        type: Number(eventsDataUpdated.type),
                        eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                        eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                    }));
                }
            
                if(formik.values.eventMapId) {
                    const eventMapObj = {
                        type: 5,
                        mapEvent: [
                            {
                                eventId: eventData?.id,
                                integrationMapId: formik.values?.eventMapId
                            }
                        ]
                    };
            
                    try {
                        await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                    } catch (error) {
                        console.log('Error with mapping the event' ,error);
                    }
                }
            
                if (!props.fromOverview) {
                    props?.setRefresh(true);
                }

                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Event details updated successfully',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            
                eventBus.dispatch('event-overview-refreshed', { refresh: true });
                eventBus.dispatch('event-details-refreshed', {
                    refresh: true 
                });
                eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
            
                handleDrawerClose();
            } catch (error) {
                console.log(error);
            } finally {
                setSpinner(false);
            }
        }
    };

    const addEventDetails = async () : Promise<void> => 
    {
        setSpinner(true);

        if (moment(`${formik.values.eventEndDateTime}`).unix() < moment(`${formik.values.eventEndDateTime}`).unix()) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                open: true,
                message: 'Event End Timing and Date should be Greater than Event Start Timing and Date',
                severity: 'error',
                positionVertical: 'top',
                positionHorizontal: 'right',
            });
            setSpinner(false);
            return;
        }
        
        const eventDetails = {
            title: formik.values.title,
            ...(formik.values.type === EventTypes.HOST && { link: formik.values.link }),
            type: formik.values.type,
            locationType: formik.values.locationType,
            timezone: eventReduxData.timeZone,
            registrationType: formik.values.registrationType,
            eventStartDateTime: moment(`${formik.values.eventStartDateTime}`).unix(),
            eventEndDateTime: moment(`${formik.values.eventEndDateTime}`).unix(),
            status: 1,
            eventCategoryId: String(eventReduxData.eventCategoryId),
        };

        if(customFormState)
        {
            const newEventDetails = {
                ...eventDetails,
                ['additionalFieldData']:{
                    ...customFormState
                }
            };

            try {
                const eventCreated = await createEvent(newEventDetails, csrfTokenData);
                if (eventCreated) {
                    if(formik.values?.eventMapId) {
                        const eventMapObj = {
                            type: 5,
                            mapEvent: [
                                {
                                    eventId: eventCreated.id,
                                    integrationMapId: formik.values?.eventMapId
                                }
                            ]
                        };
    
                        try {
                            await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                        } catch (error) {
                            console.log('Error with mapping the event' ,error);
                        }
                    }
        
                    if (!(eventCreated instanceof Error)) {
                        dispatch(addEvent({
                            eventStartDate: moment.unix(eventCreated?.eventStartDateTime).format('YYYY-MM-DD'),
                            eventStartTime: moment.unix(eventCreated?.eventStartDateTime).format('HH:mm'),
                            eventEndDate: moment.unix(eventCreated?.eventEndDateTime).format('YYYY-MM-DD'),
                            eventEndTime: moment.unix(eventCreated?.eventEndDateTime).format('HH:mm'),
                            eventStartDateTime: eventCreated.eventStartDateTime,
                            eventEndDateTime: eventCreated.eventEndDateTime,
                            closingDateTime: eventCreated.eventStartDateTime,
                            eventCategoryId: eventCreated.eventCategoryId,
                            shortName: eventCreated.link,
                            type: eventCreated.type
                        }));
        
                        setSpinner(false);
                        handleDrawerClose();
                        props?.setRefresh(true);
        
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Event Created',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
        
                        navigate('/events/' + Number(eventCreated.id) + '/overview');
                    }
                }
            } catch (error) {
                if(error?.message === 'EVENT LINK EXISTS')
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: _.startCase('Another event already has this link'),
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                }
                console.log(error);
            } finally {
                setSpinner(false);
            }
        } 
        else 
        {
            try {
                const eventCreated = await createEvent(eventDetails, csrfTokenData);
                if (eventCreated) {
                    if(formik.values?.eventMapId) {
                        const eventMapObj = {
                            type: 5,
                            mapEvent: [
                                {
                                    eventId: eventCreated.id,
                                    integrationMapId: formik.values?.eventMapId
                                }
                            ]
                        };
    
                        try {
                            await createEventRegistrationToolMap(eventMapObj, csrfTokenData);
                        } catch (error) {
                            console.log('Error with mapping the event' ,error);
                        }
                    }
        
                    if (!(eventCreated instanceof Error)) {
                        dispatch(addEvent({
                            eventStartDate: moment.unix(eventCreated.eventStartDateTime).format('YYYY-MM-DD'),
                            eventStartTime: moment.unix(eventCreated.eventStartDateTime).format('HH:mm'),
                            eventEndDate: moment.unix(eventCreated.eventEndDateTime).format('YYYY-MM-DD'),
                            eventEndTime: moment.unix(eventCreated.eventEndDateTime).format('HH:mm'),
                            eventStartDateTime: eventCreated.eventStartDateTime,
                            eventEndDateTime: eventCreated.eventEndDateTime,
                            closingDateTime: eventCreated.eventStartDateTime,
                            eventCategoryId: eventCreated.eventCategoryId,
                            shortName: eventCreated.link,
                            type: eventCreated.type
                        }));
        
                        setSpinner(false);
                        handleDrawerClose();
                        props?.setRefresh(true);
        
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Event Created',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
        
                        navigate('/events/' + Number(eventCreated.id) + '/overview');
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setSpinner(false);
            }
        }
       

    };

    const fetchMapEvents = async (): Promise<void> => {
        try 
        {
            const mapEventsData = await getMapEvents(5);
            if(mapEventsData)
            {
                setMapEvents(mapEventsData?.mapEvents);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        let eventCategoryData;
        try 
        {
            eventCategoryData = await getAllEventCategories();
            if (eventCategoryData) 
            {
                setEventCategoryDetails([...eventCategoryData]);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        if (!(_.isEmpty((eventData)))) 
        {
            dispatch(addEvent({
                title: eventData.title,
                description: eventData.description,
                eventStartDate: moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD'),
                eventStartTime: moment.unix(eventData.eventStartDateTime).format('HH:mm'),
                eventEndDate: moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD'),
                eventEndTime: moment.unix(eventData.eventEndDateTime).format('HH:mm'),
                timeZone: eventData.timezone,
                type: eventData.type,
                registrationType: eventData.registrationType,
                link: eventData.link,
                eventCategoryId: eventData.eventCategoryId ? eventData.eventCategoryId : '',
                status: eventData.status ? eventData.status : 1,
                landingPageType: eventData?.landingPageType,
                eventStartDateTime: moment.unix(eventData.eventStartDateTime).tz(eventData?.timezone).format('MM/DD/YYYY hh:mm A'),
                eventEndDateTime: moment.unix(eventData.eventEndDateTime).tz(eventData?.timezone).format('MM/DD/YYYY hh:mm A'),
            }));
            // let words = eventData.description.split(" ");
            // let wordsLength = words.length;
            setEventTitleLength(eventData?.title?.length);

            formik.setFieldValue('eventMapId', eventData?.lumaEventId ? eventData?.lumaEventId : '');

            const defaultCategory = _.filter(eventCategoryData, function (elem) : boolean
            {
                return elem.id === eventData?.eventCategoryId;
            });

            const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem) : boolean
            {
                return elem.value === eventData.timezone;
            });

            if (defaultTimezone) 
            {
                dispatch(addEvent({
                    timezoneDisplayName: defaultTimezone[0]?.name ? defaultTimezone[0]?.name : ''
                }));
            }

            if (defaultCategory) 
            {
                dispatch(addEvent({
                    eventCategory: defaultCategory[0]?.name ? defaultCategory[0]?.name : ''
                }));
            }
        }
    };

    useEffect((): void => 
    {
        fetchData();
        fetchEventsCustomFieldData();
        fetchMapEvents();
        if(eventData?.additionalFieldData)
        {
            setCustomFormState({
                ...eventData?.additionalFieldData
            });
            
        }
    }, []);

    const handleInputChange = (event, id): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item, data): void => 
    {

        if(customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else
        {
            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }
       
    };

    const handleEditEventCategory = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
            heading: 'Edit Event Category',
            component: <CreateEventCategory setEventCategoryDetails={setEventCategoryDetails} eventCategoryName={formik.values.eventCategoryId} isEdit eventCategoryId={eventReduxData?.eventCategoryId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
            open: true
        });
    };

    return (
        <Box id="createEvent">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>
                {/* Event Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Name'} required={true} />
                    <FormControlComponent
                        value={formik.values.title}
                        placeholder={'Name'}
                        type={'text'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                        {
                            formik.setFieldValue('title', event.target.value);
                            dispatch(addEvent({
                                title: event.target.value
                            }));
                            setEventTitleLength(event.target.value.length);
                        }}
                        isInvalid={formik.errors.title && formik.touched.title}
                    />
                    <Box className="title-length-error-cont">
                        {formik.errors.title && formik.touched.title ? <Typography className="error-msg">{formik.errors.title}</Typography> : <div/>}
                        <Typography className="title-length">{eventTitleLength}/100</Typography>
                    </Box>
                </Box>

                {/* Event Timing & Timezone */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Select Date & Time'} required={true} />
                    <Box className="event-timing-wrapper">
                        <div>
                            <MuiDateTimeRangePicker value={[formik.values.eventStartDateTime, formik.values.eventEndDateTime]} onChange={(newValue): void => 
                            {
                                dispatch(addEvent({
                                    eventStartDateTime: newValue[0]?.tz(formik.values.timezone).format('MM/DD/YYYY hh:mm A'),
                                    eventEndDateTime: newValue[1].tz(formik.values.timezone)?.format('MM/DD/YYYY hh:mm A')
                                }));
                                formik.setFieldValue('eventStartDateTime', newValue[0].tz(formik.values.timezone)?.format('MM/DD/YYYY hh:mm A'));
                                formik.setFieldValue('eventEndDateTime', newValue[1].tz(formik.values.timezone)?.format('MM/DD/YYYY hh:mm A')); 
                            }} desktopPaperStyle={{
                                position: 'relative',
                                left: '222px'
                            }} />
                            <Box className="events-error-msg-container">
                                {formik.touched.eventStartDateTime && formik.errors.eventStartDateTime ? <Typography className="error-msg">{formik.errors.eventStartDateTime}</Typography> : null}
                                {formik.touched.eventEndDateTime && formik.errors.eventEndDateTime ? <Typography className="error-msg">{formik.errors.eventEndDateTime}</Typography> : null}
                            </Box>
                        </div>

                        <Box className="event-timezone-component">
                            <Box className="w-100">
                                <TimezoneSelectComponent defaultValue={formik.values?.timezone} value={formik.values?.timezone} optionsArr={uniqueTimezoneArr} onChange={handleTimeZoneChange} placeholder='Select a timezone' />
                                {formik.errors.timezone && formik.touched.timezone ? <Typography className="error-msg">{formik.errors.timezone}</Typography> : null}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
                {/* Event Location Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Location Type'} required={true} />
                    <SelectComponent options={eventLocationOptions} value={Number(formik.values.locationType)} onChange={handleEventLocation} optionLabels={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EVENT_LOCATION_OPTIONS} defaultPlaceholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.EVENT_LOCATION_SELECT_BTN_LABEL} />
                    {formik.errors.locationType && formik.touched.locationType ? <Typography className="error-msg">{formik.errors.locationType}</Typography> : null}
                </Box>

                {/* Event Type */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Type'} required={true} />
                    <SelectComponent options={eventTypeOptions} value={String(formik.values.type)} onChange={handleEventType} optionLabels={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EVENT_TYPE_OPTIONS} defaultPlaceholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.EVENT_TYPE_SELECT_BTN_LABEL} />
                    {formik.errors.type && formik.touched.type ? <Typography className="error-msg">{formik.errors.type}</Typography> : null}
                </Box>

                {/* Event Category */}
                <Box className="sidebar-container-spacing">
                    <Box className="tooltip-box">
                        <FormLabelComponent label={'Event Category'} required={true} />
                        <Tooltip
                            title="Categorize events based on your preference"
                            arrow
                            placement="top"
                        >
                            <FontAwesomeIcon
                                icon={['fal', 'info-circle']}
                                className="info-icon"
                            />
                        </Tooltip>
                    </Box>
                    <Box className="event-category-container">
                        <Box width={'100%'}>
                            <AutocompleteComponentWithFilterOption value={formik.values.eventCategoryId || ''} defaultValue={formik.values.eventCategoryId || ''} onChange={handleEventCategoryChange} filterOptions={categoryFilterOptions} keyToShow='name' optionsArr={eventCategoryDetails} placeholder={eventCategoryDetails.length > 0 ? CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_SELECT : CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.FORMS.PLACEHOLDER.EVENT_CATEGORY_GROUP_EMPTY_SELECT} />
                        </Box>
                        {formik.values?.eventCategoryId && <CustomButton btnType='secondary' name='Edit' style={{ maxHeight: '40px', height: '40px', fontSize: '12px' }} onClick={handleEditEventCategory} />}
                    </Box>
                    {formik.errors.eventCategoryId && formik.touched.eventCategoryId ? <Typography className="error-msg">{formik.errors.eventCategoryId}</Typography> : null}
                </Box>

                {formik.values.type === 1 && <div>
                    {/* Public URL */}
                    <Box className="sidebar-container-spacing">
                        <Box className="tooltip-box">
                            <FormLabelComponent label={'Public URL'} required />
                            <Tooltip
                                title="Host your event landing page in this URL"
                                arrow
                                placement="top"
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'info-circle']}
                                    className="info-icon"
                                />
                            </Tooltip>
                        </Box>
                        <FormControlComponent
                            type={'text'}
                            value={formik.values.link}
                            placeholder='Public URL'
                            onChange={(event): void => 
                            {
                                formik.setFieldValue('link', event.target.value);
                                // setEventLinkLength(event.target.value.length);
                                dispatch(addEvent({
                                    link: event.target.value
                                }));
                            }}
                            isInvalid={formik.errors.link && formik.touched.link}
                        />
                        <Typography className="short-link-example">{'https://app.eventhq.com/'}{orgLink}{'/'}{eventReduxData.link}</Typography>
                        {formik.errors.link && formik.touched.link ? <Typography className="error-msg">{formik.errors.link}</Typography> : null}
                    </Box>

                    {/* Require Approval */}
                    <Box className="sidebar-container-spacing">
                        <Box className="approval-container">
                            <Box className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                                <FormLabelComponent label={'Requires Approval to attend'} noBottomMargin />
                            </Box>
                            <FormControlLabel
                                checked={formik.values.registrationType === 2}
                                control={<CustomSwitch
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('registrationType', event.target.checked ? 2 : 1);
                                        dispatch(addEvent({
                                            registrationType: event.target.checked ? 2 : 1
                                        }));
                                    }}
                                />}
                                label={''}
                            />
                        </Box>
                    </Box>
                </div>}

                {/* Luma event map */}
                {props?.isLumaIntegrated && <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Map'} />
                    <SelectComponent selectedAsString options={mapEvents} value={formik.values?.eventMapId} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                    {
                        formik.setFieldValue('eventMapId', event.target.value);
                    }} optionLabels={eventMapOptionLabel} defaultPlaceholder={'Select an event from Luma'} />
                </Box>}

                {/* Custom fields */}
                {customFields.length > 0 &&
                customFieldsSpinner ? <CustomSpinner height={'100%'}/> :
                    <Box className="custom-field-container">
                        {customFields.map((item): React.ReactElement => 
                        {
                            if (item.type === CustomFieldType.NUMBER) 
                            {
                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.SINGLE_LINE) 
                            {
                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.MULTI_LINE_TEXT) 
                            {
                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item.name + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.CHECKBOXES) 
                            {
                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DROPDOWN) 
                            {
                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.RADIO) 
                            {
                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DATE) 
                            {
                                return renderDateComponent(item, handleInputChange, customFormState?.[item.label + ' ' + item.id], null);
                            }
                        })
                        }
                    </Box>
                }

                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={!(_.isEmpty(eventData)) ? 'Update Event' : 'Create Event'} btnType={'primary'} />
                    </Stack>
                </Box>

            </Form >
        </Box >
    );
};

export default CreateEvent;