import { Box } from '@mui/material';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomSpinner = ({ height }) : React.JSX.Element => 
{
    return (
        <Box sx={{
            height: height 
        }} className="spinner-container">
            <Spinner animation="border" role="status" />
        </Box>
    );
};

export default CustomSpinner;