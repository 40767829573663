import { EventTicket, EventTicketGroup, UpdateEventTicket } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const ticketsCount = async (eventId: number | string): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAlleventTickets = async (pageSize: number, offset: number, eventId: number | string, privateTicket?: number): Promise<EventTicket[]> => 
{
    if(privateTicket)
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '?' + '&pageSize=' + pageSize + '&offset=' + offset + '&privateTicket=' + privateTicket;
        return triggerApi(API_URL, 'GET', '');
    }
    else
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '?' + '&pageSize=' + pageSize + '&offset=' + offset;
        return triggerApi(API_URL, 'GET', '');
    }
};

export const getEventTicketById = async (eventId: number, ticketId: number): Promise<EventTicket> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/' + ticketId;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventTicket = async (EVENT_ID: number, ticketData: EventTicket, csrfToken: string): Promise<EventTicket> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', ticketData, csrfToken);
};

export const ticketGroupCount = async (eventId: number | string): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/group/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllTicketGroups = async (pageSize: number, offset: number, eventId: number | string, status?: number, ticketGroupId?: number): Promise<EventTicketGroup[]> => 
{
    const query = convertJSONToGETParams({
        pageSize,
        status,
        ticketGroupId,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/group?' + query + '&offset=0';
    return triggerApi(API_URL, 'GET', '');
};

export const createTicketGroup = async (EVENT_ID: number, TICKET_GROUP_DATA: EventTicketGroup, csrfToken: string): Promise<EventTicketGroup> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/group';
    return triggerApi(API_URL, 'POST', TICKET_GROUP_DATA, csrfToken);
};

export const updateEventTicket = async (EVENT_ID: number, TICKET_ID: number, TICKET_DATA: UpdateEventTicket): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/' + TICKET_ID;
    return triggerApi(API_URL, 'PUT', TICKET_DATA);
};

// export const updateTicketGroup = async (TICKET_GROUP_ID: number, TICKET_GROUP_DATA) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/group/' + TICKET_GROUP_ID;
//     return triggerApi(API_URL, 'PUT', TICKET_GROUP_DATA);
// };

export const deleteTicket = async (EVENT_ID: string | number, TICKET_ID: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/' + TICKET_ID;
    return triggerApi(API_URL, 'DELETE', '');
};

// export const deleteTicketGroup = async (EVENT_ID: number, TICKET_GROUP_ID: number) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/group/' + TICKET_GROUP_ID;
//     return triggerApi(API_URL, 'DELETE', '');
// };
