import { createContext, useEffect, useState } from 'react';
import React from 'react';
import eventBus from '../../scripts/event-bus';
import { eventVendorCount, getAllEventVendors, removeEventVendor } from '../../scripts/apis/eventVendor';
import { useTablePagination } from '../TablePaginationContext';
import { updateVendor } from '../../scripts/apis/vendors';
import APP_CONSTANTS from '../../scripts/constants';
import { useLocation } from 'react-router-dom';

export const EventVendorsContext = createContext(null);

const EventVendorsProvider = ({ children, eventId }):React.JSX.Element => 
{

    const currentpath = useLocation().pathname;
    const isTableView = currentpath?.includes('vendors');

    const [eventVendors, setEventVendors] = useState([]);
    const [vendorsCount, setVendorsCount] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchData = async ():Promise<void> => 
    {
        try 
        {
            const count = await eventVendorCount(eventId);
            setShowSpinner(true);
            if (count) 
            {
                setVendorsCount(count);
                try 
                {
                    if(isTableView)
                    {
                        const eventVendorData = await getAllEventVendors(pageSize, currentPage - 1, eventId);
                        if (eventVendorData) 
                        {
                            if (eventVendorData) 
                            {
                                setEventVendors([...eventVendorData]);
                                setIsEmpty(eventVendorData.length === 0);
                                setShowSpinner(false);
                                setRefresh(false);
                            }
                        }
                    }
                    else
                    {
                        const eventVendorData = await getAllEventVendors(8, 0, eventId);
                        if (eventVendorData) 
                        {
                            if (eventVendorData) 
                            {
                                setEventVendors([...eventVendorData]);
                                setIsEmpty(eventVendorData.length === 0);
                                setShowSpinner(false);
                                setRefresh(false);
                            }
                        }
                    }

                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchPaginationData = async ():Promise<void> => 
    {
        if (vendorsCount) 
        {
            try 
            {
                const eventVendorData = await getAllEventVendors(pageSize, currentPage - 1, eventId);
                setShowSpinner(true);
                if (eventVendorData) 
                {
                    if (eventVendorData) 
                    {
                        setEventVendors([...eventVendorData]);
                        setIsEmpty(eventVendorData.length === 0);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                }

            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    useEffect(():void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            fetchData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchPaginationData();
        }
    }, [currentPage, pageSize]);

    useEffect(():void => 
    {
        if (refresh) 
        {
            fetchData();
        }
    }, [refresh]);

    useEffect(():void => {
        if(!isTableView)
        {
            fetchData();
        }
    }, []);

    const editVendorsFromTable = async (vendorsData):Promise<void> => 
    {
        const linkedInUrlRegex = new RegExp(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/);
        if(vendorsData?.linkedIn && !(linkedInUrlRegex.test(vendorsData.linkedIn)))
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                open: true,
                message: 'Invalid LinkedIn URL',
                severity: 'error',
                positionVertical: 'top',
                positionHorizontal: 'right',
            });
            return;

        }
        else
        {
            const vendorDetails = {
                name: vendorsData.name,
                email: vendorsData.email,
                vendorName: vendorsData.vendorName,
                description: vendorsData.description,
                social:{
                    linkedIn: vendorsData.linkedIn? vendorsData.linkedIn : '' 
                },
            };
           
            try 
            {
                const vendorsUpdated = await updateVendor(vendorDetails, vendorsData.id);
                if (vendorsUpdated) 
                {
                    setRefresh(true);
                }
    
            }
            catch (error) 
            {
                console.log(error);
            }
        }
       
    };

    const deleteEventVendorFromTable = async ():Promise<void> => 
    {
        eventBus.on('delete-vendor', async (object):Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            let deleteSuccess = true;
            if (deleteSuccess) 
            {
                if (eventVendors && eventVendors.length > 0 && acceptObj.id) 
                {
                    const vendorRemoved = await removeEventVendor(eventId, acceptObj.id);
                    if (vendorRemoved) 
                    {
                        setRefresh(true);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Vendor Removed',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                        deleteSuccess = false;
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
                            open: false,
                        });
                
                        eventBus.dispatch('selected-row-id', null);
                    }
                }
            }
        });
    };

    return (
        <EventVendorsContext.Provider value={{
            isEmpty, vendorsCount, setIsEmpty, eventVendors, deleteEventVendorFromTable, setRefresh, pageSize, updateCurrentPage, updatePageSize, showSpinner, editVendorsFromTable, isTableView
        }}>
            {children}
        </EventVendorsContext.Provider>
    );
};

export default EventVendorsProvider;