import React, { } from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ComponentsCategories = {
    singleLine: {
        category: 'input',
        heading: 'Single Line Text',
        type: 1,
        name: <Typography className="component-category-title">{'Single Line'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'input-text']} />
    },
    number: {
        category: 'number',
        heading: 'Number',
        type: 6,
        name: <Typography className="component-category-title">{'Number'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'input-numeric']} />
    },
    checkbox: {
        category: 'option',
        heading: 'Checkboxes',
        type: 3,
        name: <Typography className="component-category-title">{'Checkboxes'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'square-check']} />
    },
    dropdown: {
        category: 'option',
        heading: 'Dropdown',
        type: 4,
        name: <Typography className="component-category-title">{'Dropdown'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'square-caret-down']} />
    },
    multiline: {
        category: 'input',
        heading: 'Multi-line text',
        type: 2,
        name: <Typography className="component-category-title">{'Multi-line text'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'align-justify']} />
    },
    radio: {
        category: 'option',
        heading: 'Radio',
        type: 9,
        name: <Typography className="component-category-title">{'Radio'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'circle-dot']} />
    },
    date: {
        category: 'date',
        heading: 'Date',
        type: 5,
        name: <Typography className="component-category-title">{'Date'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'calendar']} />
    },
    // file: {
    //     heading: 'File',
    //     name: 'File',
    //     icon: <InsertDriveFile />
    // },
    headerText: {
        category: 'content',
        heading: 'Header Text',
        type: 7,
        name: <Typography className="component-category-title">{'Header Text'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'heading']} />
    },
    paragraph: {
        category: 'content',
        heading: 'Paragraph',
        type: 8,
        name: <Typography className="component-category-title">{'Paragraph'}</Typography>,
        icon: <FontAwesomeIcon icon={['fal', 'paragraph-left']} />
    },
    // image: {
    //     category: 'image',
    //     heading: 'Image',
    //     name: 'Image',
    //     icon: <Photo />
    // },
};

export const getComponentCategory = (type: number): string => 
{
    if (type === 1 || type === 2) 
    {
        return 'input';
    }
    else if (type === 6) 
    {
        return 'number';
    }
    else if (type === 3 || type === 4 || type === 9) 
    {
        return 'option';
    }
    else if (type === 5) 
    {
        return 'date';
    }
    else if (type === 7 || type === 8) 
    {
        return 'content';
    }
    else if (type === 11 || type === 12 || type === 13) 
    {
        return 'gdpr';
    }
    else 
    {
        return 'image';
    }
};

export const dateFormat = [
    {
        name: 'MM-DD-YYYY',
    },
    {
        name: 'DD-MM-YYYYY',
    },
    {
        name: 'YYYY-MM-DD',
    },
];

export default ComponentsCategories;