import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Stack, Typography, Box } from '@mui/material';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { addSponsors } from '../../redux/sponsors/Sponsors';
import { createSponsor, updateSponsor, uploadSponsorLogo } from '../../scripts/apis/sponsors';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createEventSponsors } from '../../scripts/apis/eventSponsors';
import './styles.scss';
import { CONTENT } from '../../scripts/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decompressImage } from '../../common/ImageProcessing';
import { isImageBuffer } from '../../scripts/helpers';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../pages/Settings/CustomField/CustomTypeComponent';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { ISponsorRedux, SponsorData, SponsorsProps } from './ISponsor';
import { CustomFieldLocations, CustomFieldType, customFieldCountByLocation, customFieldsByLocation } from '../../pages/Settings/CustomField/CustomField.helper';
import CustomSpinner from '../../common/CustomSpinner';
import { CustomButton } from '../../common/FormComponents/Buttons';

const Sponsor = (props: SponsorsProps):React.JSX.Element => 
{
    const sponsorData = props['sponsor-data'];
    const addEventSponsor = props?.addEventSponsor;
    const eventId = props['data-id'];
    const eventSponsor = props.eventSponsor;
    //const eventSponsorEditable = props.editable;
    const disabled = props.disabled;

    const csrfTokenData = useSelector((state): string => 
    { 
        return state['csrfTokenValue'].value.csrfToken ;
    });

    const defaultSponsorInfo: SponsorData = {
        name: '',
        pocName: '',
        pocEmail: '',
        websiteLink: '',
        logo: null,
        type: 0,
        amount: '',
    };

    const sponsorReduxData = useSelector((state: ISponsorRedux): SponsorData => 
    {
        return state.sponsors.value;
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sponsorInfo, setSponsorInfo] = useState(defaultSponsorInfo);
    const [spinner, setSpinner] = useState(false);
    const [logo, setLogo] = useState('');
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [customFormState, setCustomFormState] = useState({
    });

    const fetchSponsorsCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            setCustomFieldsSpinner(true);
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.SPONSOR);
            if(cfcount)
            {
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.SPONSOR);
                    if(customFields)
                    {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {

            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    dispatch(addSponsors({
                        logo: file
                    }));
                    setLogo(URL.createObjectURL(file));
                } 
                else 
                {
                    setLogo('');
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image',
                        severity: 'warning',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    const Preview = ():React.JSX.Element => 
    {
        return (
            <Box className="preview-container">
                <img
                    src={((logo !== '' ? logo : '') || (sponsorReduxData.logo !== '' ? sponsorReduxData.logo : ''))}
                />
            </Box>
        );
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            // .matches(/^[a-zA-Z ]*$/, 'Enter a valid Sponsor Name.')
            .required('Name is required'),
        pocName: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Enter a valid POC Name.')
            .required('POC Name is required'),
        pocEmail: Yup.string()
            .email('Enter a valid email.')
            .required('POC Email is required'),
        websiteLink: Yup.string()
            .required('Website URL is required')
            .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please enter a valid url'),
        linkedIn: Yup.string()
            .matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/company\/[a-zA-Z0-9_-]+\/?$/, 'Please enter a valid LinkedIn URL'),
        twitter: Yup.string()
            .matches(/(https:\/\/x.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/, 'Please enter a valid Twitter URL')
    });

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        initialValues: {
            name: sponsorReduxData.name ? sponsorReduxData.name : '',
            pocName: sponsorReduxData?.pocName ? sponsorReduxData?.pocName : '',
            pocEmail: sponsorReduxData?.pocEmail ? sponsorReduxData?.pocEmail : '',
            websiteLink: sponsorReduxData.websiteLink ? sponsorReduxData.websiteLink : '',
            linkedIn: sponsorReduxData?.social?.linkedIn ? sponsorReduxData?.social?.linkedIn : '',
            twitter: sponsorReduxData?.social?.twitter ? sponsorReduxData?.social?.twitter : '',
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);

            const sponsorDetails = {
                name: formik.values?.name,
                pocName: formik.values?.pocName,
                pocEmail: formik.values?.pocEmail,
                websiteLink: formik.values?.websiteLink,
                social: {
                    twitter: formik.values?.twitter,
                    linkedIn: formik.values?.linkedIn,
                }
            };

            if (!props?.addEventSponsor) 
            {
                if ((_.isEmpty(sponsorData))) 
                {
                   
                    const newSponsorInfo = {
                        ...sponsorDetails,
                        additionalFieldData: customFormState ? {
                            ...customFormState 
                        } : null
                    };

                    try 
                    {
                        const sponsorCreated = await createSponsor(newSponsorInfo, csrfTokenData);
                        if (sponsorCreated) 
                        {
                            if (sponsorReduxData.logo) 
                            {
                                const logo = new FormData();
                                logo.append('file', sponsorReduxData.logo);
                                const updateSponsorlogo = await uploadSponsorLogo(sponsorCreated.id, logo, csrfTokenData);
                                if (updateSponsorlogo) 
                                {
                                    props.setRefresh(true);
                                    handleDrawerClose();
                                }
                            }
                            else 
                            {
                                props.setRefresh(true);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Sponsor Created',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                                handleDrawerClose();
                            }
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: _.startCase(error.message.toLowerCase()),
                            severity: 'error',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                    }
                    
                   
                }
                else 
                {
                    let sponsorUpdated;
                    let sponsorLogoUpdated;
                    if (sponsorInfo.logo !== sponsorReduxData.logo) 
                    {
                        if (sponsorReduxData.logo) 
                        {
                            const image = new FormData();
                            image.append('file', sponsorReduxData.logo);
                            sponsorLogoUpdated = await uploadSponsorLogo(Number(sponsorData.id), image, csrfTokenData);
                        }
                    }
                    if (sponsorInfo?.name !== formik.values?.name || sponsorInfo?.websiteLink !== formik.values?.websiteLink || sponsorInfo?.pocName !== formik.values?.pocName || sponsorInfo?.pocEmail !== formik.values?.pocEmail || customFormState) 
                    {
                       
                        const newSponsorInfo = {
                            ...sponsorDetails,
                            additionalFieldData: customFormState ? {
                                ...customFormState 
                            } : null
                        };

                        try 
                        {
                            sponsorUpdated = await updateSponsor(sponsorData.id, newSponsorInfo);
                        }
                        catch (error) 
                        {
                            console.log(error);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: _.startCase(error.message.toLowerCase()),
                                severity: 'error',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                        }
                        
                    }
                    else setSpinner(false);
                    if (sponsorUpdated || sponsorLogoUpdated) 
                    {
                        props.setRefresh(true);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Sponsor Details Updated',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                        handleDrawerClose();
                    }
                }
            }
            else 
            {
                const newSponsorInfo = {
                    ...sponsorDetails,
                    additionalFieldData: customFormState ? {
                        ...customFormState 
                    } : null
                };
                try 
                {
                    const sponsorUpdated = await createSponsor(newSponsorInfo, csrfTokenData);
                    if (sponsorUpdated)
                    {
                        const eventSponsorDetails = {
                            status: 1,
                            sponsorId: sponsorUpdated.id,
                            eventId: eventId,
                        };


                        if (sponsorReduxData.logo) 
                        {
                            const logo = new FormData();
                            logo.append('file', sponsorReduxData.logo);
                            await uploadSponsorLogo(sponsorUpdated.id, logo, csrfTokenData);
                        }
                        try 
                        {
                            const eventSponsorCreated = await createEventSponsors(eventId, eventSponsorDetails, csrfTokenData);
                            if (eventSponsorCreated) 
                            {
                                props.setRefresh(true);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Sponsor Created',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                                handleDrawerClose();
                            }
                        } 
                        catch (error) 
                        {
                            console.log(error);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: _.startCase(error.message.toLowerCase()),
                                severity: 'error',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                        }
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: _.startCase(error.message.toLowerCase()),
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                }

            }
        }
    });

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/sponsors');
        }
        if (props?.routeFromEventSponsor && eventId) 
        {
            navigate('/events/' + eventId + '/people/sponsors');
        }
    };

    const getImageUrl = async (imageUrl) : Promise<void> => 
    {
        const updatedImage = await decompressImage(imageUrl, 1500, 1500);
        if (updatedImage) 
        {
            setLogo(updatedImage);
        }
    };

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        if (sponsorData) 
        {
            setLogo(sponsorData.logo !== null ? sponsorData.logo : '');
            setSponsorInfo(sponsorData);

            dispatch(addSponsors({
                logo: sponsorData.logo !== null ? sponsorData.logo : '',
                name: sponsorData.name,
                pocName: sponsorData.pocName ? sponsorData.pocName : '',
                pocEmail: sponsorData.pocEmail ? sponsorData.pocEmail : '',
                websiteLink: sponsorData.websiteLink,
                social: sponsorData?.social,
            }));

            if (sponsorData.logo) 
            {
                getImageUrl(sponsorData.logo);
            }
            if(sponsorData?.additionalFieldData)
            {
                setCustomFormState({
                    ...sponsorData?.additionalFieldData
                });
            
            }
        }
        fetchSponsorsCustomFieldData();
       
    }, []);

    useEffect((): void => 
    {
        if (sponsorReduxData.logo && props.addEventSponsor) 
        {
            getImageUrl(sponsorReduxData.logo);
        }
    }, [sponsorReduxData]);

    const handleInputChange = (event, id): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name, value, id): void => 
    {
        setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name, value, id): void => 
    {
        setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item, data): void => 
    {

        if(customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else
        {
            setCustomFormState((prevState):{[key:string]:string|number|string[]|number[]|boolean} => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }
       
    };

    return (
        <Box id="createSponsor">
            <CloseIconComponent onClick={handleDrawerClose} />
            {/* {!props.addEventSponsor && <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack>} */}
            <Form noValidate autoComplete="off" onSubmit={(values): void => 
            {
                return formik.handleSubmit(values);
            }}>

                {/* Sponsor name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.TITLE} required />
                    <FormControlComponent
                        value={formik.values.name}
                        type="text"
                        placeholder={CONTENT.SPONSORS_PAGE.FORMS.PLACEHOLDER}
                        disabled={disabled ? disabled : null}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('name', event.target.value);
                        }}
                        required
                        isInvalid={formik.errors.name && formik.touched.name}
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* POC Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.POC_NAME} required />
                    <FormControlComponent
                        value={formik.values.pocName}
                        type="text"
                        disabled={disabled ? disabled : null}
                        placeholder="Enter POC Name"
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('pocName', event.target.value);
                        }}
                        required
                        isInvalid={formik.errors.pocName && formik.touched.pocName}
                    />
                    {formik.errors.pocName && formik.touched.pocName ? <Typography className="error-msg">{formik.errors.pocName}</Typography> : null}
                </Box>

                {/* POC Email */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.POC_EMAIL} required />
                    <FormControlComponent
                        value={formik.values.pocEmail}
                        type="text"
                        placeholder={'Enter POC Email'}
                        disabled={disabled ? disabled : null}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('pocEmail', event.target.value?.toLowerCase());
                        }}
                        required
                        isInvalid={formik.errors.pocEmail && formik.touched.pocEmail}
                    />
                    {formik.errors.pocEmail && formik.touched.pocEmail ? <Typography className="error-msg">{formik.errors.pocEmail}</Typography> : null}
                </Box>

                {/* Website */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.WEBSITE} required />
                    <FormControlComponent
                        value={formik.values.websiteLink}
                        placeholder={'Enter Sponsor Website'}
                        disabled={disabled ? disabled : null}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('websiteLink', event.target.value);
                        }}
                        required
                        type="text"
                        isInvalid={formik.errors.websiteLink && formik.touched.websiteLink}
                    />
                    {formik.errors.websiteLink && formik.touched.websiteLink ? <Typography className="error-msg">{formik.errors.websiteLink}</Typography> : null}
                </Box>

                {/* Logo */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.LOGO} />
                    {
                        logo !== '' || sponsorReduxData.logo !== '' ?
                            // (!addEventSponsor && (logo !== '' || sponsorReduxData.logo !== '')) &&
                            <Box className="preview-upload-box">
                                <Preview />
                                {
                                    !addEventSponsor || !disabled ?
                                        <React.Fragment>
                                            <Box className="icon-box">
                                                <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={():void => 
                                                {
                                                    setLogo('');
                                                    dispatch(addSponsors({
                                                        logo: ''
                                                    }));
                                                }} />
                                            </Box>
                                        </React.Fragment>
                                        : null
                                }
                                {/* <Typography>{'Dimension : 82px X 82px , Size < 3MB'}</Typography> */}
                            </Box>
                            :
                            <React.Fragment>
                                {
                                    !addEventSponsor || !disabled ?
                                        <React.Fragment>
                                            <Box {...getRootProps()} className="empty-upload-box">
                                                <input {...getInputProps()} hidden accept="image/*" type="file" />
                                                <FontAwesomeIcon className="upload-icon" icon={['fal', 'cloud-arrow-up']} />
                                                <Typography>{'Browse File'}</Typography>
                                            </Box>
                                        </React.Fragment>
                                        :
                                        null
                                }
                            </React.Fragment>
                    }
                </Box>

                {/* LinkedIn */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.LINKEDIN} />
                    <FormControlComponent
                        value={formik.values.linkedIn}
                        placeholder={'URL'}
                        type="text"
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('linkedIn', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        isInvalid={formik.errors.linkedIn && formik.touched.linkedIn}
                    />
                    {formik.errors.linkedIn && formik.touched.linkedIn ? <Typography className="error-msg">{formik.errors.linkedIn}</Typography> : null}
                </Box>

                {/* Twitter */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPONSORS_PAGE.FORMS.LABEL.TWITTER} />
                    <FormControlComponent
                        type="text"
                        value={formik.values.twitter}
                        placeholder={'URL'}
                        disabled={disabled ? disabled : null}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('twitter', event.target.value);
                        }}
                        isInvalid={formik.errors.twitter && formik.touched.twitter}
                    />
                    {formik.errors.twitter && formik.touched.twitter ? <Typography className="error-msg">{formik.errors.twitter}</Typography> : null}
                </Box>

                {/* Custom Fields */}
                {customFields.length > 0 &&
                 <Box className="custom-field-container">
                     {customFields.length > 0 &&
                     customFieldsSpinner ? <CustomSpinner height={'100%'}/> :
                         customFields.map((item): React.ReactElement => 
                         {
                             if (item.type === CustomFieldType.NUMBER) 
                             {
                                 return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.SINGLE_LINE) 
                             {
                                 return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.MULTI_LINE_TEXT) 
                             {
                                 return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item.name + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.CHECKBOXES) 
                             {
                                 return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.DROPDOWN) 
                             {
                                 return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.RADIO) 
                             {
                                 return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                             else if (item.type === CustomFieldType.DATE) 
                             {
                                 return renderDateComponent(item, handleInputChange, customFormState?.[item.label + ' ' + item.id], disabled);
                             }
                         })
                     }
                 </Box>
                }

                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                        <CustomButton btnType='secondary' name={CONTENT.SIDE_DRAWER.CLOSE_BTN} type='button' onClick={handleDrawerClose} />
                        <CustomButton onClick={formik.handleSubmit} name={'Save'} type="submit" btnType='primary' loading={spinner} />
                    </Stack>
                </Box>

            </Form >
        </Box >
    );
};

export default Sponsor;