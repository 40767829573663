import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { Box, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addMarketing } from '../../redux/events/eventMarketing/marketing';
import { MarketingComponentProps, MarketingPayload, marketingData, marketingReduxInterface } from './IMarketingComponent';
import { CONTENT } from '../../scripts/i18n';
import { createMarketing, testMarketingEmail, updateMarketing } from '../../scripts/apis/eventMarketing';
import moment from 'moment';
// import QuillEditor from '../../common/QuillEditor';
import { AutocompletewithTags, CloseIconComponent, FormControlComponent, FormLabelComponent, MultipleSelectComponent, SelectComponent } from '../../common/FormComponents/ReusableFormComponents';
import _ from 'lodash';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { eventMarketingAttendedFilterOptions, eventMarketingMailFilterOptions } from '../../contexts/EventMarketing/EventMarketingContext';
import { getAlleventTickets, ticketsCount } from '../../scripts/apis/eventTickets';
import { IEventReduxValues, IEventsDispatch } from '../Events/interfaces/create-events_interface';
import { MuiDateTimePicker } from '../../common/FormComponents/DateTimePickers';
import { EnableApprovalStatus, EventMarketingNotificationType, EventRegistrationTypes, TicketType } from '../../pages/Events/enum';

import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import PlaceholderAutoCompleteInput from '../../common/FormComponents/PlaceholderInput';
import { getEventRegistrantStatusCount } from '../../scripts/apis/eventRegistrants';


const MarketingComponent: React.FC<MarketingComponentProps> = (props: MarketingComponentProps): React.JSX.Element => {
    const dispatch = useDispatch();
    const [spinner, setSpinner] = useState(false);
    const [mailOptions, setMailOptions] = useState<number[]>([6]);
    const [isTicketApprovalOn, setIsTicketApprovalOn] = useState(false);
    const [isTicketPaid, setIsTicketPaid] = useState(false);
    const [defaultEmailEditorValue, setDefaultEmailEditorValue] = useState('');

    const currentTimestamp = Math.floor(new Date().getTime()/1000.0);

    const emailEditorRef = React.useRef<EditorRef>(null);

    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const notificationTypes = [
        {
            id: 1,
            name: 'Email Notification'
        },
        {
            id: 2,
            name: 'Push Notification'
        }
    ];

    const attendedFilterOptions = 
    [
        {
            id: 2, name: 'Attended',
        },
        {
            id: 1, name: 'Not Attended',
        },
    ];

    const attendedFilterLabel = {
        1: 'Not Attended',
        2: 'Attended',
    };

    const notificationTypeOptionLabel = notificationTypes?.reduce((acc, notification) => { 
        if (acc) {
            acc[notification?.id] = notification.name;
        }
        return acc;
    }, {});

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const marketingReduxData = useSelector((state: marketingReduxInterface): marketingData => {
        return state.marketing.value;
    });

    const fetchTicketCount = async (): Promise<void> => 
    {
        try 
        {
            const ticketCount = await ticketsCount(String(props.data.eventId));
            if (ticketCount && ticketCount > 0) 
            {
                const eventTickets = await getAlleventTickets(ticketCount, 0, props.data.eventId);
                if(eventTickets)
                {
                    if(eventTickets?.some((ticket) => ticket.enableApproval === EnableApprovalStatus.ENABLE)) setIsTicketApprovalOn(true);
                    
                    if(eventTickets?.some((ticket) => ticket.type === TicketType.PAID)) setIsTicketPaid(true);
                    // eventTickets.forEach((ticket):void => 
                    // {
                    //     if(Number(ticket.enableApproval) === EnableApprovalStatus.ENABLE)
                    //     {
                    //         setIsTicketApprovalOn(true);
                    //     }
                    //     if(Number(ticket.type) === TicketType.PAID)
                    //     {
                    //         setIsTicketPaid(true);
                    //     }
                    // });
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    
    };

    const fetchStatusCount = async (): Promise<void> =>
    {
        try 
        {
            const statusCountData = await getEventRegistrantStatusCount(Number(props.data.eventId));

            if(statusCountData)
            {
                if(statusCountData[2] && Number(statusCountData[2]) > 0)
                {
                    setIsTicketApprovalOn(true);
                }
            }
        } 
        catch (error) 
        {
            
        }
    };

    useEffect(():void => {
        fetchTicketCount();
        fetchStatusCount();
    },[])


    useEffect((): void => {

        if (eventReduxData.registrationType) {
           
            if (eventReduxData.registrationType === EventRegistrationTypes.OPEN && isTicketApprovalOn && !isTicketPaid) {
                setMailOptions([1, 4, 3, 6]);
            }

            if (eventReduxData.registrationType === EventRegistrationTypes.OPEN && isTicketApprovalOn && isTicketPaid) {
                setMailOptions([2, 1, 4, 3, 6]);
            }
            if (eventReduxData.registrationType === EventRegistrationTypes.INVITE && !isTicketPaid) {
                setMailOptions([1, 4, 3, 6]);
            }

            if (eventReduxData.registrationType === EventRegistrationTypes.INVITE && isTicketPaid) {
                setMailOptions([2, 1, 4, 3, 6]);
            }

        }


    }, [eventReduxData, isTicketApprovalOn, isTicketPaid])

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        type: Yup.number(),
        triggerDateTime: Yup.string().required('Trigger Date & Time is required'),
        subject: Yup.lazy(():  Yup.StringSchema<string, Yup.AnyObject, undefined, ''> => 
        {
            if (marketingReduxData.type === 2) 
            {
                return Yup.string().required('Subject is required');
            }
            else {
                return Yup.string().notRequired();
            }
        }),
        // emailContent: Yup.string()
            // .matches(/<p\b[^>]*>(?:(?!<br\b[^>]*>)[\s\S])*<\/p>/i, 'Enter valid Content')
            // .required('Email Content is required')
        emailContent: Yup.string(),
            // .matches(/<p\b[^>]*>(?:(?!<br\b[^>]*>)[\s\S])*<\/p>/i, 'Enter valid Content'),
            // .required('Email Content is required'),
        notificationType: Yup.number(),
        replyTo: Yup.string().required('Reply to Email is Required').email('Enter valid Email'),
    });

    const postMarketingData = async (marketing_payload_data): Promise<void> => {
        try {
            const postMarketingData = await createMarketing(marketing_payload_data, props.data.eventId, csrfTokenData);
            if (postMarketingData) {
                handleDrawerClose();
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Details Saved',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                props.data?.setRefresh(true);
            }
        }
        catch (error) {
            setSpinner(false);
            if (error.statusCode === 400) {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: error.message,
                    severity: 'error',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            }
        }
    };

    const updateMarketingData = async (marketing_payload_data: MarketingPayload, marketingId: number | string): Promise<void> => {
        try {
            const updateMarketingData = await updateMarketing(marketing_payload_data, props.data.eventId, marketingId);
            if (updateMarketingData) {
                setSpinner(false);
                props.data.setRefresh(true);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Details Saved',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                handleDrawerClose();
            }
        }
        catch (error) {
            setSpinner(false);
            //console.log(error);
            if (error.statusCode === 400) {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: error.message,
                    severity: 'error',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            }
        }
    };

    const subject = props?.data?.rowData?.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION ? props?.data?.rowData?.marketingPushNotification?.title : props?.data?.rowData?.marketingMail?.subject;
    const emailContent = props?.data?.rowData?.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION ? props?.data?.rowData?.marketingPushNotification?.body : props?.data?.rowData?.marketingMail?.emailContent;

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: props?.data?.rowData ? props.data.rowData.name : '',
            triggerDateTime: props?.data?.rowData?.triggerTime ? moment.unix(Number(props?.data?.rowData?.triggerTime)).tz(eventReduxData?.timeZone).format('YYYY-MM-DD hh:mm A') : null,
            // subject:props?.data?.rowData?.subject ? props.data.rowData?.subject : props?.data?.rowData?.marketingMail?.subject ? props.data.rowData.marketingMail.subject : '',
            // // emailContent: props?.data?.rowData?.marketingMail?.emailContent ? props.data.rowData.marketingMail.emailContent : props?.data?.rowData?.marketingMail ? props.data.rowData?.marketingMail?.emailContent: '',
            // filter: props?.data?.rowData ? props.data.rowData.filter : []
            subject: subject ? subject : '',
            replyTo: props?.data?.rowData?.replyTo ? props.data.rowData.replyTo : '',
            emailContent: emailContent ? emailContent : '',
            filter: props?.data?.rowData ? props.data.rowData.filter : [],
            attendedStatus: props?.data?.rowData?.attendedStatus ? props.data.rowData.attendedStatus : '',
            notificationType: props?.data?.rowData?.notificationType ? props.data.rowData?.notificationType : EventMarketingNotificationType.EMAIL,
        },
        initialTouched: {
            name: true,
            subject: false,
            // emailContent: false,
            // triggerDateTime: false
            // emailContent: false,
            replyTo: false,
            triggerDateTime: false,
            notificationType: false,
        },
        validateOnChange: false,
        onSubmit: async (): Promise<void> => {
            setSpinner(true);
            const currentTimestamp = moment().add(2, 'minutes').valueOf();    
            if(currentTimestamp > moment(formik.values.triggerDateTime).tz(eventReduxData?.timeZone).unix() * 1000)
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Trigger Date & Time should be at least 2 minutes from current time',
                    severity: 'error',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                setSpinner(false);
                return;
            }
            const unlayer = emailEditorRef.current?.editor;
            
            let result;
            if(formik.values.notificationType === EventMarketingNotificationType.EMAIL)
            {
                result = await new Promise<{ design: any, html: string }>((resolve) => {
                    unlayer?.saveDesign((design) => {
                        unlayer?.exportHtml((data) => {
                            const { design: htmlDesign, html } = data;
                    
                            resolve({
                                design,
                                html,
                            });
                        });
                    });
                });
            }
            const validPayload = {
                name: formik.values.name,
                triggerTime: marketingReduxData.type === 2 ? moment(formik.values.triggerDateTime)?.tz(eventReduxData?.timeZone).unix() : 0,
                marketingMail: {
                    subject: marketingReduxData.type === 2 ? formik.values.subject : null,
                    emailContent: result?.html || '',
                },
                replyTo: formik.values.replyTo,
                filter: formik?.values?.filter ? formik.values.filter.length === 0 ? null : formik.values.filter : null,
                attendedStatus: formik.values.attendedStatus,
                marketingJson: JSON.stringify(result?.design) || null,
                notificationType: formik.values.notificationType,
                marketingPushNotification: {
                    title: marketingReduxData.type === 2 && formik.values.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION ? formik.values.subject : null,
                    body: marketingReduxData.type === 2 && formik.values.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION ? formik.values.emailContent : null
                }
            };
            const updatedPayload = formik.values.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION ? _.omit(validPayload, ['marketingMail']) : validPayload;
            if (props.data?.createMarketingData) {
                postMarketingData(updatedPayload);
            }
            else if (props.data?.marketingId) {
                updateMarketingData(updatedPayload, props.data.marketingId);
            }
        }
    });    

    const handleDrawerClose = (): void => {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleTestMarketingEmail = async (): Promise<void> => { 
        if(props?.data?.eventId && formik.values.subject !== '')
        {
            const unlayer = emailEditorRef.current?.editor;
            const result = await new Promise<{ design: any, html: string }>((resolve) => {
                unlayer?.saveDesign((design) => {
                  unlayer?.exportHtml((data) => {
                    const { design: htmlDesign, html } = data;
            
                    resolve({
                      design,
                      html,
                    });
                  });
                });
            });

            const emailContent = {
                subject: formik.values.subject,
                emailContent: result?.html || '',
            };

            try 
            {
                const testEmailSent = await testMarketingEmail(props.data.eventId, emailContent, csrfTokenData);
    
                if(testEmailSent)
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'Test Email Sent',
                        severity: 'success',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                }
            } catch (error) 
            {
                console.log(error);
            }
        }
    };

    const onReady: EmailEditorProps['onReady'] = (unlayer) => { 
        // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
        if (defaultEmailEditorValue) {
            try {
                const designJson = typeof defaultEmailEditorValue === 'string' ? JSON?.parse(defaultEmailEditorValue) : defaultEmailEditorValue;
                unlayer?.loadDesign(designJson);
            } catch (e) {
                console.error("Error parsing marketingJson:", e);
            }
        }    
    };

    useEffect((): void => {
        if (props.data.marketingId) {
            dispatch(addMarketing({
                name: props?.data!.rowData?.name,
                type: props?.data!.rowData?.type,
                notificationType: props?.data?.rowData?.notificationType,
                triggerDateTime: props?.data?.rowData?.triggerTime ? moment.unix(Number(props?.data?.rowData?.triggerTime)).tz(eventReduxData?.timeZone).format('YYYY-MM-DD hh:mm A') : null,   
                subject:props?.data?.rowData?.subject ? props.data.rowData?.subject : props?.data?.rowData?.marketingMail?.subject ? props.data.rowData.marketingMail.subject : '',
                emailContent: props?.data?.rowData?.marketingMail?.emailContent ? props.data.rowData.marketingMail.emailContent : props?.data?.rowData?.marketingMail ? props.data.rowData?.marketingMail?.emailContent: '',
                timezone: props?.data?.rowData?.marketingTimezone
            }));

            if(props?.data?.rowData?.marketingJson)
            {
                setDefaultEmailEditorValue(JSON.parse(props?.data?.rowData?.marketingJson));
            }
        }
    }, []);

    const handleFilterChange = (event, newValue): void => {
        const selectedValues = Object.keys(eventMarketingMailFilterOptions).filter((item) => { return newValue.includes(eventMarketingMailFilterOptions[item]) }).map((item) => { return Number(item) });

        formik.setFieldValue('filter', selectedValues);
    };

    const handleFilterRemove = (index): void => {
        const updatedFilters = formik.values?.filter?.filter((_, i): boolean => {
            return i !== index;
        });

        formik.setFieldValue('filter', updatedFilters);
    };

    const mergeTags = {
        org_name: {
            name: 'Organisation Name',
            value: "{{orgName}}"
        },
        event_name: {
            name: 'Event Name',
            value: "{{eventName}}"
        },
        first_name: {
            name: 'First Name',
            value: "{{firstName}}"
        },
    };

    return (
        <div id="marketingComponent">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form className="marketingForm" noValidate onSubmit={(values): void => {
                return formik.handleSubmit(values);
            }} autoComplete="off">
                {
                    marketingReduxData.type === 2 &&
                    <>
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                            <Box width={'100%'} className="sidebar-container-spacing">
                                <FormLabelComponent label="Email Name" required={true} />
                                <FormControlComponent
                                    type="text"
                                    placeholder={'Email Name'}
                                    value={formik.values.name}
                                    onChange={(event): void => {
                                        dispatch(addMarketing({
                                            name: event.target.value
                                        }));
                                        formik.setFieldValue('name', event.target.value);
                                    }}
                                    required
                                    isInvalid={(formik.errors.name && formik.touched.name) as boolean}
                                />
                                {formik.touched.name && formik.errors.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                            </Box>
                            <Box width={'100%'} className="sidebar-container-spacing">
                                <Box className="tooltip-box">
                                    <FormLabelComponent label="Send At" required />
                                    <FormHelperText className="timezone-helper">({eventReduxData.timeZone})</FormHelperText>
                                </Box>
                                <MuiDateTimePicker value={formik.values.triggerDateTime ?? ''} onChange={(newValue): void =>
                                {
                                    formik.setFieldValue('triggerDateTime', newValue?.format('MM/DD/YYYY hh:mm A'));
                                }} />
                            </Box>

                            {formik.values.notificationType === EventMarketingNotificationType.EMAIL && <Box width={'100%'} className="sidebar-container-spacing">
                            <FormLabelComponent label="Reply To" required/>
                            <FormControlComponent
                                    type="email"
                                    placeholder="Reply To email address"
                                    value={formik.values.replyTo}
                                    onChange={(event): void => {
                                        dispatch(addMarketing({
                                            replyTo: event.target.value
                                        }));
                                        formik.setFieldValue('replyTo', event.target.value?.toLowerCase());
                                    }}
                                    required
                                    isInvalid={(formik.errors.replyTo && formik.touched.replyTo) as boolean}/>
                                {formik.touched.replyTo && formik.errors.replyTo ? <Typography className="error-msg">{formik.errors.replyTo}</Typography> : null}
                            </Box>}

                            <Box width={'100%'}className="sidebar-container-spacing">
                                <FormLabelComponent label='Select Filter' />
                                <AutocompletewithTags
                                    defaultValue={formik?.values?.filter?.map((item) => { return eventMarketingMailFilterOptions[item] })}
                                    value={formik?.values?.filter?.map((item) => { return eventMarketingMailFilterOptions[item] })}
                                    options={mailOptions && mailOptions.map((item) => { return eventMarketingMailFilterOptions[item] })}
                                    onChange={handleFilterChange}
                                    placeholder='Select Filter'
                                    onRemoveClick={(index): void => {
                                        handleFilterRemove(index);
                                    }} />
                            </Box>

                            <Box width={'100%'} className="sidebar-container-spacing">
                                <FormLabelComponent label='Notification Type' />
                                <SelectComponent options={notificationTypes} optionLabels={notificationTypeOptionLabel} value={formik.values.notificationType} defaultPlaceholder='Select a Notification Type' onChange={(event) => {
                                    formik.setFieldValue('notificationType', event.target.value);
                                }} />
                            </Box>
                        </Stack>

                        {
                            ((formik.values?.filter?.includes(6) || formik.values?.filter?.includes('6')) && currentTimestamp > Number(eventReduxData?.eventStartDateTime)) &&
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label='Attended Status' />
                                <SelectComponent
                                    value={formik.values.attendedStatus}
                                    defaultPlaceholder='Select Attended Status'
                                    optionLabels={attendedFilterLabel}
                                    options={attendedFilterOptions}
                                    onChange={(event) => formik.setFieldValue('attendedStatus', event.target.value)}
                                    style={{ width: '25%' }}
                                    />
                            </div>
                        }
                        
                        <Box className="sidebar-container-spacing">
                            {formik.values.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION && <FormLabelComponent label={"Title"} required={true} />}
                            {formik.values.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION ?
                                <FormControlComponent
                                    type="text"
                                    placeholder={"Title"}
                                    value={formik.values.subject}
                                    onChange={(event): void => {
                                        dispatch(addMarketing({
                                            subject: event.target.value
                                        }));
                                        formik.setFieldValue('subject', event.target.value);
                                    }}
                                    required
                                    isInvalid={(formik.errors.subject && formik.touched.subject) as boolean}
                                />
                                :
                                <PlaceholderAutoCompleteInput initialInputValue={formik.values.subject} options={['Event Name', 'Organization Name', 'First Name', 'Last Name']} onChange={(value) => { formik.setFieldValue('subject', value) }} />
                            }
                            {formik.touched.subject && formik.errors.subject ? <Typography className="error-msg">{formik.errors.subject}</Typography> : null}
                        </Box>

                    </>
                }

                {formik.values.notificationType === EventMarketingNotificationType.EMAIL && <Box paddingBottom={'80px !important'} width={'100%'} overflow={'auto'} className="sidebar-component-spacing">
                    <Box className='unlayer-box'>
                        <EmailEditor ref={emailEditorRef} onReady={onReady} options={{ mergeTags }} />
                    </Box>
                </Box>}

                {formik.values.notificationType === EventMarketingNotificationType.PUSH_NOTIFICATION &&
                    <div className="sidebar-container-spacing">
                        <FormControlComponent 
                            type='text'
                            value={formik.values.emailContent}
                            placeholder='Notification Content'
                            as='textarea'
                            rows={5}
                            onChange={(event) => {
                                dispatch(addMarketing({
                                    emailContent: event.target.value
                                }));
                                formik.setFieldValue('emailContent', event.target.value);
                            }}
                        />
                    </div>
                }

                <Box className="submit-btn-container" sx={{ width: '80vw' }}>
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                        <CustomButton btnType='secondary' name={CONTENT.SIDE_DRAWER.CLOSE_BTN} onClick={handleDrawerClose} />
                        <CustomButton name='Save' btnType='primary' loading={spinner} type='submit' />
                    </Stack>
                    {formik.values.notificationType === EventMarketingNotificationType.EMAIL && <Box marginLeft={'auto'} paddingRight={'20px'}><CustomButton disabled={formik.values.emailContent === ' ' || formik.values.subject === ''} name='Send Test Email' btnType='secondary' type='button' onClick={handleTestMarketingEmail} /></Box>}
                </Box>
            </Form>
        </div>
    );

};

export default MarketingComponent;


// function isQuillEmpty(value: string) : boolean
// {
//     if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes('<img')) 
//     {
//         return true;
//     }
//     return false;
// }

{ /* {
                    props.data?.rowData && props.data?.rowData?.subMarketing && props.data?.rowData?.subMarketing?.length > 0 &&
                    <Box position={'relative'} className="sidebar-container-spacing">
                        <Box className="componentLabel"><Typography className="label">{'Marketing content'}</Typography></Box>
                        <Box className="sub-marketing-edit-container">
                            <Box className="sub-marketing-edit-content-subject-container">
                                <Typography className="sub-marketing-edit-content-subject">{props.data?.rowData?.subMarketing[0]?.subject}</Typography>
                            </Box>
                            <Box className="sub-marketing-edit-content-content-container">
                                <Typography className="sub-marketing-edit-content-content">{props.data?.rowData?.subMarketing[0]?.emailContent}</Typography>
                            </Box>
                        </Box>
                        <FontAwesomeIcon className="sub-marketing-content-edit-icon" icon={['fal', 'pen-to-square']} />
                    </Box>
                } */ }

{ /* <Box className="sidebar-container-spacing">
                    <Box className="componentLabel"><Typography className="label">{'Type'}</Typography><Typography className="required-icon">{'*'}</Typography></Box>
                    <Select
                        displayEmpty
                        className="marketing-option-select"
                        value={formik.values.type}
                        onChange={(event) => {
                            dispatch(addMarketing({
                                type: event.target.value
                            }));
                            formik.setFieldValue('type', event.target.value);
                        }}
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <Typography className="marketing-select-placeholder">{'Select a type'}</Typography>
                                )
                            }
                            else {
                                return (
                                    <Typography className="marketing-select-placeholder-selected">{marketingTypeOptions[selected - 1]['option']}</Typography>
                                )
                            }
                        }}
                    >
                        {marketingTypeOptions.map((elem, index) => {
                            return (
                                <MenuItem key={index} value={elem.id}>
                                    <ListItemText primary={elem.option} />
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Box> */ }