// eslint-disable-next-line import/no-unresolved
import { EventSpeaker, EventSpeakerGraph } from '../../pages/Events/interfaces';
import { Speaker } from '../../pages/Speakers/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const eventSpeakersCount = async (eventId: string | number):Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventSpeakers = async (pageSize: number, offset: number, eventId: string | number, speakerId?: number, status?: number):Promise<Speaker[]> => 
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
        speakerId,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventSpeakerGraphData = async (eventId: string | number):Promise<EventSpeakerGraph> => 
{
    const query = convertJSONToGETParams({
        icp: true,
        buyerPersona: true,
        arr: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + eventId + '/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const addEventSpeaker = async (EVENT_SPEAKER_DATA, EVENT_ID: number, csrfToken: string):Promise<EventSpeaker> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', EVENT_SPEAKER_DATA, csrfToken);
};

export const updateEventSpeaker = async (EVENT_SPEAKER_DATA, SPEAKER_ID):Promise<EventSpeaker> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + SPEAKER_ID;
    return triggerApi(API_URL, 'PUT', EVENT_SPEAKER_DATA);
};

export const removeEventSpeaker = async (EVENT_ID: string | number, SPEAKER_ID: string | number):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-speakers/' + EVENT_ID + '/' + SPEAKER_ID;
    return triggerApi(API_URL, 'DELETE', '');
};