import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { deleteBooth, getAllExpo, getExpoCount, updateEventExpo } from '../../scripts/apis/eventExpo';
import { useDispatch } from 'react-redux';
import { addExpo } from '../../redux/events/eventVenue/EventExpo';
import { useTablePagination } from '../TablePaginationContext';
import { EventExpo } from '../../pages/Events/interfaces';
import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';

export const ExpoContext = createContext(null);

const ExpoPageProvider: React.FC<{children: React.ReactNode; eventReduxData: IEventReduxValues; eventId: string}> = ({ children, eventReduxData, eventId }): React.JSX.Element => 
{

    const dispatch = useDispatch();

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [rows, setRows] = useState([]);
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [expos, setExpos] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [eventStartDateTime, setEventStartDateTime] = useState('');
    const [eventEndDateTime, setEventEndDateTime] = useState('');
    const [expoCount, setExpoCount] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const eventStartingDate = moment(moment(eventStartDateTime).format('YYYY-MM-DD'));
    const eventEndingDate = moment(moment(eventEndDateTime).format('YYYY-MM-DD'));
    const daysArr = [];
    const currentDate = eventStartingDate.clone();
    while (currentDate.isSameOrBefore(eventEndingDate, 'day')) 
    {
        daysArr.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }

    const removeExpoFromTable = async (): Promise<void> => 
    {
        if (expos && expos.length > 0 && filteredRowData.length > 0) 
        {
            eventBus.on(APP_CONSTANTS.EVENTS.EVENT_EXPO.DELETE, async (object): Promise<void> => 
            {
                const acceptObj = object.acceptObj;
                let deleteSuccess = true;
                if (deleteSuccess) 
                {
                    if (acceptObj.id) 
                    {
                        try 
                        {
                            const expoDeleted: boolean = await deleteBooth(eventId, acceptObj.id);
                            if (expoDeleted) 
                            {
                                setRefresh(true);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Expo Deleted',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                                deleteSuccess = false;
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                }
            });
        }
    };

    const fetchExpoDataCount = async (): Promise<void> => 
    {
        try 
        {
            const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
            const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

            const startTimeStamp = startDateTime.unix();
            const endTimeStamp = endDateTime.unix();
            const count = await getExpoCount(eventId, startTimeStamp, endTimeStamp);
            if (count) 
            {
                setExpoCount(count);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchExpoData = async (): Promise<void> => 
    {

        let startTime = eventReduxData.eventStartDateTime;
        let endTime = eventReduxData.eventEndDateTime;
        startTime = moment.unix(Number(startTime)).format('LLLL');
        endTime = moment.unix(Number(endTime)).format('LLLL');
        setEventStartDateTime(startTime);
        setEventEndDateTime(endTime);

        try 
        {
            // const count = await getExpoCount(eventId);
            // if (count) {
            if (daysArr[tabValue] || eventReduxData.eventStartDate) 
            {
                const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
                const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

                const startTimeStamp = startDateTime.unix();
                const endTimeStamp = endDateTime.unix();
                setShowSpinner(true);
                const expoData = await getAllExpo(eventId, pageSize, currentPage - 1, startTimeStamp, endTimeStamp);
                if (expoData) 
                {
                    setRows([...expoData]);
                    setShowSpinner(false);

                    if (refresh) 
                    {
                        setFilteredRowData([...expoData]);
                        setTabValue(tabValue);
                        dispatch(addExpo({
                            startDate: daysArr[tabValue],
                            endDate: daysArr[tabValue],
                        }));
                    }
                    else 
                    {
                        setFilteredRowData([...expoData]);
                        dispatch(addExpo({
                            startDate: moment.unix(startTimeStamp).format('YYYY-MM-DD'),
                            endDate: moment.unix(endTimeStamp).format('YYYY-MM-DD'),
                        }));
                    }
                }
            }
            // }
        }
        catch (error) 
        {
            console.log(error);
        }

        setRefresh(false);
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchExpoDataCount();
            fetchExpoData();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        if (eventReduxData.title) 
        {
            if (currentPage > 0 && pageSize) 
            {
                fetchExpoDataCount();
                fetchExpoData();
            }
        }
    }, [eventReduxData, tabValue, currentPage, pageSize]);

    useEffect((): void => 
    {
        setIsEmpty(filteredRowData.length === 0);
        setExpos([...filteredRowData]);
    }, [filteredRowData]);

    const editExpoFromTable = async (expoData: EventExpo): Promise<void> => 
    {
        const eventExpoDetails = {
            name: expoData.name,
            headline: expoData.headline,
            additionalInfo: expoData.additionalInfo,
            startDateTime: expoData.startDateTime,
            endDateTime: expoData.endDateTime,
        };

        try 
        {
            const eventExpoUpdated = await updateEventExpo(eventId, String(expoData.id), eventExpoDetails);
            if (eventExpoUpdated) 
            {
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <ExpoContext.Provider value={{
            removeExpoFromTable, tabValue, isEmpty, filteredRowData, daysArr, setTabValue, rows, setFilteredRowData, setRefresh, showSpinner, pageSize, updateCurrentPage, updatePageSize, expoCount, editExpoFromTable 
        }}>
            {children}
        </ExpoContext.Provider>
    );
};

export default ExpoPageProvider;