import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { deleteEventStage, getAllStage, getStageCount, updateEventStage } from '../../scripts/apis/eventStage';
import { eventSpeakersCount, getEventSpeakers } from '../../scripts/apis/eventSpeakers';
// import { getAllTicketGroups, ticketGroupCount } from '../../scripts/apis/eventTickets';
import { useDispatch } from 'react-redux';
import { addStage } from '../../redux/events/eventVenue/EventStage';
import { useTablePagination } from '../TablePaginationContext';
import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';

export const StagesContext = createContext(null);

const StagesPageProvider: React.FC<{children: React.ReactNode, eventReduxData?: IEventReduxValues; eventId?: string}> = ({ children, eventReduxData, eventId }): React.JSX.Element => 
{

    const dispatch = useDispatch();

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [rows, setRows] = useState([]);
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [stages, setStages] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [eventStartDateTime, setEventStartDateTime] = useState('');
    const [eventEndDateTime, setEventEndDateTime] = useState('');
    const [speakerInfo, setSpeakerInfo] = useState([]);
    // const [ticketGroupInfo, setTicketGroupInfo] = useState([]);
    const [stageCount, setStageCount] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);

    const eventStartingDate = moment(moment(eventStartDateTime).format('YYYY-MM-DD'));
    const eventEndingDate = moment(moment(eventEndDateTime).format('YYYY-MM-DD'));
    const daysArr = [];
    const currentDate = eventStartingDate.clone();
    while (currentDate.isSameOrBefore(eventEndingDate, 'day')) 
    {
        daysArr.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }

    const removeStageFromTable = (): void => 
    {
        if (stages && stages.length > 0 && filteredRowData.length > 0) 
        {
            eventBus.on(APP_CONSTANTS.EVENTS.EVENT_STAGE.DELETE, async (object): Promise<void> => 
            {
                const acceptObj = object.acceptObj;
                let deleteSuccess = true;
                if (deleteSuccess) 
                {
                    if (acceptObj.id) 
                    {
                        try 
                        {
                            const stageDeleted = await deleteEventStage(eventId, acceptObj.id);
                            if (stageDeleted) 
                            {
                                setRefresh(true);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Stage Deleted',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                                deleteSuccess = false;
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                }
            });
        }
    };

    const fetchStageDataCount = async (): Promise<void> => 
    {
        try 
        {
            const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
            const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

            const startTimeStamp = startDateTime.unix();
            const endTimeStamp = endDateTime.unix();
            const count = await getStageCount(eventId, startTimeStamp, endTimeStamp);
            if (count) 
            {
                setStageCount(count);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchStageData = async (): Promise<void> => 
    {
        let startTime = String(eventReduxData.eventStartDateTime);
        let endTime = String(eventReduxData.eventEndDateTime);
        startTime = moment.unix(Number(startTime)).format('LLLL');
        endTime = moment.unix(Number(endTime)).format('LLLL');
        setEventStartDateTime(startTime);
        setEventEndDateTime(endTime);

        try 
        {
            // const count = await getStageCount(eventId);
            // if (count) {
            if (daysArr[tabValue] || eventReduxData.eventStartDate) 
            {
                const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
                const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

                const startTimeStamp = startDateTime.unix();
                const endTimeStamp = endDateTime.unix();
                setShowSpinner(true);

                const stageData = await getAllStage(eventId, pageSize, currentPage - 1, startTimeStamp, endTimeStamp);
                if (stageData) 
                {
                    setRows([...stageData]);
                    setShowSpinner(false);

                    if (refresh) 
                    {

                        setFilteredRowData([...stageData]);
                        setTabValue(tabValue);
                        dispatch(addStage({
                            startDate: daysArr[tabValue],
                            endDate: daysArr[tabValue],
                        }));
                    }
                    else 
                    {

                        setFilteredRowData([...stageData]);
                        dispatch(addStage({
                            startDate: moment.unix(startTimeStamp).format('YYYY-MM-DD'),
                            endDate: moment.unix(endTimeStamp).format('YYYY-MM-DD'),
                        }));
                    }
                }
            }
            // }
        }
        catch (error) 
        {
            console.log(error);
        }

        try 
        {
            const speakerCount = await eventSpeakersCount(Number(eventId));
            if (speakerCount) 
            {
                const speakerData = await getEventSpeakers(speakerCount, 0, Number(eventId));
                setSpeakerInfo([...speakerData]);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        // try 
        // {
        //     const groupCount = await ticketGroupCount(Number(eventId));
        //     if (groupCount) 
        //     {
        //         const ticketGroupData = await getAllTicketGroups(groupCount, 0, Number(eventId));
        //         setTicketGroupInfo([...ticketGroupData]);
        //     }
        // }
        // catch (error) 
        // {
        //     console.log(error);
        // }

        setRefresh(false);
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchStageDataCount;
            fetchStageData();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        if (eventReduxData.title) 
        {
            if (currentPage > 0 && pageSize) 
            {
                fetchStageDataCount();
                fetchStageData();
            }
        }
    }, [eventReduxData, tabValue, currentPage, pageSize]);

    useEffect((): void => 
    {
        setIsEmpty(filteredRowData.length === 0);
        setStages([...filteredRowData]);
    }, [filteredRowData]);

    const editStageFromTable = async (stageData): Promise<void> => 
    {
        const eventStageDetails = {
            title: stageData.title,
            description: stageData.description,
            imageAlternateText: stageData.imageAlternateText,
            maximumParticipants: stageData.maximumParticipants,
            startDateTime: stageData.startDateTime,
            endDateTime: stageData.endDateTime,
            // ticketGroupIds: stageData.ticketGroupIds,
            speakerIds: stageData.speakerIds ? stageData.speakerIds : [],
            scheduledEvent: stageData.scheduled,
        };

        try 
        {
            const eventStageUpdated = await updateEventStage(eventId, String(stageData.id), eventStageDetails);
            if (eventStageUpdated) 
            {
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    return (
        <StagesContext.Provider value={{
            isEmpty, speakerInfo, rows, setRefresh, setTabValue, daysArr, setFilteredRowData, tabValue, stages, removeStageFromTable, filteredRowData, showSpinner, pageSize, updateCurrentPage, updatePageSize, stageCount, editStageFromTable 
        }}>
            {children}
        </StagesContext.Provider>
    );
};

export default StagesPageProvider;