import { Box, Typography } from '@mui/material';
import React from 'react';
import { NumberFormatter } from '../../../scripts/helpers';
import { dashboardBudgetSummaryComponentProps } from '../dashboard_interface';
import Charts from '../../../common/ECharts/Charts';
import CustomSpinner from '../../../common/CustomSpinner';
import './styles.scss';
import TableEmptyComponent from '../Table/TableEmptyComponent';
import { constructObjectForPieChart } from '../../../common/ECharts/helpers/charts.helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardBudgetSummaryGraph: React.FC<dashboardBudgetSummaryComponentProps> = (props: dashboardBudgetSummaryComponentProps): React.JSX.Element => 
{

    const BudgetSummaryComponent: React.FC = (): React.JSX.Element => 
    {
        const budgetSumaryGraphData = [
            {
                name: 'Spent',
                percentage: Math.round((props?.data?.actualSpent / props?.data?.budget) * 100),
                value: props?.data?.actualSpent
            },
            {
                name: 'Remaining',
                percentage: Math.round((props?.data?.remaining / props?.data?.budget) * 100),
                value: props?.data?.remaining
            }
        ];

        const budgetSummaryGraphData = constructObjectForPieChart(budgetSumaryGraphData, ['50%','72%'], null, '$');
    
        return (
            <Box className="dashboard-budget-summary-chart-container">
                <div className="budget-summary-chart">
                    <Charts height={'250px'} chartOption={budgetSummaryGraphData} />
                </div>
            </Box>
        );
    };

    return (
        <>
            {
                <Box className="budget_graph">
                    <Box className="title-container">
                        <Typography className="graph-title">{'Budget Summary'}</Typography>
                        <Typography className="budget-value">{`${props?.data?.budget ? '$'+ NumberFormatter(props.data.budget) : '-'}`}</Typography>
                    </Box>
                    {props?.isChartReady ? props.data.budget !== 0 ? <BudgetSummaryComponent /> : (<div style={{ height: '250px' }} className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : <CustomSpinner height={'250px'} /> }
                </Box>
            }
        </>
    );
};

export default DashboardBudgetSummaryGraph;