import React from 'react';
import { DesktopDateTimeRangePicker, LocalizationProvider, DatePicker, TimePicker, DateTimePicker, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMuiDatePickerProps, IMuiDateTimePickerProps, IMuiDateTimeRangePickerProps } from './IReusableFormComponents';

export const MuiDateTimeRangePicker = (props: IMuiDateTimeRangePickerProps): React.JSX.Element => 
{ 
    const value = [moment(props?.value[0]), moment(props?.value[1])];
    const min = props?.min ? moment(props?.min) : undefined;
    const max = props?.max ? moment(props?.max) : undefined;

    return (
        <div className="dateTimePickers">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimeRangePicker
                    value={[value[0], value[1]]}
                    defaultValue={[value[0], value[1]]}
                    onChange={props.onChange}
                    className="date-picker"
                    minDateTime={min}
                    maxDateTime={max}
                    slotProps={{
                        desktopPaper: {
                            style: props?.desktopPaperStyle
                        },
                        field: {
                            sx: {
                                '& .MuiOutlinedInput-root': {
                                    height: '40px !important',
                                    paddingLeft: '8px',
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    marginLeft: '4px'
                                },
                                '& .calender-picker-icon': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    width: '16px',
                                    height: '16px'
                                },
                                '& .date-picker-input': { 
                                    borderRadius: 'var(--radius-md, 8px)',
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '& .date-picker-input:hover': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg_hover, #F9FAFB)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .date-picker-input:active': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                                '& .date-picker-input:focus': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                            },
                        },
                        textField:
                        {
                            className: 'date-picker-input',
                            label: null,
                            error: false,
                            InputProps: {
                                startAdornment: <FontAwesomeIcon className="calender-picker-icon" icon={['fal', 'calendar']} />,
                            },
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export const MuiDateRangePicker = (props: IMuiDateTimeRangePickerProps): React.JSX.Element => 
    { 
        const value = [moment(props?.value[0]), moment(props?.value[1])];
        const min = props?.min ? moment(props?.min) : undefined;
        const max = props?.max ? moment(props?.max) : undefined;

        return (
            <div className="dateTimePickers">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDateRangePicker
                        value={[value[0], value[1]]}
                        defaultValue={[value[0], value[1]]}
                        onChange={props?.onChange}
                        className="date-picker"
                        minDate={min}
                        maxDate={max}
                        slotProps={{
                            desktopPaper: {
                                style: props?.desktopPaperStyle
                            },
                            field: {
                                sx: {
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px !important',
                                        paddingLeft: '8px',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        marginLeft: '4px'
                                    },
                                    '& .calender-picker-icon': {
                                        color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                        width: '16px',
                                        height: '16px'
                                    },
                                    '& .date-picker-input': { 
                                        borderRadius: 'var(--radius-md, 8px)',
                                        border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                        background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '& .date-picker-input:hover': {
                                        border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                        background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg_hover, #F9FAFB)',
                                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                    },
                                    '& .date-picker-input:active': {
                                        border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                        background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                    },
                                    '& .date-picker-input:focus': {
                                        border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                        background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                    },
                                },
                            },
                            textField:
                            {
                                className: 'date-picker-input',
                                label: null,
                                error: false,
                                InputProps: {
                                    startAdornment: <FontAwesomeIcon className="calender-picker-icon" icon={['fal', 'calendar']} />,
                                },
                            }
                        }}
                    />
                </LocalizationProvider>
            </div>
        );
    };

export const MuiDateTimePicker = (props: IMuiDateTimePickerProps): React.JSX.Element => 
{
    const value = moment(props?.value);
    const [open, setOpen] = React.useState(false);

    return (
        <div id="dateTimePickers">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    open={open}
                    onOpen={(): void => 
                    {
                        return setOpen(true); 
                    }}
                    onClose={(): void => 
                    {
                        return setOpen(false); 
                    }}
                    value={value}
                    defaultValue={value}
                    onChange={(newValue): void => 
                    {
                        return props?.onChange(newValue); 
                    }}
                    className="mui-date-time-picker"
                    minDateTime={props?.min ? moment(props?.min) : undefined}
                    maxDateTime={props?.max ? moment(props?.max) : undefined}
                    disabled={props?.disabled}
                    slotProps={{
                        desktopPaper: {
                            style: props?.desktopPaperStyle
                        },
                        field: {
                            sx: {
                                '& .MuiOutlinedInput-root': {
                                    height: '38px !important',
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    marginLeft: '8px'
                                },
                                '& .calender-picker-icon': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    width: '16px',
                                    height: '16px',
                                },
                                '& .date-time-picker-input': { 
                                    borderRadius: 'var(--radius-md, 8px)',
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '& .date-time-picker-input:hover': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg_hover, #F9FAFB)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .date-time-picker-input:active': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                                '& .date-time-picker-input:focus': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                            },
                        },
                        textField:
                        {
                            className: 'date-time-picker-input',
                            label: null,
                            error: false,
                            InputProps: {
                                startAdornment: <FontAwesomeIcon onClick={(): void => 
                                {
                                    return setOpen(true); 
                                }} className="dateTime-picker-icon" icon={['fal', 'calendar']} />,
                                endAdornment: null
                            },
                            onClick: (): void => 
                            {
                                return setOpen(true);
                            }
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export const MuiDatePicker = (props: IMuiDatePickerProps): React.JSX.Element =>
{
    const min = props?.min ? moment(props?.min) : undefined;
    const max = props?.max ? moment(props?.max) : undefined;
    const value = moment(props?.value);
    const [open, setOpen] = React.useState(false);

    return(
        <div id="dateTimePickers">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    value={value}
                    minDate={min}
                    maxDate={max}
                    disabled={props?.disabled}
                    open={open}
                    onOpen={(): void => 
                    {
                        return setOpen(true); 
                    }}
                    onClose={(): void => 
                    {
                        return setOpen(false); 
                    }}
                    sx={props?.style}
                    onChange={(newValue): void => 
                    {
                        return props?.onChange(newValue); 
                    }}
                    defaultValue={value}
                    slotProps={{
                        desktopPaper: {
                            style: props?.desktopPaperStyle
                        },
                        field: {
                            sx: {
                                '& .MuiOutlinedInput-root': {
                                    height: '40px !important',
                                    paddingLeft: '8px',
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    marginLeft: '4px',
                                    padding: 'unset'
                                },
                                '& .calender-picker-icon': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    width: '16px',
                                    height: '16px'
                                },
                                '& .date-picker-input': { 
                                    borderRadius: 'var(--radius-md, 8px)',
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '& .date-picker-input:hover': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg_hover, #F9FAFB)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .date-picker-input:active': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                                '& .date-picker-input:focus': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                            },
                        },
                        textField:
                        {
                            className: 'date-picker-input',
                            label: null,
                            error: false,
                            onClick: (): void => 
                            {
                                if(!props?.disabled)
                                {
                                    return setOpen(true);
                                }
                            },
                            InputProps: {
                                startAdornment: <FontAwesomeIcon style={{ marginLeft: '6px' }} className="calender-picker-icon" icon={['fal', 'calendar']} />,
                                endAdornment: null
                            },
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export const MuiTimePicker = (props: IMuiDatePickerProps): React.JSX.Element =>
{
    const value = moment(props?.value);
    
    return(
        <div id="dateTimePickers">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                    value={value}
                    onChange={(newValue): void => 
                    {
                        return props?.onChange(newValue); 
                    }}
                    defaultValue={value}
                    slotProps={{
                        desktopPaper: {
                            style: props?.desktopPaperStyle
                        },
                        field: {
                            sx: {
                                '& .MuiOutlinedInput-root': {
                                    height: '40px !important',
                                    paddingLeft: '8px',
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    marginLeft: '4px',
                                    padding: 'unset'
                                },
                                '& .calender-picker-icon': {
                                    color: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054)',
                                    width: '16px',
                                    height: '16px'
                                },
                                '& .date-picker-input': { 
                                    borderRadius: 'var(--radius-md, 8px)',
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '& .date-picker-input:hover': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg_hover, #F9FAFB)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                },
                                '& .date-picker-input:active': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                                '& .date-picker-input:focus': {
                                    border: '1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)',
                                    background: 'var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF)',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)',
                                },
                            },
                        },
                        textField:
                            {
                                className: 'date-picker-input',
                                label: null,
                                error: false,
                            }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};