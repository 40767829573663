import { useMemo, useState } from "react";
import { CustomButton } from "../FormComponents/Buttons";
import { FormControlComponent } from "../FormComponents/ReusableFormComponents";
import { CustomSwitch } from "../StyledComponents/Switch.styled";
import { ColumnDef } from "@tanstack/react-table";

const ColumnVisibilityDropdown: React.FC<{ columns: ColumnDef<any>[]; setColumns: React.SetStateAction<React.Dispatch<ColumnDef<any>[]>> | any }> = ({ columns, setColumns }): React.JSX.Element =>
{
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const filteredInitialColumns = columns.filter(column => column.id !== 'actions' && column.id !== 'copyLink');
    const [filteredColumns, setFilteredColumns] = useState<ColumnDef<any>[]>(filteredInitialColumns);
    const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>(
        columns?.reduce((acc, column) => {
            acc[column.id as string] = column.id === 'actions' ? true : true;
            acc[column.id as string] = column.id === 'copyLink' ? true : true;
            return acc;
        }, {} as { [key: string]: boolean })
    );

    const handleClick = () => 
    {
        setIsVisible(!isVisible);
    };

    const handleToggleChange = (columnId: string) => {
        setColumnVisibility(prev => ({
            ...prev,
            [columnId]: !prev[columnId]
        }));
    };

    const handleShowAll = () => {
        setColumnVisibility(filteredInitialColumns.reduce((acc, column) => {
            acc[column.id as string] = true;
            return acc;
        }, {} as { [key: string]: boolean }));
    };

    const handleHideAll = () => {
        setColumnVisibility(filteredInitialColumns.reduce((acc, column) => {
            acc[column.id as string] = false;
            return acc;
        }, {} as { [key: string]: boolean }));
    };

    const filterOutColumns = () => {
        const visibleColumns = columns.filter(column => columnVisibility[column.id as string]);
        setColumns(visibleColumns);
    };

    const handleSearchColumns = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        const value = event.target.value;
        setSearchValue(value);
        const filtered = filteredInitialColumns.filter(column => {
            if(typeof(column?.header) === 'string')
            {
                return (column.header as string).toLowerCase().includes(value.toLowerCase());
            }
            else
            {
                return (column?.meta?.headerName as string).toLowerCase().includes(value.toLowerCase());
            }
        });
        setFilteredColumns(filtered);
    };

    useMemo(() => {
        filterOutColumns();
    }, [columnVisibility, setColumns]);

    return (
        <div className="column-visibility-dropdown">
            <CustomButton onClick={handleClick} name="Columns" btnType="tertiary_grey" />
            {
                isVisible && 
                (
                    <div className="tanstack-table-columnVisibility-content">
                        <div className="search-box-cont">
                            <FormControlComponent 
                                type="text"
                                placeholder="Search"
                                onChange={handleSearchColumns}
                                value={searchValue}
                            />
                        </div>
                        <div>
                            {
                                filteredColumns?.map((column, index) => {
                                    const optionLabel = typeof(column.header) === 'string' ? column.header : column?.meta?.headerName as string;
                                    return (
                                        <div key={index} className="switch-columnHeader-div">
                                            <CustomSwitch 
                                                checked={columnVisibility[column.id as string]} 
                                                onChange={() => handleToggleChange(column.id as string)}
                                            />
                                            <span>{optionLabel as React.ReactNode || ''}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="action-button-div">
                            <CustomButton name='Show All' btnType="secondary" onClick={handleShowAll} />
                            <CustomButton name='Hide All' btnType="secondary" onClick={handleHideAll} />
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default ColumnVisibilityDropdown;