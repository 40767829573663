import React, { useEffect, useMemo, useState } from 'react';
import { CONTENT } from '../../scripts/i18n';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { count, deleteAudience, getAllAudience, getAudienceById, getAudienceColumnProperties, searchAudience } from '../../scripts/apis/audience';
import ViewAudienceInfo from './ViewAudienceInfo';
import { useTablePagination } from '../../contexts/TablePaginationContext';
import { buildInitialsForPicture, getSimilarKey } from '../../scripts/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import AudienceGraph from './AudienceGraph';
import MuiChip from '../../common/FormComponents/MuiChip';
import { Audience } from './interfaces';
import { FormControlComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { userMe } from '../../scripts/apis/users';
import { LocalStorage } from '../../scripts/LocalStorage';
import { User } from '../../interfaces/settings/user_interface';
import { TableActions } from '../../common/TableActions';
import DeletePopup from '../../common/DeletePopup';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { generateAudienceColumnsForTanstackTable } from './audienceColumn.helper';
import { createColumnHelper } from '@tanstack/react-table';
import AudienceToolbar from '../../components/Audience/AudienceToolbar';
import toast from 'react-hot-toast';
import HeaderBar from '../../common/Headerbar';

import './styles.scss';

/**
 * @returns
 * Functional component to render the audience page
 * Variable to store the props data of the rows
 * Variable to store the column data
 * Variable to store the state of the audience rows
 * Function to handle the crud operations in the audience data grid row
 */

const AudiencePage = (): React.JSX.Element => 
{

    const navigate = useNavigate();

    const [rows, setRows] = useState<Audience[]>([]);
    const [audienceInfo, setAudienceInfo] = useState<Audience[]>([]);
    const [audCount, setAudCount] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [baseColumnProperties, setBaseColumnProperties] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [chartVisibility, setChartVisibility] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);

    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const [tableSearch, setTableSearch] = useState<string>('');
    const [searchBy, setSearchBy] = useState<number>(1);

    const [userData, setUserData] = useState<User | undefined>(LocalStorage.get('@UserMe') || undefined);

    const { audienceId } = useParams();

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    const fieldsToShow = ['companyLogo', 'firstName', 'lastName', 'email', 'icp', 'buyerPersona'];
    const columns = generateColumns(baseColumnProperties, setAnchorEl, anchorEl, true);

    const columnHelper = createColumnHelper<Audience>();
    const generateInitialColumns = () => [
        columnHelper.accessor('actions', {
            cell: ({ row }) => (
                <TableActions 
                        actions={[
                            { title: 'View', onClick: () => openDrawer(row.original) },
                            { title: 'Delete', onClick: () => {
                                setSelectedAudience(row.original);
                                setShowDeletePopup(true);
                            } }
                        ]}
                    />
            ),
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ];

    let audienceColumns = [...generateAudienceColumnsForTanstackTable()];

    if (userData?.role === 3) {
        audienceColumns = [...audienceColumns, ...generateInitialColumns()];
    }
    const [allColumns, setAllColumns] = useState(audienceColumns);

    const openDrawer = (data, routeFromId?: string): void => 
    {
        const clearbitFName = data?.clearbitData?.givenName;
        const clearbitLName = data?.clearbitData?.familyName;

        const name = clearbitFName && clearbitLName ? clearbitFName + ' ' + clearbitLName : data?.firstName;

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: name,
            // event: 'edit-event',
            hideCloseButton: true,
            component: <ViewAudienceInfo audienceData={data} routeFromId={routeFromId} name={name} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.REQUIRED_SHOW, {
            hideRequiredField: true,
        });
    };

    const fetchTotalAudienceCount = async (): Promise<void> => 
    {
        try 
        {
            const audienceCount = await count();
            if (audienceCount) 
            {
                setAudCount(audienceCount);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchUserData = async (): Promise<void> =>
    {
        try 
        {
            const user = await userMe();
            if(user)
            {
                setUserData(user);
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching user data');
        }
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (audienceInfo && audienceInfo?.length && audienceId) 
        {
            try 
            {
                const audienceDataFromId = await getAudienceById(Number(audienceId));
                if (audienceDataFromId) 
                {
                    openDrawer(audienceDataFromId, 'true');
                }
                else if (!audienceDataFromId) 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'The audience you\'re trying to access doesn\'t exist',
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'center',
                    });
                    navigate('/audiences');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);
            if (audCount) 
            {
                try 
                {
                    const audienceData = await getAllAudience(pageSize, currentPage - 1);

                    if (audienceData) 
                    {
                        setAudienceInfo([...audienceData]);
                        setIsEmpty(audienceData?.length === 0);
                        setRows([...audienceData]);
                        setShowSpinner(false);

                        const initialColumnKeys = ['firstName', 'email', 'lastName'];
                        const initialColumns = initialColumnKeys.map((field) => ({
                            field,
                            headerName: _.startCase(field),
                            // hide: !fieldsToShow.includes(field),
                            disableColumnMenu: true,
                        }));

                        try 
                        {
                            const properties = await getAudienceColumnProperties();
                            if (properties) 
                            {
                                const updatedProperties = properties?.map(property => ({
                                    ...property,
                                    clearbit: true
                                }));

                                const filteredBaseColumnProperties = updatedProperties?.filter((property):boolean => 
                                {
                                    return property.field !== 'email' && property.field !== 'firstName' && property?.field !== 'lastName';
                                })
                                setBaseColumnProperties([...initialColumns, ...filteredBaseColumnProperties]);
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }

                        eventBus.dispatch(APP_CONSTANTS.EVENTS.FILTERS.FILTER_ROW, {
                            filterData: 'audienceRowsData',
                            rows: rows,
                            placeHolder: CONTENT.AUDIENCE_PAGE.FILTER_PLACEHOLDER,
                            filterObj: {
                                id: 'firstName',
                                name: 'firstName',
                            },
                            options: rows,
                        });

                        eventBus.on(APP_CONSTANTS.EVENTS.DATA_GRID.AUDIENCE_ROW_CLICK, (data): void => 
                        {
                            openDrawer(data.rowData);
                        });

                        eventBus.on(APP_CONSTANTS.EVENTS.FILTERS.FILTERED_ROW, (filteredObj): void => 
                        {
                            const defaultRows = rows;
                            const filteredRow = _.filter(rows, function (item) 
                            {
                                if (filteredObj.filteredData?.length > 0) 
                                {
                                    return filteredObj.filteredData.includes(item.firstName);
                                }
                                else return defaultRows;
                            });
                            setRows(filteredRow);
                        });

                        eventBus.on(APP_CONSTANTS.EVENTS.AUDIENCE.DELETE, async (object): Promise<void> => 
                        {
                            const acceptObj = object.acceptObj;
                            const audienceRows = audienceData;
                            if (audienceRows && audienceRows?.length > 0) 
                            {
                                acceptObj.id ? await deleteAudience(acceptObj.id) : null;
                                _.remove(audienceRows, {
                                    id: acceptObj.id
                                });
                                setRows([...audienceRows]);
                            }
                        });
                    }

                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const deleteAudienceFn = async (): Promise<void> =>
    {
        try 
        {
            const couponDeleted = await deleteAudience(selectedAudience?.id as string);
            if (couponDeleted) 
            {
                toast.success(`${selectedAudience?.firstName} ${selectedAudience?.lastName || ''} has been removed successfully!`);
                setRefresh(true);
                setShowDeletePopup(false);
                setSelectedAudience(null);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        fetchData();
        fetchTotalAudienceCount();

        if(!LocalStorage.get('@UserMe'))
        {
            fetchUserData();
        }
    }, []);

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, [audienceInfo]);

    useEffect((): void => 
    {

        if (currentPage > 0 && pageSize) 
        {
            fetchData();
        }

        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
            heading: CONTENT.AUDIENCE_PAGE.HEADING,
            listCount: audCount,
            closedChartsButton: true,
            closedChartClickEventName: 'close-audience-page-charts',
        });
        eventBus.on('close-audience-page-charts', (data): void => 
        {
            if (data.closeChart) 
            {
                setChartVisibility(false);
            }
            else 
            {
                setChartVisibility(true);
            }
        });
    }, [pageSize, currentPage, audCount]);

    useEffect(() => 
    {
        const delayDebounceFn = setTimeout(async (): Promise<void> => 
        {
            if ((tableSearch && tableSearch.length >= 2 && (searchBy === 1 || searchBy === 2))) 
            {
                try 
                {
                    const audienceData = await searchAudience(
                        tableSearch?.includes('@') ? tableSearch : undefined,
                        !tableSearch?.includes('@') ? tableSearch : undefined,
                    );

                    if (audienceData) 
                    {
                        setRows(audienceData);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
            else if (tableSearch === '') 
            {
                fetchData();
                setShowSpinner(false);
            }
        }, 300);
        return (): void => 
        {
            return clearTimeout(delayDebounceFn);
        };
    }, [tableSearch, searchBy]);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <Box id="audiencePage">
            <HeaderBar 
                title='Audience'
                buttons={[
                    <Box className="top-bar-search-container">
                        <FormControlComponent startIcon='search' key='search' clearable value={tableSearch} placeholder='Search' type='text' onChange={(event) => {
                                setTableSearch(event.target.value);
                            }} />
                    </Box>,
                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                ]}
            />
            {chartVisibility && <AudienceGraph audCount={audCount} trendsTitle={'Total Audience'}></AudienceGraph>}
            <Box key={chartVisibility ? 'audience-page-chart-table' : 'audience-page-table'} sx={chartVisibility?{
                flex: 1,
                height: '56% !important',
            }:{
                height: 'calc(100vh - 60px)',
                maxHeight: 'calc(100vh - 60px)',
            }}>
                <AudienceToolbar 
                    audienceCount={audCount}
                    columns={audienceColumns}
                    setColumns={setAllColumns}
                    rows={audienceInfo}
                    setRows={setRows}
                />
                <TanstackTable 
                    data={rows}
                    initialColumns={allColumns}
                    rowCount={audCount}
                    pageSize={pageSize}
                    currentPage={currentPage - 1}
                    updateCurrentPage={updateCurrentPage as any}
                    updatePageSize={updatePageSize}
                    showSpinner={showSpinner}
                    hidePagination={tableSearch?.length >= 2}
                    height={chartVisibility ? `calc(100% - 170px)` : `calc(100% - 109px)`}
                    rightPinnedColumns={['actions']}
                    onRowClick={(row) => openDrawer(row)}
                />
            </Box>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={deleteAudienceFn} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedAudience(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedAudience?.firstName} ${selectedAudience?.lastName || ''}?`}
                        modalTitle='Delete Audience'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};
export default AudiencePage;

export const generateColumns = (data, setAnchorEl, anchorEl, ignoreClearbitName?: boolean) => 
{
    const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];

    // const baseColumns = Object.keys(data[0] || {}).map((field) => ({
    //     field,
    //     headerName: _.startCase(field),
    //     hide: !fieldsToShow.includes(field),
    //     disableColumnMenu: true,
    // }));


    // let clearBitDataKeys = [];
    // let hubspotDataKeys = [];

    // data.forEach((item) => {
    // if (item.clearbitData && typeof item.clearbitData === 'object') {
    // clearBitDataKeys = [...clearBitDataKeys, ...generateColumnsRecursive(item, 'clearbitData')];
    // }
    // else if (item.hubspotContactData && typeof item.clearbitData === 'object') {
    //     hubspotDataKeys = [...hubspotDataKeys, ...generateColumnsRecursive(item.hubspotContactData, 'hubspotContactData')];
    // }
    // });

    const columns = [];
    data.forEach((item: {
        field?: string;
        hide?: boolean;
        headerName?: string;
        clearbit?: boolean;
        disableColumnMenu?: boolean;
    }): void => 
    {
        if (item.field === 'companyLogo') 
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                sortable: false,
                filterable: false,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    const clearbitFName = params.row?.clearbitData?.givenName;
                    const clearbitLName = params.row?.clearbitData?.familyName;

                    const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : params?.row?.name ? buildInitialsForPicture('', params?.row?.name, '') : buildInitialsForPicture('', params.row?.firstName, '');
                    return <Box className="avatar-container">
                        {
                            <Avatar className="logo-avatar-img" src={params.value ? params.value : ''}>
                                {!params.value ? name : ''}
                            </Avatar>
                        }
                    </Box>;
                },
                valueGetter: (params): string => 
                {
                    return params.row?.clearbitData?.[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 60,
            });
        }
        else if (item.field === 'firstName') 
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    if (item.field === 'firstName') 
                    {
                        const clearbitFName = params.row?.clearbitData?.givenName;
                        const clearbitLName = params.row?.clearbitData?.familyName;
                        const name = clearbitFName && clearbitLName ? clearbitFName + ' ' + clearbitLName : params.value;
                        if(ignoreClearbitName)
                        {
                            return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params?.row?.firstName}</Typography>}><Box className="data-grid-bolded-cellContent">{params?.row?.firstName}</Box></Tooltip>;
                        }
                        else 
                        {
                            return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{name}</Typography>}><Box className="data-grid-bolded-cellContent">{name}</Box></Tooltip>;
                        }
                    }
                    else 
                    {
                        return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="data-grid-bolded-cellContent">{params.value}</Box></Tooltip>;
                    }
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 160
            });
        }
        else if (item.field === 'email') 
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>;
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 160
            });
        }
        else if (item.field === 'lastName') 
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: 'Last Name',
                renderCell: (params): React.ReactElement => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params?.row?.lastName || params?.row?.additionalData?.lastName || '-'}</Typography>}><Box className="MuiDataGrid-cellContent">{params?.row?.lastName || params?.row?.additionalData?.lastName || '-'}</Box></Tooltip>;
                },
                valueGetter: (params): string => 
                {
                    return params.row['lastName'] || params.row?.additionalData?.lastName || '-';
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 160
            });
        }
        else if (item.field === 'icp' || item.field === 'buyerPersona') 
        {

            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    const icpcolor = item.field === 'icp' ? 'blue' : 'pink';

                    const handlePopoverOpen = (event, params): void => 
                    {
                        setAnchorEl({
                            id: params.id, field: params.field 
                        });
                    };
                    const handlePopoverClose = (): void => 
                    {
                        setAnchorEl(false);
                    };

                    return (
                        <Box
                            onMouseEnter={(event): void => 
                            {
                                handlePopoverOpen(event, params); 
                            }}
                            onMouseLeave={handlePopoverClose}
                        >
                            {anchorEl?.id === params?.id && anchorEl?.field === params?.field ? (
                                // sx={{ right: item.field === 'buyerPersona' ? 0 : null }}
                                <Box className="popupBlock">
                                    <Box className="tooltip-chip-cont">
                                        {params.value.map((tag, index): React.ReactElement => 
                                        {
                                            return (
                                                <Box className={icpcolor} key={index} >
                                                    {tag}
                                                </Box>
                                            ); 
                                        })}
                                    </Box>
                                </Box>
                            ) : (
                                <Box onMouseLeave={handlePopoverClose}>
                                    {params.value?.length > 0 ? (
                                        params.value?.length > 1 ? (
                                            <Box className="table-chip-cell">
                                                <MuiChip label={params.value[0]} chipColor={icpcolor}/>
                                                <Typography className="table-chip-count">
                                                    +{params.value?.length - 1}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <MuiChip label={params.value[0]} chipColor={icpcolor}/>
                                        )
                                    ) : null}
                                </Box>
                            )}
                        </Box>
                    );
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                width: 105,
                minWidth: 105
            });
        }
        else if (item?.clearbit)
        {
            columns.push({
                field: `clearbitData.${item?.field}`,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>;
                },
                renderHeader: item?.clearbit ? () => (
                    <Tooltip title="Enriched by Clearbit">
                        <div className="clearbit-column-header">
                            <span className="header-name">{_.startCase(item.headerName)}</span>
                            <Box component="img" src={'https://www.google.com/s2/favicons?sz=64&domain_url=https://clearbit.com/'} className="clearbit-logo" />
                        </div>
                    </Tooltip>
                ) : undefined,
                valueGetter: (params): string => 
                {
                    let value = '';
                    if (params.row?.clearbitData) 
                    {
                        const keys = item.field?.split('.');
                        let tempValue = params.row?.clearbitData;
                        for (let key of keys) {
                            if (tempValue && key in tempValue) {
                                tempValue = tempValue[key];
                            } else {
                                tempValue = undefined;
                                break;
                            }
                        }
                        if (tempValue !== undefined) {
                            value = tempValue;
                        }
                    }            
                    return value ? value : '';
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 140
            });
        }
        else
        {
            columns.push({
                field: item.field,
                // hideable: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.ReactElement => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>;
                },
                valueGetter: (params): string => 
                {
                    let value = '';
                    // if (params.row?.clearbitData && !item?.clearbit) 
                    // {
                    //     const keys = item.field?.split('.');
                    //     let tempValue = params.row?.clearbitData;
                    //     for (let key of keys) {
                    //         if (tempValue && key in tempValue) {
                    //             tempValue = tempValue[key];
                    //         } else {
                    //             tempValue = undefined;
                    //             break;
                    //         }
                    //     }
                    //     if (tempValue !== undefined) {
                    //         value = tempValue;
                    //     }
                    // }
                    if (params.row?.additionalData) 
                    {
                        const similarKey = getSimilarKey(params?.row?.additionalData, item?.field);
                        if (similarKey) 
                        {
                            value += params?.row?.additionalData[similarKey];
                        }
                    }                    
                    return value ? value : '';
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 140
            });
        }
    });

    const filteredColumns = columns.filter((column): boolean => 
    {
        return !excludedFields.includes(column.field); 
    });
    return filteredColumns;
};