import React, { useEffect, useRef, useState } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridRowId, GridRowSelectionModel, useGridApiContext, useGridSelector, gridPageSelector, gridPageSizeSelector } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import CustomSpinner from '../../../../common/CustomSpinner';
import APP_CONSTANTS from '../../../../scripts/constants';
import eventBus from '../../../../scripts/event-bus';
import CheckboxWrapper from '../../../../common/CheckboxWrapper';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { LocalStorage } from '../../../../scripts/LocalStorage';

interface ISponsorPortalDatagrid {
    rows: any[];
    columns: GridColDef[];
    rowClickEvent?: string;
    height?: string;
    isEmpty: boolean;
    rowCount: number;
    isPagination: boolean;
    pageSize?: number;
    page?: number;
    updatePageSize?: (value: number) => void;
    updateCurrentPage?: (value: number) => void;
    showSpinner: boolean;
    fullHeight?: boolean;
    pinnedColumns?: string[];
    rightPinnedColumns?: string[];
    showColumnFilter?: boolean;
    customToolbar?: React.JSX.Element | any;
    hidePagination?: boolean;
    enableExport?: boolean;
    exportButton?: React.JSX.Element;
    allData?: any[];
    dataCount?: number;
    customDefaultPageSize?: number;
    hiddenFields?: string[];
    checkBoxSelection?: boolean;
}

const SponsorPortalDatagrid: React.FC<ISponsorPortalDatagrid> = (props): React.JSX.Element => 
{

    const location = useLocation().pathname;

    const rows = props.rows;
    const columns = props.columns;
    const eventBusConst = props.rowClickEvent;
    const gridHeight = props.height;
    const isEmpty = props.isEmpty;
    const rowCount = (!isEmpty && props.rowCount) && Number(props.rowCount);

    const isPaginationEnabled = props.isPagination ? props.isPagination : false;
    const [visibleColumns, setVisibleColumns] = useState({
    });
    const [tableHeight, setTableHeight] = useState(0);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(null);
    // const [spinner, setSpinner] = useState(false);
    const [cellUpdatedCount, setCellUpdatedCount] = useState(0);
    const [cellUpdated, setCellUpdated] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const tableContainerRef = useRef(null);

    useEffect((): () => void => 
    {
        const handleResize = (): void => 
        {
            
            props?.updatePageSize ? props?.updatePageSize(25) : null;

            setWindowHeight(window.innerHeight);
            if (props.fullHeight) 
            {
                if (tableContainerRef.current) 
                {
                    const tableHeight = tableContainerRef.current.clientHeight;
                    setTableHeight(tableHeight);
                    // if (rowCount > 10) {
                    //     setTableHeight(tableHeight - 49);
                    // } else {
                    //     setTableHeight(tableHeight - 12);
                    // }
                }
            }
            else if (props?.eventsModule) 
            {
                const tableHeight = tableContainerRef.current.clientHeight;
                setTableHeight(tableHeight);
            }
            else 
            {
                const tableHeight = tableContainerRef.current.clientHeight;
                setTableHeight(tableHeight);
                // setTableHeight(calculateTableContainerHeightForAudience({ windowHeight: windowHeight }));
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return (): void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowHeight, rowCount, tableContainerRef]);

    useEffect((): void => 
    {
        if (isEditing) 
        {
            setTableHeight(tableHeight - 64);
        }
        if (isEditing === false) 
        {
            setTableHeight(tableHeight + 64);
        }

    }, [isEditing]);


    useEffect((): void => 
    {
        if (props.columns.length > 0) 
        {
            props.columns.forEach((element): void => 
            {
                if (element?.hide) 
                {
                    if (element.field) 
                    {
                        setVisibleColumns((prevColumns): object => 
                        {
                            return {
                                ...prevColumns,
                                [element.field]: false
                            };
                        });
                    }
                }
            });
        }
    }, [props.columns]);

    // useEffect((): void => 
    // {
    //     eventBus.on('selected-row-id', (rowId): void => 
    //     {
    //         if (rowId) 
    //         {
    //             setSelectionModel([rowId]);
    //         }
    //         else 
    //         {
    //             setSelectionModel([]);
    //         }
    //     });
    // }, []);

    useEffect(() => {
        setCurrentPage(0);
    }, [location]);

    useEffect((): void => 
    {
        if (cellUpdated) 
        {
            setCellUpdatedCount(cellUpdatedCount + 1);
            setCellUpdated(false);
        }
    }, [cellUpdated]);

    const handleCellClick = (event): void => 
    {
        if (Object.keys(event.row).length > 0) 
        {
            eventBus.dispatch(eventBusConst, {
                rowData: event.row,
            });
        }
    };

    const getTogglableColumns = (columns: GridColDef[]): string[] => 
    {
        if (props?.hiddenFields) 
        {
            return columns
                .filter((column): boolean => 
                {
                    return !props?.hiddenFields.includes(column.field);
                })
                .map((column: GridColDef): string => 
                {
                    return column.field;
                });
        }
    };

    useEffect(() => {
        eventBus.on('selected-row-ids', (ids) => {
            setSelectionModel(ids);
        })
    }, []);

    function CustomPagination(): React.JSX.Element 
    {

        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

        const handlePageSizeChange = (event): void =>
        {
            apiRef.current.setPageSize(Number(event.target.value));
        };

        const pageSizeOptions = [
            {
                name: '25', value: 25,
            },
            {
                name: '50', value: 50,
            },
            {
                name: '75', value: 75,
            },
            {
                name: '100', value: 100,
            },
        ]

        return (
            <Box className="pagination-container">
                <Box className="page-indicator-component">
                    <Typography>{`Page ${page+1} of ${isPaginationEnabled ? Math.ceil(rowCount / props.pageSize) : Math.ceil(rows.length / 5)}`}</Typography>
                </Box>
                <CustomPaginationComponent paginationCount={isPaginationEnabled ? Math.ceil(rowCount / props.pageSize) : Math.ceil(rows.length / 5)} selectedPage={page + 1} onChangeFunc={(value: number): void => 
                {
                    return apiRef.current.setPage(value - 1);
                }
                } />
                <Select IconComponent={(props): React.JSX.Element => 
                {
                    return (
                        <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                    ); 
                }} value={pageSize ? pageSize : 25} onChange={handlePageSizeChange} className="pagesize-select" defaultValue={25} renderValue={(selected) =>
                {
                    return(
                        <Box>
                            <Typography className="selected-pagesize-text">{`${selected} Per Page`}</Typography>
                        </Box>
                    )
                }}>
                    {pageSizeOptions?.map((elem, index) => {
                        return (
                            <MenuItem key={index} value={elem.value}>{elem.name}</MenuItem>
                        )
                    })}
                </Select>  
            </Box>
        );
    }

    return (
        <Box id="audTableDataGrid" ref={tableContainerRef}>
            {(!isEmpty) && <Box height={gridHeight} className="grid-box" >
                {(!props?.showSpinner) ?
                    <div className="datagridContainer" style={{
                        maxHeight: tableHeight ? `${tableHeight}px` : '100%', overflowY: 'auto'
                    }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            rowHeight={52}
                            rowCount={isPaginationEnabled ? rowCount : rows.length}
                            disableColumnResize={false}
                            pagination={isPaginationEnabled || false}
                            keepNonExistentRowsSelected={true}
                            paginationModel={{
                                page: currentPage, pageSize: isPaginationEnabled ? props.pageSize : 5
                            }}
                            paginationMode={isPaginationEnabled ? 'server' : 'client'}
                            onPaginationModelChange={(paginationModel): void => 
                            {
                                props.updateCurrentPage(paginationModel.page + 1);
                                setCurrentPage(paginationModel.page);
                                props.updatePageSize(paginationModel.pageSize);
                            }}
                            rowSelectionModel={selectionModel}
                            onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel): void => 
                            {
                                eventBus.dispatch('selected-row-ids', rowSelectionModel);
                                setSelectionModel(rowSelectionModel);
                            }
                            }
                            loading={props.showSpinner ? props.showSpinner : false}
                            initialState={{
                                pinnedColumns: {
                                    left: props.pinnedColumns, right: props?.rightPinnedColumns
                                },
                            }}
                            checkboxSelection={props?.checkBoxSelection}
                            sx={{
                                // "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                //   display: "none"
                                // },
                                "& .MuiDataGrid-footerContainer": {
                                    display: props?.hidePagination ? 'none' : 'flex'
                                }
                            }}
                            slots={{
                                baseCheckbox: CheckboxWrapper,
                                loadingOverlay: (): React.JSX.Element => 
                                {
                                    return CustomSpinner({
                                        height: '100%'
                                    });
                                },
                                toolbar: props?.customToolbar || undefined,
                                pagination: props?.hidePagination ? null : CustomPagination,
                            }}
                            onCellClick={(params, event): void => 
                            {
                                if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field)) 
                                {
                                    return handleCellClick(params);
                                }
                            }}
                            hideFooter={false}
                            pageSizeOptions={[{
                                value: 25, label: '25 per page'
                            }, {
                                value: 50, label: '50 per page'
                            }, {
                                value: 75, label: '75 per page'
                            }, {
                                value: 100, label: '100 per page'
                            }]}
                        />
                    </div>
                    : <CustomSpinner height={'100%'} />}
            </Box>}
        </Box>
    );
};

export default SponsorPortalDatagrid;

interface ICustomPaginationComponentProps {
    paginationCount: number;
    selectedPage: number;
    onChangeFunc: (value: number | null) => void;
}


export const CustomPaginationComponent = ({ paginationCount, selectedPage, onChangeFunc }: ICustomPaginationComponentProps): React.JSX.Element => 
{
    const paginationContainerRef = useRef(null);

    const paginationButtons: React.JSX.Element[] = [];

    if (typeof paginationCount === 'number' && paginationCount < 10000000) 
    {
        let startPage = selectedPage;
        let endPage = startPage + 5;
    
        if (endPage > paginationCount) {
            endPage = paginationCount;
            startPage = endPage - 5;
            if (startPage < 1) startPage = 1;
        }
    
        for (let i = startPage; i <= endPage; i++) 
        {
            paginationButtons.push(
                <Box key={i} className={selectedPage === i ? 'selected-pagination-button' : 'pagination-button'} onClick={(): void => 
                {
                    return onChangeFunc(i);
                }}>{i}</Box>
            );
        }
    }

    return (
        <Box id='customPagination'>

            <CustomButton btnType='tertiary' disabled={selectedPage === 1} onClick={(): void => 
            {
                if (selectedPage !== 1) 
                {
                    return onChangeFunc(selectedPage !== 1 ? selectedPage - 1 : null);
                }
            }} name='Prev' startIcon={<FontAwesomeIcon style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} className="navigation-icon" />} />

            <Box flexBasis={'200px'} maxWidth={'200px'} overflow={'hidden'} >
                <Box className="pagination-numbers" ref={paginationContainerRef}>
                    {paginationButtons}
                </Box>

            </Box>

            <CustomButton disabled={selectedPage === paginationCount} btnType='tertiary' name='Next' onClick={(): void => 
            {
                if (selectedPage !== paginationCount) 
                {
                    return onChangeFunc(selectedPage !== paginationCount ? selectedPage + 1 : null);
                }
            }} endIcon={<FontAwesomeIcon icon={['fal', 'arrow-right']} className="navigation-icon" />} />

        </Box>
    );
};
