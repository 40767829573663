import { Avatar, AvatarGroup, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import moment from "moment";
import React, { useContext } from "react";
import { EventSession } from "../../interfaces";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import _ from "lodash";
import { ActionsDropdown } from "../../EventBudget/EventBudgetDataGrid";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddSession from "../../../../components/Events/Agenda/AddSession";
import { CONTENT } from "../../../../scripts/i18n";
import { EventStageContext } from "../../../../contexts/EventStage/EventStageContext";

const StageDataGrid = (props) =>
{
    const { rows, setRefresh, stageData, eventId } = props;

    const { deleteSessionFromTable } = useContext(EventStageContext);

    const columns: GridColDef[] = [
        {
            field: 'title', headerName: '', editable: false, flex: 1, disableColumnMenu: true, headerClassName: 'top_events_header', renderCell: (params): React.ReactElement => 
            {
                return sessionName(params.row); 
            }
        },
        {
            field: 'timing', headerName: '', editable: false, flex: 1, disableColumnMenu: true, renderCell: (params): React.ReactElement => 
            {
                return sessionTiming(params.row);
            }, headerClassName: 'top_events_header'
        },
        {
            field: 'speakers', headerName: '', editable: false, flex: 1, disableColumnMenu: true, renderCell: (params): React.ReactElement => 
            {
                return sessionSpeakers(params.row);
            }, headerClassName: 'top_events_header'
        },
        {
            field: 'type', headerName: '', editable: false, flex: 1, disableColumnMenu: true, headerClassName: 'top_events_header', sortable: false, renderCell: (params): React.ReactElement => 
            {
                return sessionType(params.row);
            },
        },
        {
            field: 'actions', headerName: '', editable: false, maxWidth: 60, flex: 1, disableColumnMenu: true, headerClassName: 'top_events_header', renderCell: (params): React.ReactElement => 
            {
                return sessionActions(params.row);
            }
        }
    ];

    const sessionName = (row: EventSession): React.ReactElement => 
    {
        return (
            <Typography className="MuiDataGrid-cellContent">{row.title}</Typography>
        );
    };
    
    const sessionTiming = (row: EventSession): React.ReactElement => 
    {
        const startDateTime = moment.unix(Number(row.startDateTime)).format('hh:mm A');
        const endDateTime = moment.unix(Number(row.endDateTime)).format('hh:mm A');
        return(
            <Typography className="MuiDataGrid-cellContent">{startDateTime} - {endDateTime}</Typography>
        )
    };

    const sessionSpeakers = (row: EventSession): React.ReactElement =>
    {        
        const speakerImage = row?.speakerIds && row?.speakerIds?.length > 0 ? row.speakerIds.map((item) => 
        {
            const ids = _.find(row?.speakers, ['id', String(item)]);
            if (ids) 
            {
                return ids?.additionalInfo?.image;
            }
        }) : [];

        const speakerName = row?.speakerIds && row?.speakerIds?.length > 0 ? row.speakerIds.map((item) => 
        {
            const ids = _.find(row?.speakers, ['id', String(item)]);
            if (ids) 
            {
                return ids?.name;
            }
        }) : [];
        
        return (
            <AvatarGroup max={2} total={row?.speakerIds?.length}>
                {speakerImage?.length > 0 && speakerImage.map((item, index) => 
                    {
                        return (
                            <Tooltip disableInteractive title={<Typography fontSize={'14px'}>{speakerName[index]}</Typography>}>
                                <Avatar key={index} alt="speaker image" src={item} style={{ width: 24, height: 24 }}>
                                    {item ? null : speakerName[index]?.charAt(0)}
                                </Avatar>
                            </Tooltip>
                        )
                    }
                )}
            </AvatarGroup>
        )
    };

    const sessionType = (row: EventSession): React.ReactElement =>
    {
        const sessionTypes = {
            1: 'Session',
            2: 'Keynote',
            3: 'Break',
        };
    
        const sessionTypeClass = {
            1: 'blue',
            2: 'violet',
            3: 'grey'
        };
        return (
            <MuiChip label={sessionTypes[row?.type ?? '']} chipColor={sessionTypeClass[row?.type ?? '']} />
        )
    };

    const sessionActions = (row: EventSession): React.ReactElement =>
    {
        const editClick = (): void => 
        {
            handleEdit(row);
        };

        const deleteClick = (): void => 
        {
            handleDelete(row);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleEdit = (row: EventSession): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit Session',
            component: <AddSession eventId={eventId} sessionData={row} setRefresh={setRefresh} stageId={stageData?.id} stageData={stageData} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const handleDelete = (row: EventSession): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: 'Delete Session ?',
            content: `Are you sure you want to delete ${row.title} ?`,
            acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
            acceptEvent: 'delete-event-session',
            rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
            acceptObj: {
                id: row.id
            },
        });

        deleteSessionFromTable();
    };

    return (
        <div id="stageDataGrid" style= {{ flex:1, height: '100%' }}>
            <DataGridPro 
                rows={rows} 
                columns={columns} 
                columnHeaderHeight={0}
                slots={{
                    columnHeaders: () => null,
                }}
                disableColumnMenu 
                disableColumnFilter 
                disableColumnSelector 
                disableDensitySelector 
                disableRowSelectionOnClick 
                disableColumnResize 
                disableMultipleColumnsFiltering 
                disableColumnReorder 
                hideFooter
                onCellClick={(params, event): void => 
                {
                    if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field) && !params.isEditable) 
                    {
                        return handleEdit(params.row);
                    }
                }}
                className="stage-datagrid"
            />
        </div>
    )
};

export default StageDataGrid;