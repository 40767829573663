/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/named */
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import FormTabs from '../../../common/FormTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import FormPreview from '../RegistrationSubPages/Preview/FormPreview';
import FormComponentSideDrawer from './FormComponentSideDrawer';
import SelectedFromComponents from '../RegistrationSubPages/FormComponents/SelectedFormComponents';
import { useSelector } from 'react-redux';
import { FormComponentDisplayField, IFormComponentDisplayFieldValues } from '../Common/registration_interface';
import TextInputSideDrawer from './TextInputSideDrawer';
import eventBus from '../../../scripts/event-bus';
import NumberInputSideDrawer from './NumberInputDrawer';
import DateInputSideDrawer from './DateInputDrawer';
import SelectionInputSideDrawer from './SelectionInputDrawer';
import StructuralSideDrawer from './StructuralDrawer';
import GdprSideDrawer from './GdprSideDrawer';
import ExistingPropertiesSidedrawer from './ExistingPropertiesSidedrawer';
import SelectedFormContent from './SelectedFormContent';
import { CustomFieldType } from '../enum/custom-field.enum';
interface IFormComponentProps {
    formId: string | number;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    RegistrationFormData: any;
    existingProperties?: boolean;
    leadCaptureFormId?: string | number;
}

export enum RegistrationFormComponentType {
    ALL_COMP_DRAWER = 0,
    SINGLE_LINE = 1,
    MULTI_LINE = 2,
    CHECKBOXES = 3,
    DROPDOWN = 4,
    DATE = 5,
    NUMBER = 6,
    HEADER = 7,
    PARAGRAPH = 8,
    RADIO = 9,
    IMAGE = 10,
    GDPR_COMMUNICATION_PROCESSING = 11,
    GDPR_LEGITIMATE_INTEREST = 12,
    GDPR_FORM_SUBMIT_AS_CONSENT = 13,
}

const RegistrationFormContent = ({ formId, setRefresh, RegistrationFormData, existingProperties, leadCaptureFormId }: IFormComponentProps): React.JSX.Element => 
{
    const navigate = useNavigate();
    const currentPath = useLocation().pathname;
    const [componentTypeDrawer, setComponentTypeDrawer] = useState<RegistrationFormComponentType>(RegistrationFormComponentType.ALL_COMP_DRAWER);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const contentCount = {
        heading: RegistrationFormData?.formFields?.reduce((acc, field) => field.type === CustomFieldType.HEADER ? acc + 1 : acc, 0),
        paragraph: RegistrationFormData?.formFields?.reduce((acc, field) => field.type === CustomFieldType.PARAGRAPH ? acc + 1 : acc, 0),
    };

    const [selectedTab, setSelectedTab] = useState<'recent'|'all'>(leadCaptureFormId ? 'all' : 'recent');

    const [selectedPageinSponsorPortal, setSelectedPageinSponsorPortal] = useState<'form'|'preview'>('form');

    const tabData = [
        {
            tabName: 'Recently Used Fields',
            selectedTab: selectedTab === 'recent',
            navigation: () => { 
                setSelectedTab('recent');
                eventBus.dispatch('event-open-form-Component-drawer', {
                    componentType: 0
                }); 
            },
        },
        {
            tabName: 'Create New Field',
            selectedTab: selectedTab === 'all',
            navigation: () => {
                setSelectedTab('all');
                eventBus.dispatch('event-open-form-Component-drawer', {
                    componentType: 0
                });
            },
        },
    ];

    const formFieldData = useSelector((state: FormComponentDisplayField): IFormComponentDisplayFieldValues => 
    {
        return state.formComponentDisplayField.value;
    });

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    useEffect((): void => 
    {
        eventBus.on('event-open-form-Component-drawer', (data): void => 
        {
            setComponentTypeDrawer(data.componentType);
            if(data.data)
            {
                setSelectedComponent(data.data);
            }
            else
            {
                setSelectedComponent(null);
            }
        });

    }, []);

    return (
        <Box height={leadCaptureFormId ? 'calc(100vh - 48px)' : 'calc(100vh - 58px)'} id='registrationFormContent'>
            <div className="form-tabs-wrapper">

                {!currentPath?.includes('sponsor-portal') && <div className="tabs">
                    <FormTabs tabs={tabData} />
                </div>}

                <Box height={leadCaptureFormId ? '100%' : 'calc(100% - 42px)'} className="form-component-side-drawer">
                    {(!currentPath?.includes('sponsor-portal') && selectedTab === 'recent' && (componentTypeDrawer === RegistrationFormComponentType.ALL_COMP_DRAWER || componentTypeDrawer === null)) && <ExistingPropertiesSidedrawer RegistrationFormData={RegistrationFormData} setRefresh={setRefresh} formId={formId} />}

                    {((selectedTab === 'all' || currentPath?.includes('sponsor-portal')) && (componentTypeDrawer === RegistrationFormComponentType.ALL_COMP_DRAWER || componentTypeDrawer === null)) && <FormComponentSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} setRefresh={setRefresh} formId={formId} />}

                    {/* {componentTypeDrawer === RegistrationFormComponentType.ALL_COMP_DRAWER && <FormComponentSideDrawer RegistrationFormData={RegistrationFormData} setRefresh={setRefresh} formId={formId} />} */}

                    {componentTypeDrawer === RegistrationFormComponentType.SINGLE_LINE && <TextInputSideDrawer leadCaptureFormId={leadCaptureFormId} drawerTitle='Single Line Text Box' formId={formId} RegistrationFormData={RegistrationFormData} setRefresh={setRefresh} componentType={RegistrationFormComponentType.SINGLE_LINE} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.MULTI_LINE && <TextInputSideDrawer leadCaptureFormId={leadCaptureFormId} drawerTitle='Multiple Line Text Box' formId={formId} RegistrationFormData={RegistrationFormData} setRefresh={setRefresh} componentType={RegistrationFormComponentType.MULTI_LINE} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.NUMBER && <NumberInputSideDrawer leadCaptureFormId={leadCaptureFormId} drawerTitle='Number Field' formId={formId} RegistrationFormData={RegistrationFormData} setRefresh={setRefresh} componentType={RegistrationFormComponentType.NUMBER} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.DATE && <DateInputSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} drawerTitle='Date Picker' formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.DATE} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.DROPDOWN && <SelectionInputSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} drawerTitle='Dropdown Menu' formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.DROPDOWN} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.RADIO && <SelectionInputSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} drawerTitle='Radio Button' formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.RADIO} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.CHECKBOXES && <SelectionInputSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} drawerTitle='Checkbox' formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.CHECKBOXES} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.PARAGRAPH && <StructuralSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} drawerTitle='Paragraph Text' formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.PARAGRAPH} csrfTokenData={csrfTokenData} isHeader={false} existingComponentData={selectedComponent} contentCount={contentCount} />}

                    {componentTypeDrawer === RegistrationFormComponentType.HEADER && <StructuralSideDrawer leadCaptureFormId={leadCaptureFormId} RegistrationFormData={RegistrationFormData} drawerTitle='Section Header' formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.HEADER} csrfTokenData={csrfTokenData} isHeader={true} existingComponentData={selectedComponent} contentCount={contentCount} />}

                    {componentTypeDrawer === RegistrationFormComponentType.GDPR_COMMUNICATION_PROCESSING && <GdprSideDrawer drawerTitle='GDPR Consent' gdprContentType={componentTypeDrawer} formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.GDPR_COMMUNICATION_PROCESSING} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.GDPR_LEGITIMATE_INTEREST && <GdprSideDrawer drawerTitle='GDPR Consent' gdprContentType={componentTypeDrawer} formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.GDPR_LEGITIMATE_INTEREST} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                    {componentTypeDrawer === RegistrationFormComponentType.GDPR_FORM_SUBMIT_AS_CONSENT && <GdprSideDrawer drawerTitle='GDPR Consent' gdprContentType={componentTypeDrawer} formId={formId} setRefresh={setRefresh} componentType={RegistrationFormComponentType.GDPR_FORM_SUBMIT_AS_CONSENT} csrfTokenData={csrfTokenData} existingComponentData={selectedComponent} />}

                </Box>
            </div>
            <Box className="form-component-space">
                <Box className="tabs-header">
                    <FormTabs
                        tabs={[
                            {
                                tabName: 'Form',
                                selectedTab: currentPath === `/registrations/${formId}/form` || (currentPath?.includes('sponsor-portal') && selectedPageinSponsorPortal === 'form'),
                                navigation: (): void => 
                                {
                                    if(currentPath?.includes('sponsor-portal'))
                                    {
                                        return setSelectedPageinSponsorPortal('form');
                                    }
                                    return navigate(`/registrations/${formId}/form`);
                                }
                            },
                            {
                                tabName: 'Preview',
                                selectedTab: currentPath === `/registrations/${formId}/preview` || (currentPath?.includes('sponsor-portal') && selectedPageinSponsorPortal === 'preview'),
                                navigation: (): void => 
                                {
                                    if(currentPath?.includes('sponsor-portal'))
                                    {
                                        return setSelectedPageinSponsorPortal('preview');
                                    }
                                    return navigate(`/registrations/${formId}/preview`);
                                }
                            }]}
                    />
                </Box>
                <Box className="present-form-components">
                    {/* sx={{
                    backgroundImage: `url(${formBackgroundImage}) !important`
                    }}  */}
                    {(currentPath === `/registrations/${formId}/form` || (currentPath?.includes('sponsor-portal') && selectedPageinSponsorPortal === 'form')) && <SelectedFormContent leadCaptureFormId={leadCaptureFormId} formId={formId} registrationFormData={RegistrationFormData} setRefresh={setRefresh} />}
                    {(currentPath === `/registrations/${formId}/preview` || (currentPath?.includes('sponsor-portal') && selectedPageinSponsorPortal === 'preview')) && <Box className='form-block'> <FormPreview registrationFormData={{ registrationForm: RegistrationFormData }} formId={formId} maxWidth={'50%'} /> </Box>}
                </Box>
            </Box>
        </Box>
    );
};

export default RegistrationFormContent;