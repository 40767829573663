import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { store } from './redux/index.ts';

import 'reset-css';
import 'normalize.css';
import './fontawesomeIcons';


LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE);

ReactDOM.createRoot(document.getElementById('root')!).render(
//   <StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    // </StrictMode>,
)
