import { AnalyticsBudgetGraph, Budget, BudgetGraph } from '../../pages/Budget/interfaces';
import { AnnualBudget } from '../../pages/Budget/interfaces/annual-budget_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getBudgetCount = async ():Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getBudgetFromEventId = async (eventId: number):Promise<Budget> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/' + eventId;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllBudgetDataCount = async ():Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllBudgetData = async (pageSize:number, offset:number):Promise<Budget[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget?'+ '&pageSize='+pageSize+'&offset='+offset;
    return triggerApi(API_URL, 'GET', '');
};

export const getBudgetGraphData = async ():Promise<BudgetGraph> => 
{
    const query = convertJSONToGETParams({
        trend: true,
        expenditure: true,
        budgetVsActual: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/graph/?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getBudgetAnalyticsGraphData = async ():Promise<AnalyticsBudgetGraph> => 
{
    const query = convertJSONToGETParams({
        budgetSpent: true,
        quarterly: true,
        budgetSpentEventType: true,
        avgCostEventType: true,
        budgetSpentEventLocation: true,
        avgCostEventLocation: true,
        topExpenses: true,
        categoryBudgetActualSpending: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/analytics/graph/?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAnnualBudget = async (year: number | string): Promise<AnnualBudget> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/' + year + '/annual';
    return triggerApi(API_URL, 'GET', '');
};

export const createAnnualBudget = async (budgetData, csrfToken: string): Promise<AnnualBudget> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/annual';
    return triggerApi(API_URL, 'POST', budgetData, csrfToken);
};

export const updateAnnualBudget = async (budgetId: number | string, budgetData): Promise<AnnualBudget> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/budget/' + budgetId + '/annual';
    return triggerApi(API_URL, 'PUT', budgetData);
};
