import { Box, Tooltip, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import MuiChip from "../../common/FormComponents/MuiChip";
import { Speaker } from "./interfaces";

const data = [
  {
    "field": "companyLogo",
    "headerName": "Logo",
    "hide": false,
    "disableColumnMenu": true
  },
  {
    "field": "firstName",
    "headerName": "Name",
    "hide": false,
    "disableColumnMenu": true
  },
  {
    "field": "email",
    "headerName": "Email",
    "hide": false,
    "disableColumnMenu": true
  },
  {
    "field": "icp",
    "headerName": "ICP",
    "hide": false,
    "disableColumnMenu": true
  },
  {
    "field": "buyerPersona",
    "headerName": "Buyer Persona",
    "hide": false,
    "disableColumnMenu": true
  },
  {
    "field": "givenName",
    "headerName": "Given Name",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "familyName",
    "headerName": "Family Name",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "location",
    "headerName": "Location",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "linkedinHandle",
    "headerName": "Linkedin",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "phone",
    "headerName": "Phone",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "geo.city",
    "headerName": "City",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "geo.state",
    "headerName": "State",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "geo.country",
    "headerName": "Country",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "employment.title",
    "headerName": "title",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "employment.role",
    "headerName": "role",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "employment.subRole",
    "headerName": "subRole",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "employment.seniority",
    "headerName": "Seniority",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "category.subIndustry",
    "headerName": "Sub Industry",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "company",
    "headerName": "Company",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "companyDomain",
    "headerName": "Company Domain",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "companyGeo.country",
    "headerName": "Company Location",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "metrics.employees",
    "headerName": "Employees",
    "hide": true,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "metrics.employeesRange",
    "headerName": "Employees Range",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "metrics.raised",
    "headerName": "Raised",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "metrics.annualRevenue",
    "headerName": "Annual Revenue",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "metrics.estimatedAnnualRevenue",
    "headerName": "Estimated Annual Revenue",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "companyTechCategories",
    "headerName": "Tech Categories",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  },
  {
    "field": "companyTech",
    "headerName": "Tech",
    "hide": false,
    "disableColumnMenu": true,
    "clearbit": true,
  }
];

export const generateSpeakerColumnsForTanstackTable = () => {
  const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];

  const columnHelper = createColumnHelper();

  const columns: any[] = [];

  data.forEach((item): void => {
    if (item.field === 'icp' || item.field === 'buyerPersona') {
      columns.push(columnHelper.accessor(item?.field || '', {
        cell: (row: any) => {
          const icpChipColor = item.field === 'icp' ? 'blue' : 'pink';

          const handlePopoverOpen = (event, params): void => {
            setAnchorEl({
              id: params.id
            });
          };
          const handlePopoverClose = (): void => {
            setAnchorEl(false);
          };

          return (
            <Box>
              {/* {anchorEl?.id === row?.row?.original?.id ? (
                              <Box className="popupBlock">
                                  <Box className="tooltip-chip-cont">
                                      {row?.getValue()?.map((tag, index): React.JSX.Element => 
                                      {
                                          return (
                                              <MuiChip chipColor={icpChipColor} key={index} label={tag} />
                                          );
                                      })}
                                  </Box>
                              </Box>
                          ) : ( */}
              <Tooltip disableInteractive placement='top' title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                <Box>
                  {row?.getValue()?.length > 0 ? (
                    row?.getValue()?.length > 1 ? (
                      <Box className="table-chip-cell">
                        <MuiChip chipColor={icpChipColor} label={row?.getValue()[0]} />
                        <p className="table-chip-count">
                          +{row?.getValue()?.length - 1}
                        </p>
                      </Box>
                    ) : (
                      <MuiChip chipColor={icpChipColor} label={row?.getValue()[0]} />
                    )
                  ) : null}
                </Box>
              </Tooltip>
              {/* )} */}
            </Box>
          );
        },
        header: _.startCase(item.headerName),
        size: 200,
        enableSorting: false,
        id: item?.field === 'icp' ? 'icp' : 'buyerPersona',
      }));
    }
    else if (item?.clearbit) {
      columns.push(
        columnHelper.accessor((row) => {
          const speakerRow = row as Speaker;
          return speakerRow?.clearbitData && getNestedValue(speakerRow?.clearbitData, item?.field);
        },
          {
            id: `clearbitData.${item?.field}`,
            cell: (row) => {
              return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{row?.getValue()}</Typography>}><Box maxWidth={200} className="cellContent">{row?.getValue()}</Box></Tooltip>;
            },
            header: () => (
              <Tooltip arrow placement="bottom" title="Enriched by Clearbit">
                <div className="clearbit-column-header">
                  <span className="header-name">{_.startCase(item.headerName)}</span>
                  <Box component="img" src={'https://www.google.com/s2/favicons?sz=64&domain_url=https://clearbit.com/'} className="clearbit-logo" />
                </div>
              </Tooltip>
            ),
            meta: {
              headerName: _.startCase(item.headerName)
            },
            size: 200,
            enableResizing: true,
            maxSize: 300,
            sortingFn: 'alphanumericCaseSensitive',
          })
      );
    }
  });

  const filteredColumns = columns.filter((column): boolean => {
    return !excludedFields.includes(column.field);
  });

  return filteredColumns;
};

const getNestedValue = (obj: Record<string, any>, path: string): any => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};