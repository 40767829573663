import { Col, Row } from "react-bootstrap";
import { convertToArrayOfObjectsForPieChart, NumberFormatter } from "../../../../../../scripts/helpers";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";
import CardComponent from "../../../../../../components/Events/Registrations/CardComponent";
import MuiChip from "../../../../../../common/FormComponents/MuiChip";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { GraphComponent } from "../Common/GraphComponent";

import './styles.scss';
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";
import { constructObjectForPieChart } from "../../../../../../common/ECharts/helpers/charts.helper";

interface OpportunitiesProps {
    opportunitiesOverview: IRevenueOverview;
    graphReady: IRevenueGraphReady;
}

const Opportunities: React.FC<OpportunitiesProps> = (props): React.JSX.Element =>
{

    const { opportunitiesOverview, graphReady } = props;

    const opportunitiesOverviewData = [
        {
            name: 'Open Opportunities',
            value: opportunitiesOverview?.opportunitiesOverview?.openOpportunitiesCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.openOpportunitiesCount) || '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.openOpportunities && Number(opportunitiesOverview?.opportunitiesOverview?.openOpportunities) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.openOpportunities)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'New Opportunities',
            value: opportunitiesOverview?.opportunitiesOverview?.newOpportunitiesCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.newOpportunitiesCount) || '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.newOpportunities && Number(opportunitiesOverview?.opportunitiesOverview?.newOpportunities) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.newOpportunities)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'Influenced Opportunities',
            value: opportunitiesOverview?.opportunitiesOverview?.influencedOpportunitiesCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.influencedOpportunitiesCount) || '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.influencedOpportunities && Number(opportunitiesOverview?.opportunitiesOverview?.influencedOpportunities) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.influencedOpportunities)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'Projected Revenue',
            value: opportunitiesOverview?.opportunitiesOverview?.projectedRevenueCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.projectedRevenueCount) || '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.projectedRevenue && Number(opportunitiesOverview?.opportunitiesOverview?.projectedRevenue) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.projectedRevenue)}` : '-',
            chipColor: 'grey'
        }
    ];

    const opportunitiesStageData = convertObjectToArrays(opportunitiesOverview?.opportunitiesStages);
    const opportunitiesStageOption = generateOpenOpportunitiesGraphOption(opportunitiesStageData as any);

    const icpGraphData = convertToArrayOfObjectsForPieChart(opportunitiesOverview?.opportunitiesICP as any);
    const icpGraphOption = constructObjectForPieChart(icpGraphData, ['50%', '72%'], undefined, undefined);

    const bpGraphData = convertToArrayOfObjectsForPieChart(opportunitiesOverview?.opportunitesBuyerPersona as any);
    const bpGraphOption = constructObjectForPieChart(bpGraphData, ['50%', '72%'], undefined, undefined);

    return (
        <div id="revenueOpportunitiesComponent">
            <AnalyticsPageHeader 
                header="Opportunities"
                description="Understand your deal performance and win rates"
            />

            <div className="content-div">

                {/* Row to render the cards related to Oppotunities overview */}
                <Row className="custom-row">
                    {
                        opportunitiesOverviewData.map((info, index) => 
                        {
                            return (
                                <Col className="px-0" key={index}>
                                    <CardComponent 
                                        header={info.name}
                                        footerLeft={info.value}
                                        footerStringBoldLeft
                                        footerRight={info.chipLabel && <MuiChip label={info.chipLabel} chipColor={info.chipColor as 'grey'} />}
                                        cardMinHeight="82px"
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>

                {/* Row to render graphs related to opportunities stage and icp / bp breakdown */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent 
                            title="Opportunities Stage"
                            chartOption={opportunitiesStageOption}
                            height='240px'
                            graphReady={graphReady?.opportunitiesStage}
                            isDataAvailable={opportunitiesStageData && opportunitiesStageData[1]?.length > 0 && opportunitiesStageData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>
                    <Col className="px-0">
                        <Row className="custom-row">
                            <Col className="px-0">
                                <GraphComponent 
                                    title="ICP Breakdown"
                                    chartOption={icpGraphOption}
                                    graphReady={graphReady?.opportunitiesICP}
                                    isDataAvailable={icpGraphData && icpGraphData?.length > 0}
                                    height='240px'
                                />
                            </Col>
                            <Col className="px-0">
                                <GraphComponent 
                                    title="Buyer Persona Breakdown"
                                    chartOption={bpGraphOption}
                                    graphReady={graphReady?.opportunitiesBuyerPersona}
                                    isDataAvailable={bpGraphData && bpGraphData?.length > 0}
                                    height='240px'
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default Opportunities;