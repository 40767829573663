import { ITransactionalMarketingPayload, MarketingPayload } from '../../components/Marketing/IMarketingComponent';
import { EventMarketing, EventMarketingEmailBody } from '../../pages/Events/interfaces';
import { EventMarketingType } from '../../pages/Events/enum';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const getAllEventMarketingCount = async (eventId: string | number): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventMarketing = async (pageSize: number, offset: number, eventId: string | number, type?: number): Promise<EventMarketing[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '?&pageSize=' + pageSize + '&offset=' + offset + '&type=' + type;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingById = async (eventId: string | number, marketingId: string | number): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId;
    return triggerApi(API_URL, 'GET', '');
};

export const testMarketingEmail = async (eventId: string | number, marketingMail: EventMarketingEmailBody, csrfToken: string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/marketing/test';
    return triggerApi(API_URL, 'POST', marketingMail, csrfToken);
};

export const createMarketing = async (data: MarketingPayload, eventId: string | number, csrfToken: string): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/marketing';
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

export const updateMarketing = async (data: MarketingPayload, eventId: string | number, marketingId: string | number): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId + '/marketing';
    return triggerApi(API_URL, 'PUT', data);
};

export const updateTransactionalMarketing = async (data: ITransactionalMarketingPayload, eventId: string | number, marketingId: string | number): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId + '/transactional';
    return triggerApi(API_URL, 'PUT', data);
};

export const deleteMarketing = async (marketingId: string | number, eventId: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId + '/marketing';
    return triggerApi(API_URL, 'DELETE', '');
};

// export const getSubEventMarketing = async (marketingId: string | number) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/sub/' + marketingId;
//     return triggerApi(API_URL, 'GET', '');
// };

// export const createSubMarketing = async (data: SubMarketingPostReqPayload, marketingId: string | number, csrfToken: string) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/sub/' + marketingId;
//     return triggerApi(API_URL, 'POST', data, csrfToken);
// };

// export const updateSubMarketing = async (data: SubMarketingPostReqPayload, marketingId: string | number, subMarketingId: string | number,) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/sub/' + marketingId + '/' + subMarketingId;
//     return triggerApi(API_URL, 'PUT', data);
// };

// export const deleteSubMarketing = async (marketingId: string | number, subMarketingId: string | number) => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/sub/' + marketingId + '/' + subMarketingId;
//     return triggerApi(API_URL, 'DELETE', '');
// };

export const getEventMarketingByType = async (eventId: string | number, emailType: EventMarketingType): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '?&pageSize=' + 10 + '&offset=' + 0 +'&type=' + emailType;
    return triggerApi(API_URL, 'GET', '');
};