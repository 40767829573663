import { useState } from "react";
import FormTabs from "../../common/FormTabs";
import TableFilter from "../../common/TanstackTable/TableFilter";
import ColumnVisibilityDropdown from "../../common/TanstackTable/ColumnVisibilityDropdown";
import { Budget } from "../../pages/Budget/interfaces";

import './styles.scss';

const BudgetsPageToolbar: React.FC<{ count: number, rows: Budget[], setRows: React.SetStateAction<React.Dispatch<Budget[]>>, columns: any[], setColumns: React.SetStateAction<React.Dispatch<any[]>> }> = ({ setRows, count, rows, columns, setColumns }): React.JSX.Element =>
{
    const columnOptions = columns
        .filter(column => column.id !== 'actions')
        .map(column => {
            return {
                label: typeof(column?.header) === 'string' ? column?.header : column?.meta?.headerName,
                value: column?.accessorKey || column?.id
            } 
        }
    );

    const [filters, setFilters] = useState([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].inputValue = value;
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const handleColumnChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].selectedColumn = value;
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const addFilter = () => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        const availableColumns = columnOptions.filter(option => !selectedColumns.includes(option.value));
        const defaultColumn = availableColumns.length > 0 ? availableColumns[0].value : '';
        setFilters([...filters, { selectedColumn: defaultColumn, inputValue: '' }]);
    };

    const removeFilter = (index: number) => 
    {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const clearFilters = () => 
    {
        setFilters([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
        // filterFn([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
    };

    const getBudgetValue = (budget: Budget) => {
        return Number(budget?.budget) || '';
    };

    const getExpenseValue = (budget: Budget) => { 
        return Number(budget?.actualSpend) || '';
    };

    const getBudgetAnalysis = (budget: Budget) => { 
        const budgetValue = Number(budget?.budget);
        const expense = Number(budget?.actualSpend);

        if(expense > budgetValue) return 'Overspend';
        if(expense < budgetValue) return 'Underspend';
        if(budgetValue === expense) return 'In Budget';
    };

    const customFilterFunctions = {
        budget: (obj: any, inputValue: string | number) => {
            const budget = getBudgetValue(obj);
            return budget?.toString()?.includes(inputValue?.toString()) || false;
        },
        actualSpend: (obj: any, inputValue: string | number) => {
            const expense = getExpenseValue(obj);
            return expense?.toString()?.includes(inputValue?.toString()) || false;
        },
        analysis: (obj: any, inputValue: string | number) => { 
            const analysis = getBudgetAnalysis(obj);
            return analysis?.toString()?.toLowerCase()?.includes(inputValue?.toString()?.toLowerCase()) || false;
        }
    };

    return (
        <div className="budgets-page-toolbar">
            <FormTabs 
                tabs={[{
                    tabName: 'All',
                    count: count,
                    navigation: () => {},
                    selectedTab: true,
                }]}
            />
            <div className="toolbar-actions">
                <TableFilter 
                    rows={rows}
                    columnOptions={columnOptions}
                    setRows={setRows}
                    filters={filters}
                    handleColumnChange={handleColumnChange}
                    handleInputChange={handleInputChange}
                    // filterFn={() => filterFn(filters)}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    clearFilters={clearFilters}
                    customFilterFunctions={customFilterFunctions}
                />
                <ColumnVisibilityDropdown 
                    columns={columns}
                    setColumns={setColumns}
                />
            </div>
        </div>
    )
};

export default BudgetsPageToolbar;