import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { EventSession } from '../../interfaces';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import AddSession from '../../../../components/Events/Agenda/AddSession';
import StageDataGrid from './StageDataGrid';
import { Speaker } from '../../../Speakers/interfaces';
import moment from 'moment';
import { ActionsDropdown } from '../../EventBudget/EventBudgetDataGrid';
import AddStage from '../../../../components/Events/Agenda/AddStage';
import './styles.scss';
import { EventStageContext } from '../../../../contexts/EventStage/EventStageContext';
import { CONTENT } from '../../../../scripts/i18n';

interface Stage {
    id: string;
    title: string;
    description: string;
    startDateTime: number;
    endDateTime: number;
    maximumParticipants: number;
    sessions: EventSession[];
}

interface StageAccordianProps {
    stages: Stage[];
    eventId: string | number;
    eventSpeakersList: Speaker[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    selectedDate: string;
}

const StageAccordian: React.FC<StageAccordianProps> = ({ stages, eventId, setRefresh, selectedDate }) => {
    
    const { deleteStageFromTable } = useContext(EventStageContext);

    const [expanded, setExpanded] = useState<Record<string, boolean>>({});

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(prevExpanded => ({ ...prevExpanded, [panel]: isExpanded }));
    };

    const openSessionSidedrawer = (stage: Stage, stageId: number | string) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Session',
            component: <AddSession eventId={eventId} stageData={stage} stageId={stageId} setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    
    const handleEditStage = (stage: Stage) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit Stage',
            component: <AddStage eventId={eventId} stageId={stage.id} setRefresh={setRefresh} stageData={stage} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const StageActions = (stage: Stage): React.ReactElement =>
    {
        const editClick = (): void =>
        {
            handleEditStage(stage);
        };

        const deleteClick = (): void =>
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
                open: true,
                title: 'Delete Stage ?',
                content: `Are you sure you want to delete ${stage.title} ?`,
                acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
                acceptEvent: 'delete-event-stage',
                rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
                acceptObj: {
                    id: stage.id
                },
            });
    
            deleteStageFromTable();
            
        }

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    useEffect(() => {
        if(stages && stages?.length === 0) return;
        const initialExpandedState = stages?.reduce((acc, stage) => {
            acc[stage.id] = true;
            return acc;
        }, {} as Record<string, boolean>);
        setExpanded(initialExpandedState);
    }, [stages]);

    return (
        <div id="stageAccordian">
            {stages
            ?.filter(stage => {
                const stageDate = moment.unix(stage?.startDateTime).format('DD MMM');
                return stageDate === selectedDate;
            })
            ?.map((item) => (
                <Accordion expanded={expanded[item.id] === true} onChange={handleChange(item.id)} key={item.id} className="agenda-accordian">
                    <AccordionSummary
                        aria-controls={`panel${item.id}-content`}
                        id={`panel${item.id}-header`}
                        className="stage-accoridan-summary"
                    >
                        <Box className="stage-header-cont">
                            <Box className="header-expand-cont">
                                {expanded[item.id] === true ? <FontAwesomeIcon style={{ pointerEvents: 'auto' }} icon={['fal', 'chevron-down']} /> : <FontAwesomeIcon style={{ pointerEvents: 'auto' }} icon={['fal', 'chevron-right']} />}
                                <Typography className="stage-accordian-title">{item.title}</Typography>
                            </Box>
                            <CustomButton onClick={(event) => { event.stopPropagation(); openSessionSidedrawer(item, item.id)}} style={{ border: 'unset', background: 'var(--colors-gray-light-mode-25, #FCFCFD)', boxShadow: 'unset', color: 'var(--Component-colors-Components-Buttons-Tertiary-button-tertiary-fg, #475467)' }} btnType='secondary' type='button' name='Add Session' startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />} />
                        </Box>
                        <Box className="stage-actions-button">
                            {StageActions(item)}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ flex: 1, height: '100%' }}>
                        <StageDataGrid eventId={eventId} rows={item.sessions} setRefresh={setRefresh} stageData={item} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default StageAccordian;