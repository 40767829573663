import { Col, Row } from "react-bootstrap";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";

import './styles.scss';
import { NumberFormatter } from "../../../../../../scripts/helpers";
import CardComponent from "../../../../../../components/Events/Registrations/CardComponent";
import MuiChip from "../../../../../../common/FormComponents/MuiChip";
import { GraphComponent } from "../Common/GraphComponent";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";

interface RevenueOverviewProps {
    revenueOverviewData: IRevenueOverview;
    revenueOverviewDataReady: boolean;
    graphReady: IRevenueGraphReady;
}

const RevenueOverview: React.FC<RevenueOverviewProps> = (props): React.JSX.Element =>
{

    const { revenueOverviewData, revenueOverviewDataReady, graphReady } = props;

    const pipelineInfo = [
        {
            name: 'Pipeline',
            value: revenueOverviewData?.revenueOverview?.pipelineDealCount && NumberFormatter(revenueOverviewData?.revenueOverview?.pipelineDealCount) || '-',
            chipLabel: revenueOverviewData?.revenueOverview?.pipleline && Number(revenueOverviewData.revenueOverview.pipleline) > 0 ? `$${NumberFormatter(revenueOverviewData.revenueOverview.pipleline)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'New Pipeline',
            value: revenueOverviewData?.revenueOverview?.newPipelineDealCount && NumberFormatter(revenueOverviewData?.revenueOverview?.newPipelineDealCount) || '-',
            chipLabel: revenueOverviewData?.revenueOverview?.newPipeline && Number(revenueOverviewData.revenueOverview.newPipeline) > 0 ? `$${NumberFormatter(revenueOverviewData.revenueOverview.newPipeline)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'Influenced Pipeline',
            value: revenueOverviewData?.revenueOverview?.influencedPipelineDealCount && NumberFormatter(revenueOverviewData?.revenueOverview?.influencedPipelineDealCount) || '-',
            chipLabel: revenueOverviewData?.revenueOverview?.influencedPipeline && Number(revenueOverviewData.revenueOverview.influencedPipeline) > 0 ? `$${NumberFormatter(revenueOverviewData.revenueOverview.influencedPipeline)}` : '-',
            chipColor: 'grey'
        }
    ];

    const registrationInfo = [
        {
            name: 'Registration',
            value: revenueOverviewData?.revenueOverview?.registration && Number(revenueOverviewData.revenueOverview.registration) > 0 ? `${NumberFormatter(revenueOverviewData.revenueOverview.registration)}` : '-',
        },
        {
            name: 'Attendees',
            value: revenueOverviewData?.revenueOverview?.attendees && Number(revenueOverviewData.revenueOverview.attendees) > 0 ? `${NumberFormatter(revenueOverviewData.revenueOverview.attendees)}` : '-',
        },
        {
            name: 'Open Opportunities',
            value: revenueOverviewData?.revenueOverview?.openOpportunitiesCount && Number(revenueOverviewData.revenueOverview.openOpportunitiesCount) > 0 ? `$${NumberFormatter(revenueOverviewData.revenueOverview.openOpportunitiesCount)}` : '-',
            chipLabel: revenueOverviewData?.revenueOverview?.openOpportunities && Number(revenueOverviewData.revenueOverview.openOpportunities) > 0 ? `${NumberFormatter(revenueOverviewData.revenueOverview.openOpportunities)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'Closed Lost',
            value: revenueOverviewData?.revenueOverview?.closedLostCount && Number(revenueOverviewData.revenueOverview.closedLostCount) > 0 ? `$${NumberFormatter(revenueOverviewData.revenueOverview.closedLostCount)}` : '-',
            chipLabel: revenueOverviewData?.revenueOverview?.closedLost && Number(revenueOverviewData.revenueOverview.closedLost) > 0 ? `${NumberFormatter(revenueOverviewData.revenueOverview.closedLost)}` : '-',
            chipColor: 'grey'
        },
        {
            name: 'Closed Won',
            value: revenueOverviewData?.revenueOverview?.closedWonCount && Number(revenueOverviewData.revenueOverview.closedWonCount) > 0 ? `$${NumberFormatter(revenueOverviewData.revenueOverview.closedWonCount)}` : '-',
            chipLabel: revenueOverviewData?.revenueOverview?.closedWon && Number(revenueOverviewData.revenueOverview.closedWon) > 0 ? `${NumberFormatter(revenueOverviewData.revenueOverview.closedWon)}` : '-',
            chipColor: 'green'
        }
    ];

    const openStagesData = convertObjectToArrays(revenueOverviewData?.openOpportunitiesStages);
    const leadStagesData = convertObjectToArrays(revenueOverviewData?.leadStages);

    const openOpportunitiesGraphOption = generateOpenOpportunitiesGraphOption(openStagesData as any);

    const leadStageGraphOption = generateOpenOpportunitiesGraphOption(leadStagesData as any);    

    return (
        <div id="revenueOverviewComponent">
            <AnalyticsPageHeader 
                header='Revenue Overview'
                description='Get to understand the RoI of your event'
            />

            <div className="content-div">
                {/* Cards having data related to Pipeline */}
                <Row className="custom-row">
                    {
                        pipelineInfo?.map((info, index) => {
                            return (
                                <Col className="px-0" key={index} >
                                    <CardComponent 
                                        header={info.name}
                                        footerLeft={info.value}
                                        footerRight={<MuiChip label={info.chipLabel} chipColor={info.chipColor as 'grey'} />}
                                        footerStringBoldLeft
                                        cardMinHeight="82px"
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>  

                {/* Cards having data related to registrations */}
                <Row className="custom-row">
                    {
                        registrationInfo?.map((info, index) => {
                            return (
                                <Col className="px-0" key={index}>
                                    <CardComponent 
                                        header={info.name}
                                        footerLeft={info.value}
                                        footerStringBoldLeft
                                        footerRight={info.chipLabel && <MuiChip label={info.chipLabel} chipColor={info.chipColor as 'grey'} />}
                                        cardMinHeight="82px"
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>

                {/* Open opportunities stages and lead stages graphs */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent
                            graphReady={graphReady?.openStages}
                            isDataAvailable={openStagesData[1]?.length > 0 && openStagesData[1]?.some((item: number | string) => item !== 0)} 
                            chartOption={openOpportunitiesGraphOption}
                            height='240px'
                            title="Open Opportunities Stages"
                        />
                    </Col>
                    <Col>
                        <GraphComponent
                            graphReady={graphReady?.leadStages}
                            isDataAvailable={leadStagesData[1]?.length > 0 && leadStagesData[1]?.some((item: number | string) => item !== 0)} 
                            chartOption={leadStageGraphOption}
                            height='240px'
                            title="Lead Stages"
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default RevenueOverview;