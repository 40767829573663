import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import eventBus from '../scripts/event-bus';
import CheckboxWrapper from './CheckboxWrapper';
import APP_CONSTANTS from '../scripts/constants';
// eslint-disable-next-line import/named
import { DataGridPro, GridColDef, GridColumnVisibilityModel, GridRowId, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridCellModes, GridCellModesModel, GridCellParams, useGridApiContext, useGridSelector, gridPageSelector, gridPageSizeSelector } from '@mui/x-data-grid-pro';
import CustomSpinner from './CustomSpinner';
import { ITableDataGrid } from './IColumnFilterDataGrid';
import { useCSVDownloader } from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import FormTabs from './FormTabs';
import { useLocation } from 'react-router-dom';
import DataGridtabs from './DateGridTabs/DataGridTabs';
import { CustomButton } from './FormComponents/Buttons';
import { useDispatch } from 'react-redux';
import { addPagination } from '../redux/paginationData';
import { useSelector } from 'react-redux';
//import { calculateContainerHeightForAudience } from '../pages/Audience/AudienceGraph';

/**
 * @returns
 * Functional component to render a datagrid
 * Variable to store the props data of the rows
 * Variable to store the props data of the columns
 * Variable to store the props data of the row click event string
 * Variable to store the initial state of the loader in the data grid
 * Variable to store the state of the selected rows using checkboxes
 * Variable to store the state of the selected rows while clicking the grid cells
 * Variable to store the column fields
 * Function to handle cell click of the data grid
 */

const ColumnFilterDataGrid = (props: ITableDataGrid): React.JSX.Element => 
{

    const location = useLocation().pathname;

    const rows = props.rows;
    const columns = props.columns;
    const eventBusConst = props.rowClickEvent;
    const gridHeight = props.height;
    const isEmpty = props.isEmpty;
    const rowCount = (!isEmpty && props.rowCount) && Number(props.rowCount);

    const isPaginationEnabled = props.isPagination ? props.isPagination : false;
    const [visibleColumns, setVisibleColumns] = useState({
    });
    const [tableHeight, setTableHeight] = useState(0);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(null);
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({
    });
    const [saveData, isDataSaved] = useState(false);
    const [rowsUpdated, setRowsUpdated] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [cellUpdatedCount, setCellUpdatedCount] = useState(0);
    const [cellUpdated, setCellUpdated] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    const tableContainerRef = useRef(null);

    const { CSVDownloader } = useCSVDownloader();

    useEffect((): () => void => 
    {
        const handleResize = (): void => 
        {
            if(props?.customDefaultPageSize)
            {
                props.updatePageSize(props?.customDefaultPageSize);
            }
            else 
            {
                props.updatePageSize(25);
            }

            setWindowHeight(window.innerHeight);
            if (props.fullHeight) 
            {
                if (tableContainerRef.current) 
                {
                    const tableHeight = tableContainerRef.current.clientHeight;
                    setTableHeight(tableHeight);
                    // if (rowCount > 10) {
                    //     setTableHeight(tableHeight - 49);
                    // } else {
                    //     setTableHeight(tableHeight - 12);
                    // }
                }
            }
            else if (props?.eventsModule) 
            {
                const tableHeight = tableContainerRef.current.clientHeight;
                setTableHeight(tableHeight);
            }
            else 
            {
                const tableHeight = tableContainerRef.current.clientHeight;
                setTableHeight(tableHeight);
                // setTableHeight(calculateTableContainerHeightForAudience({ windowHeight: windowHeight }));
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return (): void => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowHeight, rowCount, tableContainerRef]);

    useEffect((): void => 
    {
        if (isEditing) 
        {
            setTableHeight(tableHeight - 64);
        }
        if (isEditing === false) 
        {
            setTableHeight(tableHeight + 64);
        }

    }, [isEditing]);


    useEffect((): void => 
    {
        if (props.columns.length > 0) 
        {
            props.columns.forEach((element): void => 
            {
                if (element?.hide) 
                {
                    if (element.field) 
                    {
                        setVisibleColumns((prevColumns): object => 
                        {
                            return {
                                ...prevColumns,
                                [element.field]: false
                            };
                        });
                    }
                }
            });
        }
    }, [props.columns]);

    useEffect((): void => 
    {
        eventBus.on('selected-row-id', (rowId): void => 
        {
            if (rowId) 
            {
                setSelectionModel([rowId]);
            }
            else 
            {
                setSelectionModel([]);
            }
        });
    }, []);

    useEffect(() => {
        setCurrentPage(0);
    }, [location.pathname]);


    useEffect((): void => 
    {
        // const handleFocusOut = (event) => {
        //     if (event.relatedTarget == null) {
        //         setIsEditing(false);
        //     }
        // };

        // if (isEditing) {
        //     document.addEventListener('focusout', handleFocusOut);
        // }

        // return () => {
        //     document.removeEventListener('focusout', handleFocusOut);
        // };
        if (rowsUpdated) 
        {
            setIsEditing(true);
        }
        else 
        {
            setIsEditing(false);
        }
        eventBus.on('table-selected-tab', (data): void => 
        {
            setSelectedTab(data);
        });

    }, [rowsUpdated]);

    const handleCellClick = (event): void => 
    {
        // setSelectedRowsData(event.row);
        if (Object.keys(event.row).length > 0) 
        {
            eventBus.dispatch(eventBusConst, {
                rowData: event.row,
            });
        }
    };

    const handleCellClickForEdit = React.useCallback(
        (params: GridCellParams, event: React.MouseEvent): void => 
        {
            if (!params.isEditable) 
            {
                return;
            }

            if (props.editAction) 
            {
                if (params.colDef.editable) 
                {
                    setIsEditing(true);
                }
            }

            if (
                (event.target as HTMLDivElement).nodeType === 1 &&
                !event.currentTarget.contains(event.target as Element)
            ) 
            {
                return;
            }

            setCellModesModel((prevModel: GridCellModesModel): GridCellModesModel => 
            {
                return {
                    ...Object.keys(prevModel).reduce(
                        (acc, id: string): object => 
                        {
                            return {
                                ...acc,
                                [id]: Object.keys(prevModel[id]).reduce(
                                    (acc2, field: string): object => 
                                    {
                                        return {
                                            ...acc2,
                                            [field]: {
                                                mode: GridCellModes.View
                                            },
                                        };
                                    },
                                    {
                                    },
                                ),
                            };
                        },
                        {
                        },
                    ),
                    [params.id]: {
                        ...Object.keys(prevModel[params.id] || {
                        }).reduce(
                            (acc, field: string): object => 
                            {
                                return {
                                    ...acc, [field]: {
                                        mode: GridCellModes.View
                                    }
                                };
                            },
                            {
                            },
                        ),
                        [params.field]: {
                            mode: GridCellModes.Edit
                        },
                    },
                };
            });
        },
        [],
    );

    const handleCellModesModelChange = React.useCallback(
        (newModel: GridCellModesModel): void => 
        {
            setCellModesModel(newModel);
        },
        [],
    );

    useEffect((): void => 
    {
        if (cellUpdated) 
        {
            setCellUpdatedCount(cellUpdatedCount + 1);
            setCellUpdated(false);
        }
    }, [cellUpdated]);


    const processRowUpdate = useCallback(
        (newRow, oldRow): object => 
        {
            setRowsUpdated((prev): object => 
            {
                const updateRows = {
                    ...prev
                };
                updateRows[newRow.id] = newRow;
                return updateRows;
            });
            if (!(JSON.stringify(oldRow) === JSON.stringify(newRow))) 
            {
                setCellUpdated(true);
            }
            return newRow;
        }, [],
    );

    const handleSaveClick = (): void => 
    {
        const updatedRowIds = Object.keys(rowsUpdated);

        if (updatedRowIds.length > 0) 
        {
            Object.keys(rowsUpdated).forEach((key): void => 
            {
                const updatedRow = rowsUpdated[key];
                setSpinner(true);
                props.editAction(updatedRow).then((): void => 
                {
                    setSpinner(false);
                    setCellUpdatedCount(0);
                    setIsEditing(false);
                    delete rowsUpdated[key];
                }).catch((error): void => 
                {
                    setSpinner(false);
                    setIsEditing(false);
                    setCellUpdatedCount(0);
                    props.setRefresh(true);
                    console.log(error);
                });
            });
        }
        else 
        {
            setIsEditing(false);
        }
    };

    const handleCancelClick = (): void => 
    {
        if (!saveData) 
        {
            props.setRefresh(true);
            setIsEditing(false);
            isDataSaved(false);
            setCellUpdatedCount(0);
        }
        else 
        {
            isDataSaved(false);
        }
    };

    const CustomToolbar = (): React.JSX.Element => 
    {
        return (
            <Box className="datagrid-toolbar-container">
                {/* <Box className="recordsText">
                    {rowCount} {rowCount > 1 ? 'Records' : 'Record'}
                </Box> */}
                <div>
                    {props?.datagridTabsData && props?.datagridTabsData?.tabs?.length > 0 ? <FormTabs tabs={props?.datagridTabsData?.tabs} /> : <DataGridtabs dataLength={props?.dataCount} selectedTab={selectedTab} />}
                    {/* {props?.datagridTabsData && props?.datagridTabsData?.tabs?.length > 0 ? <DataGridtabs width={props?.tabsWidth} tabsData={props?.datagridTabsData} selectedTab={selectedTab} /> : <DataGridtabs dataLength={props?.dataCount} selectedTab={selectedTab} />} */}
                </div>
                <GridToolbarContainer>
                    {props?.enableExport && props?.allData?.length > 0 && <CSVDownloader filename={'registrants'} bom={true} config={{
                        delimiter: ';',
                    }} data={props?.allData}>{props?.exportButton}</CSVDownloader>}
                    <GridToolbarFilterButton className="toolbar-columns-button" />
                    <GridToolbarColumnsButton className="toolbar-columns-button" />
                </GridToolbarContainer>
            </Box>
        );
    };

    const getTogglableColumns = (columns: GridColDef[]): string[] => 
    {
        if (props?.hiddenFields) 
        {
            return columns
                .filter((column): boolean => 
                {
                    return !props?.hiddenFields.includes(column.field);
                })
                .map((column: GridColDef): string => 
                {
                    return column.field;
                });
        }
    };

    function CustomPagination(): React.JSX.Element 
    {

        const dispatch = useDispatch();

        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

        const handlePageSizeChange = (event): void =>
        {
            apiRef.current.setPageSize(Number(event.target.value));
            dispatch(addPagination({
                registrants: {
                    // page: page+1,
                    pageSize: Number(event.target.value),
                }
            }))
        };

        const pageSizeOptions = [
            {
                name: '25', value: 25,
            },
            {
                name: '50', value: 50,
            },
            {
                name: '75', value: 75,
            },
            {
                name: '100', value: 100,
            },
            {
                name: 'All', value: rowCount,
            }
        ]

        return (
            <Box className="pagination-container">
                <Box className="page-indicator-component">
                    <Typography>{`Page ${page+1} of ${isPaginationEnabled ? Math.ceil(rowCount / props.pageSize) : Math.ceil(rows.length / 5)}`}</Typography>
                </Box>
                <CustomPaginationComponent paginationCount={isPaginationEnabled ? Math.ceil(rowCount / props.pageSize) : Math.ceil(rows.length / 5)} selectedPage={page + 1} onChangeFunc={(value: number): void => 
                {
                    return apiRef.current.setPage(value - 1);
                }
                } />
                <Select IconComponent={(props): React.JSX.Element => 
                {
                    return (
                        <FontAwesomeIcon {...props} icon={['fal', 'chevron-down']} className={`material-icons ${props.className}`} />
                    ); 
                }} value={pageSize ? pageSize : 25} onChange={handlePageSizeChange} className="pagesize-select" defaultValue={25} renderValue={(selected) =>
                {
                    return(
                        <Box>
                            <Typography className="selected-pagesize-text">{`${selected} Per Page`}</Typography>
                        </Box>
                    )
                }}>
                    {pageSizeOptions?.map((elem, index) => {
                        return (
                            <MenuItem key={index} value={elem.value}>{elem.name}</MenuItem>
                        )
                    })}
                </Select>  
            </Box>
        );
    }

    useEffect(() => {
        eventBus.on('selected-row-ids', (ids) => {
            setSelectionModel(ids);
        })
    }, [selectionModel]);

    return (
        <Box id="audTableDataGrid" ref={tableContainerRef}>
            {(!isEmpty || props?.datagridTabsData?.tabs || props?.customToolbar) && <Box height={gridHeight} className="grid-box" >
                {((rows.length > 0 || rows?.length === 0 || props?.datagridTabsData?.tabs) && !spinner) ?
                    <div className="datagridContainer" style={{
                        maxHeight: tableHeight ? `${tableHeight}px` : '100%', overflowY: 'auto'
                    }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            rowHeight={52}
                            rowCount={isPaginationEnabled ? rowCount : rows.length}
                            // style={{ height: "400px", overflow: "scroll", borderRadius: "10px" }}
                            // keepNonExistentRowsSelected={true}
                            disableRowSelectionOnClick={true}
                            disableColumnResize={false}
                            columnVisibilityModel={visibleColumns}
                            pagination={true}
                            paginationModel={{
                                page: currentPage, pageSize: isPaginationEnabled ? props.pageSize : 5
                            }}
                            paginationMode={isPaginationEnabled ? 'server' : 'client'}
                            onPaginationModelChange={(paginationModel): void => 
                            {
                                props.updateCurrentPage(paginationModel.page + 1);
                                setCurrentPage(paginationModel.page);
                                props.updatePageSize(paginationModel.pageSize);
                            }}
                            processRowUpdate={processRowUpdate}
                            onCellModesModelChange={handleCellModesModelChange}
                            cellModesModel={cellModesModel}
                            onProcessRowUpdateError={(error): void => 
                            {
                                console.log(error);
                            }}
                            rowSelectionModel={selectionModel}
                            onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel): void => 
                            {
                                eventBus.dispatch('datagrid-selected-row-ids', rowSelectionModel);
                                setSelectionModel(rowSelectionModel);
                            }
                            }
                            loading={props.showSpinner ? props.showSpinner : false}
                            initialState={{
                                pinnedColumns: {
                                    left: props.pinnedColumns, right: props?.rightPinnedColumns
                                },
                            }}
                            checkboxSelection={props?.checkbox}
                            slots={{
                                baseCheckbox: CheckboxWrapper,
                                loadingOverlay: (): React.JSX.Element => 
                                {
                                    return CustomSpinner({
                                        height: '100%'
                                    });
                                },
                                toolbar: props.showColumnFilter ? props?.customToolbar ? props?.customToolbar : CustomToolbar : null,
                                pagination: props?.hidePagination ? null : CustomPagination,
                            }}
                            slotProps={{
                                panel: {
                                    sx:
                                    {
                                        // "& .MuiInputLabel-shrink": { visibility: "hidden", display: 'none' },
                                        '& .MuiInput-root.Mui-focused:after': {
                                            borderBottom: 'unset'
                                        },
                                        '& .MuiInput-root:after': {
                                            borderBottom: 'none !important'
                                        },
                                        '& .MuiInput-root:before': {
                                            borderBottom: 'none !important'
                                        },
                                        // "& .MuiInputLabel-animated": { display: 'none' },
                                    },
                                    placement: 'bottom-end'
                                },
                                columnsPanel: {
                                    getTogglableColumns,
                                },
                            }}
                            // onCellClick={(event) => {
                            //     if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(event.field)) {
                            //         return handleCellClick(event);
                            //     }
                            // }}
                            onCellClick={(params, event): void => 
                            {
                                if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field) && !params.isEditable) 
                                {
                                    return handleCellClick(params);
                                }
                                else 
                                {
                                    if (props.editAction) 
                                    {
                                        handleCellClickForEdit(params, event);
                                    }
                                    else 
                                    {
                                        if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field)) 
                                        {
                                            return handleCellClick(params);
                                        }
                                    }
                                }
                            }}
                            hideFooter={false}
                            onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel): void => 
                            {
                                return setVisibleColumns(newModel);
                            }
                            }
                            pageSizeOptions={[{
                                value: 25, label: '25 per page'
                            }, {
                                value: 50, label: '50 per page'
                            }, {
                                value: 75, label: '75 per page'
                            }, {
                                value: 100, label: '100 per page'
                            }]}
                        />
                    </div>
                    : <CustomSpinner height={'100%'} />}
            </Box>}
            {isEditing && (
                <BottomDrawer
                    handleCancelClick={handleCancelClick}
                    handleSaveClick={handleSaveClick}
                    width={'100%'}
                    left={null}
                    cellUpdatedCount={cellUpdatedCount}
                />
            )}
        </Box>
    );
};

export default ColumnFilterDataGrid;


const BottomDrawer = (props: { handleSaveClick: () => void, handleCancelClick: () => void, width: string, left: string | null, cellUpdatedCount: number }): React.JSX.Element => 
{
    return (
        <Box id="bottomDrawer" width={props.width} left={props.left !== null ? props.left : ''} >
            <Button className="save-btn" onClick={props.handleSaveClick}>Save</Button>
            <Button className="cancel-btn" onClick={props.handleCancelClick}>Cancel</Button>
            {props.cellUpdatedCount > 0 &&
                <Typography className='instruction-text'> {`${props.cellUpdatedCount} unsaved ${props.cellUpdatedCount > 1 ? 'changes' : 'change'}`}</Typography>
            }

        </Box>
    );
};

interface ICustomPaginationComponentProps {
    paginationCount: number;
    selectedPage: number;
    onChangeFunc: (value: number) => void;
}


export const CustomPaginationComponent = ({ paginationCount, selectedPage, onChangeFunc }: ICustomPaginationComponentProps): React.JSX.Element => 
{
    const paginationContainerRef = useRef(null);
    
    const dispatch = useDispatch();

    const paginationData = useSelector((state) => {
        return state['paginationData']?.value;
    })

    const paginationButtons: React.JSX.Element[] = [];

    const visiblePageNumbers = 2;

    const hiddenPageNumbers = [];

    const middlePageNumbers = Math.ceil(paginationCount / 2);

    // if (typeof paginationCount === 'number' && paginationCount < 10000000) 
    // {

    //     for (let i = 1; i <= paginationCount; i++) 
    //     {
    //         if (i > 3 && i < (paginationCount - 2)) 
    //         {
    //             hiddenPageNumbers.push(i);

    //             if (selectedPage === i) 
    //             {
    //                 paginationButtons.push(
    //                     <Box key={i} className={selectedPage === i ? 'selected-pagination-button' : 'pagination-button'} onClick={(): void => 
    //                     {
    //                         return onChangeFunc(i);
    //                     }}>{i}</Box>
    //                 );
    //             }

    //             continue;

    //         }
    //         else if (i > (paginationCount - 2) || i === paginationCount) 
    //         {
    //             paginationButtons.push(
    //                 <Box key={i} className={selectedPage === i ? 'selected-pagination-button' : 'pagination-button'} onClick={(): void => 
    //                 {
    //                     return onChangeFunc(i);
    //                 }}>{i}</Box>
    //             );
    //         }
    //         else 
    //         {
    //             paginationButtons.push(
    //                 <Box key={i} className={selectedPage === i ? 'selected-pagination-button' : 'pagination-button'} onClick={(): void => 
    //                 {
    //                     return onChangeFunc(i);
    //                 }}>{i}</Box>
    //             );
    //         }
    //     }

    //     if (paginationCount > 3) 
    //     {
    //         if (hiddenPageNumbers.includes(selectedPage) && hiddenPageNumbers.length == 1) 
    //         {
    //             paginationButtons.splice(3, 0);
    //         }
    //         else 
    //         {
    //             if (selectedPage > 3 && selectedPage <= middlePageNumbers) 
    //             {
    //                 paginationButtons.splice(4, 0, <Box key={'ellipsis'}>{'...'}</Box>);
    //             }
    //             else 
    //             {
    //                 paginationButtons.splice(3, 0, <Box key={'ellipsis'}>{'...'}</Box>);
    //             }

    //         }

    //     }
    // }
    if (typeof paginationCount === 'number' && paginationCount < 10000000) 
    {
        let startPage = selectedPage;
        let endPage = startPage + 5;
    
        if (endPage > paginationCount) {
            endPage = paginationCount;
            startPage = endPage - 5;
            if (startPage < 1) startPage = 1;
        }
    
        for (let i = startPage; i <= endPage; i++) 
        {   
            paginationButtons.push(
                <Box key={i} className={selectedPage === i ? 'selected-pagination-button' : 'pagination-button'} onClick={(): void => 
                {
                    return onChangeFunc(i);
                }}>{i}</Box>
            );
        }
    }

    // useEffect(():void => 
    // {
    //     if(selectedPage > 3 && selectedPage < (paginationCount - 2))
    //     {
    //         if(paginationContainerRef.current)
    //         {
    //             paginationContainerRef.current.style.transform = 'translateX(-40px)';
    //         }
    //     }

    // },[selectedPage]);




    return (
        <Box id='customPagination'>

            <CustomButton btnType='tertiary' disabled={selectedPage === 1} onClick={(): void => 
            {
                if (selectedPage !== 1) 
                {
                    dispatch(addPagination({
                        registrants: {
                            page: selectedPage !== 1 ? selectedPage - 1 : 1,
                            pageSize: paginationData?.registrants?.pageSize
                        }
                    }))
                    return onChangeFunc(selectedPage !== 1 ? selectedPage - 1 : null);
                }
            }} name='Prev' startIcon={<FontAwesomeIcon style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} className="navigation-icon" />} />

            <Box flexBasis={'200px'} maxWidth={'200px'} overflow={'hidden'} >
                <Box className="pagination-numbers" ref={paginationContainerRef}>
                    {paginationButtons}
                </Box>

            </Box>

            <CustomButton disabled={selectedPage === paginationCount} btnType='tertiary' name='Next' onClick={(): void => 
            {
                if (selectedPage !== paginationCount) 
                {
                    return onChangeFunc(selectedPage !== paginationCount ? selectedPage + 1 : null);
                }
            }} endIcon={<FontAwesomeIcon icon={['fal', 'arrow-right']} className="navigation-icon" />} />

        </Box>
    );
};
