import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import { CONTENT } from '../../scripts/i18n';
import APP_CONSTANTS from '../../scripts/constants';
import { useNavigate, useParams } from 'react-router-dom';
import CreateEvent from '../../components/Events/CreateEvent';
import { resetEvents } from '../../redux/events/Events';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { EventsPageContext } from '../../contexts/EventsPageContext';
import { getEventById } from '../../scripts/apis/events';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import EventsGraph from './EventsGraph';
import { Event } from './interfaces';
import { NumberFormatter } from '../../scripts/helpers';
import MuiChip from '../../common/FormComponents/MuiChip';
import eventsEmptyImage from '../../assets/icons/empty-state/eventEmpty.svg';
import { EventIntegrationType, EventStatus } from './enum';
import { IntegrationTypes } from '../Settings/enum/integrations.enum';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { Integrations } from '../Settings/interface/integration_interface';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { TableActions } from '../../common/TableActions';
import EventsPageToolbar from '../../components/Events/EventComponents/EventsPageToolbar';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';

import './styles.scss';

const EventsPage: React.FC = (): React.JSX.Element => 
{
    const dispatch = useDispatch();

    const { eventId } = useParams();

    const { 
        isEmpty, 
        setRows, 
        rows, 
        eventDataCount, 
        statusCounts, 
        events, 
        // setEvents,
        deleteEventFromEventsTable, 
        setRefresh, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        currentPage, 
        showSpinner, 
        isChartReady, 
        eventsTypeData, 
        eventStatusData, 
        eventLocationData, 
        eventsTrendData, 
        integrations,
        showDeletePopup,
        setShowDeletePopup 
    } = useContext(EventsPageContext);

    const columnHelper = createColumnHelper<Event>();
    const eventColumns = [
        columnHelper.accessor('title', { 
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Event Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'title'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_TYPES[row.getValue() as keyof typeof CONTENT.DASHBOARD_PAGE.EVENT_TYPES]}</p>)
            },
            header: 'Type',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('locationType', {
            cell: (row) => {
                return (<p className="cellContent">{CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[row.getValue()]}</p>)
            },
            header: 'Location',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'locationType'
        }),
        columnHelper.accessor('eventDates', {
            cell: (row) => {
                return (<p className="cellContent">{`${moment.unix(row.row.original.eventStartDateTime).format('MMM DD')} - ${moment.unix(row.row.original.eventEndDateTime).format('MMM DD')}`}</p>)
            },
            header: 'Event Dates',
            sortingFn: (rowA, rowB) => {
                const dateA = rowA.original.eventStartDateTime;
                const dateB = rowB.original.eventStartDateTime;
                return dateA - dateB;
            },
            id: 'eventDates'
        }),
        columnHelper.accessor('integrationType', {
            cell: (row) => {
                return (
                    <MuiChip 
                        label={row.row.original?.integrationType === 1 ? 'Native' : _.startCase(String(EventIntegrationType[row.row.original?.integrationType as unknown as keyof typeof EventIntegrationType]).toLowerCase())} 
                        chipColor={row.row.original?.integrationType === 1 ? 'blue' : row.row.original?.integrationType === 2 ? 'violet' : row.row.original?.integrationType === 3 ? 'pink' : 'null'}
                    />
                )
            },
            header: 'Source',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'integrationType'
        }),
        columnHelper.accessor('roi.roi', {
            cell: (row) => {
                return (<MuiChip label={row.row?.original?.roi?.roi ? row.row?.original?.roi?.roi : '-'} chipColor={row.row?.original?.roi?.roi && row.row?.original?.roi?.roi >= 1 ? 'green' : row.row?.original?.roi?.roi && row.row?.original?.roi?.roi < 1 ? 'red' : 'null'} />)
            },
            header: 'RoI',
            sortingFn: 'auto',
            id: 'roi'
        }),
        columnHelper.accessor('budget', {
            cell: (row) => {
                return (<p className="cellContent">{row.row?.original?.budget && row.row?.original?.budget !== 0 ? `$${NumberFormatter(Number(row.row?.original?.budget))}` : '-'}</p>)
            },
            header: 'Budget',
            sortingFn: 'auto',
            id: 'budget'
        }),
        columnHelper.accessor('eventPhase', {
            cell: (row) => {
                const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                const isOngoingEvent = (currentTimestamp > row.row?.original?.eventStartDateTime && currentTimestamp < row.row?.original?.eventEndDateTime);
                const isUpcomingEvent = row.row?.original?.eventStartDateTime > currentTimestamp;
                const isEventEnded = currentTimestamp > row.row?.original?.eventEndDateTime;
                return (
                    <MuiChip 
                        label={isOngoingEvent ? 'Ongoing' : isUpcomingEvent ? 'Upcoming' : isEventEnded ? 'Ended' : '-'} 
                        chipColor={isOngoingEvent ? 'yellow' : isUpcomingEvent ? 'green' : isEventEnded ? 'grey' : 'null'}
                    />
                )
            },
            header: 'Phase',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'eventPhase'
        }),
        columnHelper.accessor('status', { 
            cell: (row) => {
                return (<MuiChip label={row.row?.original?.status === EventStatus.DRAFT ? 'Draft' : row.row?.original?.status === EventStatus.PUBLISHED && 'Published'} chipColor={row.row?.original?.status === EventStatus.DRAFT ? 'pink' : row.row?.original?.status === EventStatus.PUBLISHED && 'blue'} />)
            },
            header: 'Status',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return (
                    <TableActions 
                        actions={[
                        {
                            title: 'Edit',
                            onClick: (): void => 
                            {
                                handleEdit(row.row.original);
                            }
                        }, 
                        {
                            title: 'Delete',
                            onClick: (): void => 
                            {
                                setSelectedEvent(row.row.original);
                                setShowDeletePopup(true);
                            }
                        }]}
                    />
                );
            },
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ];

    // const [searchText, setSearchText] = useState<string>('');
    const [allColumns, setAllColumns] = useState(eventColumns);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [chartVisibility, setChartVisibility] = useState(false);

    const isLumaIntegrated = integrations?.some((integration: Integrations): boolean => {
        return integration?.type === IntegrationTypes.LUMA;
    })

    const navigate = useNavigate();

    const handleEdit = (eventInfo: Event, routeFromId?: boolean): void => 
    {
        dispatch(resetEvents());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EDIT.HEADING,
            component: <CreateEvent eventData={eventInfo} setRefresh={setRefresh} routeFromId={routeFromId} isLumaIntegrated={isLumaIntegrated} />,
            hideCloseButton: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const openDrawer = (): void => 
    {
        navigate('/events/create-event');
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (events && events.length && eventId) 
        {
            try 
            {
                const eventDataFromId = await getEventById(eventId);
                if (eventDataFromId) 
                {
                    handleEdit(eventDataFromId, true);
                }
                else if (!eventDataFromId) 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'The event you\'re trying to access doesn\'t exist',
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'center',
                    });
                    navigate('/events');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, [events]);

    // const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => 
    // {
    //     setSearchText(event.target.value);
    //     debouncedSearch(event.target.value, setEvents, searchEvents, getEvents, pageSize, currentPage);
    // };

    return (
        <Box id="eventsPage">
            <HeaderBar 
                title='Events'
                buttons={[
                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                    <CustomButton name='Create Event' btnType='primary' onClick={() => openDrawer()} />
                ]}
            />
            {chartVisibility ? <EventsGraph isChartReady={isChartReady} eventsTypeData={eventsTypeData} eventLocationData={eventLocationData} eventStatusData={eventStatusData} eventsTrendData={eventsTrendData} /> : null}

            <Box key={chartVisibility ? 'event-page-chart-table' : 'event-page-table'}  
                sx={chartVisibility?{
                    flex: 1,
                    height: '56% !important',
                }:{
                    height: 'calc(100vh - 60px)',
                    maxHeight: 'calc(100vh - 60px)',
                }}
            >
                {
                    isEmpty ? (<TableEmptyComponent emptyImg={eventsEmptyImage} openDrawer={openDrawer} infoText={'No Event'} subInfoText={'Create Your First Event'} buttonName={'Create Event'} />) :
                        (
                        <div className="h-100">
                            <EventsPageToolbar 
                                rows={events}
                                setEvents={setRows}
                                statusCount={statusCounts}
                                // handleSearch={handleSearch}
                                // searchText={searchText}
                                columns={eventColumns}
                                // currentPage={currentPage}
                                // pageSize={pageSize}
                                setColumns={setAllColumns}
                                eventsCount={eventDataCount}
                                lumaIntegration={isLumaIntegrated}
                            />
                            <TanstackTable 
                                data={rows}
                                initialColumns={allColumns}
                                pageSize={pageSize}
                                updatePageSize={updatePageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                rowCount={eventDataCount}
                                showNoRowsOverlay={false}
                                rightPinnedColumns={['actions']}
                                height={chartVisibility ? `calc(100% - 124px)` : `calc(100% - 114px)`}
                                onRowClick={(row) => navigate(`/events/${row.id}/overview`)}
                                showSpinner={showSpinner}

                            />
                        </div>
                        )
                }
            </Box>

            {
                showDeletePopup && (
                    <DeletePopup
                        show={showDeletePopup} 
                        modalTitle='Delete Event ?'
                        modalContent={`Are you sure you want to delete the event - ${selectedEvent?.title} ?`}
                        acceptBtn='Delete'
                        rejectBtn='Cancel'
                        acceptClick={() => deleteEventFromEventsTable(selectedEvent?.id as string)}
                        cancelClick={() => setShowDeletePopup(false)}
                    />
                )
            }
        </Box>
    );
};

// Needed for future - *****IMPORTANT***** -

// const debouncedSearch = debounce(async (filters, setRows, searchEvents, getEvents, pageSize, currentPage) => 
// {
//     if (filters.length > 0 && filters.some((filter: { inputValue: string; }) => filter.inputValue !== '')) {
//         try {
//             const searchValues = filters.map((filter: { inputValue: any; }) => filter.inputValue);
//             const filteredSpeakers = await searchEvents(searchValues);
//             if (filteredSpeakers) {
//                 setRows(filteredSpeakers);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     } else {
//         try {
//             const speakersData = await getEvents(pageSize, currentPage - 1);
//             if (speakersData) {
//                 setRows(speakersData);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }, 300);

export default EventsPage;