import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRegistrationForm, getRegistrationForms, registrationFormsCount } from '../scripts/apis/registrationForm';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { resetRegistrationForms } from '../redux/registrationsForms/RegistrationForms';
import RegistrationFormTemplate from '../pages/Registration/RegistrationFormTemplate';
import { useTablePagination } from './TablePaginationContext';
import { RegistrationForm, RegistrationFormType } from '../pages/Registration/interfaces/registration-form_interface';
import toast from 'react-hot-toast';

interface IRegistrationsContext { 
    isEmpty: boolean;
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    registrationFormData: RegistrationForm[];
    formsCount: number;
    deleteFromRegistrationsTable: (formData: RegistrationForm) => void;
    openDrawer: (createComp: boolean) => void;
    pageSize: number;
    updateCurrentPage: (value: number) => void;
    updatePageSize: (value: number) => void;
    showSpinner: boolean;
    registrationForms: RegistrationForm[];
    setRegistrationForms: React.Dispatch<React.SetStateAction<RegistrationForm[]>>;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedForm: RegistrationForm | null;
    setSelectedForm: React.Dispatch<React.SetStateAction<RegistrationForm | null>>;
}

export const RegistrationsContext = createContext<IRegistrationsContext>({
    isEmpty: false,
    setIsEmpty: () => {},
    setRefresh: () => {},
    registrationFormData: [],
    formsCount: 0,
    deleteFromRegistrationsTable: () => {},
    openDrawer: () => {},
    pageSize: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    registrationForms: [],
    setRegistrationForms: () => {},
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    selectedForm: null,
    setSelectedForm: () => {},
});

const RegistrationPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }):React.JSX.Element => 
{

    const [registrationFormData, setRegistrationFormData] = useState<RegistrationForm[]>([]);
    const [formsCount, setFormsCount] = useState<number>(0);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [registrationForms, setRegistrationForms] = useState<RegistrationForm[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedForm, setSelectedForm] = useState<RegistrationForm | null>(null);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchData = async ():Promise<void> => 
    {
        try 
        {
            const count = await registrationFormsCount();
            setShowSpinner(true);
            if (count) 
            {
                setFormsCount(count);
                try 
                {
                    const forms = await getRegistrationForms(pageSize, currentPage - 1, RegistrationFormType.REGISTRATION_FORM);

                    if (forms) 
                    {
                        setRegistrationFormData([...forms]);
                        setRegistrationForms([...forms]);
                        setIsEmpty(forms.length === 0);
                        setShowSpinner(false);
                        setRefresh(false);
                    }

                }
                catch (error) 
                {
                    console.log(error);
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchPaginationData = async ():Promise<void> => 
    {

        if (formsCount) 
        {
            try 
            {
                setShowSpinner(true);
                const forms = await getRegistrationForms(pageSize, currentPage - 1);
                if (forms) 
                {
                    setRegistrationFormData([...forms]);
                    setRegistrationForms([...forms]);
                    setIsEmpty(forms.length === 0);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
          
        }
    };


    const openDrawer = (createComp = false):void => 
    {
        if (createComp) 
        {
            dispatch(resetRegistrationForms());
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading: 'Create Registration Form',
                component: <RegistrationFormTemplate setRefresh={setRefresh} />
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const deleteFromRegistrationsTable = async (formData: RegistrationForm):Promise<void> => 
    {
        try 
        {
            const formDeleted = await deleteRegistrationForm(formData?.id);
            if (formDeleted) 
            {
                toast.success('Form deleted successfully');
                setSelectedForm(null);
                setShowDeletePopup(false);
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }           
    };

    useEffect(():void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            fetchData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchPaginationData();
        }
    }, [currentPage, pageSize]);

    useEffect(():void => 
    {
        if (refresh) 
        {
            fetchData();
        }
    }, [refresh]);

    return (
        <RegistrationsContext.Provider 
            value={{
                isEmpty, 
                setIsEmpty, 
                setRefresh, 
                registrationFormData, 
                formsCount, 
                deleteFromRegistrationsTable, 
                openDrawer, 
                pageSize, 
                updateCurrentPage, 
                updatePageSize, 
                showSpinner,
                registrationForms,
                setRegistrationForms,
                selectedForm,
                setSelectedForm,
                setShowDeletePopup,
                showDeletePopup
            }}
        >
            {children}
        </RegistrationsContext.Provider>
    );
};

export default RegistrationPageProvider;