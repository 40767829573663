import React, { useEffect, useState } from 'react';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomSnackbarDialog = (): React.JSX.Element => 
{
    const [heading, setHeading] = useState(null);
    const [dialogContent, setDialogContent] = useState(null);
    const [actionButton, setActionButton] = useState(null);
    const [open, setOpen] = useState(false);
    const [removalSnackbar, setRemovalSnackbar] = useState(false);

    const handleDialogClose = (): void => 
    {
        setOpen(false);
    };

    useEffect(():()=>void => 
    {
        const updateEvent = (data): void => 
        {
            setHeading(data.heading);
            setDialogContent(data.dialogContent);
            setActionButton(data.actionButton);
            setRemovalSnackbar(data.removalSnackbar);
        };

        const openEvent = (data): void => 
        {
            setOpen(data.open);
        };

        const closeEvent = (): void => 
        {
            handleDialogClose();
        };

        eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.UPDATE_EVENT, updateEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.OPEN_EVENT, openEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.CLOSE_EVENT, closeEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.CLOSE_EVENT, (data): void => 
        {
            setOpen(data.open);
        });

        setTimeout((): void => 
        {
            return handleDialogClose(); 
        }, 6000);

        return (): void => 
        {
            eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.UPDATE_EVENT, updateEvent);
            eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.OPEN_EVENT, openEvent);
            eventBus.on(APP_CONSTANTS.EVENTS.SNACKBAR_DIALOG.CLOSE_EVENT, closeEvent);
        };

    }, []);

    return (
        <div>
            <Dialog id="customSnackbarDialog" slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    }
                }
            }} PaperProps={{
                sx: {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    minWidth: removalSnackbar ? '465px !important' : '552px !important',
                    minHeight: removalSnackbar ? '68px !important' : '143px !important',
                    padding: '24px',
                    borderRadius: '16px',
                    border: '1px solid #ffffff',
                    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.10)'
                }
            }} open={open} onClose={handleDialogClose} className="custom-dialog-container">
                <div className="custom-snackbar-header">
                    <DialogTitle sx={{
                        padding: 'unset'
                    }}>{heading}</DialogTitle>
                    <FontAwesomeIcon className="close-icon" icon={['fal', 'xmark']} onClick={handleDialogClose} />
                </div>
                {dialogContent ? <DialogContent sx={{
                    padding: '16px 0 20px 0'
                }}>
                    {dialogContent}
                </DialogContent> : null}
                {actionButton ? <DialogActions sx={{
                    justifyContent: 'flex-start'
                }}>
                    {actionButton}
                </DialogActions> : null}
            </Dialog>
        </div>
    );
};

export default CustomSnackbarDialog;