import React, { useEffect, useState } from 'react';
import { getSpeakers, speakersCount } from '../../../../scripts/apis/speakers';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import CreateSpeaker from '../../../../components/Speakers/CreateSpeaker';
import _, { set } from 'lodash';
import { Form } from 'react-bootstrap';
import { addEventSpeaker, eventSpeakersCount, getEventSpeakers } from '../../../../scripts/apis/eventSpeakers';
import APP_CONSTANTS from '../../../../scripts/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import eventBus from '../../../../scripts/event-bus';
import { addSpeakers, resetSpeakers } from '../../../../redux/speakers/Speakers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutocompleteComponent, CloseIconComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import ButtonGroup from '../../../../common/ButtonGroup';

const AddEventSpeakers = (props) : React.JSX.Element => 
{
    const eventId = props?.eventId;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const defaultSpeakerObj = {
        status: 0,
        id: 0,
        eventId: 0,
    };

    const currentpath = useLocation().pathname;

    const [speakers, setSpeakers] = useState([]);
    const [speaker, setSpeaker] = useState(defaultSpeakerObj);
    const [speakerValue, setSpeakerValue] = useState('');
    const [speakerInputValue, setSpeakerInputValue] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [eventSpeakerData, setEventSpeakerData] = useState([]);

    const [isSelect, setIsSelect] = useState<boolean>(currentpath?.includes('speakers') || false);

    const key = isSelect ? 'select' : 'create';

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const handleSpeakerInputChange = (event, newValue): void => 
    {
        setSpeakerValue(newValue);
        setSpeakerInputValue(newValue);

        if (newValue === '') 
        {
            dispatch(resetSpeakers());
        }

        const selectedSpeaker = _.filter(speakers, function (speaker) : boolean
        {
            return newValue === speaker.name;
        })[0];
        setSpeaker(selectedSpeaker);

        if (selectedSpeaker) 
        {
            dispatch(addSpeakers({
                name: selectedSpeaker.name,
                email: selectedSpeaker.email,
                additionalInfo: {
                    headline: selectedSpeaker.additionalInfo.headline ? selectedSpeaker.additionalInfo.headline : '',
                    bio: selectedSpeaker.additionalInfo.bio ? selectedSpeaker.additionalInfo.bio : '',
                    image: selectedSpeaker.additionalInfo.image ? selectedSpeaker.additionalInfo.image : '',
                    social: {
                        linkedIn: selectedSpeaker.additionalInfo?.social?.linkedIn ? selectedSpeaker.additionalInfo?.social?.linkedIn : '',
                        twitter: selectedSpeaker.additionalInfo?.social?.twitter ? selectedSpeaker.additionalInfo?.social?.twitter : '',
                    }
                }
            }));
        }
    };

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch('close-side-drawer', {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        dispatch(resetSpeakers());
    };

    const formSubmit = async (event): Promise<void> => 
    {
        event.preventDefault();

        if (!speaker || speakerValue === '') 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                open: true,
                message: 'Select a Speaker',
                severity: 'error',
                positionVertical: 'top',
                positionHorizontal: 'right',
            });
            return;
        }

        setSpinner(true);
        // let eventSpeakerUpdated;
        const eventSpeakerData = {
            status: speaker.status,
            speakerId: speaker.id,
            eventId: eventId,
        };
        try 
        {
            const eventSpeakerUpdated = await addEventSpeaker(eventSpeakerData, eventId, csrfTokenData);
            if (eventSpeakerUpdated) 
            {
                props.setRefresh(true);
                handleDrawerClose();
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Speaker Selected',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            }

        }
        catch (error) 
        {
            console.log(error);
        }

    };

    useEffect((): void => 
    {
        const fetchData = async (): Promise<void> => 
        {
            try 
            {
                const count = await speakersCount();
                if (count) 
                {
                    const speakerData = await getSpeakers(count, 0);
                    const speakerCount = await eventSpeakersCount(eventId);
                    if (speakerCount) 
                    {
                        const eventSpeakers = await getEventSpeakers(speakerCount, 0, eventId);
                        if (eventSpeakers) 
                        {
                            setEventSpeakerData([...(_.map(eventSpeakers, 'id'))]);
                        }
                    }
                    if (speakerData) 
                    {
                        setSpeakers([...speakerData]);
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
            }

        };
        fetchData();

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data): void => 
        {
            setSpinner(data.spinner);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });
    }, []);

    return (
        <Box id="createEventSpeaker">

            {isSelect && <CloseIconComponent onClick={handleDrawerClose} />}

            {!currentpath?.includes('speakers') && <ButtonGroup tabs={[
                {
                tabName: 'Create Speaker',
                selectedTab: !isSelect,
                navigation: (): void => 
                    {
                        setIsSelect(false);
                        dispatch(resetSpeakers());
                    }    
                },
                {
                    tabName: 'Select Speaker',
                    selectedTab: isSelect,
                    navigation: (): void => 
                    {
                        setIsSelect(true);
                        dispatch(resetSpeakers());
                        setSpeakerValue('');
                    }
                },
            ]} />}

            <Form noValidate onSubmit={formSubmit}>

                {isSelect && <Box padding={'24px 0 0'}>
                    <AutocompleteComponent 
                        value={speakerValue}
                        onChange={handleSpeakerInputChange}
                        optionsArr={_.compact(speakers && speakers.length > 0 ? speakers.map((option): string => 
                        {
                            if (!(eventSpeakerData.includes(option?.id))) 
                            {
                                return option?.name;
                            }
                        }) : [])}
                        placeholder='Choose a speaker'
                    />
                </Box>}

                {(isSelect && speakerValue) ? <CreateSpeaker key={key} create-event-speaker={!isSelect} disabled={isSelect} bottomBtn={isSelect} addEventSpecificSpeaker={isSelect} routeFromEventSpeaker={props?.routeFromEventSpeaker} data-eventId={eventId} />
                :
                    (!isSelect) && <CreateSpeaker setRefresh={props.setRefresh} key={key} create-event-speaker={!isSelect} disabled={isSelect} bottomBtn={isSelect} addEventSpecificSpeaker={isSelect} routeFromEventSpeaker={props?.routeFromEventSpeaker} data-eventId={eventId} />
                }

                {isSelect && <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton name='Cancel' onClick={handleDrawerClose} btnType='secondary' />
                        <CustomButton name='Add' type="submit" onClick={formSubmit} loading={spinner} btnType='primary' />
                    </Stack>
                </Box>}
            </Form>
        </Box>
    );
};

export default AddEventSpeakers;