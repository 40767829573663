import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import { deleteEventSession, getAllSessions, getSessionCount, updateEventSession } from '../../scripts/apis/eventSession';
import { eventSpeakersCount, getEventSpeakers } from '../../scripts/apis/eventSpeakers';
import { getAllTicketGroups, ticketGroupCount } from '../../scripts/apis/eventTickets';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useDispatch } from 'react-redux';
import { addSession } from '../../redux/events/eventVenue/EventSessions';
import { useTablePagination } from '../TablePaginationContext';
import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';

export const SessionsContext = createContext(null);

const SessionsPageProvider: React.FC<{children: React.ReactNode; eventId?: string; eventReduxData?: IEventReduxValues }> = ({ children, eventId, eventReduxData }): React.JSX.Element => 
{

    const dispatch = useDispatch();

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [rows, setRows] = useState([]);
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [eventStartDateTime, setEventStartDateTime] = useState('');
    const [eventEndDateTime, setEventEndDateTime] = useState('');
    const [speakerInfo, setSpeakerInfo] = useState([]);
    const [ticketGroupInfo, setTicketGroupInfo] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [sessionCount, setSessionCount] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const eventStartingDate = moment(moment(eventStartDateTime).format('YYYY-MM-DD'));
    const eventEndingDate = moment(moment(eventEndDateTime).format('YYYY-MM-DD'));
    const daysArr = [];
    const currentDate = eventStartingDate.clone();
    while (currentDate.isSameOrBefore(eventEndingDate, 'day')) 
    {
        daysArr.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }

    const fetchSessionDataCount = async (): Promise<void> => 
    {
        try 
        {
            const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
            const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

            const startTimeStamp = startDateTime.unix();
            const endTimeStamp = endDateTime.unix();
            const count = await getSessionCount(eventId, startTimeStamp, endTimeStamp);
            if (count) 
            {
                setSessionCount(count);
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchSessionsData = async (): Promise<void> => 
    {
        let startTime = String(eventReduxData.eventStartDateTime);
        let endTime = String(eventReduxData.eventEndDateTime);
        startTime = moment.unix(Number(startTime)).format('LLLL');
        endTime = moment.unix(Number(endTime)).format('LLLL');
        setEventStartDateTime(startTime);
        setEventEndDateTime(endTime);

        try 
        {

            if (daysArr[tabValue] || eventReduxData.eventStartDate) 
            {

                const startDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
                const endDateTime = moment(`${daysArr.length > 0 ? daysArr[tabValue] : eventReduxData.eventStartDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

                const startTimeStamp = startDateTime.unix();
                const endTimeStamp = endDateTime.unix();

                setShowSpinner(true);

                const sessionData = await getAllSessions(eventId, pageSize, currentPage - 1, startTimeStamp, endTimeStamp);

                if (sessionData) 
                {
                    setRows([...sessionData]);
                    setShowSpinner(false);

                    if (refresh) 
                    {
                        setFilteredRowData([...sessionData]);
                        setTabValue(tabValue);
                        dispatch(addSession({
                            startDate: daysArr[tabValue],
                            endDate: daysArr[tabValue],
                        }));

                    }
                    else 
                    {
                        setFilteredRowData([...sessionData]);
                        dispatch(addSession({
                            startDate: moment.unix(startTimeStamp).format('YYYY-MM-DD'),
                            endDate: moment.unix(endTimeStamp).format('YYYY-MM-DD'),
                        }));
                    }
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }

        try 
        {
            const speakerCount = await eventSpeakersCount(Number(eventId));
            if (speakerCount) 
            {
                const speakerData = await getEventSpeakers(speakerCount, 0, Number(eventId));
                if (speakerData) 
                {
                    setSpeakerInfo([...speakerData]);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        try 
        {
            const groupCount = await ticketGroupCount(Number(eventId));
            if (groupCount) 
            {
                const ticketGroupData = await getAllTicketGroups(groupCount, 0, Number(eventId));
                if (ticketGroupData) 
                {
                    setTicketGroupInfo([...ticketGroupData]);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }

        setRefresh(false);
    };

    const editSessionsFromTable = async (sessionData): Promise<void> => 
    {
        const eventSessionDetails = {
            title: sessionData.title,
            description: sessionData.description,
            imageAlternateText: sessionData.imageAlternateText,
            maximumParticipants: sessionData.maximumParticipants,
            startDateTime: sessionData.startDateTime,
            endDateTime: sessionData.endDateTime,
            // ticketGroupIds: sessionData.ticketGroupIds,
            speakerIds: sessionData.speakerIds ? sessionData.speakerIds : [],
            scheduledEvent: sessionData.scheduled,
        };
        try 
        {
            const speakerDetailsUpdated = await updateEventSession(sessionData.eventId, sessionData.id, eventSessionDetails);
            if (speakerDetailsUpdated) 
            {
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const removeSessionFromTable = (): void => 
    {
        if (sessions && sessions.length > 0 && filteredRowData.length > 0) 
        {
            eventBus.on(APP_CONSTANTS.EVENTS.EVENT_SESSION.DELETE, async (object): Promise<void> => 
            {
                const acceptObj = object.acceptObj;
                let deleteSuccess = true;
                if (deleteSuccess) 
                {
                    if (acceptObj.id) 
                    {
                        try 
                        {
                            const sessionDeleted = await deleteEventSession(eventId, acceptObj.id);
                            if (sessionDeleted) 
                            {
                                setRefresh(true);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Session Deleted',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                                deleteSuccess = false;
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                }
            });
        }
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchSessionDataCount();
            fetchSessionsData();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        if (eventReduxData.title) 
        {
            if (currentPage > 0 && pageSize) 
            {
                fetchSessionDataCount();
                fetchSessionsData();
            }
        }
    }, [eventReduxData, tabValue, currentPage, pageSize]);

    useEffect((): void => 
    {
        setIsEmpty(filteredRowData.length === 0);
        setSessions([...filteredRowData]);
    }, [filteredRowData]);

    return (
        <SessionsContext.Provider value={{
            isEmpty, speakerInfo, rows, setRefresh, ticketGroupInfo, setTabValue, daysArr, setFilteredRowData, tabValue, sessions, removeSessionFromTable, filteredRowData, showSpinner, pageSize, updateCurrentPage, updatePageSize, sessionCount, editSessionsFromTable 
        }}>
            {children}
        </SessionsContext.Provider>
    );
};

export default SessionsPageProvider;

