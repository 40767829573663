import { createContext, useEffect, useState } from 'react';
import React from 'react';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { useTablePagination } from './TablePaginationContext';
import { deleteEventsgoals, getEventsgoals, updateEventgoals } from '../scripts/apis/eventsgoals';
import _ from 'lodash';
import { IEventGoal } from '../pages/Events/interfaces';

export const GoalsContext = createContext(null);

const GoalsProvider = ({ children, eventId }):React.JSX.Element => 
{
    const [rows, setRows] = useState<IEventGoal[]>([]);
    const [isEmpty, setIsEmpty] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const { pageSize, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchGoalsData = async (): Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);
            const goalsData = await getEventsgoals(eventId);

            if (goalsData) 
            {
                setRows([...goalsData]);  
                setShowSpinner(false);
                setIsEmpty(goalsData.length === 0);
                setRefresh(false);
            }
        }
        catch (error) 
        {
            console.log(error);
            setRefresh(false);
            setShowSpinner(false);
        }
    };
    
    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchGoalsData();
        }
    }, [refresh]);

    useEffect((): void => 
    {      
        fetchGoalsData();
    }, []);

    const deleteGoalsFromIndex = async (goalInfo): Promise<void> => 
    {
        let deleteSuccess = true;
        eventBus.on('delete-goal', async (): Promise<void> => 
        {
            if (deleteSuccess) 
            {
                const newRows = rows.filter((item): boolean => 
                {
                    return item.id !== goalInfo.id;
                });
                if (newRows.length == 0) 
                {
                    try 
                    {
                        const goalsUpdated = await deleteEventsgoals(eventId);
                        if (goalsUpdated) 
                        {
                            setRefresh(true);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: 'Goals updated successfully',
                                severity: 'success',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                            deleteSuccess = false;
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        setRefresh(true);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: _.startCase(error.message.toLowerCase()),
                            severity: 'error',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                        deleteSuccess = false;
                    }
                }
                else 
                {
                    try 
                    {
                        const payload = {
                            goals: newRows
                        };
                        const goalsCreated = await updateEventgoals(eventId, JSON.parse(JSON.stringify(payload)));
                        if (goalsCreated) 
                        {
                            setRefresh(true);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: 'Goals updated successfully',
                                severity: 'success',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });

                            deleteSuccess = false;
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        setRefresh(true);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: _.startCase(error.message.toLowerCase()),
                            severity: 'error',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                        deleteSuccess = false;
                    }
                }
            }

        });

    };

    // const editGoalsFromTable = async (goalsData):Promise<void> => 
    // {
    //     const goalsDetails = {
    //         type: goalsData.type ? goalsData.type : '',
    //         weightage: goalsData.weightage ? goalsData?.weightage : '',
    //         target: goalsData.target ? goalsData?.target : '',
    //     };

    //     try 
    //     {
    //         const goalsUpdated = await updateEventgoals(goalsData.eventId, goalsDetails);
    //         if (goalsUpdated) 
    //         {
    //             setRefresh(true);
    //         }
    //     }
    //     catch (error) 
    //     {
    //         console.log(error);
    //     }
    // };
   
    return (
        <GoalsContext.Provider value={{
            rows, isEmpty, setRefresh, deleteGoalsFromIndex, pageSize, updateCurrentPage, updatePageSize, showSpinner, eventId  
        }}>
            {children}
        </GoalsContext.Provider>
    );
};

export default GoalsProvider;