import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import APP_CONSTANTS from '../../../scripts/constants';
import eventBus from '../../../scripts/event-bus';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import { RegistrationsContext } from '../../../contexts/RegistrationsContext';
import { useDispatch } from 'react-redux';
import { resetRegistrationForms } from '../../../redux/registrationsForms/RegistrationForms';
import RegistrationFormTemplate from '../RegistrationFormTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { checkValidFormToDelete, getRegistrationFormById } from '../../../scripts/apis/registrationForm';
import moment from 'moment';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import registrationEmptyImg from '../../../assets/icons/empty-state/regFormsEmpty.svg';
import { RegistrationForm, RegistrationFormType } from '../interfaces/registration-form_interface';
import MuiChip from '../../../common/FormComponents/MuiChip';
import _ from 'lodash';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import RegistrationsPageToolbar from '../../../components/Registrations/RegistrationsPageToolbar';
import DeletePopup from '../../../common/DeletePopup';
import HeaderBar from '../../../common/Headerbar';

import './styles.scss';

const RegistrationsPage = (): React.JSX.Element => 
{

    const { formId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSpinner, setSpinner] = useState(false);

    const { 
        isEmpty, 
        registrationFormData, 
        formsCount, 
        deleteFromRegistrationsTable, 
        setRefresh, 
        openDrawer, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        registrationForms,
        setRegistrationForms,
        selectedForm,
        setSelectedForm,
        showDeletePopup,
        setShowDeletePopup 
    } = useContext(RegistrationsContext);

    const handleEdit = (data: RegistrationForm, routeFromId?: boolean): void => 
    {
        dispatch(resetRegistrationForms());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit Registration Form',
            hideCloseButton: true,
            component: <RegistrationFormTemplate registrationForm-data={data} setRefresh={setRefresh} routeFromId={routeFromId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleDelete = async (data: RegistrationForm): Promise<void> => 
    {
        const formName = data.name;
        try 
        {
            setSpinner(true);
            const validFormDelete = await checkValidFormToDelete(Number(data.id));
            if (validFormDelete === 'true') 
            {
                setSpinner(false);
                setSelectedForm(data);
                setShowDeletePopup(true);
            }
            else 
            {
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
                    open: true,
                    title: 'Delete Form',
                    content: 'Form ' + formName + ', is in use.',
                    rejectBtn: 'Cancel'
                });
            }
        }
        catch (error) 
        {
            setSpinner(false);
            console.log(error);
        }


    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (registrationFormData && registrationFormData.length && formId) 
        {
            try 
            {
                const registrationFormById = await getRegistrationFormById(Number(formId));
                if (registrationFormById) 
                {
                    handleEdit(registrationFormById, true);
                }
            }
            catch (error) 
            {
                console.log(error);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'The Registration form you\'re trying to access doesn\'t exist',
                    severity: 'error',
                    positionVertical: 'top',
                    positionHorizontal: 'center',
                });
                navigate('/registrations');
            }
        }
    };

    useEffect((): void => 
    {
        if (registrationFormData) 
        {
            fetchDataFromRoute();
        }
    }, [registrationFormData]);

    const columnHelper = createColumnHelper<RegistrationForm>();

    const registrationPageColumns = [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                const formTypeLabel = RegistrationFormType[row.row.original.type];
                const chipColors = {
                    [RegistrationFormType.REGISTRATION_FORM]: 'blue',
                    [RegistrationFormType.SPEAKERS]: 'green',
                    [RegistrationFormType.SPONSORS]: 'orange',
                    [RegistrationFormType.LEAD_CAPTURE]: 'violet',
                }

                return (
                    <MuiChip label={formTypeLabel === 'REGISTRATION_FORM' ? 'Event Registrations' : _.startCase(_.lowerCase(formTypeLabel)).replace(/_/g, ' ')} chipColor={formTypeLabel === 'REGISTRATION_FORM' ? 'blue' : chipColors[row?.type]} />
                )
            },
            header: 'Registration Form Type',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('modified', {
            cell: (row) => {
                const timing = moment(row.getValue());
                const dateTime = timing.format('DD MMMM, YYYY');
                return (
                    <p className="cellContent">{dateTime}</p>
                );
            },
            header: 'Last Updated',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'modified'
        }),
        columnHelper.accessor('actions', {
            cell: (row) => {
                const editClick = (): void => {
                    eventBus.dispatch('selected-row-id', row.row.original.id);
                    handleEdit(row.row.original);
                };

                const deleteClick = (): void => {
                    eventBus.dispatch('selected-row-id', row.row.original.id);
                    handleDelete(row.row.original);
                };

                return <div style={{ display: 'flex', justifyContent: 'flex-end' }}><ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} /></div>;
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'actions'
        }),
    ];
    const [allColumns, setAllColumns] = useState(registrationPageColumns);

    return (
        <Box id='registrationsPage'>
            <HeaderBar 
                title='Registration Forms'
                buttons={[
                    <CustomButton name='Create Form' btnType='primary' onClick={() => openDrawer(true)} />
                ]}
            />
            <Box sx={{
                height:'100%'
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={registrationEmptyImg} openDrawer={openDrawer} infoText={'No Forms'} subInfoText={'Add your first Registration Form'} buttonName={'Add Form'} />) :
                    (
                        <div className="h-100">
                            <RegistrationsPageToolbar 
                                columns={registrationPageColumns}
                                count={formsCount}
                                rows={registrationFormData}
                                setRows={setRegistrationForms}
                                setColumns={setAllColumns}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={registrationForms}
                                pageSize={pageSize}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                rowCount={formsCount}
                                showSpinner={showSpinner}
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => navigate(`/registrations/${row?.id}/form`)}
                            />
                        </div>
                    )}
            </Box>
            {/* {isSpinner && <CustomSpinner height={'100vh'} />} */}

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteFromRegistrationsTable(selectedForm as RegistrationForm)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedForm(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedForm?.name}?`}
                        modalTitle='Delete Form'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>

    );
};
export default RegistrationsPage;