import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { DataGridPro, DataGridProProps, GridColDef, GridRenderCellParams, GridRowId, GridRowSelectionModel, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
// eslint-disable-next-line import/named
import { Box, ButtonProps, IconButton, Popover, Typography } from '@mui/material';
import { deleteEventBudget, updateEventBudget } from '../../../scripts/apis/eventBudget';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import { refreshBudgetDetails } from '../../../redux/events/eventBudget/EventBudget';
import APP_CONSTANTS from '../../../scripts/constants';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { IRefreshEventBudget } from './EventBudgetMain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eventBus from '../../../scripts/event-bus';
import { CONTENT } from '../../../scripts/i18n';
import MuiChip from '../../../common/FormComponents/MuiChip';

interface Row {
    category: string[];
    tags: number;
    itemName: string;
    budget: number;
    actualSpending: number;
    analysis: string;
    id: number;
    budgetId?: string | number;
    subBudgetId?: string | number;
}

interface IEventBudgetDataGrid {
    rows: Row[],
    pageSize: number,
    rowCount: number,
    updateCurrentPage: (value: string | number) => void,
    page: number,
    budgetDetails: object
}

const tags = {
    1: 'Marketing',
    2: 'Sales',
    3: 'Design',
    4: 'Booth',
    5: 'Vendor',
};

const TagsColor = (value: number): 'green' | 'blue' | 'pink' | 'red' | 'yellow'|'null' => 
{
    switch (value) 
    {
    case 1:
        return 'green';
    case 2:
        return 'blue';
    case 3:
        return 'pink';
    case 4:
        return 'red';
    case 5:
        return 'yellow';
    default:
        return 'null';
    }
};

const AnalysisColor = (value: string): 'yellow' | 'red' | 'green'|'null' => 
{
    switch (value) 
    {
    case 'Underspend':
        return 'yellow';
    case 'Overspend':
        return 'red';
    case 'In Budget':
        return 'green';
    default:
        return 'null';
    }
};



const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams): React.JSX.Element => 
{
    const { id, field, rowNode, row } = props;

    const [isOpened, setIsOpened] = useState(false);
    const apiRef = useGridApiContext();
    const refreshBudgetDetailsValue = useSelector((state: IRefreshEventBudget): boolean => 
    {
        return state.eventBudget.value.refreshBudget;
    });
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector
    );

    useEffect((): void => 
    {
        // if (refreshBudgetDetailsValue) {
        //     setIsOpened(false);
        // } else {
        if (rowNode['childrenExpanded']) 
        {
            setIsOpened(rowNode['childrenExpanded']);
        }
        // }
    }, [refreshBudgetDetailsValue, rowNode['childrenExpanded']]);
    const filteredDescendantCount =
        filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps['onClick'] = (event): void => 
    {
        if (rowNode.type !== 'group') 
        {
            return;
        }
        setIsOpened(!isOpened);
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const inputString = rowNode['groupingKey'];
    const resultString = inputString.replace(/ -\d+$/, '');

    return (
        <Box sx={{
            ml: rowNode.depth * 4
        }} >
            <div>
                {filteredDescendantCount > 0 ? (
                    <Box className="groupingCategoryBtn">
                        <IconButton onClick={handleClick} sx={{
                            transform: isOpened ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                            marginLeft: '-5px',
                        }}>
                            <FontAwesomeIcon style={{
                                height: '16px',
                                width: '16px',
                            }} icon={['fal', 'chevron-right']} />
                        </IconButton>
                        <Typography className="group-category-name">
                            {resultString} {'('}
                            {filteredDescendantCount}
                            {')'}
                        </Typography>
                    </Box>
                ) : row.tags ? (
                    <Box className="categoryBtn">
                        {/* <Box></Box> */}
                        <Typography className="category-name">
                            {resultString}
                        </Typography>
                    </Box>
                ) : (
                    <span />
                )}
            </div>
        </Box>
    );
};

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Category',
    renderCell: (params): React.JSX.Element => 
    {
        return <CustomGridTreeDataGroupingCell {...params} />;
    },
    flex: 1.5,
    headerClassName: 'budget-category-header'
};

const EventBudgetDataGrid = ({ rows, pageSize, rowCount, updateCurrentPage, page, budgetDetails }: IEventBudgetDataGrid): React.JSX.Element => 
{

    const dispatch = useDispatch();

    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const [updatedRows, setUpdatedRows] = useState([]);
    
    const availablePaths = [];

    const deleteEventBudgetData = async (eventId: number, budgetId: number): Promise<void> => 
    {
        try 
        {
            const data = await deleteEventBudget(
                eventId, budgetId
            );
            if (data) 
            {
                dispatch(refreshBudgetDetails({
                    refreshBudget: true
                }));
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Budget Deleted',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const deleteSubBudgetData = async (budgetId: number, subBudgetId: number): Promise<void> => 
    {

        if(budgetDetails?.['subBudget']?.length > 0)
        {
            const newSubBudget =  budgetDetails['subBudget'].filter((item, index):any => 
            {
                if(index !== subBudgetId)
                {
                    return item;
                }
            } );

            const newPayload = {
                ...budgetDetails,
                ['subBudget']: newSubBudget?.length > 0 ? newSubBudget : null
            };

            try 
            {
                const data = await updateEventBudget(
                    Number(budgetDetails['eventId']),
                    Number(budgetId),
                    newPayload
                );
                if (data) 
                {
                    dispatch(refreshBudgetDetails({
                        refreshBudget: true
                    }));
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'Sub Category Deleted',
                        severity: 'success',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row): string[] => 
    {
        if (availablePaths?.some((subarray): boolean => 
        {
            return JSON.stringify(subarray) === JSON.stringify(row.category);
        }) === false) 
        {
            availablePaths.push(row.category);
            return row.category;
        }
        else 
        {
            return [];
        }
    };

    const deleteEventBudgetfromTable = (): void => 
    {
        eventBus.on('delete-event-budget', async (object): Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            let deleteSuccess = true;

            if (acceptObj.subBudgetId && acceptObj.subBudgetId !== 'undefined') 
            {
                if (deleteSuccess) 
                {
                    await deleteSubBudgetData(acceptObj.budgetId, Number(acceptObj.subBudgetId));
                    deleteSuccess = false;
                }
            }
            else 
            {
                if (deleteSuccess) 
                {
                    await deleteEventBudgetData(Number(acceptObj.eventId), acceptObj.budgetId);
                    deleteSuccess = false;
                }
            }

        });

    };

    const actions = (row): React.JSX.Element => 
    {
        const handleDeleteClick = (): void => 
        {
            const budgetName = row.itemName ? row.itemName : row.category[0];
            eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
                open: true,
                title: CONTENT.EVENTS_PAGE.DELETE_POPUP.TITLE,
                content: 'Are you sure you want to delete the budget ' + budgetName + '?',
                acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
                acceptEvent: 'delete-event-budget',
                rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
                acceptObj: {
                    budgetId: row.budgetId,
                    eventId: row.eventId,
                    subBudgetId: String(row?.subBudgetId)
                },
            });
            eventBus.dispatch('selected-row-id', row.id);

            deleteEventBudgetfromTable();

        };
        const handleEditClick = (): void => 
        {
            eventBus.dispatch('selected-row-id', row.id);
            row.onClick();
        };
        return <ActionsDropdown onDeleteClick={handleDeleteClick} onEditClick={handleEditClick} />;

    };

    const columns: GridColDef[] = [
        {
            field: 'tags',
            headerName: 'Tags',
            disableColumnMenu: true,
            renderCell: (params): React.JSX.Element => 
            {
                const chipTagsColor = TagsColor(Number(params.row.tags));

                //return <div className={className}>{params.value}</div>;
                return <MuiChip label={params.value} chipColor={chipTagsColor}/>;
            },
            valueGetter: (params): string => 
            {
                if (params.row.tags) 
                {
                    return tags[params.row.tags];
                }
                return '';
            },
            flex: 0.7,
        } as GridColDef<Row, string>,
        {
            field: 'itemName',
            headerName: 'Item Name',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params): React.JSX.Element => 
            {
                return <div className="itemCol">{params.value}</div>;
            },
            editable: false
        },
        {
            field: 'budget',
            headerName: 'Budget',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params): React.JSX.Element => 
            {
                return <div className="budgetCol">{`$${params.value}`}</div>;
            },
            editable: false
        },
        {
            field: 'actualSpending',
            headerName: 'Actual Spending',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params): React.JSX.Element => 
            {
                return <div className="actualSpendCol">{`$${params.value}`}</div>;
            },
            editable: false
        },
        {
            field: 'analysis',
            headerName: 'Analysis',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params): React.JSX.Element => 
            {
                const chipAnalysisColor = AnalysisColor(params.value);
                //return <div className={className}>{params.value}</div>;
                return <MuiChip label={params.value} chipColor={chipAnalysisColor}/>;
            },
            valueGetter: (params): string => 
            {
                const row = params.row;
                if (Number(row.budget) > Number(row.actualSpending)) 
                {
                    return 'Underspend';
                }
                else if (Number(row.budget) < Number(row.actualSpending)) 
                {
                    return 'Overspend';
                }
                else 
                {
                    return 'In Budget';
                }
            },
        },
        {
            field: 'actions', headerName: '', editable: false, sortable: false, width: 50, disableColumnMenu: true, renderCell: (params): React.JSX.Element => 
            {
                return actions(params.row);
            },

        }
    ];

    const dummyrow = [
        {
            'budgetId': '',
            'eventId': '',
            'id': 1000,
            'category': [],
            'tags': null,
            'budget': null,
            'itemName': null,
            'actualSpending': null
        }
    ];

    useEffect((): void => 
    {
        eventBus.on('selected-row-id', (rowId): void => 
        {
            if (rowId) 
            {
                setSelectionModel([rowId]);
            }
            else 
            {
                setSelectionModel([]);
            }
        });
        if (rows?.length > 0) 
        {
            const updatedRowsId = rows.map((item): Row => 
            {
      
                const regex = /^-\d+$/;
                if (item.itemName) 
                {
                    const itemNameArray = item.itemName.split(' ');
                    const removeUnwantedChar = itemNameArray.map((word): string => 
                    {
                        if (!regex.test(word)) 
                        {
                            return word;
                        }
                    });
                    if (removeUnwantedChar.length == 1) 
                    {
                        item.itemName = removeUnwantedChar[0];
                    }
                    else 
                    {
                        let validStr = '';
                        for (let i = 0; i < removeUnwantedChar.length; i++) 
                        {
                            if (i !== (removeUnwantedChar.length - 1)) 
                            {
                                if (removeUnwantedChar[i] !== undefined) 
                                {
                                    validStr = validStr + removeUnwantedChar[i] + ' ';
                                }

                            }
                            else 
                            {
                                if (removeUnwantedChar[i] !== undefined) 
                                {
                                    validStr = validStr + removeUnwantedChar[i];
                                }
                            }
                        }
                        item.itemName = validStr;
                    }
                }
                return item;
            });

            setUpdatedRows(updatedRowsId);
        }
    }, [rows]);

    // const processRowUpdate = React.useCallback(
    //     async (newRow: GridRowModel) => {
    //       // Make the HTTP request to save in the backend
    //      console.log(newRow);

    //     },
    //     [],
    //   );

    //   const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    //       console.log(error);
    //   }, []);

    return (
        <div className="event-budget-table-container">
            <DataGridPro
                treeData
                rows={rows?.length > 0 ? updatedRows : dummyrow}
                columns={columns}
                getTreeDataPath={(row): string[] => 
                {
                    const rowPath = getTreeDataPath(row); return rowPath;
                }}
                groupingColDef={groupingColDef}
                className="event-budget-table"
                onCellClick={(params): void => 
                {
                    if (!APP_CONSTANTS.DATA_GRID_NON_CLICKABLE_COLUMNS.includes(params.field) && !params.isEditable) 
                    {
                        if (params.row.onClick) 
                        {
                            params.row.onClick();
                        }
                    }
                }}
                paginationMode="server"
                rowSelectionModel={selectionModel}
                // processRowUpdate={processRowUpdate}
                // onProcessRowUpdateError={handleProcessRowUpdateError}
                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel): void => 
                {
                    return setSelectionModel(rowSelectionModel);
                }
                }
                sx={{
                    height: '100%'
                }}
                pagination={true}
                rowCount={rowCount}
                paginationModel={{
                    pageSize: pageSize, page: page
                }}
                onPaginationModelChange={(paginationModel): void => 
                {
                    updateCurrentPage(paginationModel.page + 1);
                }}
            />
        </div>
    );
};

export const ActionsDropdown: React.FC<{onDeleteClick: () => void, onEditClick: () => void,  extraActions?: any}> = ({ onDeleteClick, onEditClick, extraActions }): React.JSX.Element => 
{
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenDropdown = (event): void => 
    {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleCloseDropdown = (event): void => 
    {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handleDelete = (event): void => 
    {
        onDeleteClick();
        handleCloseDropdown(event);
        event.stopPropagation();

    };
    const handleEdit = (event): void => 
    {
        onEditClick();
        handleCloseDropdown(event);
        event.stopPropagation();
    };

    return (
        <div>
            <IconButton className="actions-column-button" onClick={handleOpenDropdown}>
                <FontAwesomeIcon icon={['fal', 'ellipsis-vertical']} />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div id="dropDownBlock">
                    <div onClick={(event) => {handleEdit(event)}} className="dropDownMenu">
                        Edit
                    </div>
                    <div onClick={(event) => {handleDelete(event)}} className="dropDownMenuDelete">
                        Delete
                    </div>
                    {
                        extraActions && extraActions.map((action, index): React.JSX.Element => 
                        {
                            return (
                                <div key={index} onClick={(event) => {action.onClick(); event.stopPropagation(); handleCloseDropdown(event)}} className="dropDownMenu">
                                    {action.name}
                                </div>
                            );
                        })
                    }
                </div>
            </Popover>
        </div>
    );
};

export default EventBudgetDataGrid;

export const uniqueId = (): string => 
{
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
};


