import React, { memo } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import './styles.scss';
import { DataGridTabsProps } from './datagridtabs_interface';

const DataGridtabs = memo(({ width, tabsData, selectedTab, dataLength }: DataGridTabsProps): React.JSX.Element =>
{
    const defaultTabs = [ 
        {
            label: 'All',
            number: dataLength,
        },
    ];

    return (
        <div className="tabs-container">
            <Tabs value={selectedTab} aria-label="datagrid tabs">
                {tabsData ? tabsData?.tabs?.map((tab, index): React.JSX.Element => 
                {
                    return (
                        <Tab value={index} onClick={(event): void => 
                        {
                            if(tab?.onClick)
                            {
                                tab.onClick(event);
                            }
                        }} className="tab-component-button" label={<Box className="tab-button-cont">
                            <span className="tab-label">{tab.label}</span>
                            <span className="data-count">{tab.number}</span>
                        </Box>} key={index} disableRipple />
                    ); 
                }) : defaultTabs.map((tab, index): React.JSX.Element => 
                {
                    return (
                        <Tab value={index} className="tab-component-button" label={<Box className="tab-button-cont">
                            <span className="tab-label">{tab.label}</span>
                            <span className="data-count">{tab.number}</span>
                        </Box>} key={index} disableRipple />
                    );
                }
                )}
            </Tabs>
        </div>
    );
});

DataGridtabs.displayName = 'DataGridtabs';

export default DataGridtabs;