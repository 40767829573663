/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { bulkUpdateRegistrantStatus, deleteEventRegistrant, downloadInvoices, downloadRegistrantCSV, getAllEventRegistrants, getEventRegistrantById, getEventRegistrantCount, getEventRegistrantStatusCount, searchEventRegistrant, uploadRegistrants } from '../../../../../scripts/apis/eventRegistrants';
import './styles.scss';
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import RegistrantDetails from './RegistrantDetails';
import TableEmptyComponent from '../../../../../common/TableEmptyComponent';

// eslint-disable-next-line @typescript-eslint/no-var-requires
import registrantsEmptyImg from '../../../../../assets/icons/empty-state/registrantsEmpty.svg';

import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataImport from '../../../../../common/DataImport';
import { getRegistrationFormById } from '../../../../../scripts/apis/registrationForm';
import { camelCase } from 'camel-case';
import { useTablePagination } from '../../../../../contexts/TablePaginationContext';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { getAudienceColumnProperties } from '../../../../../scripts/apis/audience';
import { generateColumns } from '../../../../Audience/AudiencePage';
import ColumnFilterDataGrid from '../../../../../common/ColumnFilterDataGrid';
// eslint-disable-next-line import/named
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import AudienceGraph from '../../../../Audience/AudienceGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONTENT } from '../../../../../scripts/i18n';
import { getAlleventTickets, ticketsCount } from '../../../../../scripts/apis/eventTickets';
import { EnableApprovalStatus, EnableInvoiceStatus, EnableWaitlistStatus, TicketType } from '../../../enum';
import MuiChip from '../../../../../common/FormComponents/MuiChip';
import { EventRegistrantAttendedStatus, EventRegistrantPaymentStatus, EventRegistrantStatus } from '../../../enum/event-registrant.enum';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { EventGraphObject, EventTicket } from '../../../interfaces';
import { EventRegistrant } from '../../../interfaces/event-registrant_interface';
import { FormControlComponent } from '../../../../../common/FormComponents/ReusableFormComponents';
import { ActionsDropdown } from '../../../EventBudget/EventBudgetDataGrid';
import { CustomFieldMandatory } from '../../../../Registration/enum/custom-field.enum';
import FormTabs from '../../../../../common/FormTabs';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import DeletePopup from '../../../../../common/DeletePopup';

const Registrants = (props): React.JSX.Element => 
{

    const { status, eventRegistrantId } = useParams();
    const currentPath = useLocation().pathname;

    const eventId = props['data-id'];
    const eventReduxData = props.reduxData;

    const options = {
        sample_template_url: `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-registrant/${eventId}/generate-csv`,
        sample_template_button_text: 'Registrants Template'
    };

    const navigate = useNavigate();

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const paginationData = useSelector((state) =>
    {
        return state['paginationData']?.value?.registrants;
    });

    // let isEventNotPublished = eventReduxData.status !== 2;

    const [rows, setRows] = useState<EventRegistrant[]>([]);
    const [registrantData, setRegistrantData] = useState<EventRegistrant[]>([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [csvBoxready, isCsvBoxReady] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [registrantCount, setRegistrantCount] = useState(0);
    const [baseColumnProperties, setBaseColumnProperties] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [chartVisibility, setChartVisibility] = useState(false);
    const [registrantStatusCount, setRegistrantStatusCount] = useState<EventGraphObject>();
    const [ticketsData, setTicketsData] = useState<EventTicket[]>([]);
    const [additionalDataProperties, setAdditionalDataProperties] = useState([]);
    const [tableSearch, setTableSearch] = useState('');
    const [csvConversionSpinner, setCsvConversionSpinner] = useState<boolean>(false);
    const [selectedRegistrants, setSelectedRegistrants] = useState<string[] | number[]>([]);
    const [bulkUpdateSpinner, setBulkUpdateSpinner] = useState<boolean>(false);
    const [invoiceDownloadSpinner, setInvoiceDownloadSpinner] = useState<boolean>(false);

    const [isPaidTicketPresent, setPaidTicketPresent] = useState(false);
    const [isApprovalTicketPresent, setApprovalTicketPresent] = useState(false);
    const [showBulkActionsPopup, setShowBulkActionsPopup] = useState<boolean>(false);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fieldsToShow = ['companyLogo', 'firstName', 'lastName', 'email', 'status'];
    const allProperties = _.concat(additionalDataProperties, baseColumnProperties);
    let columns = generateColumns(allProperties, setAnchorEl, anchorEl, true);

    const companyLogoIndex = columns.findIndex(column => column.field === 'companyLogo');

    if (companyLogoIndex !== -1) {
        const companyLogoColumn = columns[companyLogoIndex];
        columns = [
            companyLogoColumn,
            ...columns.slice(0, companyLogoIndex),
            ...columns.slice(companyLogoIndex + 1)
        ];
    }

    const lastNameIndex = columns.findIndex(column => column.field === 'lastName');
    if (lastNameIndex !== -1) {
        const lastNameColumn = columns[lastNameIndex];
        // Remove the lastName column from its original position
        columns = [
            ...columns.slice(0, lastNameIndex),
            ...columns.slice(lastNameIndex + 1)
        ];
        // Insert the lastName column at the 2nd index
        columns = [
            ...columns.slice(0, 2),
            lastNameColumn,
            ...columns.slice(2)
        ];
    }


    const registrantTableTabs = {
        tabs: [
            {
                tabName: 'All',
                selectedTab: currentPath === `/events/${eventId}/people/registrants`,
                count: registrantCount,
                navigation: (): void => 
                {
                    eventBus.dispatch('datagrid-selected-row-ids', []);
                    setSelectedRegistrants([]);
                    return navigate(`/events/${eventId}/people/registrants`);
                }
            },
            {
                tabName: 'Confirmed',
                selectedTab: currentPath === `/events/${eventId}/people/registrants/confirmed`,
                count: registrantStatusCount?.CONFIRMED || '0',
                navigation: (): void => 
                {
                    eventBus.dispatch('datagrid-selected-row-ids', []);
                    setSelectedRegistrants([]);
                    return navigate(`/events/${eventId}/people/registrants/confirmed`);
                }
            }
        ]
    }; 

    const registrantStatus = (data): React.JSX.Element => 
    {
        const registrantClass = {
            1: 'grey',
            2: 'green',
            3: 'red',
            4: 'yellow',
            5: 'deleted',
            6: 'blue'
        };

        const status = data.status;
        const registrantStatusLabel = CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[Number(status)];
        const registrantChipColor = registrantClass[Number(status)];

        return (
            <Box className="registrant-status">
                <MuiChip label={registrantStatusLabel} chipColor={registrantChipColor} />
            </Box>
        );
    };

    const registrantStatusColumn: GridColDef = {
        field: 'status', align: 'center', headerName: 'Status', renderCell: (params):React.JSX.Element => 
        {
            return registrantStatus(params.row);
        },
        valueGetter: (params): string => 
        {
            return CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[Number(params.row.status)];
        },
        editable: false,
        disableColumnMenu: true,
    };

    const registrantPaymentStatus = (data): React.JSX.Element =>
    {
        const paymentStatusClass = {
            1: 'grey',
            2: 'blue',
            3: 'orange',
            4: 'red',
            5: 'yellow'
        };

        const status = data?.paymentStatus;
        const paymentStatusLabel = CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS[Number(status)];
        const paymentChipColor = paymentStatusClass[Number(status)];

        return (
            <Box className="registrant-payment-status">
                <MuiChip label={paymentStatusLabel} chipColor={paymentChipColor} />
            </Box>
        );
    };

    const registrantAttendedStatus = (data): React.JSX.Element => 
    {
        return(
            <Box className="registrant-attended-status">
                <MuiChip label={data?.attendeeStatus === EventRegistrantAttendedStatus.NO ? 'No' : 'Yes'} chipColor={data?.attendeeStatus === EventRegistrantAttendedStatus.NO ? 'red' : 'green'} />
            </Box>
        );
    };

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const eventStartDate = new Date(Number(eventReduxData?.eventStartDateTime) * 1000);
    eventStartDate.setHours(0, 0, 0, 0);

    const isCurrentDateSameorAfterEventStartDate = currentDate.getTime() >= eventStartDate.getTime();

    const isWaitListEnabledForTicket = ticketsData && ticketsData?.length > 0 && ticketsData?.some((ticket):boolean => { 
        return ticket?.enableWaitlistStatus === EnableWaitlistStatus.ENABLE;
    })

    const attendedStatusColumn = {
        field: 'attendedStatus', align: 'center', headerName: 'Attended Status', renderCell: (params):React.JSX.Element => 
        {
            return registrantAttendedStatus(params.row);
        },
        valueGetter: (params): string => 
        {
            return params.row.attendeeStatus === EventRegistrantAttendedStatus.YES ? 'Yes' : 'No';
        },
        editable: false,
        disableColumnMenu: true,
    };

    const registrantPaymentStatusColumn = {
        field: 'paymentStatus', align: 'center', headerName: 'Payment Status', renderCell: (params):React.JSX.Element => 
        {
            return registrantPaymentStatus(params.row);
        },
        valueGetter: (params): string => 
        {
            return CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS[Number(params.row.paymentStatus)];
        },
        editable: false,
        disableColumnMenu: true,
    };    

    columns?.splice(3, 0, registrantStatusColumn);
    if(isCurrentDateSameorAfterEventStartDate)
    {
        columns?.splice(5, 0, attendedStatusColumn);
        registrantTableTabs.tabs.splice(6, 0, {
            tabName: 'Attendee',
            selectedTab: currentPath === `/events/${eventId}/people/registrants/attendee`,
            count: registrantStatusCount?.ATTENDEE || '0',
            navigation: (): void => 
            {
                eventBus.dispatch('datagrid-selected-row-ids', []);
                setSelectedRegistrants([]);
                return navigate(`/events/${eventId}/people/registrants/attendee`);
            }
        });
    }

    if(isPaidTicketPresent)
    {

        columns?.splice(4, 0 , registrantPaymentStatusColumn);
        registrantTableTabs.tabs.splice(7, 0, {
            tabName: 'Paid',
            selectedTab: currentPath === `/events/${eventId}/people/registrants/paid`,
            count: registrantStatusCount?.PAID || '0',
            navigation: (): void => 
            {
                eventBus.dispatch('datagrid-selected-row-ids', []);
                return navigate(`/events/${eventId}/people/registrants/paid`);
            }
        });
    }

    if(isPaidTicketPresent || (registrantStatusCount && registrantStatusCount?.APPROVED && Number(registrantStatusCount?.APPROVED) > 0))
    {
        registrantTableTabs.tabs.splice(4, 0, {
            tabName: 'Approved',
            selectedTab: currentPath === `/events/${eventId}/people/registrants/approved`,
            count: registrantStatusCount?.APPROVED || '0',
            navigation: (): void => 
            {
                eventBus.dispatch('datagrid-selected-row-ids', []);
                setSelectedRegistrants([]);
                return navigate(`/events/${eventId}/people/registrants/approved`);
            }
        });
    }

    if(isApprovalTicketPresent)
    {
        registrantTableTabs.tabs.splice(1, 0, {
            tabName: 'In Review',
            selectedTab: currentPath === `/events/${eventId}/people/registrants/invite`,
            count: registrantStatusCount?.INVITE || '0',
            navigation: (): void => 
            {
                eventBus.dispatch('datagrid-selected-row-ids', []);
                setSelectedRegistrants([]);
                return navigate(`/events/${eventId}/people/registrants/invite`);
            }
        });

        if((eventReduxData?.enableWaitlistStatus === EnableWaitlistStatus.ENABLE) || isWaitListEnabledForTicket)
        {
            registrantTableTabs.tabs.splice(2, 0, {
                tabName: 'Waitlist',
                selectedTab: currentPath === `/events/${eventId}/people/registrants/waitlist`,
                count: registrantStatusCount?.WAITLIST || '0',
                navigation: (): void => 
                {
                    eventBus.dispatch('datagrid-selected-row-ids', []);
                    setSelectedRegistrants([]);
                    return navigate(`/events/${eventId}/people/registrants/waitlist`);
                }
            });
        }
        registrantTableTabs.tabs.splice(3, 0, {
            tabName: 'Rejected',
            selectedTab: currentPath === `/events/${eventId}/people/registrants/rejected`,
            count: registrantStatusCount?.REJECTED || '0',
            navigation: (): void => 
            {
                eventBus.dispatch('datagrid-selected-row-ids', []);
                setSelectedRegistrants([]);
                return navigate(`/events/${eventId}/people/registrants/rejected`);
            }
        });

    }

    // tickets column
    const isTicketPresent = registrantData?.some((obj) => {
        return (!(_.isEmpty(obj?.ticket)));
    });

    if(isTicketPresent)
    {
        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];

        const ticketColumn: GridColDef = {
            field: 'ticket', headerName: 'Ticket', flex: 1, minWidth: 120, renderCell: (params):React.JSX.Element => 
            {
                return params?.row?.ticket && <MuiChip label={params?.row?.ticket?.name} chipColor={chipColors[params?.row?.ticket?.id % chipColors.length]} />;
            },
            valueGetter: (params): string => 
            {
                return params.row?.ticket?.name;
            },
            editable: false,
            disableColumnMenu: true,
        };
        columns?.splice(4, 0, ticketColumn);
    }

    // coupon column
    const isCouponPresent = registrantData?.some((obj) => {
        return (!(_.isEmpty(obj?.coupon)));
    });

    if(isCouponPresent)
    {
        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
        
        const couponColumn: GridColDef = {
            field: 'coupon', headerName: 'Coupon', flex: 1, hideable: true, hide: true, minWidth: 120, renderCell: (params):React.JSX.Element => 
            {
                return params?.row?.coupon && <MuiChip label={params?.row?.coupon?.name} chipColor={chipColors[params?.row?.coupon?.id % chipColors.length]} />;
            },
            valueGetter: (params): string => 
            {
                return params.row?.coupon?.name;
            },
            editable: false,
            disableColumnMenu: true,
        };

        columns?.splice(5, 0, couponColumn);
    }
    // utm column
    const utmColumn: GridColDef = {
        field: 'shareLink', headerName: 'Share Link', flex: 1, minWidth: 160, renderCell: (params):React.JSX.Element => 
        {
            const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
            return (params?.row?.utm && <MuiChip label={params?.row?.utm?.name} chipColor={chipColors[params?.row?.utm?.id % chipColors.length]} />);
        },
        valueGetter: (params): string => 
        {
            return params.row?.utm?.name;
        },
        editable: false,
        disableColumnMenu: true,
    };
    columns?.splice(5, 0, utmColumn);

    // actions column
    const actions = {
        field: 'actions', headerName: '', editable: false, sortable: false, flex: 1, filterable: false, maxWidth: 40, disableColumnMenu: true, renderCell: (params): React.ReactElement => 
        {
            return registrantActions(params.row);
        }
    }

    // actions functionality
    const registrantActions = (row) =>
    {
        const editClick = (): void =>
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DATA_GRID.EVENT_REGISTRANT_ROW_CLICK, {
                rowData: row
            });
        };

        const deleteClick = (): void => 
        {
            eventBus.dispatch('selected-row-id', row.id);
            handleDelete(row);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    columns.push(actions);

    // handle delete registrant
    const handleDelete = (row: EventRegistrant): void => 
    {

        const confirmationText = `Are you sure you want to remove ${row?.firstName} from this event? \nNote: Registrant has to register again.`;
        const formatted = confirmationText.split('\n').map((item, i): React.JSX.Element => {
            return <React.Fragment key={i}>{item}<br /></React.Fragment>;
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: 'Remove Registrant',
            content: formatted,
            acceptBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.ACCEPT_BTN,
            acceptEvent: 'remove-registrant',
            rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
            acceptObj: {
                id: row.id
            },
        });

        deleteRegistrantFromTable();
    };

    // delete functionality
    const deleteRegistrantFromTable = async (): Promise<void> =>
    {
        let deleteSuccess = true;
        eventBus.on('remove-registrant', async (object):Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            
            if (deleteSuccess) 
            {
                if (rows && rows?.length > 0 && acceptObj.id) 
                {
                    try 
                    {
                        const eventDeleted = await deleteEventRegistrant(eventId, acceptObj.id);
                        if (eventDeleted) 
                        {
                            setRefresh(true);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: 'Registrant Removed',
                                severity: 'success',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                            deleteSuccess = false;
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }

                }
            }

        });
    };

    const openDrawer = (data): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: `${data?.firstName ?? ''} ${data?.lastName || data?.additionalData?.lastName || ''}`,
            hideCloseButton: true,
            component: <RegistrantDetails statusCount={registrantStatusCount} registrant={data} eventId={eventId}  />,
            noSidebarPadding: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const registrationFormData = async (): Promise<void> => 
    {
        if (eventReduxData.registrationFormId) 
        {
            try 
            {
                const registrationForm = await getRegistrationFormById(eventReduxData.registrationFormId);
                if (registrationForm) 
                {
                    const formComponents = registrationForm?.formFields;
                    const csvColArray = [];
                    const dynamicComponents = formComponents && formComponents?.length > 0 && formComponents?.map((component):{
                        column_name: string;
                        display_label: string;
                        info_hint: string;
                        type: string;
                        required: boolean;
                    } => 
                    {
                        const label = component?.propertyKey;
                        csvColArray.push(label);
                        return {
                            column_name: label,
                            display_label: label,
                            info_hint: component?.placeholder,
                            type: 'text',
                            required: component?.fieldMandatory == CustomFieldMandatory.MANDATORY
                        };
                    });
                    if (eventReduxData.registrationType === 2) 
                    {
                        // const eventTicketsCount = await ticketsCount(eventId);
                        // const eventTickets = await getAlleventTickets(eventTicketsCount, 0, eventId);

                        const colName = 'Ticket Name';
                        const ticketName = {
                            column_name: camelCase(colName),
                            display_label: colName,
                            info_hint: 'Enter the name of the ticket',
                            type: 'text',
                            required: true,
                            // validators: {
                            //     values: eventTickets.map((eventTicket) =>
                            //     {
                            //         return {
                            //             value: eventTicket.name,
                            //             display_label: eventTicket.name
                            //         }
                            //     }),
                            //     case_sensitive: false
                            // },
                        };
                        dynamicComponents.push(ticketName);
                    }
                    setDynamicColumns([...dynamicComponents]);
                    isCsvBoxReady(true);
                }

            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const handleDownloadCSV = async ():Promise<void> => 
    {
        setCsvConversionSpinner(true);
        try 
        {
            const response = await downloadRegistrantCSV(eventId);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${eventReduxData.title} registrants.csv`);
        }
        catch (error) 
        {
            console.log(error);
            toast.error('Error downloading the file');
        }
        finally
        {
            setCsvConversionSpinner(false);
        }
    };

    const fetchRegistrantData = async ():Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);
            const count = await getEventRegistrantCount(Number(eventId));
            const allStatusCount = await getEventRegistrantStatusCount(Number(eventId));
            if(allStatusCount)
            {
                const regStatusEnumObject = _.mapValues(EventRegistrantStatus, (value) => value.toString());
                setRegistrantStatusCount(_.mapKeys(allStatusCount, (value, key) => regStatusEnumObject[key]));
            }
            if (count) 
            {
                setRegistrantCount(count);
                try 
                {
                    const params = {
                        status: ['invite', 'waitlist', 'approved', 'rejected', 'confirmed'].includes(status) ? EventRegistrantStatus[status.toUpperCase() as keyof typeof EventRegistrantStatus] : undefined,
                        attendeeStatus: status === 'attendee' ? EventRegistrantAttendedStatus.YES : undefined,
                        paidStatus: status === 'paid' ? EventRegistrantPaymentStatus.PAID : undefined
                    };

                    const registrantData = await getAllEventRegistrants(
                        Number(eventId),
                        paginationData?.rowsPerPage ? paginationData?.rowsPerPage : pageSize ? pageSize : 25,
                        currentPage - 1,
                        status ? params.status : undefined,
                        status ? params.attendeeStatus : undefined,
                        status ? params.paidStatus : undefined
                    );
                
                    if (registrantData) {
                        setRows(registrantData);
                        setRegistrantData(registrantData);
                        setIsEmpty(registrantData.length === 0);

                        const initialColumnKeys = ['firstName', 'email', 'lastName'];
                        const initialColumns = initialColumnKeys.map((field) => ({
                            field,
                            headerName: _.startCase(field),
                            // hide: !fieldsToShow.includes(field),
                            disableColumnMenu: true,
                        }));

                        if (registrantData?.length > 0 && registrantData.some(obj => 'additionalData' in obj)) {
                            const additionalData = registrantData.map(obj => _.omit(obj.additionalData, 'gdprData'));
                            const additionalDataKeys = Object.keys(additionalData[0]);
                            const additionalDataColumns = additionalDataKeys.map((field) => ({
                                field,
                                headerName: _.startCase(field),
                                // hide: !fieldsToShow.includes(field),
                                disableColumnMenu: true,
                            }));
                            setAdditionalDataProperties([...initialColumns, ...additionalDataColumns]);
                        }
                    }
                } 
                catch (error) 
                {
                    console.log(error);
                } 
                finally 
                {
                    setShowSpinner(false);
                    setRefresh(false);
                }

                try 
                {
                    setShowSpinner(true);
                    const properties = await getAudienceColumnProperties();
                    if (properties) 
                    {
                        const updatedProperties = properties?.map(property => ({
                            ...property,
                            clearbit: true
                        }));

                        setShowSpinner(false);
                        const filteredBaseColumnProperties = updatedProperties?.filter((property):boolean => 
                        {
                            return property.field !== 'email' && property.field !== 'firstName' && property?.field !== 'lastName';
                        })
                        setBaseColumnProperties(filteredBaseColumnProperties);
                    }
                }
                catch (error) 
                {
                    setShowSpinner(false);
                    console.log(error);
                }

            }
        }
        catch (error) 
        {
            console.log(error);
        }
       
    };   

    useEffect(() => 
    {
        const delayDebounceFn = setTimeout(async (): Promise<void> => 
        {
            if ((tableSearch && tableSearch.length >= 2)) 
            {
                try 
                {
                    const params = {
                        status: ['invite', 'waitlist', 'approved', 'rejected', 'confirmed'].includes(status) ? EventRegistrantStatus[status?.toUpperCase() as keyof typeof EventRegistrantStatus] : undefined,
                        attendeeStatus: status === 'attendee' ? EventRegistrantAttendedStatus.YES : undefined,
                        paidStatus: status === 'paid' ? EventRegistrantPaymentStatus.PAID : undefined,
                    };

                    const registrantData = await searchEventRegistrant(
                        eventId,
                        tableSearch?.includes('@') ? tableSearch : undefined,
                        !tableSearch?.includes('@') ? tableSearch : undefined,
                        undefined,
                        status ? params.status : undefined,
                        status ? params.paidStatus : undefined,
                        status ? params.attendeeStatus : undefined,
                    );

                    if (registrantData) 
                    {
                        setRows(registrantData);
                        setRegistrantData(registrantData);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
            else if (tableSearch === '') 
            {
                fetchRegistrantData();
            }
        }, 300);
        return (): void => 
        {
            return clearTimeout(delayDebounceFn);
        };
    }, [tableSearch]);

    useEffect(():void => 
    {
        if (eventReduxData.title) 
        {
            registrationFormData();
            if (currentPage > 0 && pageSize) 
            {
                fetchRegistrantData();
            }
        }

        eventBus.on('import-event-registrant', (data):void => 
        {
            const registrantObj = {
            };
            const aundienceBatches = _.chunk(data.regregistrantArr, 20);
            aundienceBatches.forEach(async (registrantList):Promise<void> => 
            {
                registrantObj['registrants'] = registrantList;
                await uploadRegistrants(Number(eventId), registrantObj, csrfTokenData);
            });
        });
    }, [eventReduxData, currentPage, pageSize, status]);

    useEffect(():void => 
    {
        if (refresh) 
        {
            fetchRegistrantData(); 
        }
    }, [refresh, status]);

    const fetchDataFromId = async ():Promise<void> => 
    {
        if (eventRegistrantId) 
        {
            try 
            {
                const registrantData = await getEventRegistrantById(eventId, Number(eventRegistrantId));
                if (registrantData) 
                {
                    openDrawer(registrantData);
                }
                else 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'The Registrant you\'re trying to access doesn\'t exist',
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'center',
                    });
                    navigate('/events/' + eventId + '/people/registrants');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const fetchEventTickets = async ():Promise<void> =>
    {
        try 
        {
            const eventTicketsCount = await ticketsCount(eventId);
            const eventTickets = await getAlleventTickets(eventTicketsCount, 0, eventId);
            if (eventTickets && eventTickets?.length > 0) 
            {
                setTicketsData(eventTickets);
                const isPaidTicketsPresent = eventTickets?.some((ticket):boolean => 
                {
                    return ticket?.type === TicketType.PAID;
                });

                const doesAnyTicketRequiresApproval = eventTickets?.some((ticket) => ticket?.enableApproval === EnableApprovalStatus.ENABLE);

                if(isPaidTicketsPresent)
                {
                    setPaidTicketPresent(true);
                }

                if(doesAnyTicketRequiresApproval)
                {
                    setApprovalTicketPresent(true);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleInvoiceDownload = async (): Promise<void> =>
    {
        setInvoiceDownloadSpinner(true);
        try 
        {
            const response = await downloadInvoices(eventId);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${eventReduxData.title} invoices.zip`);
            toast.success('Invoices downloaded successfully');
        } 
        catch (error) 
        {
            console.error('Error downloading the file:', error);
            toast.error('Error in downloading invoices');
        }
        finally
        {
            setInvoiceDownloadSpinner(false);
        }
    };

    const handleBulkActions = async (moveToConfirmed?: boolean): Promise<void> =>
    {
        setBulkUpdateSpinner(true);

        const selectedStatus = ['invite', 'waitlist', 'approved', 'rejected', 'confirmed'].includes(status) && EventRegistrantStatus[status.toUpperCase() as keyof typeof EventRegistrantStatus];

        const data = {
            registrantIds: selectedRegistrants,
            status: moveToConfirmed ? EventRegistrantStatus.CONFIRMED : selectedStatus
        };

        try 
        {
            const isStatusUpdated = await bulkUpdateRegistrantStatus(eventId, data);
            if(isStatusUpdated)
            {
                setRefresh(true);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Status Updated',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setBulkUpdateSpinner(false);
            setShowBulkActionsPopup(false);
        }
    };

    useEffect(():void => 
    {
        fetchDataFromId();
        fetchEventTickets();

        eventBus.on('registrant-status-updated', (data):void => 
        {
            if(data.refresh)
            {
                setRefresh(data.refresh);
            }
        });
    }, [status]);

    useEffect(() => {
        eventBus.on(APP_CONSTANTS.EVENTS.DATA_GRID.EVENT_REGISTRANT_ROW_CLICK, (data):void => 
        {
            openDrawer(data.rowData);
        });
    }, [registrantStatusCount]);

    useEffect(() => {
        eventBus.on('datagrid-selected-row-ids', (ids: string[] | number[]) =>
        {
            setSelectedRegistrants(ids || []);
        });

        eventBus.on('selected-row-id', (ids) => {
            setSelectedRegistrants(ids || []);
        })
    }, []);

    const RegistrantsTableToolbar = (): React.JSX.Element => 
    {
        return (
            <div className="registrants-toolbar">
                <FormTabs tabs={registrantTableTabs.tabs} />
                <div className="buttons-container">
                    
                    <CustomButton loading={csvConversionSpinner} onClick={handleDownloadCSV} name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'download']} />} />
                    <GridToolbarFilterButton className="toolbar-columns-button" />
                    <GridToolbarColumnsButton className="toolbar-columns-button" />

                    {(selectedRegistrants?.length > 0 && !currentPath?.endsWith('registrants')) && <CustomButton loading={bulkUpdateSpinner} name={'Actions' } type='button' btnType='primary' onClick={() => setShowBulkActionsPopup(true)} />}
                </div>
            </div>
        )
    };

    return (
        <Box id="eventRegistrants">
            {/* <CSVImport open={modalOpen} close={handleClose} eventName={selectedEvent} eventId={eventId} /> */}
            <Box id='inside-event-module-name-section'>{'Registrants'}
                <Box className="event-registrants-content-grid">
                    <Box className="event-registrants-button-container">
                        <CustomButton
                            name={chartVisibility? 'Close Charts' :'Open Charts'}
                            onClick={():void => 
                            {
                                setChartVisibility(!chartVisibility);
                            }}
                            btnType='secondary'
                        />
                        {(!isEmpty && registrantCount !== 0) && <Box className="registrant-search-cont">
                            <FormControlComponent startIcon='search' key='search' clearable value={tableSearch} placeholder='Search' type='text' onChange={(event) => {
                                setTableSearch(event.target.value);
                            }} />
                            {/* <SelectComponent value={searchBy} options={searchByOptions} defaultPlaceholder='Search By' onChange={(event) => setSearchBy(event.target.value)} optionLabels={searchByOptionLabel} /> */}
                            {/* <SelectComponent showNone='Search by Ticket' value={selectedTicket} options={ticketsData} onChange={(event) => setSelectedTicket(event.target.value)} optionLabels={eventTicketsOptionLabel} /> */}
                        </Box>}
                        {csvBoxready ?  <DataImport licenseKey={import.meta.env.VITE_REGISTRANT_SHEET_ID} eventId={eventId} dynamicColumns={dynamicColumns} options={options} />: <Tooltip disableInteractive placement='top'
                            title={
                                <Typography className="reg-import-msg">{'Registration Form needs to be added.'}</Typography>
                            }
                        >
                            <CustomButton btnType='secondary' name='Import' startIcon={<FontAwesomeIcon icon={['fal', 'file-import']} />} />
                        </Tooltip>
                        }
                        {(isPaidTicketPresent && (ticketsData?.some((ticket) => ticket.enableInvoice === EnableInvoiceStatus.ENABLE))) && 
                            
                                <CustomButton 
                                    name=""
                                    btnType='secondary'
                                    onClick={handleInvoiceDownload}
                                    startIcon={<Tooltip title={<p>{'Download invoices'}</p>} placement='top'><FontAwesomeIcon icon={['fal', 'download']} /></Tooltip>}
                                    loading={invoiceDownloadSpinner}
                                />
                            
                        }
                    </Box>
                </Box>
            </Box>
           
            { chartVisibility ? <AudienceGraph audCount={registrantCount || '-'} eventId={eventId} trendsTitle={'Total Registrants'} eventRegistrantsGraph={true}></AudienceGraph> : null }
            <Box key={chartVisibility ? 'registrant-page-chart-table' : 'registrant-page-table'} 
                sx={chartVisibility?{
                    flex: 1,
                    height: '60% !important',
                }:{
                    height: 'calc(100vh - 118px)',
                    maxHeight: 'calc(100vh - 118px)',
                }}>
                {isEmpty && registrantCount === '0' ?
                    (<TableEmptyComponent emptyImg={registrantsEmptyImg} infoText={'No Registrants'} />) :
                    (<ColumnFilterDataGrid checkbox hiddenFields={['firstName', 'email', 'companyLogo', 'status']} customToolbar={RegistrantsTableToolbar} height={'100%'} rows={rows} columns={columns} eventsModule={true} rowClickEvent={APP_CONSTANTS.EVENTS.DATA_GRID.EVENT_REGISTRANT_ROW_CLICK} rowCount={status ? registrantStatusCount?.[status?.toUpperCase()] ? registrantStatusCount?.[status?.toUpperCase()] : 0 : registrantCount} isPagination={true} hidePagination={tableSearch !== ''} pageSize={pageSize} page={currentPage - 1} updateCurrentPage={updateCurrentPage} updatePageSize={updatePageSize} customDefaultPageSize={100} showSpinner={showSpinner} showColumnFilter={true} isEmpty={isEmpty}
                        pinnedColumns={[GRID_CHECKBOX_SELECTION_COL_DEF.field, ...fieldsToShow]} rightPinnedColumns={['actions']}
                    />)
                }
            </Box>

            {
                showBulkActionsPopup && 
                <DeletePopup
                    show={showBulkActionsPopup}
                    modalTitle='Actions'
                    modalContent={`Choose an action to perform on ${selectedRegistrants?.length} registrants`}
                    cancelClick={(): void => setShowBulkActionsPopup(false)}
                    extraActions={[
                        <CustomButton btnType='secondary' name='Retrigger Email' onClick={() => handleBulkActions() } disabled={bulkUpdateSpinner} />,
                        !currentPath.includes('confirmed') && <CustomButton btnType='primary' name='Move to confirmed' onClick={() => handleBulkActions(true) } disabled={bulkUpdateSpinner} />,
                    ].filter(Boolean)}
                />
            }
        </Box>
    );
};

export default Registrants;
