import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import _ from 'lodash';
import CreateSpeaker from '../../components/Speakers/CreateSpeaker';
import { resetSpeakers } from '../../redux/speakers/Speakers';
import { useNavigate, useParams } from 'react-router-dom';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import DataImport from '../../common/DataImport';
import { SpeakersContext } from '../../contexts/SpeakersPageContext';
import { getSpeakerById } from '../../scripts/apis/speakers';
import SpeakersGraph from './SpeakersGraph';
import { useDispatch } from 'react-redux';
import speakerEmptyImg from '../../assets/icons/empty-state/speakerEmpty.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { Speaker } from './interfaces';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { generateSpeakerColumnsForTanstackTable } from './speakerColumn.helper';
import { TableActions } from '../../common/TableActions';
import { CustomButton } from '../../common/FormComponents/Buttons';
import SpeakersToolbar from '../../components/Speakers/GlobalSpeakerToolbar';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import EventsCardHeaderComponent from '../../components/Events/EventsCardHeaderComponent';
import toast from 'react-hot-toast';
import CardComponent from '../../components/Events/Registrations/CardComponent';
import AddEventSpeakers from '../Events/EventDetails/People/AddEventSpeakers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import mailIcon from '../../assets/icons/envelope-light.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../assets/icons/linkedin-solid.svg';

import './styles.scss';

const SpeakersPage: React.FC<{ eventId?: string | number }> = (props): React.JSX.Element => {

    const { eventId } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { speakerId } = useParams();
    const [chartVisibility, setChartVisibility] = useState(false);

    const {
        speakerDataCount,
        isEmpty,
        speakers,
        setSpeakers,
        deleteSpeakerFromTable,
        setRefresh,
        pageSize,
        updateCurrentPage,
        updatePageSize,
        showSpinner,
        isChartReady,
        speakersIcpData,
        speakerBuyerPersonaData,
        speakerRevenueData,
        currentPage,
        trendData,
        speakerData,
        showDeletePopup,
        setShowDeletePopup,
        selectedSpeaker,
        setSelectedSpeaker,
        isTableView,
        cardView,
    } = useContext(SpeakersContext);

    // const [searchText, setSearchText] = useState<string>('');
    const columnHelper = createColumnHelper<Speaker>();

    const generateInitialColumns = () => [
        columnHelper.accessor(row => row?.additionalInfo?.image, {
            cell: ({ getValue, row }) => {
                const profilePicture = getValue();
                const nameSplitted = row.original.name.split(' ');
                const emptyImagesrc = nameSplitted.length >= 2 ? nameSplitted[0][0] + nameSplitted.slice(1).join(' ')[0] : row.original.name.charAt(0);
                return profilePicture ? <Avatar className="speaker-avatar" src={profilePicture} /> : <Avatar className="empty-speaker-avatar" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>;
            },
            header: 'Image',
            id: 'image',
            size: 100,
            enableSorting: false,
        }),
        columnHelper.accessor('name', {
            cell: ({ getValue }) => <p className="bolded-cellContent">{getValue()}</p>,
            header: 'Name',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name',
        }),
        columnHelper.accessor('email', {
            cell: ({ getValue }) => <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{getValue()}</Typography>}><Box className="cellContent">{getValue()}</Box></Tooltip>,
            header: 'Email',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'email',
        }),
        columnHelper.accessor('additionalInfo.headline', {
            cell: ({ getValue }) => <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{getValue()}</Typography>}><Box className="cellContent">{getValue()}</Box></Tooltip>,
            header: 'Headline',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'headline',
        }),
        columnHelper.accessor('actions' as any, {
            cell: ({ row }) => (
                <TableActions
                    actions={[
                        {
                            title: 'Edit', onClick: () => {
                                if (eventId) {
                                    editEventSpeaker(row.original, false);
                                }
                                else {
                                    editSpeaker(row.original);
                                }
                            }
                        },
                        {
                            title: 'Delete', onClick: () => {
                                setShowDeletePopup(true);
                                setSelectedSpeaker(row.original);
                            }
                        }
                    ]}
                />
            ),
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ];

    const speakerColumns = [...generateInitialColumns(), ...generateSpeakerColumnsForTanstackTable()].flat();
    const [allColumns, setAllColumns] = useState(speakerColumns);

    const editSpeaker = (speakerInfo: Speaker, routeFromId?: boolean): void => {
        dispatch(resetSpeakers());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPEAKERS_PAGE.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <CreateSpeaker createorUpdateGlobalSpeaker={true} speakerId={speakerInfo.id} speakerData={speakerInfo} speakerEdit={true} setRefresh={setRefresh} routeFromId={routeFromId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const editEventSpeaker = (speakerInfo: Speaker, routeFromEventSpeaker?: boolean): void => {
        dispatch(resetSpeakers());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPEAKERS_PAGE.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <CreateSpeaker isTableView={isTableView} speakerId={speakerInfo.id} speakerData={speakerInfo} speakerEdit={true} setRefresh={setRefresh} routeFromEventSpeaker={routeFromEventSpeaker} data-eventId={eventId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const openDrawer = (createComp?: boolean): void => {
        if (createComp) {
            dispatch(resetSpeakers());
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading: CONTENT.SPEAKERS_PAGE.SIDE_DRAWER.HEADING.CREATE,
                hideCloseButton: true,
                component: <CreateSpeaker createorUpdateGlobalSpeaker={true} setRefresh={setRefresh} />,
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const openEventSpeakerDrawer = (): void => {
        dispatch(resetSpeakers());

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Speaker',
            event: 'add-speaker',
            hideCloseButton: true,
            component: <AddEventSpeakers isTableView={isTableView} eventId={eventId} setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createEventSpeakerDrawer = (): void => {
        dispatch(resetSpeakers());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create Speaker',
            event: 'create-speaker',
            hideCloseButton: true,
            component: <CreateSpeaker bottomBtn={false} data-eventId={eventId} create-event-speaker={true} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    useEffect((): void => {
        const fetchDataFromRoute = async (): Promise<void> => {
            if (speakers && speakers.length && speakerId) {
                try {
                    const speakerDataFromId = await getSpeakerById(Number(speakerId));
                    if (speakerDataFromId) {
                        editSpeaker(speakerDataFromId, true);
                    }
                    else if (!speakerDataFromId) {
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'The speaker you\'re trying to access doesn\'t exist',
                            severity: 'error',
                            positionVertical: 'top',
                            positionHorizontal: 'center',
                        });
                        navigate('/speakers');
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        };
        fetchDataFromRoute();
    }, [speakers]);

    useEffect((): void => 
    {
        if(!pageSize && cardView)
        {
            updatePageSize(25);
        }
    }, [pageSize]);   

    return (
        <div style={{ height: (isTableView && eventId) ? 'calc(100vh - 57px)' : '', overflow: (isTableView && eventId) ? 'hidden' : '' }} id={eventId ? "eventSpeakers" : "speakersPage"}>
            {(isTableView || !cardView) &&
                <>
                    <HeaderBar
                        title={eventId ? <p><FontAwesomeIcon onClick={() => navigate('/events/' + eventId + '/overview')} cursor="pointer" style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} /> Speakers</p> : <p>Speakers</p>}
                        buttons={
                            eventId ?
                                [
                                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                                    <DataImport licenseKey={import.meta.env.VITE_SPEAKER_SHEET_ID} eventId={eventId} />,
                                    <CustomButton btnType='secondary' name='Select Speaker' onClick={openEventSpeakerDrawer} />,
                                    <CustomButton btnType='secondary' name='Add Speaker' onClick={createEventSpeakerDrawer} />
                                ] :
                                [
                                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                                    <DataImport licenseKey={import.meta.env.VITE_SPEAKER_SHEET_ID} />,
                                    <CustomButton name='Add Speaker' btnType='primary' onClick={() => openDrawer(true)} />
                                ]
                        }
                    />
                    {chartVisibility && <SpeakersGraph speakerCount={speakerDataCount} isChartReady={isChartReady} speakersIcpData={speakersIcpData} speakerBuyerPersonaData={speakerBuyerPersonaData} speakerRevenueData={speakerRevenueData} trendData={trendData} />}
                    <div
                        style={{
                            height: chartVisibility ? '60%' : 'calc(100vh - 60px)',
                            maxHeight: !chartVisibility ? 'calc(100vh - 60px)' : '',
                            flex: 1
                        }}
                    >
                        {
                            isEmpty ? (
                                <TableEmptyComponent
                                    emptyImg={speakerEmptyImg}
                                    openDrawer={openDrawer}
                                    infoText={'No Speaker'}
                                    subInfoText={'Add your first Speaker'}
                                    buttonName={'Add Speaker'}
                                />
                            ) : (
                                <div className="h-100">
                                    <SpeakersToolbar
                                        // searchText={searchText}
                                        // handleSearch={handleSearch}
                                        speakerDataCount={speakerDataCount}
                                        columns={speakerColumns}
                                        rows={speakerData}
                                        setSpeakers={setSpeakers}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        setColumns={setAllColumns}
                                    />
                                    <TanstackTable
                                        data={speakers}
                                        initialColumns={allColumns}
                                        rowCount={speakerDataCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updateCurrentPage={updateCurrentPage as any}
                                        updatePageSize={updatePageSize}
                                        onRowClick={(row) => {
                                            if (eventId) {
                                                editEventSpeaker(row as Speaker);
                                            }
                                            else {
                                                editSpeaker(row as Speaker)
                                            }
                                        }}
                                        showSpinner={showSpinner}
                                        height={
                                            chartVisibility ? 
                                                (eventId ? `calc(100% - 120px)` : `calc(100% - 170px)`) : 
                                                (eventId ? `calc(100% - 168px)` : `calc(100% - 109px)`)
                                        }
                                        rightPinnedColumns={['actions']}
                                    // leftPinnedColumns={['image', 'name', 'email']}
                                    />
                                </div>
                            )
                        }
                    </div>
                </>
            }

            {
                !isTableView && <div className="speakers-container">

                    <EventsCardHeaderComponent heading='Speakers' count={speakerDataCount} buttonAction={() => navigate('/events/' + eventId + '/people/speakers')} />

                    <Grid container spacing={2}>
                        {speakers?.map((speaker: Speaker, index: number) => {

                            const profilePicture = speaker?.additionalInfo && speaker?.additionalInfo.image ? speaker?.additionalInfo.image : '';
                            const nameSplitted = speaker?.name.split(' ');
                            let firstNameInitial: string;
                            let lastNameInitial: string;
                            let emptyImagesrc: string;
                            let speakerSocialsArr: { icon: string, link: string }[] = [];

                            speakerSocialsArr?.unshift({
                                icon: mailIcon,
                                link: speaker?.email
                            })

                            if (speaker?.additionalInfo?.social?.twitter) {
                                speakerSocialsArr.push({
                                    icon: twitterIcon,
                                    link: speaker?.additionalInfo?.social?.twitter
                                });
                            }
                            if (speaker?.additionalInfo?.social?.linkedIn) {
                                speakerSocialsArr.push({
                                    icon: linkedInIcon,
                                    link: speaker?.additionalInfo?.social?.linkedIn
                                });
                            }

                            if (nameSplitted.length >= 2) {
                                firstNameInitial = nameSplitted[0][0];
                                const lastName = nameSplitted.slice(1).join(' ');
                                lastNameInitial = lastName[0];
                                emptyImagesrc = firstNameInitial + lastNameInitial;
                            }
                            else {
                                emptyImagesrc = speaker?.name.charAt(0);
                            }

                            const speakerImage = (<div>{profilePicture !== '' ? <Avatar className="speaker-card-avatar" src={profilePicture}></Avatar> : <Avatar className="card-empty-speaker-avatar" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
                            const speakerName = (<div className="speaker-name-container"><p>{speaker?.name}</p></div>);
                            const speakerSocials = (<div className="speaker-socials-icons">{speakerSocialsArr.map((social, index) => {
                                return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
                                    if (social.link !== speaker?.email) {
                                        window.open(social.link, '_blank');
                                        event.stopPropagation();
                                    }
                                    else {
                                        navigator.clipboard.writeText(`${speaker.email}`);
                                        toast.success('Email copied to clipboard');
                                        event.stopPropagation();
                                    }

                                }} />
                            })}</div>);

                            const footerContent = (
                                <div className="speaker-headline-social-contents">

                                    <p>{speaker?.additionalInfo?.headline}</p>

                                    {speakerSocials}
                                </div>
                            );

                            return (
                                <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <CardComponent
                                        header={speakerImage}
                                        cardMinHeight='144px'
                                        contentHeading={speakerName}
                                        contentHeadingBold
                                        footerLeft={footerContent}
                                        gapNotRequired
                                        onClick={() => { editEventSpeaker(speaker) }}
                                    />
                                </Grid>
                            )
                        })}

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <CardComponent
                                emptyText='+ Add Speaker'
                                onClick={openEventSpeakerDrawer}
                                emptyCardHeight='116px'
                            />
                        </Grid>
                    </Grid>
                </div>
            }

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => deleteSpeakerFromTable(selectedSpeaker as Speaker)}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedSpeaker(null);
                    }}
                    modalContent={`Are you sure you want to delete ${selectedSpeaker?.name}?`}
                    modalTitle='Delete Speaker'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                />
            }
        </div>
    );
};
export default SpeakersPage;


// Needed for future *****IMP*****

// const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
//     setSearchText(event.target.value);
//     debouncedSearch(event.target.value, setSpeakers, searchSpeakers, getSpeakers, pageSize, currentPage);
// }; 

// const debouncedSearch = debounce(async (filters, setSpeakers, searchSpeakers, getSpeakers, pageSize, currentPage) => {
//     if (filters.length > 0 && filters.some(filter => filter.inputValue !== '')) {
//         try {
//             const searchValues = filters.map(filter => filter.inputValue);
//             const filteredSpeakers = await searchSpeakers(
//                 searchValues.some(value => value.includes('@')) ? undefined : searchValues,
//                 searchValues.some(value => value.includes('@')) ? searchValues : undefined
//             );
//             if (filteredSpeakers) {
//                 setSpeakers(filteredSpeakers);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     } else {
//         try {
//             const speakersData = await getSpeakers(pageSize, currentPage - 1);
//             if (speakersData) {
//                 setSpeakers(speakersData);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }, 300);