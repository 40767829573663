/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import IntegrationsPage from './IntegrationsPage';
import { IntegrationTypes } from '../enum/integrations.enum';
import NavigationTabs from '../../../common/NavigationTabs';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

enum IntegrationGroups {
    CRM = 'CRM',
    PAYMENTS = 'Payments',
    MARKETING = 'Marketing',
    EVENT_REGISTRATION = 'Event Registration',
    CSM = 'CSM',
    ENRICHMENT = 'Enrichment',
}

const IntegrationsTabPage = (): React.JSX.Element => {
    // const tab = props.subTab;


    const currentPath = useLocation().pathname;

    const allIntegrationsData = [
        {
            name: 'HubSpot',
            description:
                'Integrate your Hubspot CRM for analytics.',
            type: IntegrationTypes.HUBSPOT,
            group: IntegrationGroups.CRM,
            website: 'https://www.hubspot.com/',
        },
        {
            name: 'Razorpay',
            type: IntegrationTypes.RAZORPAY,
            comingSoon: false,
            group: IntegrationGroups.PAYMENTS,
            website: 'https://razorpay.com/',
        },
        {
            name: 'Luma',
            description:
                'Duis hendrerit ac nisi nec tristique. Quisque scelerisque est tortor, at aliquet ligula varius vitae.',
            type: IntegrationTypes.LUMA,
            comingSoon: false,
            noRedirect: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://lu.ma/',
        },
        {
            name: 'Stripe',
            type: IntegrationTypes.STRIPE,
            comingSoon: true,
            group: IntegrationGroups.PAYMENTS,
            website: 'https://stripe.com/',
        },
        {
            name: 'Salesforce',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra commodo fermentum.',
            type: IntegrationTypes.SALES_FORCE,
            comingSoon: true,
            group: IntegrationGroups.CRM,
            website: 'https://www.salesforce.com/',
        },
        {
            name: 'Pipedrive',
            description:
                'Duis hendrerit ac nisi nec tristique. Quisque scelerisque est tortor, at aliquet ligula varius vitae.',
            type: IntegrationTypes.PIPEDRIVE,
            comingSoon: true,
            group: IntegrationGroups.CRM,
            website: 'https://www.pipedrive.com/',
        },
        {
            name: 'Gainsight',
            description:
                'Vivamus nunc nulla, tempus ac congue mattis, dignissim et libero. Nulla fringilla risus at interdum sollicitudin.',
            type: IntegrationTypes.GAINSIGHT,
            comingSoon: true,
            group: IntegrationGroups.CSM,
            website: 'https://www.gainsight.com/',
        },
        {
            name: 'Totango',
            description:
                'In posuere quam in risus ornare ultricies. Pellentesque vel interdum enim. Vivamus tristique mattis risus ac maximus.',
            type: IntegrationTypes.TOTANGO,
            comingSoon: true,
            group: IntegrationGroups.CSM,
            website: 'https://www.totango.com/',
        },
        {
            name: 'Apollo.io',
            description:
                'Ut porta ante in tortor aliquet consectetur. Morbi iaculis suscipit lorem, ut mollis metus maximus sit amet.',
            type: IntegrationTypes.APOLLO_IO,
            comingSoon: true,
            group: IntegrationGroups.ENRICHMENT,
            website: 'https://www.apollo.io/',
        },
        {
            name: 'Zoominfo',
            description:
                'Duis hendrerit ac nisi nec tristique. Quisque scelerisque est tortor, at aliquet ligula varius vitae.',
            type: IntegrationTypes.ZOOMINFO,
            comingSoon: true,
            group: IntegrationGroups.ENRICHMENT,
            website: 'https://www.zoominfo.com/',
        },
        {
            name: 'Goldcast',
            description:
                'Suspendisse laoreet lacus in sapien fringilla, vel semper leo fermentum.',
            type: IntegrationTypes.GOLDCAST,
            comingSoon: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://www.goldcast.io/',
        },
        {
            name: 'Splashthat',
            description:
                'Curabitur neque neque, convallis accumsan lobortis in, consectetur quis est.',
            type: IntegrationTypes.SPLASHTHAT,
            comingSoon: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://splashthat.com/',
        },
        {
            name: 'Zoom webinar',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec viverra commodo fermentum. Vivamus nunc nulla, tempus ac congue mattis, dignissim et libero',
            type: IntegrationTypes.ZOOM_WEBINAR,
            comingSoon: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://zoom.us/',
        },
        {
            name: 'Eventbrite',
            description:
                'Nulla fringilla risus at interdum sollicitudin. In posuere quam in risus ornare ultricies. Pellentesque vel interdum enim. ',
            type: IntegrationTypes.EVENTBRITE,
            comingSoon: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://www.eventbrite.com/',
        },
        {
            name: 'Marketo',
            description:
                'Ut porta ante in tortor aliquet consectetur. Morbi iaculis suscipit lorem, ut mollis metus maximus sit amet. ',
            type: IntegrationTypes.MARKETO,
            comingSoon: true,
            group: IntegrationGroups.MARKETING,
            website: 'https://www.marketo.com/',
        },
        {
            name: 'Clearbit',
            description:
                'Ut porta ante in tortor aliquet consectetur. Morbi iaculis suscipit lorem, ut mollis metus maximus sit amet.',
            type: IntegrationTypes.CLEARBIT,
            comingSoon: true,
            group: IntegrationGroups.ENRICHMENT,
            website: 'https://clearbit.com/',
        },
    ];

    const tabData = [
        {
            value: '1',
            title: 'All Integrations',
            to: '/settings/integrations',
            data: <IntegrationsPage integrationsArr={allIntegrationsData} />,
            selected: currentPath === '/settings/integrations',
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ALL,
        },
        {
            value: '2',
            title: 'CRM',
            to: '/settings/integrations/crm',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.CRM;
            }).true} />,
            selected: currentPath === '/settings/integrations/crm',
            // // count: userDataCount,
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CRM,
        },
        {
            value: '3',
            title: 'CSM Tools',
            to: '/settings/integrations/csm-tools',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.CSM;
            }).true} />,
            selected: currentPath === '/settings/integrations/csm-tools',
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CSM_TOOLS,
        },
        {
            value: '4',
            title: 'Enrichment',
            to: '/settings/integrations/enrichment',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.ENRICHMENT;
            }).true} />,
            selected: currentPath === '/settings/integrations/enrichment',
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ENRICHMENT,
        },
        {
            value: '5',
            title: 'Event Registration Tools',
            to: '/settings/integrations/registration-tools',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.EVENT_REGISTRATION;
            }).true} />,
            selected: currentPath === '/settings/integrations/registration-tools',
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.EVENT_REGISTRATION_TOOLS,
        },
        {
            value: '6',
            title: 'Marketing Tools',
            to: '/settings/integrations/marketing-tools',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.MARKETING;
            }).true} />,
            selected: currentPath === '/settings/integrations/marketing-tools',
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.MARKETING_TOOLS,
        },
        {
            value: '7',
            title: 'Payments',
            to: '/settings/integrations/payments',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.PAYMENTS;
            }).true} />,
            selected: currentPath === '/settings/integrations/payments',
            // selected: tab === CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.PAYMENTS,
        }
    ];

    return (
        <Box id="integrationsTabPage">
            <Box className="heading-block">
                <Typography className="heading-text">Integrations</Typography>
            </Box>
            <div className="integration-tab-container">
                <NavigationTabs borderBottom={'1px solid #F1F1F1'} tabData={tabData} />
            </div>
        </Box>
    );
};

export default IntegrationsTabPage;