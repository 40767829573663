import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField, Box, Stack, Typography, createFilterOptions, Divider, Tooltip } from '@mui/material';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import _, { debounce } from 'lodash';
import { getAllSponsors, sponsorsCount } from '../../../../scripts/apis/sponsors';
import { addSponsors, resetSponsors } from '../../../../redux/sponsors/Sponsors';
import Sponsor from '../../../../components/Sponsors/Sponsor';
import { createEventSponsors, getAllSponsorTiers, getEventSponsors, getEventSponsorsCount, removeEventSponsor, sponsorTierCount, updateEventSponsor } from '../../../../scripts/apis/eventSponsors';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateSponsorGroup from './Sponsors/CreateSponsorGroup';
import './styles.scss';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { AutocompleteComponent, AutocompleteComponentWithFilterOption, CloseIconComponent, FormLabelComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllEventRegistrants, searchEventRegistrant } from '../../../../scripts/apis/eventRegistrants';
import { EventRegistrant } from '../../interfaces/event-registrant_interface';
import MuiChip from '../../../../common/FormComponents/MuiChip';
import { CONTENT } from '../../../../scripts/i18n';
import ButtonGroup from '../../../../common/ButtonGroup';
import { EventRegistrantStatus } from '../../enum/event-registrant.enum';
import toast from 'react-hot-toast';
import DeletePopup from '../../../../common/DeletePopup';

const AddSponsor = (props):React.JSX.Element => 
{

    const currentpath = useLocation().pathname;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const filter = createFilterOptions();
    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const defaultSponsorObj = {
        status: 0,
        id: 0,
        eventId: 0
    };

    const eventId = props['data-id'];
    const eventSponsorInfo = props['sponsor-data'];
    const [spinner, setSpinner] = useState(false);
    const [sponsorsData, setSponsorsData] = useState([]);
    const [sponsor, setSponsor] = useState(defaultSponsorObj);
    const [sponsorSelectValue, setSponsorSelectValue] = useState('');
    const [sponsorInputValue, setSponsorInputValue] = useState('');
    const [sponsorTierData, setSponsorTierData] = useState([]);
    const [tierSelectValue, setTierSelectValue] = useState('');
    const [selectedTierId, setSelectedTierId] = useState('');
    const [eventSponsorData, setEventSponsorData] = useState([]);
    const [registrants, setRegistrants] = useState<EventRegistrant[]>([]);
    const [selectedRegistrant, setSelectedRegistrant] = useState<EventRegistrant[]>([]);
    const [registrantInputValue, setRegistrantInputValue] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const [component, setComponent] = useState<'select' | 'create'>('create');

    const key = component === 'select' ? 'select' : 'create';

    const formSubmit = async (event):Promise<void> => 
    {
        event.preventDefault();

        if (sponsorSelectValue === '' || tierSelectValue === '') 
        {
            toast.error(sponsorSelectValue === '' ? 'Select a Sponsor' : 'Select a Sponsor Tier');
            return;
        }

        setSpinner(true);
        if (!eventSponsorInfo) 
        {
            const eventSponsorData = {
                status: sponsor.status,
                sponsorId: sponsor.id,
                eventId: eventId,
                sponsorTierId: selectedTierId,
                registrantIds: selectedRegistrant?.length > 0 ? selectedRegistrant?.map((registrant): string => registrant?.id as string) : null,
            };
            try 
            {
                await createEventSponsors(eventId, eventSponsorData, csrfTokenData);
                toast.success('Sponsor Added');
            }
            catch (error) 
            {
                console.log(error);
                toast.error('Error in updating sponsor details');
            }
            finally
            {
                props.setRefresh(true);
                handleDrawerClose();
            }

        }
        else 
        {
            const eventSponsorData = {
                status: 1,
                sponsorId: eventSponsorInfo.id,
                sponsorTierId: selectedTierId,
                registrantIds: selectedRegistrant?.length > 0 ? selectedRegistrant?.map((registrant): string => registrant?.id as string) : null,
            };
            if (selectedTierId !== eventSponsorInfo.sponsorTierId || !_.isEqual(selectedRegistrant, eventSponsorInfo?.registrants)) 
            {
                try 
                {
                    await updateEventSponsor(eventId, Number(eventSponsorInfo.id), eventSponsorData);
                    toast.success('Sponsor Details Updated');
                }
                catch (error) 
                {
                    console.log(error);
                    toast.error('Error in updating sponsor details');
                }
                finally
                {
                    props.setRefresh(true);
                    handleDrawerClose();
                }

            }
        }
    };

    const handleSponsorSelectChange = (event, newValue):void => 
    {
        setSponsorInputValue(newValue);
        setSponsorSelectValue(newValue);

        if (newValue === '') 
        {
            dispatch(resetSponsors());
        }
        const selectedSponsor = _.filter(sponsorsData, function (sponsor):boolean 
        {
            return newValue === sponsor.name;
        })[0];
        setSponsor(selectedSponsor);
        if (selectedSponsor) 
        {
            dispatch(addSponsors({
                name: selectedSponsor.name,
                pocName: selectedSponsor?.pocName ? selectedSponsor?.pocName : '',
                pocEmail: selectedSponsor?.pocEmail ? selectedSponsor?.pocEmail : '',
                websiteLink: selectedSponsor.websiteLink,
                logo: selectedSponsor.logo ? selectedSponsor.logo : '',
                social: selectedSponsor?.social,
            }));
        }
    };

    const handleTierSelectChange = (event, newValue):void => 
    {
        // setTierSelectValue(newValue);
        // const selectedSponsorTier = _.filter(sponsorTierData, function (tier) {
        //     return newValue === tier.name;
        // });
        // setSelectedTierId(selectedSponsorTier[0].id);

        if (newValue && newValue.includes('Add :')) 
        {
            const tierName = newValue.split('Add : ')[1];

            setTimeout(():void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    heading: 'Create New Sponsor Tier',
                    component: <CreateSponsorGroup setTierSelectValue={setTierSelectValue} dataId={eventId} setSponsorTierData={setSponsorTierData} groupData={''} tierName={tierName} setSelectedTierId={setSelectedTierId} setRefresh={props.setRefresh} />,
                    componentHeight: '352px',
                    componentWidth: '420px'
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true
                });
            });
        }
        else 
        {
            setTierSelectValue(newValue);

            const selectedSponsorTier = _.filter(sponsorTierData, function (tier):boolean 
            {
                return newValue === tier?.name;
            })[0];

            setSelectedTierId(selectedSponsorTier ? selectedSponsorTier?.id : '');
        }
    };

    const handleTierFilterOptions = (options, params):unknown[] => 
    {
        const filtered = filter(options, params);

        if (params.inputValue !== '') 
        {
            filtered.push('Add : ' + params.inputValue);
        }

        return filtered;
    };
    
    const handleEditSponsorTier = (): void => 
    {
        setTimeout((): void => 
        { 
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                heading: 'Edit Sponsor Tier',
                component: <CreateSponsorGroup selectedTierId={selectedTierId} setSponsorTierData={setSponsorTierData} setTierSelectValue={setTierSelectValue} dataId={eventId} tierName={tierSelectValue} setSelectedTierId={setSelectedTierId} isEdit  />,
                componentHeight: '352px',
                componentWidth: '420px'
            });
    
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        });
    };

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch('close-side-drawer', {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        dispatch(resetSponsors());
    };

    const fetchInitialRegistrantData = async ():Promise<void> => 
    {
        try 
        {
            const registrantData = await getAllEventRegistrants(eventId, 25, 0, EventRegistrantStatus.CONFIRMED);
            if(registrantData)
            {
                setRegistrants(registrantData);
            }

            if (eventSponsorInfo && eventSponsorInfo?.registrants?.length > 0)
            {
                const filteredRegistrants = _.filter(registrantData, function (registrant) {
                    return !eventSponsorInfo?.registrants?.some((element: EventRegistrant):boolean => element.id == registrant.id);
                });
    
                if (filteredRegistrants?.length <= 10)
                {
                    const registrantDataNextBatch = await getAllEventRegistrants(eventId, 25, 1, EventRegistrantStatus.CONFIRMED);
                    if(registrantDataNextBatch)
                    {
                        setRegistrants(registrantDataNextBatch);
                    }
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching registrants', error);
        }
    };

    const handleRegistrantSearch = (event: React.SyntheticEvent<Element, Event>, newValue: string):void => 
    {
        setRegistrantInputValue(newValue);

        if(newValue === '')
        {
            const mergedRegistrants = [...registrants];
            for (const selectedOption of selectedRegistrant) {
                if (!mergedRegistrants.some(registrant => registrant.id === selectedOption.id)) {
                    mergedRegistrants.push(selectedOption);
                }
            }
            setRegistrants(mergedRegistrants);
        }
        else
        {
            delayDebounceRegistrantSearch(newValue);
        }
    };

    const delayDebounceRegistrantSearch = debounce(async(newValue: string): Promise<void> =>
    {
        if (newValue?.length > 2) 
        {
            try 
            {

                const params = {
                    email: newValue,
                };

                const registrantData = await searchEventRegistrant(
                    eventId,
                    params?.email ? params.email : undefined,
                    undefined,
                    undefined,
                    EventRegistrantStatus.CONFIRMED,
                );

                if (registrantData) {
                    const mergedRegistrants = [...registrants];
                    for (const data of registrantData) {
                        if (!mergedRegistrants.some(registrant => registrant.id === data.id)) {
                            mergedRegistrants.push(data);
                        }
                    }
                    setRegistrants(mergedRegistrants);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    }, 300);

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        const fetchData = async ():Promise<void> => 
        {
            try 
            {
                const count = await sponsorsCount();
                const sponsors = await getAllSponsors(count, 0);
                const eventSponsorCount = await getEventSponsorsCount(eventId);
                const eventSponsors = await getEventSponsors(eventSponsorCount, 0, eventId);
                setEventSponsorData([...(_.map(eventSponsors, 'id'))]);
                setSponsorsData([...sponsors]);
                const tierCount = await sponsorTierCount(eventId);
                const tierData = await getAllSponsorTiers(tierCount, 0, eventId);
                setSponsorTierData([...tierData]);

                if (eventSponsorInfo) 
                {
                    dispatch(addSponsors({
                        name: eventSponsorInfo.name,
                        pocName: eventSponsorInfo.pocName ? eventSponsorInfo.pocName : '',
                        pocEmail: eventSponsorInfo.pocEmail ? eventSponsorInfo.pocEmail : '',
                        websiteLink: eventSponsorInfo.websiteLink,
                        logo: eventSponsorInfo.logo,
                        social: eventSponsorInfo?.social,
                    }));

                    const updatedRegistrants = eventSponsorInfo?.registrants?.map((registrant: EventRegistrant) => ({
                        ...registrant,
                        id: String(registrant.id),
                    })) || [];
                    setSelectedRegistrant(updatedRegistrants);
                    // setRegistrants(eventSponsorInfo?.registrants ? eventSponsorInfo?.registrants : [])
                    setSponsorSelectValue(eventSponsorInfo?.name);
                    setSponsorInputValue(eventSponsorInfo?.id);

                    const defaultSponsorTier = _.filter(tierData, function (elem):boolean 
                    {
                        return elem.id === eventSponsorInfo.sponsorTierId;
                    });
                    setTierSelectValue(defaultSponsorTier[0]?.name);
                    setSelectedTierId(defaultSponsorTier[0]?.id);
                    // setTierSelectInputValue(defaultSponsorTier[0]?.name);
                }
                else
                {
                    setTierSelectValue(tierData[0]?.name);
                    setSelectedTierId(tierData[0]?.id);
                }

            }
            catch (error) 
            {
                console.log(error);
            }

        };
        fetchData();

        if(props?.editTier && !currentpath?.includes('sponsors'))
        {
            setComponent('select');
        }
        else if (props?.select)
        {
            setComponent('select');
        }
        

        // if(!eventSponsorInfo)
        // {
            fetchInitialRegistrantData();
        // }
    }, []);

    const removeSponsor = async ():Promise<void> =>
    {
        try 
        {
            const sponsorDeleted = await removeEventSponsor(eventId as string, eventSponsorInfo?.id as string);
            if (sponsorDeleted) 
            {
                toast.success(`Sponsor ${eventSponsorInfo?.name} deleted successfully`);
                setShowDeletePopup(false);
                handleDrawerClose();
                props?.setRefresh(true);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error(`${error?.message}`);
        }
    };

    return (
        <Box id="createEventSponsor">

            {component === 'select' && <CloseIconComponent onClick={handleDrawerClose} />}

            {(!currentpath?.includes('sponsors') && !props?.editTier) && <ButtonGroup tabs={[
                {
                tabName: 'Create Sponsor',
                selectedTab: component === 'create',
                navigation: (): void => 
                    {
                        setComponent('create');
                        dispatch(resetSponsors());
                    }    
                },
                {
                    tabName: 'Select Sponsor',
                    selectedTab: component === 'select',
                    navigation: (): void => 
                    {
                        setComponent('select');
                        dispatch(resetSponsors());
                        // setSponsorSelectValue('');
                    }
                },
            ]} />}

            <Form noValidate onSubmit={formSubmit}>

                {/*  &&  */}
                {(!props?.editTier && component === 'select') &&
                    <Box padding={'24px 0 0'}>
                        <AutocompleteComponent 
                            value={sponsorSelectValue}
                            onChange={handleSponsorSelectChange}
                            optionsArr={_.compact(sponsorsData && sponsorsData?.length > 0 ? sponsorsData?.map((option) => 
                            {
                                if (!(eventSponsorData?.includes(option?.id))) 
                                {
                                    return option?.name;
                                }
                            }) : [])}
                            placeholder='Choose a sponsor'
                        />
                    </Box>
                }

                {
                    component === 'select' ?
                        <Box className="container-spacing">
                            <FormLabelComponent label="Add or Create s Sponsor Tier" required />
                            <Box className="sponsor-tier-dropdown-container">
                                <Box width={'100%'}>
                                    <AutocompleteComponentWithFilterOption value={tierSelectValue} defaultValue={tierSelectValue} onChange={handleTierSelectChange} keyToShow='name' optionsArr={sponsorTierData} filterOptions={handleTierFilterOptions} placeholder={sponsorTierData.length > 0 ? 'Add a Sponsor Tier' : 'Type something to create a Sponsor Tier'} />
                                </Box>
                                {tierSelectValue && tierSelectValue !== '' && <CustomButton btnType='secondary' name='Edit' style={{ maxHeight: '40px', height: '40px', fontSize: '12px' }} onClick={handleEditSponsorTier} />}
                            </Box>
                        </Box>
                        :
                        null
                }

                {(component === 'select' && sponsorSelectValue) && <div className="container-spacing">
                    <div className="sponsor-attendee-label">
                        <FormLabelComponent label='Sponsor Attendee' />
                        <Tooltip placement='top' arrow title={'Attendees who are confirmed can be added'}><FontAwesomeIcon icon={['fal', 'info-circle']} /></Tooltip>
                    </div>
                    <Autocomplete  
                        defaultValue={selectedRegistrant}
                        value={selectedRegistrant}
                        onChange={(event, newValue): void => {
                            setSelectedRegistrant([...newValue]);
                        }}
                        noOptionsText="No confirmed attendee"
                        inputValue={registrantInputValue}
                        popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                        onInputChange={handleRegistrantSearch}
                        renderTags={() => null}
                        multiple
                        options={registrants}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              {option.email}
                            </li>
                        )}
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value): boolean => option.email === value.email}
                        getOptionLabel={(option):string => option?.email}
                        className="registrant-select"
                        renderInput={(params):React.JSX.Element => <TextField placeholder='Search for an Attendee' className="sponsor-placeholder" {...params} variant="standard" />}
                        slotProps={{
                            paper: {
                                sx: {
                                    borderRadius: '8px',
                                    background: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: '4px',
                                    marginTop: '14px',
                                    zIndex: 3,
                                    width: '100%',
                                    padding: '4px',
                                    boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                                    '& .MuiAutocomplete-listbox': {
                                        width: '100% !important'
                                    },
                                    '& .MuiAutocomplete-input': {
                                        maxWidth: props?.maxWidth ? props?.maxWidth : '150px',
                                    },
                                    '& .MuiAutocomplete-option': {
                                        color: 'var(--colors-text-text-primary-900, #101828)',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        fontStyle: 'normal',
                                        lineHeight: '24px',
                                        borderRadius: '8px',
                                    },
                                    '& .MuiAutocomplete-option.Mui-focused': {
                                        color: 'var(--colors-text-text-primary-900, #101828)',
                                        borderRadius: 'var(--spacing-sm, 6px)',
                                        backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                    },
                                    '& .MuiAutocomplete-option[aria-selected=true]': {
                                        color: 'var(--colors-text-text-primary-900, #101828)',
                                        borderRadius: 'var(--spacing-sm, 6px)',
                                        backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    },
                                    '& .check-icon': {
                                        color: '#1570EF'
                                    }
                                }
                            }
                        }}
                    />

                    {<Box className="registrant-selected-container">
                            {
                                selectedRegistrant && selectedRegistrant?.length > 0 && selectedRegistrant?.map((element: EventRegistrant): React.JSX.Element => 
                                {
                                    const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                                    return (
                                        <MuiChip 
                                            chipColor={chipColors[element.id % chipColors.length]}
                                            label={<Stack direction={'row'} spacing={1} key={element.id} className="registrant-selected-label">
                                            {element?.firstName}
                                            <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                            {
                                                const filteredRegistrants = selectedRegistrant.filter((registrant): boolean => registrant.id !== element.id);
                                                setSelectedRegistrant([...filteredRegistrants]);
                                            }} />
                                        </Stack>}
                                        />
                                        
                                    );
                                })
                            }
                        </Box>
                    }

                    <Divider />
                </div>}

                {(component === 'select' && sponsorSelectValue) ? <Sponsor key={key} setRefresh={props?.setRefresh} disabled={true} bottomBtn={false} addEventSponsor={true} routeFromEventSponsor={props?.routeFromEventSponsor} data-id={eventId}/>
                :
                    (component === 'create') && <Sponsor key={key} setRefresh={props?.setRefresh} disabled={props?.editTier && !(component === 'create')} routeFromEventSponsor={props?.routeFromEventSponsor} addEventSponsor={true} data-id={eventId}/>
                }

                {component === 'select' && <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} name={'Cancel'} btnType='secondary' />
                        <CustomButton type="submit" btnType='primary' onClick={formSubmit} loading={spinner} name='Save' />
                    </Stack>
                </Box>}

                {((props?.editTier) && (!props?.isTableView)) &&  
                    <Box className="submit-btn-container">
                        <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Stack direction={'row'} spacing={1} display={'flex'}>
                                <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                            </Stack>
                            {!props?.isTableView && <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />}
                        </Stack>
                    </Box>
                }
            </Form>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={removeSponsor}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${eventSponsorInfo?.name}?`}
                    modalTitle='Delete Sponsor'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                />
            }
        </Box>
    );
};

export default AddSponsor;