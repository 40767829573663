import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { createEventCategory, deleteEventCategory, getAllEventCategories, updateEventCategory } from '../../scripts/apis/eventCategory';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import _ from 'lodash';
import { CONTENT } from '../../scripts/i18n';
import { useDispatch } from 'react-redux';
import { addEvent } from '../../redux/events/Events';
import { useSelector } from 'react-redux';
import { EventCategory } from '../../pages/Events/interfaces';
import { CustomButton } from '../../common/FormComponents/Buttons';

interface IEventCategoryProps { 
    setEventCategoryDetails: (eventCategoryDetails: EventCategory[]) => void;
    eventCategoryName: string;
    isEdit?: boolean;
    eventCategoryId?: string | number;
}

const CreateEventCategory: React.FC<IEventCategoryProps> = ({ setEventCategoryDetails, eventCategoryName, isEdit, eventCategoryId }): React.JSX.Element => 
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [validated, setValidated] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [groupName, setGroupName] = useState(eventCategoryName);
    const formSubmit = async (event): Promise<void> => 
    {
        setSpinner(true);
        event.preventDefault();
        setValidated(true);
        const eventCategoryData = {
            name: groupName,
        };

        if (isEdit) {

            if(eventCategoryName !== groupName)
            {
                try 
                {
                    const categoryUpdated = await updateEventCategory(Number(eventCategoryId), eventCategoryData);
                    if(categoryUpdated)
                    {
                        const eventCategoryData: EventCategory[] = await getAllEventCategories();
                        if (eventCategoryData) 
                        {
                            setEventCategoryDetails([...eventCategoryData]);
                            const group = _.find(eventCategoryData, ['id', String(categoryUpdated.id)]);
                            dispatch(addEvent({
                                eventCategory: group?.name ? group?.name : ''
                            }));
    
                            dispatch(addEvent({
                                eventCategoryId: group?.id ? group?.id : ''
                            }));
    
                            handleDialogClose();
                        }
                    }
                } 
                catch (error) 
                {
                    console.log(error)
                }
            }
        }
        else {
            try 
            {
                const categoryCreated: EventCategory = await createEventCategory(eventCategoryData, csrfTokenData);
                if (categoryCreated) 
                {
                    // eventBus.dispatch(APP_CONSTANTS.EVENTS.SOFT_RELOAD, true);
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.FORM_SUBMIT_CONFIRMATION, {
                        submit: true,
                        page: 'tickets',
                    });
                    eventBus.dispatch('dialog-submit', true);
                    const eventCategoryData: EventCategory[] = await getAllEventCategories();
                    if (eventCategoryData) 
                    {
                        setEventCategoryDetails([...eventCategoryData]);
                        const group = _.find(eventCategoryData, ['id', String(categoryCreated.id)]);
                        dispatch(addEvent({
                            eventCategory: group?.name ? group?.name : ''
                        }));

                        dispatch(addEvent({
                            eventCategoryId: group?.id ? group?.id : ''
                        }));

                        handleDialogClose();
                    }

                }
            }
            catch (error) 
            {
                console.log(error);
                handleDialogClose();
            }
        }
    };

    const handleDialogClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleDelete = async (): Promise<void> =>
    {
        setSpinner(true);
        try 
        {
            const categoryDeleted = await deleteEventCategory(eventCategoryId);
            if(categoryDeleted)
            {
                const eventCategoryData: EventCategory[] = await getAllEventCategories();
                if (eventCategoryData) 
                {
                    setEventCategoryDetails([...eventCategoryData]);
                    handleDialogClose();

                    dispatch(addEvent({
                        eventCategory: ''
                    }));

                    dispatch(addEvent({
                        eventCategoryId: ''
                    }));
                }
            }
        } 
        catch (error) 
        {
            console.log(error, 'Error in deleting event category');
        }
        finally
        {
            setSpinner(false);
        }
    };

    useEffect((): void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });
    }, []);

    return (
        <Box id="createEventCategory">
            {/* <Stack direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack> */}
            <Form noValidate validated={validated} onSubmit={formSubmit} autoComplete='off'>
                <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="form-label">{CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.CREATE_EVENT_CATEGORY.LABEL}</Typography><Typography className="required-icon">{'*'}</Typography></Box></Form.Label>
                <InputGroup className="input-group">
                    <Form.Control
                        className="rounded-end name"
                        type="text"
                        size="lg"
                        placeholder={CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.CREATE_EVENT_CATEGORY.PLACEHOLDER}
                        defaultValue={eventCategoryName ? eventCategoryName : ''}
                        name={'ticket-group'}
                        onChange={(event): void => 
                        {
                            setGroupName(event.target.value);
                        }}
                        required
                    />
                </InputGroup>
                <Box className="dialog-submit-button-container" marginTop={'127px'} justifyContent={'space-between'}>
                    {isEdit && <CustomButton disabled={spinner} btnType='danger' name='Delete' onClick={handleDelete} />}
                    <Box className="dialog-submit-inner-container">
                        <CustomButton btnType='secondary' name='Cancel' onClick={handleDialogClose} />
                        <CustomButton btnType='primary' name={isEdit ? 'Save' : 'Create'} disabled={spinner || (isEdit && eventCategoryName === groupName) || groupName === ''} onClick={formSubmit} type='submit' />
                    </Box>
                </Box>
            </Form>
        </Box>
    );
};

export default CreateEventCategory;