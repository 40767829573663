/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import eventBus from '../../../scripts/event-bus';
import { createRegistrationFormFields, updateRegistrationForm, updateRegistrationFormFields } from '../../../scripts/apis/registrationForm';
import APP_CONSTANTS from '../../../scripts/constants';
import { resetFormField } from '../../../redux/registrationsForms/FormComponentDisplayField';
import { useDispatch } from 'react-redux';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import LexicalEditor from '../../../lexical';
import { RegistrationForm } from '../interfaces/registration-form_interface';
import { CustomFieldDisabled } from '../enum/custom-field.enum';
import { validateEmptyHtml } from '../../../scripts/helpers';
import toast from 'react-hot-toast';
import { createLeadCaptureField, updateLeadCaptureField } from '../../../scripts/apis/sponsorPortal/sponsorPortal';

interface INumberInputSideDrawerProps {
    drawerTitle : string;
    formId: string | number;
    csrfTokenData: string;
    setRefresh: (refresh: boolean) => void;
    componentType: number;
    existingComponentData?: any;
    RegistrationFormData: RegistrationForm;
    leadCaptureFormId?: string | number;
}

const NumberInputSideDrawer = ({ drawerTitle, formId, csrfTokenData, setRefresh, componentType, existingComponentData, RegistrationFormData, leadCaptureFormId }: INumberInputSideDrawerProps): React.JSX.Element => 
{
    const dispatch = useDispatch();
    const [backBtnClicked, setBackBtnClicked] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const isExistingField = RegistrationFormData?.formFields?.some((customField) => customField.id === existingComponentData?.id);

    const validationSchema = Yup.object().shape({
        componentMandatory: Yup.number(),
        minValueEnabled: Yup.number(),
        maxValueEnabled: Yup.number(),
        minValue: Yup.number(),
        maxValue: Yup.number(),
        label: Yup.string(),
        labelHtmlText: Yup.string().required('Label is required'),
        helpText: Yup.string(),
        placeholder: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            componentMandatory: existingComponentData?.fieldMandatory ? existingComponentData.fieldMandatory : 2,
            minValueEnabled: existingComponentData?.fieldNumeric?.min ? 1 : 2,
            maxValueEnabled: existingComponentData?.fieldNumeric?.max ? 1 : 2,
            minValue: existingComponentData?.fieldNumeric?.min ? existingComponentData.fieldNumeric.min : '',
            maxValue: existingComponentData?.fieldNumeric?.max ? existingComponentData.fieldNumeric.max : '',
            label: existingComponentData?.label ? existingComponentData.label : '',
            labelHtmlText: existingComponentData?.label ? existingComponentData.label : '',
            helpText: existingComponentData?.helpText ? existingComponentData.helpText : '',
            placeholder: existingComponentData?.placeholder ? existingComponentData.placeholder : '',
        },
        onSubmit: async (values): Promise<void> => 
        {
            const componentDetails = {
                type: componentType,
                label: values.label === '<p><br></p>' ? null : values.label,
                default: existingComponentData?.default ? existingComponentData.default : null,
                placeholder: values.placeholder,
                helpText: values.helpText === '<p><br></p>' ? null : values.helpText,
                fieldMandatory: values.componentMandatory,
                fieldDisabled: CustomFieldDisabled.NOT_DISABLED,
                fieldNumeric: {
                    'default': '',
                    'min': values.minValueEnabled === 1 ? values.minValue : '',
                    'max': values.maxValueEnabled === 1 ? values.maxValue : ''
                },
                fieldLocations: existingComponentData?.fieldLocations?.length > 0 ? existingComponentData?.fieldLocations : [5],
                fieldName: existingComponentData?.fieldName ? existingComponentData.fieldName : values.label,
            };

            // lead capture form api handlers
            if(leadCaptureFormId)
            {
                return handleSubmissionLeadCaptureForms(componentDetails);
            }

            if (existingComponentData && isExistingField) 
            {
                setSpinner(true);
                updateFormComponent(componentDetails);
            }
            else if (!isExistingField && existingComponentData)
            {
                setSpinner(true);
                updateForm();
            }
            else
            {
                setSpinner(true);
                createFormComponent(componentDetails);
            }
        }
    });

    const createFormComponent = async (componentDetails): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await createRegistrationFormFields(Number(formId), componentDetails, csrfTokenData);
            if (componentUpdated) 
            {
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Component Successfully saved',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateFormComponent = async (componentDetails): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await updateRegistrationFormFields(Number(formId), existingComponentData.id , componentDetails);

            if (componentUpdated) 
            {
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Component successfully saved',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateForm = async (): Promise<void> =>
    {
        const fields = RegistrationFormData?.fields;
        fields.push(existingComponentData?.id);
        try 
        {
            const formUpdated = await updateRegistrationForm(Number(formId), {fields});
            if(formUpdated)
            {
                setSpinner(false);
                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                    open: true,
                    message: 'Component successfully saved',
                    severity: 'success',
                    positionVertical: 'top',
                    positionHorizontal: 'right',
                });
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleSubmissionLeadCaptureForms = async (data: any): Promise<void> =>
    {
        setSpinner(true);

        const createLeadCaptureFormField = async (): Promise<void> =>
        {
            try 
            {
                const fieldCreated = await createLeadCaptureField(leadCaptureFormId, data);
                if (fieldCreated) 
                {
                    setSpinner(false);
                    toast.success('Field created successfully');
                    setBackBtnClicked(true);
                    setRefresh(true);
                    eventBus.dispatch('event-update-form-component', true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in creating field');
            }
            finally
            {
                setSpinner(false);
            }
        };

        const updateLeadCaptureFormField = async (): Promise<void> =>
        {
            try 
            {
                const fieldUpdated = await updateLeadCaptureField(leadCaptureFormId, existingComponentData?.id, data);
                if (fieldUpdated) 
                {
                    setSpinner(false);
                    toast.success('Field updated successfully');
                    setBackBtnClicked(true);
                    setRefresh(true);
                    eventBus.dispatch('event-update-form-component', true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in updating field');
            }
            finally
            {
                setSpinner(false);
            }
        };

        if (existingComponentData && isExistingField) 
        {
            updateLeadCaptureFormField();
        }
        else
        {
            createLeadCaptureFormField();
        }
    };

    useEffect(():void => 
    {
        if(backBtnClicked)
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: 0
            });
            setBackBtnClicked(false);
            dispatch(resetFormField());
            eventBus.dispatch('event-update-form-component', true);
        }
       
    },[backBtnClicked]);

    
    return(
        <Box id='numberInputSideDrawer'>
            <Box padding={leadCaptureFormId ? '15px 24px' : '13px 24px'} className="drawer-header">
                <Box className="drawer-inner-cont" onClick={():void => 
                    {
                        setBackBtnClicked(true);
                            
                    }}>
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} className='back-icon' />
                    <Typography className='component-type-name'>{drawerTitle}</Typography>
                </Box>
            </Box>
            <Box className="drawer-content">
                <Box className="required-block">
                    <Typography className='form-label'>{'Required'}</Typography>
                    <FormControlLabel
                        checked={formik.values.componentMandatory === 1}
                        control={
                            <CustomSwitch
                                sx={{
                                    m: 1 
                                }}
                                name="required"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                {
                                    formik.setFieldValue('componentMandatory', event.target.checked ? 1 : 2);
                                }}
                            />}
                        label={null}
                        className='captcha-switch'
                    />
                </Box>
                <Box className="required-block">
                    <Typography className='form-label'>{'Min Value'}</Typography>
                    <FormControlLabel
                        checked={formik.values.minValueEnabled === 1}
                        control={
                            <CustomSwitch
                                sx={{
                                    m: 1 
                                }}
                                name="required"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                {
                                    formik.setFieldValue('minValueEnabled', event.target.checked ? 1 : 2);
                                }}
                            />}
                        label={null}
                        className='captcha-switch'
                    />
                </Box>
                {formik.values.minValueEnabled === 1 &&
                <FormControlComponent
                    required
                    type='number'
                    value={formik.values.minValue}
                    onChange={(event): void => 
                    {
                        formik.setFieldValue('minValue', event.target.value); 
                    }}
                    onKeyDown={(evt): void => 
                    {
                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                    }}
                    placeholder='0'
                    isInvalid={(formik.errors.minValue && formik.touched.minValue) as boolean}
                />
                 
                }
                <Box className="required-block">
                    <Typography className='form-label'>{'Max Value'}</Typography>
                    <FormControlLabel
                        checked={formik.values.maxValueEnabled === 1}
                        control={
                            <CustomSwitch
                                sx={{
                                    m: 1 
                                }}
                                name="required"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                                {
                                    formik.setFieldValue('maxValueEnabled', event.target.checked ? 1 : 2);
                                }}
                            />}
                        label={null}
                        className='captcha-switch'
                    />
                </Box>
                {formik.values.maxValueEnabled === 1 &&
                <FormControlComponent
                    required
                    type='number'
                    value={formik.values.maxValue}
                    onChange={(event): void => 
                    {
                        formik.setFieldValue('maxValue', event.target.value); 
                    }}
                    onKeyDown={(evt): void => 
                    {
                        return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); 
                    }}
                    placeholder='9999'
                    isInvalid={(formik.errors.maxValue && formik.touched.maxValue) as boolean}
                />
                 
                }
                <Box className="editor-block">
                    <LexicalEditor noDrag placeHolder='Label' toolBarOptions={['Bold', 'Italic', 'Underline']} minHeight='40px' maxHeight='96px' label='Label' required value={formik.values.label} handleChange={(html, json) => {
                        formik.setFieldValue('labelHtmlText', validateEmptyHtml(html));
                        
                        formik.setFieldValue('label', html);
                    }} />
                    {formik.touched.labelHtmlText && formik.errors.labelHtmlText && <div className="error-msg">{formik.errors.labelHtmlText}</div>}
                </Box>
                <Box className="editor-block">
                    <LexicalEditor noDrag placeHolder='Description' toolBarOptions={['Bold', 'Italic', 'Underline']} minHeight='96px' label='Description' value={formik.values.helpText} handleChange={(html, json) => {
                        formik.setFieldValue('helpText', html);
                    }} />
                </Box>
                <Box className="editor-block">
                    <FormLabelComponent label='Placeholder' />
                    <FormControlComponent
                        required
                        type='text'
                        value={formik.values.placeholder}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('placeholder', event.target.value);
                        }}
                        placeholder='Enter Placeholder Text'
                        isInvalid={false}
                    />
                </Box>
            </Box>
            <Box className="bottom-btn-block">
                <CustomButton btnType='primary' name='Save' loading={spinner} type='submit' onClick={() => formik.handleSubmit()} />
            </Box>
        </Box>
    );
};

export default NumberInputSideDrawer;