export const columnProperties = [
    {
      "field": "companyLogo",
      "headerName": "Logo",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "firstName",
      "headerName": "Name",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "email",
      "headerName": "Email",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "icp",
      "headerName": "ICP",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "buyerPersona",
      "headerName": "Buyer Persona",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "givenName",
      "headerName": "Given Name",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "familyName",
      "headerName": "Family Name",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "location",
      "headerName": "Location",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "linkedinHandle",
      "headerName": "Linkedin",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "phone",
      "headerName": "Phone",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "geo.city",
      "headerName": "City",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "geo.state",
      "headerName": "State",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "geo.country",
      "headerName": "Country",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "employment.title",
      "headerName": "title",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "employment.role",
      "headerName": "role",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "employment.subRole",
      "headerName": "subRole",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "employment.seniority",
      "headerName": "Seniority",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "category.subIndustry",
      "headerName": "Sub Industry",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "company",
      "headerName": "Company",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "companyDomain",
      "headerName": "Company Domain",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "companyGeo.country",
      "headerName": "Company Location",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "metrics.employees",
      "headerName": "Employees",
      "hide": true,
      "disableColumnMenu": true
    },
    {
      "field": "metrics.employeesRange",
      "headerName": "Employees Range",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "metrics.raised",
      "headerName": "Raised",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "metrics.annualRevenue",
      "headerName": "Annual Revenue",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "metrics.estimatedAnnualRevenue",
      "headerName": "Estimated Annual Revenue",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "companyTechCategories",
      "headerName": "Tech Categories",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "companyTech",
      "headerName": "Tech",
      "hide": false,
      "disableColumnMenu": true
    }
]