import React, { useEffect, useState } from 'react';
import { Alert, Box, Snackbar, Typography } from '@mui/material';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { CONTENT } from '../scripts/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Alerts = ():React.JSX.Element => 
{

    type Vertical = 'top' | 'bottom';
    type Horizontal = 'left' | 'center' | 'right';

    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState();
    const [positionVertical, setPositionVertical] = useState<Vertical>('top');
    const [positionHorizontal, setPositionHorizontal] = useState<Horizontal>('right');
    const [alert, setAlert] = useState(false);
    const [listData, setListData] = useState([]);

    const handleAlertClose = (event, reason): void => 
    {
        if (reason === 'clickaway') 
        {
            return;
        }
        setAlert(false);
    };

    useEffect((): void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.ALERT.OPEN, (data): void => 
        {
            setAlert(data.open);
            setMessage(data.message);
            setSeverity(data.severity);
            setPositionVertical(data.positionVertical);
            setPositionHorizontal(data.positionHorizontal);
            if (data.listData) 
            {
                setListData(data.listData);
            } 
            else 
            {
                setListData([]);
            }

        });
    }, []);

    return (
        <Box id="alerts">
            <Snackbar anchorOrigin={{
                vertical: positionVertical,
                horizontal: positionHorizontal
            }}
            open={alert}
            autoHideDuration={3000}
            onClose={handleAlertClose}
            >
                <Alert severity={severity} className="alert-container">
                    {listData.length > 0 ? listData.map((item, index: number): React.JSX.Element => 
                    { 
                        return ( 
                            <li key={index}> 
                                {
                                    CONTENT.REQUIRED_DETAILS_FOR_PUBLISHING_EVENT[item]
                                }
                            </li>
                        ); 
                    }) : null}
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'8px'}>
                        <Typography className="message">{message}</Typography>
                        <FontAwesomeIcon cursor={'pointer'} icon={['fal', 'xmark']} onClick={handleAlertClose} />
                    </Box>
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Alerts;