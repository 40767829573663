import { BudgetGraph } from '../../pages/Budget/interfaces';
import { EventBudget } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getBudgetCount = async (eventId: number):Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getBudgetFromEventId = async (eventId: number, pageSize: number, offset: number, status: number):Promise<EventBudget[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + eventId + '?&' + 'pageSize=' + pageSize + '&' + 'offset=' + offset + '&' + 'status=' + status;
    return triggerApi(API_URL, 'GET', '');
};

export const addBudgetByEventId = async (eventId: number, budgetData: object, csrfToken: string):Promise<EventBudget> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + eventId;
    return triggerApi(API_URL, 'POST', budgetData, csrfToken);
};

export const updateEventBudget = async (eventId: number, budgetId: number, budgetData: object):Promise<EventBudget> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + eventId + '/' + budgetId;
    return triggerApi(API_URL, 'PUT', budgetData);
};

export const getEventBudgetGraph = async (eventId: string): Promise<BudgetGraph> => 
{
    const query = convertJSONToGETParams({
        trend: true,
        expenditure: true,
        budgetVsActual: true,
        categoryBudget: true,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + eventId + '/graph?&' + query;
    return triggerApi(API_URL, 'GET', '');
};

// export const updateEventSubBudget = async (budgetId: number, subBudgetId: number, budgetData: object):Promise<EventSubBudget> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + 'sub/' + budgetId + '/' + subBudgetId;
//     return triggerApi(API_URL, 'PUT', budgetData);
// };

// export const addSubBudgetByBudgetId = async (budgetId: number, budgetData: object, csrfToken: string):Promise<EventSubBudget> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/sub/' + budgetId;
//     return triggerApi(API_URL, 'POST', budgetData, csrfToken);
// };

export const deleteEventBudget = async (eventId: number, budgetId: number):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/' + eventId + '/' + budgetId;
    return triggerApi(API_URL, 'DELETE', '');
};

// export const deleteEventSubBudget = async (budgetId: number, subBbudgetId: number):Promise<boolean> => 
// {
//     const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-budget/sub/' + budgetId + '/' + subBbudgetId;
//     return triggerApi(API_URL, 'DELETE', '');
// };