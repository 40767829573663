import { useEffect, useMemo, useState } from "react";
import eventBus from "../../../../scripts/event-bus";
import SponsorPortalDatagrid from "../../Components/Common/SponsorPortalDatagrid";
import { generateColumnsForSponsorPortalTables } from "../../helpers/sponsor-portal.helper";
import FormTabs from "../../../../common/FormTabs";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllTargetList, removeTargetsFromTargetList } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { columnProperties } from "./columnProperties";
import toast from "react-hot-toast";
import { GRID_CHECKBOX_SELECTION_FIELD, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import _ from "lodash";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";

const TargetListPage: React.FC = (): React.JSX.Element =>
{
    const [targetRows, setTargetRows] = useState<any[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedTargets, setSelectedTargets] = useState<string[] | number[]>([]);
    const [removeTargetListSpinner, setRemoveTargetListSpinner] = useState<boolean>(false);
    const [columnProperty, setColumnProperty] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState<'all' | 'myList'>('all');
    const [refresh, setRefresh] = useState<boolean>(false);

    const columns = generateColumnsForSponsorPortalTables(columnProperty?.filter((item) => item?.field !== 'email'), setAnchorEl, anchorEl, true);

    const removeFromTargetList = async (): Promise<void> =>
    {
        setRemoveTargetListSpinner(true);
        try 
        {
            const initialTargets = targetRows;
            const initialTargetIds = initialTargets?.map((target: any) => target.id);
            
            const updatedTargetListIds = initialTargetIds.filter(id => !selectedTargets.includes(id));

            const targetListUpdated = await removeTargetsFromTargetList( { registrantIds: selectedTargets?.length > 0 ? selectedTargets : null } );
            if(targetListUpdated)
            {
                toast.success('Removed from Target list');
                eventBus.dispatch('selected-row-ids', []);
                setRefresh(true);
            }
        } 
        catch (error) 
        {
            toast.error('Failed to remove from Target list');
        }
        finally
        {
            setRemoveTargetListSpinner(false);
        }
    };

    const TargetListToolbar = () => 
    {
        const tabs = [
            {
                tabName: 'All',
                selectedTab: selectedTab === 'all',
                count: targetRows?.length,
                navigation: () => 
                { 
                    setSelectedTab('all');
                    // fetchSelectedTabData();
                }
            },
            // {
            //     tabName: 'My List',
            //     selectedTab: selectedTab === 'myList',
            //     count: myListCount,
            //     navigation: () => 
            //     { 
            //         setSelectedTab('myList');
            //         fetchSelectedTabData(true);
            //     }
            // }
        ];
    
        return (
            <div className="target-list-toolbar">
                <FormTabs tabs={tabs} />
                <GridToolbarFilterButton className="datagrid-buttons" />
                {selectedTargets?.length > 0 && 
                    <div className="button-container">
                        <CustomButton name='Cancel' btnType="secondary" onClick={() => { eventBus.dispatch('selected-row-ids', []) }} />
                        <CustomButton name='Remove from Target List' btnType="danger" loading={removeTargetListSpinner} onClick={() => removeFromTargetList() } startIcon={<FontAwesomeIcon icon={['fal', 'trash-can']} />} />
                    </div>
                }
            </div>
        )
    };

    const fetchSelectedTabData = async (myList?: boolean): Promise<void> =>
    {
        try 
        {
            const targets = await getAllTargetList();

            if(targets)
            {
                setIsEmpty(targets.length === 0);
                setTargetRows(targets);
            }
        } 
        catch (error) 
        {
            
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const allTargets = await getAllTargetList();

            // const myList = await getAllTargetList(true);

            if(allTargets)
            {
                setIsEmpty(allTargets.length === 0);
                setTargetRows(allTargets);

                const initialColumnKeys = ['firstName', 'lastName'];
                const initialColumns = initialColumnKeys.map((field) => ({
                    field,
                    headerName: _.startCase(field),
                    disableColumnMenu: true,
                }));

                if (allTargets?.length > 0 && allTargets.some(obj => 'additionalData' in obj)) {
                const additionalData = allTargets.map(obj => _.omit(obj.additionalData, 'gdprData'));
                const additionalDataKeys = Object.keys(additionalData[0]);
                const additionalDataColumns = additionalDataKeys.map((field) => ({
                    field,
                    headerName: _.startCase(field),
                    disableColumnMenu: true,
                }));
                setColumnProperty([...initialColumns, ...additionalDataColumns, ...columnProperties?.filter((item) => item?.field !== 'firstName' && item?.field !== 'lastName')]);
                }
            }
            
            // if(myList)
            // {
            //     setMyListCount(myList.length);
            //     setIsEmpty(myList.length === 0);
            // }
        } 
        catch (error) 
        {
            
        }
        finally
        {
            setShowSpinner(false);
        }
    }; 

    useEffect(() => {
        fetchData();

        eventBus.on('selected-row-ids', (ids: string[] | number[]) =>
        {
          setSelectedTargets(ids);
        })
    }, []);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <div id="targetListPage">
            <div style={{ height: 'calc(100vh - 120px)', maxHeight: 'calc(100vh - 120px)' }}>
                {!isEmpty ? <SponsorPortalDatagrid 
                    rows={targetRows}
                    columns={columns}
                    isEmpty={isEmpty}
                    // pageSize={targetRows?.length} 
                    // page={currentPage - 1}
                    // updateCurrentPage={updateCurrentPage} 
                    // updatePageSize={updatePageSize}
                    showSpinner={showSpinner}
                    isPagination={false}
                    hidePagination
                    checkBoxSelection
                    rowCount={targetRows?.length}
                    pinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'companyLogo', 'firstName', 'lastName', 'icp', 'buyerPersona']}
                    customToolbar={TargetListToolbar}
                /> : <TableEmptyComponent infoText={'No Targets available'}/>}
            </div>
        </div>
    );
};

export default TargetListPage;