import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, TextField, Stack, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
// import { isEmpty } from 'lodash';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Spinner } from 'react-bootstrap';
import { CONTENT } from '../../scripts/i18n';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { goalsRequiredValues } from '../../pages/Events/EventDetails/Overview/Goals/GoalsTable';
import APP_CONSTANTS from '../../scripts/constants';
import eventBus from '../../scripts/event-bus';
import { createEventgoals, deleteEventsgoals, updateEventgoals } from '../../scripts/apis/eventsgoals';
import _ from 'lodash';
import { CustomButton } from '../../common/FormComponents/Buttons';

const GoalsDrawerSection = ({ eventId, goalsData, setRefresh }): React.JSX.Element => 
{

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const options = ['Total Registrations', 'Attendee', 'Pipeline Generated', 'Revenue Recognised'];
    const [selectedOptions, setSelectedOptions] = useState([

    ]);
    const [spinner, setSpinner] = useState(false);
    const [totalWeightage, setTotalWeightage] = useState(null);
    
    useEffect((): void => 
    {
        if (goalsData.length > 0) 
        {
            const totalWeightageValue = goalsData.reduce((total, item): number => 
            {
                return total + item.weightage;
            }, 0);

            const optionsSelected = goalsData.map((item): string => 
            {
                return CONTENT.EVENTS_MODULE.GOALS.FORMS.GOAL_TYPES[item.type];
            });

            setSelectedOptions(optionsSelected);

            setTotalWeightage(totalWeightageValue);
        }
    }, [goalsData]);

    const [goalsValues, setGoalsValues] = useState(goalsData ? goalsData : []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => 
    {
        const { name, value } = event.target;
        setGoalsValues((prevData): goalsRequiredValues[] => 
        {
            return prevData.map((option, i): goalsRequiredValues => 
            {
                return (i === index ? {
                    ...option, [name]: value
                } : option);
            });
        });
    };

    const onRemoveClick = (index: number): void => 
    {
        const updatedGoalTypes = goalsValues.filter((_, i): boolean => 
        {
            return i !== index;

        });

        setGoalsValues(updatedGoalTypes);
        const removeOption = [...selectedOptions];
        removeOption.splice(index, 1);
        setSelectedOptions(removeOption);
    };

    const handleGoalsRemoval = (event: React.SyntheticEvent, index: number): void => 
    {
        const list = [...goalsValues];

        list.splice(index, 1);
        setGoalsValues(list);

        const removeOption = [...selectedOptions];
        removeOption.splice(index, 1);
        setSelectedOptions(removeOption);
    };

    const handleOptionSelect = (option): void => 
    {
        if (option.length > 0) 
        {
            option.forEach((item): void => 
            {
                if (selectedOptions.includes(item)) 
                {
                    return;
                }
                else 
                {
                    setGoalsValues([...goalsValues, {
                        type: CONTENT.EVENTS_MODULE.GOALS.FORMS.TYPES[option[option.length - 1]],
                        weightage: null,
                        target: ''
                    }]);
                }
            });
        }
        else 
        {
            setGoalsValues([]);
        }
        setSelectedOptions(option);
    };

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleSubmit = async (): Promise<void> => 
    {
        if (goalsValues.length > 0) 
        {
            const weightageValues = goalsValues.map((item): number => 
            {
                if (item.weightage) 
                {
                    return Number(item.weightage);
                }
            });

            if (weightageValues.length > 0) 
            {
                let total = 0;
                weightageValues.forEach((item): void => 
                {
                    total += item;
                });
                setTotalWeightage(total);

                if (total == 100) 
                {

                    if (goalsData.length > 0) 
                    {
                        try 
                        {

                            const updatedGoalsValues = _.map(goalsValues, (obj) => _.omit(obj, ['actual']));

                            const payload = {
                                goals: updatedGoalsValues
                            };
                            const goalsUpdated = await updateEventgoals(eventId, payload);
                            if (goalsUpdated) 
                            {
                                setRefresh(true);
                                handleDrawerClose();
                                setSpinner(false);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Goals updated successfully',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                            setSpinner(false);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: _.startCase(error.message.toLowerCase()),
                                severity: 'error',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                        }
                    }
                    else 
                    {
                        const payload = {
                            goals: goalsValues
                        };
                        try 
                        {
                            const goalsCreated = await createEventgoals(eventId, payload, csrfTokenData);
                            if (goalsCreated) 
                            {
                                setRefresh(true);
                                handleDrawerClose();
                                setSpinner(false);
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                    open: true,
                                    message: 'Goals created successfully',
                                    severity: 'success',
                                    positionVertical: 'top',
                                    positionHorizontal: 'right',
                                });
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                            setSpinner(false);
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                                open: true,
                                message: _.startCase(error.message.toLowerCase()),
                                severity: 'error',
                                positionVertical: 'top',
                                positionHorizontal: 'right',
                            });
                        }

                    }

                }
            }
        }
        else 
        {
            if (goalsValues.length === 0) 
            {
                try 
                {
                    const goalsUpdated = await deleteEventsgoals(eventId);
                    if (goalsUpdated) 
                    {
                        setRefresh(true);
                        handleDrawerClose();
                        setSpinner(false);
                        eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                            open: true,
                            message: 'Goals updated successfully',
                            severity: 'success',
                            positionVertical: 'top',
                            positionHorizontal: 'right',
                        });
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setSpinner(false);
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: _.startCase(error.message.toLowerCase()),
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'right',
                    });
                }
            }

        }
    };


    const formSubmit = (event): void => 
    {
        event.preventDefault();
        handleSubmit();
    };

    return (
        <Box id='createGoals'>
            <CloseIconComponent onClick={handleDrawerClose} />

            <Form noValidate={false} autoComplete="off" onSubmit={(event): void => 
            {
                return formSubmit(event);
            }}>
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label='Select Goal' required />
                    <Autocomplete
                        value={selectedOptions}
                        options={options}
                        getOptionDisabled={(option): boolean => 
                        {
                            return selectedOptions.includes(option);
                        }}
                        className="autocomplete-select"
                        multiple
                        renderTags={(): null => 
                        {
                            return null;
                        }}
                        onChange={(event, value): void => 
                        {
                            return handleOptionSelect(value);
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    borderRadius: '8px',
                                    background: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    gap: '4px',
                                    marginTop: '14px',
                                    zIndex: 3,
                                    width: '100%',
                                    padding: '4px',
                                    boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                                    '& .MuiAutocomplete-listbox': {
                                        width: '100% !important',
                                    },
                                    '& .MuiAutocomplete-input': {
                                        maxWidth: '150px',
                                    },
                                    '& .MuiAutocomplete-option': {
                                        color: '#525866',
                                        backgroundColor: '#fff',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        lineHeight: '20px',
                                        borderRadius: '4px',
                                    },
                                    '& .MuiAutocomplete-option.Mui-focused': {
                                        backgroundColor: '#F6F8FA',
                                        color: '#161922',
                                        borderRadius: '4px',
                                    },
                                    '& .MuiAutocomplete-option[aria-selected=true]': {
                                        color: '#375DFB',
                                        background: '#EBF1FF',
                                        borderRadius: '4px',
                                    },
                                    '& .MuiAutocomplete-option[aria-disabled=true]': {
                                        opacity: 'unset !important'
                                    },
                                }
                            }
                        }}
                        renderInput={(params): React.ReactElement => 
                        {
                            return (
                                <TextField
                                    {...params}
                                    className="selector-field"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps, sx: {
                                            borderRadius: '10px', height: '3rem', marginBottom: '0px'
                                        }, endAdornment: (
                                            <React.Fragment>
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            );
                        }}
                    />
                </Box>
                <Box className="goals-box-container">
                    {
                        selectedOptions && selectedOptions.length > 0 && selectedOptions.map((element, index): React.JSX.Element => 
                        {
                            return (
                                <Stack direction={'row'} spacing={1} className="autocomplete-selected-goalselement" key={index}>
                                    <Typography className='goals-element'>{element}</Typography>
                                    <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                    {
                                        onRemoveClick(index);
                                    }} />
                                </Stack>
                            );
                        })
                    }
                </Box>

                {goalsValues.map((option, index): React.ReactElement => 
                {
                    return (
                        <Box className="select-goal-type" key={index}>
                            <Box className='goals-card-header'>
                                <Typography className="select-goal-type-name">
                                    {CONTENT.EVENTS_MODULE.GOALS.FORMS.GOAL_TYPES[option.type]}
                                </Typography>
                                <FontAwesomeIcon className="goals-remove-icon" onClick={(event): void => 
                                {
                                    handleGoalsRemoval(event, index);
                                }} icon={['fal', 'circle-minus']} />
                            </Box>
                            <Box style={{
                                gap: '8px',
                                padding: '8px',
                            }}>
                                <Box className='card-body'>
                                    <div>
                                        <FormLabelComponent label='Weightage' required />
                                        <FormControlComponent name="weightage" placeholder="30" onChange={(event): void => 
                                        {
                                            handleInputChange(event, index);
                                        }} value={option.weightage} type='text' isInvalid={(totalWeightage && totalWeightage >= 0 ? totalWeightage !== 100 : false)} required />
                                    </div>

                                    <div>
                                        <FormLabelComponent label='Target' required />
                                        <FormControlComponent name="target" placeholder="30000" onChange={(event): void => 
                                        {
                                            handleInputChange(event, index);
                                        }} value={option.target}
                                        asInputGroup={['Pipeline Generated', 'Revenue Recognised'].includes(CONTENT.EVENTS_MODULE.GOALS.FORMS.GOAL_TYPES[option.type])?true:null}
                                        inputGroupText={['Pipeline Generated', 'Revenue Recognised'].includes(CONTENT.EVENTS_MODULE.GOALS.FORMS.GOAL_TYPES[option.type])?'$':null}
                                        type='text'
                                        required
                                        />
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}

                {(totalWeightage == 100 || totalWeightage == null || goalsValues?.length === 0) ? null : (
                    // <Typography className="error-msg">{'Invalid Weightage'}</Typography>
                    <Box className="error-msg">
                        {selectedOptions.length > 1 ? 'Sum of weightages should be 100' : 'Weightage should be equal to 100'}
                    </Box> 
                )}

                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' type='button' onClick={handleDrawerClose} />
                        <CustomButton name='Save' loading={spinner} btnType='primary' type='submit' />
                    </Stack>
                </Box>
            </Form>
        </Box>
    );
};
export default GoalsDrawerSection;